import { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { ICONS } from "assets";
import styles from "./styles.module.scss";
import cls from "classnames";
import toast from "react-hot-toast";

const MAX_VIDEO_SIZE = 500 * 1048576; // 500MB in bytes

interface IProps {
  videoSize?: string;
  videoName?: string;
  error?: string;
  containerStyle?: React.CSSProperties;
  progressUpload?: number;
  handleDropVideo: (video: File) => void;
  onRemoveVideo: () => void;
}

const VideoDropZone: FC<IProps> = ({
  videoSize,
  videoName,
  error,
  containerStyle,
  progressUpload,
  handleDropVideo,
  onRemoveVideo,
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (!!file) {
      if (file.size > MAX_VIDEO_SIZE) {
        return toast.error("The maximum allowed size of video is 500MB");
      }

      return handleDropVideo(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "video/mp4": [".mp4"],
      "video/x-m4v": [".m4v"],
      "video/quicktime": [".mov"],
      "video/x-msvideo": [".avi"],
      "video/x-ms-wmv": [".wmv"],
      "video/x-flv": [".flv"],
      "video/x-matroska": [".mkv"],
      "video/webm": [".webm"],
      "video/ogg": [".ogv", ".ogg"],
    },
  });

  const onClickRemoveButton = (e: any) => {
    e.stopPropagation();
    onRemoveVideo();
  };

  return (
    <div style={containerStyle}>
      <div
        className={cls(
          styles.dropZone,
          !!error && styles.error,
          videoName && styles.focused
        )}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {progressUpload ? (
          <>
            <div className={styles.progress_text}>
              Please wait, saving video: {progressUpload}%
            </div>
            <div className={styles.progress}>
              <div
                className={styles.progress_bar}
                style={{ width: `${progressUpload}%` }}
              ></div>
            </div>
          </>
        ) : videoName ? (
          <div className={styles.video} onClick={onClickRemoveButton}>
            <div className={styles.video_icon}>
              <ICONS.Video />
            </div>

            <div style={{ flex: 1 }}>
              <p className={styles.video_name}>{videoName}</p>
              <p className={styles.video_size}>{videoSize}</p>
            </div>

            <ICONS.Delete />
          </div>
        ) : (
          <div>
            <div className={styles.dropZone_attach}>
              <ICONS.AttachFile style={{ width: 16, height: 16 }} />
              <p className={styles.dropZone_attach_text}>Attach video file</p>
            </div>
            <p className={styles.dropZone_placeholder}>Max file size 500 MB</p>
          </div>
        )}
      </div>
      {!!error && <p className={styles.dropZone_error}>{error}</p>}
    </div>
  );
};

export default VideoDropZone;
