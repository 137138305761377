import { FC, useState } from "react";
import moment, { Moment } from "moment";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar, TableHead } from "components";
import Section from "./Section";
import { Order } from "types/enums";

const MonthlySummary: FC = () => {
  const [date, setDate] = useState<Moment>(moment());

  const renderPayments = (isMore: boolean = true) => {
    return Array.from(Array(5).keys()).map((_, index) => (
      <div key={index} className={styles.item}>
        <h5 className={styles.item_title}>
          Wedding photo shoot on the beach with our family
        </h5>
        <div className={styles.item_user}>
          <div className={styles.item_user_avatar}>
            <Avatar dimensions={{ width: 32, height: 32 }} />
          </div>
          <div className={styles.item_user_name}>Jaxson Calzoni</div>
        </div>

        {isMore && <div className={styles.item_hours}>3:30</div>}

        <div className={styles.item_amount}>$350.50</div>
      </div>
    ));
  };

  return (
    <div>
      <header className={styles.wrapper_header}>
        <div className={styles.wrapper_header_period}>
          <div
            className={cls(
              styles.wrapper_header_period_arrow,
              styles.wrapper_header_period_arrow_rotate
            )}
            onClick={() => setDate(moment(date).subtract(1, "month"))}
          >
            <ICONS.CalendarArrow />
          </div>

          <div className={styles.wrapper_header_period_info}>
            <h3 className={styles.wrapper_header_period_info_title}>
              {date.format("MMMM YYYY")}
            </h3>
            <p className={styles.wrapper_header_period_info_total}>
              Monthly Total
            </p>
            <p className={styles.wrapper_header_period_info_value}>$3,101.00</p>
          </div>

          <div
            className={styles.wrapper_header_period_arrow}
            onClick={() => setDate(moment(date).add(1, "month"))}
          >
            <ICONS.CalendarArrow />
          </div>
        </div>
      </header>

      <div className={styles.wrapper_table}>
        <Section title="Hourly" amount={400} width={14.28}>
          <TableHead
            items={[
              { title: "Contract", disableSort: true },
              { title: "Client", disableSort: true },
              { title: "Hours", disableSort: true },
              { title: "Amount", disableSort: true },
            ]}
            flexes={[3, 2, 1, 1]}
            sorted={{ sortedBy: "", type: Order.ASC }}
            onChangeSort={() => {}}
          />
          {renderPayments()}
        </Section>
      </div>

      <Section title="Fixed-price" amount={400} width={16.66}>
        <TableHead
          items={[
            { title: "Contract", disableSort: true },
            { title: "Client", disableSort: true },
            { title: "Amount", disableSort: true },
          ]}
          flexes={[3, 2, 1]}
          sorted={{ sortedBy: "", type: Order.ASC }}
          onChangeSort={() => {}}
        />
        {renderPayments(false)}
      </Section>
    </div>
  );
};

export default MonthlySummary;
