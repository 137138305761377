import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { Avatar, RentedBlock, ScheduleCalendar } from "components";
import styles from "./styles.module.scss";
import { useLazyQuery } from "@apollo/client";
import ScheduleEquipmentModal from "../ScheduleStudioModal";
import { StudiosQueries } from "graphql/queries";
import { IRental } from "types";

interface IProps {
  studioId?: number;
}

interface IStudioRental {
  endsAt: string;
  startsAt: string;
  studioId: number;
  rental: IRental;
}

const StudioCalendar: FC<IProps> = ({ studioId }) => {
  const [currentDate, setCurrentDate] = useState<string>(
    moment().format("DD-MM-YYYY")
  );
  const [currentHours, setCurrentHours] = useState<string[]>([]);
  const [rangeDate, setRangeDate] = useState<string[]>([]);
  const [selectedStudiosRental, setSelectedStudiosRental] = useState<
    IStudioRental[]
  >([]);
  const [isOpen, setOpen] = useState(false);

  const [fetchEquipmentByDate, { data, refetch }] = useLazyQuery<{
    findStudioRentalsByDates: IStudioRental[];
  }>(StudiosQueries.GET_RENTAL_STUDIO_BY_DATES, {
    variables: {
      input: {
        studioId,
        from: moment(rangeDate[0]),
        to: moment(rangeDate[1]),
      },
    },
    onCompleted(data) {
      refetch();

      const foundRental = data.findStudioRentalsByDates.filter(
        (rentalStudio) => {
          return (
            moment(rentalStudio.startsAt).format("DD-MM-YYYY") ===
              currentDate ||
            moment(rentalStudio.endsAt).format("DD-MM-YYYY") === currentDate
          );
        }
      );

      setSelectedStudiosRental(foundRental);
    },
  });

  useEffect(() => {
    if (studioId) fetchEquipmentByDate();
  }, [rangeDate, currentDate, studioId]);

  const generateFreeHours = () => {
    const freeHours = [];
    const startHour = 9;
    const endHour = 18;

    for (let hour = startHour; hour < endHour; hour++) {
      freeHours.push({
        startsAt: moment(currentDate, "DD-MM-YYYY").hour(hour).toISOString(),
        endsAt: moment(currentDate, "DD-MM-YYYY")
          .hour(hour + 1)
          .toISOString(),
      });
    }

    return freeHours;
  };

  const freeHours = generateFreeHours();

  const onClickRentedBlock = (hours: string[]) => {
    if (moment().isAfter(hours[0])) return;

    setCurrentHours(hours);

    setOpen(true);
  };

  return (
    <div className={styles.wrapper}>
      <ScheduleCalendar
        currentDate={currentDate}
        onSelectDay={(date: string) => setCurrentDate(date)}
        setRangeDate={setRangeDate}
        rentedDates={data?.findStudioRentalsByDates}
      />

      <div className={styles.rented_block}>
        {freeHours.map((it) => {
          const selectedRental = selectedStudiosRental.find((rental) => {
            return (
              rental.startsAt === it.startsAt && rental.endsAt === it.endsAt
            );
          });

          return (
            <RentedBlock
              key={it.startsAt}
              disabled={moment().isAfter(it.startsAt)}
              selectedRental={selectedRental?.rental}
              onOpenModal={() => {
                if (!selectedRental)
                  onClickRentedBlock([it.startsAt, it.endsAt]);
              }}
              content={`${moment(it.startsAt).format("HH:mm")} - ${moment(
                it.endsAt
              ).format("HH:mm")}`}
            />
          );
        })}
      </div>

      <ScheduleEquipmentModal
        selectedHours={currentHours}
        studioId={studioId}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        refetch={refetch}
      />
    </div>
  );
};

export default StudioCalendar;
