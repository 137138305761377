import { FC, useRef, useState, useMemo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styles from "./styles.module.scss";

import { CategoriesQueries } from "graphql/queries";

import { ICONS } from "assets";

import { Filter as FilterComponents } from "components";

import { useOutsideClick } from "hooks";

import { ICategory, IProfessionalFilter } from "types/interfaces";
import { RatePeriod, UserAccount } from "types/enums";

type Props = {
  filter: IProfessionalFilter;
  onChange: (value: IProfessionalFilter) => void;
};

const Filter: FC<Props> = ({ filter: initialFilter, onChange }) => {
  const [filter, setFilter] = useState<IProfessionalFilter>(initialFilter);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const divRef = useRef(null);
  useOutsideClick({
    ref: divRef,
    onClickOutside: () => setIsOpenFilter(false),
  });

  useEffect(() => {
    if (!isOpenFilter) {
      setFilter(initialFilter);
    }
  }, [isOpenFilter, initialFilter]);

  const { data, loading } = useQuery<{ categories: Array<ICategory> }>(
    CategoriesQueries.GET_CATEGORIES
  );

  const handleApplyFilter = () => {
    onChange(filter);
    setIsOpenFilter(false);
  };

  const handleClearFilter = () => {
    onChange({
      rating: null,
      rateAmount: null,
      category: [],
      account: null,
      ratePeriods: [],
      blocked: null,
    });
    setIsOpenFilter(false);
  };

  const categoriesOptions = useMemo(() => {
    if (!data) return [];

    return data.categories.map((c) => ({ label: c.title, value: c.id }));
  }, [data]);

  return (
    <div ref={divRef} className={styles.wrapper}>
      <div
        className={styles.wrapper_icon}
        onClick={() => setIsOpenFilter(!isOpenFilter)}
      >
        <ICONS.Filter />
      </div>

      {isOpenFilter && (
        <div className={styles.wrapper_filter}>
          <FilterComponents.Wrapper
            onClear={handleClearFilter}
            onApply={handleApplyFilter}
          >
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.Rating
                value={filter.rating}
                onChange={(val) => setFilter({ ...filter, rating: val })}
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.NumberRange
                from={filter.rateAmount ? filter.rateAmount.from : null}
                to={filter.rateAmount ? filter.rateAmount.to : null}
                onChange={(from, to) =>
                  setFilter({ ...filter, rateAmount: { from, to } })
                }
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.List
                items={[
                  { label: "All", value: null },
                  { label: "Agency", value: UserAccount.Agency },
                  { label: "Freelancer", value: UserAccount.Freelancer },
                ]}
                title="Type"
                value={filter.account}
                onChange={(val) => setFilter({ ...filter, account: val })}
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.CheckboxList
                loading={loading}
                items={categoriesOptions}
                title="Category"
                value={filter.category}
                onChange={(val) => setFilter({ ...filter, category: val })}
              />
            </div>
            {/* <div className={styles.wrapper_filter_item}>
              <FilterComponents.List
                items={[
                  { label: "All", value: "All" },
                  { label: "Active", value: "Active" },
                  { label: "Inactive", value: "Inactive" },
                ]}
                title="Status"
                value={status}
                onChange={setStatus}
              />
            </div> */}
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.CheckboxList
                items={[
                  { label: "Hourly", value: RatePeriod.Hourly },
                  { label: "Per session", value: RatePeriod.Session },
                  { label: "Per day", value: RatePeriod.Day },
                ]}
                title="Work Type"
                value={filter.ratePeriods}
                onChange={(val) => setFilter({ ...filter, ratePeriods: val })}
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.Checkbox
                label="User blocked"
                title="Blocked"
                value={!!filter.blocked}
                onChange={(val) => setFilter({ ...filter, blocked: val })}
              />
            </div>
          </FilterComponents.Wrapper>
        </div>
      )}
    </div>
  );
};

export default Filter;
