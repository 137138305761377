import { TokensService } from "utils";
import { io, Socket } from "socket.io-client";

class SocketService {
  private socket: Socket | null;
  private listeners: { event: string; callback: Function }[];

  constructor() {
    this.socket = null;
    this.listeners = [];
  }

  async connect() {
    const token = TokensService.getTokens();
    this.socket = io("wss://api.mosawer.com/chat", {
      reconnectionDelayMax: 10000,
      extraHeaders: {
        //@ts-ignore
        authorization: token,
      },
    });
    this.setupEventListeners();
  }

  disconnect(clearSetup: boolean = false): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;

      if (clearSetup) {
        this.listeners = [];
      }
    }
  }

  addEventListener<T>(
    event: string,
    callback: (data: T) => void,
    notSave?: boolean
  ): void {
    const listener = { event, callback };

    if (!notSave) {
      this.listeners.push(listener);
    }

    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  removeEventListener<T>(event: string, callback: (data: T) => void): void {
    const listenerIndex = this.listeners.findIndex(
      (listener) => listener.event === event && listener.callback === callback
    );
    if (listenerIndex !== -1) {
      this.listeners.splice(listenerIndex, 1);
    }
    if (this.socket) {
      this.socket.off(event, callback);
    }
  }

  emitEvent<T>(event: string, data: T): void {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  isSocketConnected(): boolean {
    return this.socket !== null && this.socket.connected;
  }

  private setupEventListeners(): void {
    if (this.socket) {
      for (const listener of this.listeners) {
        // @ts-ignore
        this.socket.on(listener.event, listener.callback);
      }
    }
  }
}

const socketService = new SocketService();
export default socketService;
