import { gql } from "@apollo/client";

export const GET_CHAT_MESSAGE = gql`
  query chatMessages($input: ChatMessagesInput!) {
    chatMessages(input: $input) {
      id
      type
      body
      attachment
      attachmentProps
      unread
      unreadBy
      userId
      adminId
      createdAt
      offerAction
      offerId
      admin {
        id
        firstName
        lastName
      }
      user {
        id
        firstName
        lastName
        avatar
      }
      offer {
        id
        type
        payAmount
        payCurrency
        payType
        declineBy
        declined
        declineReason
        job {
          headline
          asap
          location {
            id
            full_title
            short_title
            latitude
            longitude
          }
          date
          hours
          length
        }
      }
    }
  }
`;
