import { FC } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";

import { ICONS } from "assets";
import { Avatar, Categories, JobTags } from "components";
import { IContract } from "types/interfaces";
import moment from "moment";
import { UserService } from "utils";
import { ProfileType } from "types/enums";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "50%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

type Props = {
  payload: IContract | false;
  showPhotographer?: boolean;
  onClose: () => void;
};

const ContractModal: FC<Props> = ({
  payload,
  showPhotographer = true,
  onClose,
}) => {
  if (!payload) return null;

  const contact = showPhotographer ? payload.photographer : payload.client;

  return (
    <Modal
      isOpen={!!payload}
      //@ts-ignore
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>Contract</h4>
          <div className={styles.wrapper_header_icon} onClick={onClose}>
            <ICONS.Cross />
          </div>
        </header>

        <div className={styles.wrapper_info}>
          <div className={styles.wrapper_info_avatar}>
            <Avatar img={contact.avatar} />
          </div>

          <div>
            <p className={styles.wrapper_info_name}>
              {contact.firstName} {contact.lastName}
            </p>
            <p className={styles.wrapper_info_location}>
              {contact.profile?.location?.short_title}
            </p>
          </div>
        </div>

        <h2 className={styles.wrapper_title}>{payload.job.headline}</h2>

        <div className={styles.wrapper_tags}>
          <div>
            <JobTags job={payload.job} />
          </div>
          <div className={styles.wrapper_tags_date}>
            {moment(payload.startAt).format("MMM DD, YYYY")}
          </div>
        </div>

        <div className={styles.wrapper_project}>
          <p className={styles.wrapper_project_label}>Project Budget</p>
          <p className={styles.wrapper_project_amount}>
            {UserService.getBudgetAmount(payload)}
          </p>
          <p className={styles.wrapper_project_description}>
            {payload.job.description}
          </p>

          <Categories categoris={[payload.job.category]} />
        </div>

        <div className={styles.wrapper_feedbacks}>
          {payload.feedbacks.length === 0 ? (
            <>
              <h4 className={styles.wrapper_feedbacks_title}>Feedback</h4>

              <p className={styles.wrapper_feedbacks_nolabel}>
                No feedback yet
              </p>
            </>
          ) : (
            <div>
              {payload.feedbacks.map((f) => (
                <div key={f.id} className={styles.wrapper_feedbacks_item}>
                  <h4 className={styles.wrapper_feedbacks_title}>
                    {f.creator.profile?.type === ProfileType.Client
                      ? "Client’s Feedback"
                      : "Photographer’s Feedback"}
                  </h4>
                  <div className={styles.wrapper_feedbacks_item_rating}>
                    <div className={styles.wrapper_feedbacks_item_rating_icon}>
                      <ICONS.Star />
                    </div>
                    <p className={styles.wrapper_feedbacks_item_rating_text}>
                      {UserService.addZeroToRate(f.avgRating)}
                    </p>
                  </div>

                  {f.text && (
                    <p className={styles.wrapper_feedbacks_item_message}>
                      {f.text}
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ContractModal;
