import { gql } from "@apollo/client";

export const GET_USER = gql`
  query user($id: Int!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      avatar
      blocked
      completedContracts
      profile {
        account
        photos
        gender
        type
        id
        location {
          id
          full_title
          short_title
          latitude
          longitude
        }
        rating
        rateAmount
        rateCurrency
        ratePeriod
        jobs
        contracts
        completed
        spent
        description
        categories {
          id
          title
          title_en
          title_ar
        }
      }
      createdAt
    }
  }
`;

export const GET_CLIENTS = gql`
  query adminClients($input: AdminClientsInput!) {
    adminClients(input: $input) {
      data {
        id
        firstName
        lastName
        avatar
        blocked
        profile {
          id
          location {
            id
            full_title
            short_title
            latitude
            longitude
          }
          jobs
          contracts
          completed
          rating
          spent
        }
        createdAt
      }
      meta {
        itemCount
      }
    }
  }
`;

export const GET_PROFESSIONALS = gql`
  query adminProfessionals($input: AdminProfessionalsInput!) {
    adminProfessionals(input: $input) {
      data {
        id
        firstName
        lastName
        avatar
        blocked
        profile {
          id
          location {
            id
            full_title
            short_title
            latitude
            longitude
          }
          ratePeriod
          rateAmount
          rateCurrency
          jobs
          contracts
          completed
          rating
          spent
        }
        createdAt
      }
      meta {
        itemCount
      }
    }
  }
`;

export const CLIENT_PROFILE = gql`
  query clientProfile($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      avatar
      blocked
      profile {
        id
        location {
          id
          full_title
          short_title
          latitude
          longitude
        }
        jobs
        contracts
        completed
        rating
        spent
      }
      createdAt
    }
    adminClientJobs(id: $id, input: { skip: 0, take: 1 }) {
      meta {
        itemCount
      }
    }
    active: adminClientContracts(
      id: $id
      input: { skip: 0, take: 1, ended: false }
    ) {
      meta {
        itemCount
      }
    }
    ended: adminClientContracts(
      id: $id
      input: { skip: 0, take: 1, ended: true }
    ) {
      meta {
        itemCount
      }
    }
  }
`;

export const PROFESSIONAL_PROFILE = gql`
  query professionalProfile($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      avatar
      blocked
      profile {
        id
        location {
          id
          full_title
          short_title
          latitude
          longitude
        }
        ratePeriod
        rateAmount
        rateCurrency
        jobs
        contracts
        completed
        rating
        spent
        fee
      }
      createdAt
    }
    active: adminPhotographerContracts(
      input: { skip: 0, take: 1, ended: false }
      id: $id
    ) {
      data {
        id
      }
      meta {
        itemCount
      }
    }
    completed: adminPhotographerContracts(
      input: { skip: 0, take: 1, ended: true }
      id: $id
    ) {
      data {
        id
      }
      meta {
        itemCount
      }
    }
  }
`;

export const GET_CLIENT_ACTIVE_CONTRACTS = gql`
  query adminClientContracts(
    $adminClientContractsId: Int!
    $input: FindContractsForAdminInput!
  ) {
    adminClientContracts(id: $adminClientContractsId, input: $input) {
      data {
        id
        payCurrency
        payType
        payAmount
        startAt
        endAt
        feedbacks {
          id
          creatorId
          subjectId
          text
          avgRating
          creator {
            profile {
              id
              type
            }
          }
        }
        job {
          headline
          asap
          date
          description
          category {
            id
            title
          }
          location {
            short_title
          }
        }
        photographer {
          id
          firstName
          lastName
          avatar
          profile {
            id
            type
            location {
              short_title
            }
          }
        }
      }
      meta {
        itemCount
      }
    }
  }
`;

export const GET_CLIENT_POSTINGS = gql`
  query adminClientJobs($id: Int!, $input: PaginationAbstract!) {
    adminClientJobs(id: $id, input: $input) {
      data {
        id
        headline
        asap
        date
        length
        _count {
          JobCandidate
        }
        location {
          short_title
        }
        category {
          title
          id
        }
      }
      meta {
        itemCount
      }
    }
  }
`;

export const GET_PROFESSIONAL_CONTRACTS = gql`
  query adminPhotographerContracts(
    $adminPhotographerContractsId: Int!
    $input: FindContractsForAdminInput!
  ) {
    adminPhotographerContracts(
      id: $adminPhotographerContractsId
      input: $input
    ) {
      data {
        id
        payCurrency
        payType
        payAmount
        startAt
        endAt
        feedbacks {
          id
          creatorId
          subjectId
          text
          avgRating
          creator {
            id
            profile {
              id
              type
            }
          }
        }
        job {
          headline
          asap
          date
          description
          category {
            id
            title
          }
          location {
            short_title
          }
        }
        photographer {
          id
          firstName
          lastName
          avatar
          profile {
            id
            type
            location {
              short_title
            }
            fee
          }
        }
        client {
          id
          firstName
          lastName
          avatar
          profile {
            id
            type
            location {
              short_title
            }
            fee
          }
        }
      }
      meta {
        itemCount
      }
    }
  }
`;

export const GET_PROFESSIONAL_JOB_COUNTER = gql`
  query adminPhotographerJobs($id: Int!) {
    proposals: adminPhotographerJobs(
      input: {
        skip: 0
        take: 1
        type: Proposal
        status: Submitted
        userId: $id
      }
    ) {
      data {
        id
      }
      meta {
        itemCount
      }
    }
    invitations: adminPhotographerJobs(
      input: {
        skip: 0
        take: 1
        type: Invitation
        status: Submitted
        userId: $id
      }
    ) {
      data {
        id
      }
      meta {
        itemCount
      }
    }
    interviews: adminPhotographerJobs(
      input: { skip: 0, take: 1, status: Active, userId: $id }
    ) {
      data {
        id
      }
      meta {
        itemCount
      }
    }
  }
`;

export const GET_UNVERIFIED_USERS = gql`
  query users($input: FindUnverifiedUsersInput!) {
    findUnverifiedUsers(input: $input) {
      count
      users {
        id
        email
        name
        avatar
        profileType
        profileAccount
        location
        createdAt
      }
    }
  }
`;

export const GET_ALLOW_CREATED_COURSE = gql`
  query FindUserPrivileges($input: FindUserPrivilegesInput!) {
    findUserPrivileges(input: $input) {
      isExemptFromRequiredCourseCreationRules
    }
  }
`;
