import axios from "axios";

export const uploadFileToStorage = async (
  file: File,
  url: string,
  onProgress?: (progress: number) => void
) => {
  try {
    let blob = await fetch(URL.createObjectURL(file)).then((r) => r.blob());

    const response = await axios.put(url, blob, {
      headers: {
        "Content-Type": "application/octet-stream",
        "Access-Control-Allow-Origin": "*",
      },
      onUploadProgress: (progressEvent) => {
        const total = progressEvent.total;
        const current = progressEvent.loaded;

        if (!total || !onProgress) return;

        const percentCompleted = Math.round((current / total) * 100);
        onProgress(percentCompleted);
      },
    });

    if (response.status !== 200) {
      throw Error("Save file error");
    }

    return true;
  } catch (error) {
    throw error;
  }
};
