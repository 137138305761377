import { gql } from "@apollo/client";

export const GET_BANNERS = gql`
  query FindBanners($input: FindBannersInput!) {
    findBanners(input: $input) {
      banners {
        id
        title
        image
        redirection {
          target
          targetTitle
          targetId
        }
        status
        publisher
        targetAudience
        order
        createdAt
        promotionDetail {
          id
          bannerId
          promoterId
          promotionEndDate
        }
      }
    }
  }
`;

export const GET_BANNER_DETAIL = gql`
  query findBannerById($input: FindBannerByIdInput!) {
    findBannerById(input: $input) {
      id
      title
      image
      redirection {
        target
        targetId
        targetTitle
      }
      status
      publisher
      targetAudience
      order
      createdAt
      promotionDetail {
        id
        bannerId
        promoterId
        promotionEndDate
      }
    }
  }
`;
