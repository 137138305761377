import { FC, useRef } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import cls from "classnames";
import styles from "./styles.module.scss";

import { Avatar, Button, Input } from "components";
import { ICONS } from "assets";

import { ICategory, IQuestion } from "types/interfaces";
import { QuestionType, VariantButton } from "types/enums";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "40%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
    zIndex: 2000,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 2000,
  },
};

type Props = {
  item: ICategory;
  onClose: () => void;
  onCreate: (newManager: any) => void;
};

const parseAnswers = (question?: IQuestion[]) => {
  const initialAnswers = [
    { en: "", ar: "" },
    { en: "", ar: "" },
  ];
  if (!question || question?.length === 0) return initialAnswers;

  const quest = question[0];
  if (!quest.answers || quest.answers.length === 0) return initialAnswers;

  return quest.answers.map((a) => JSON.parse(a));
};

const validate = (values: any) => {
  const errors: any = {};

  if (!values.cover) {
    errors.cover = "Cover is required";
  }

  if (values.title_en.trim() === "") {
    errors.title_en = "Is required";
  }
  if (values.title_ar.trim() === "") {
    errors.title_ar = "Is required";
  }

  if (values.questionType !== "None") {
    if (values.question.title_en.trim() === "") {
      errors.question = { title_en: "Is required" };
    }

    if (values.question.title_ar.trim() === "") {
      if (!errors.question) {
        errors.question = { title_ar: "Is required" };
      } else {
        errors.question.title_ar = "Is required";
      }
    }

    if (values.questionType === QuestionType.Checkbox) {
      values.question.answers.forEach((a: any, index: number) => {
        if (a.en.trim() === "" || a.ar.trim() === "") {
          if (!errors.question) {
            errors.question = { answers: [] };
          }
          if (!errors.question.answers) {
            errors.question.answers = [];
          }

          errors.question.answers[index] = {};
        }

        if (a.en.trim() === "") {
          errors.question.answers[index].en = "Is required";
        }
        if (a.ar.trim() === "") {
          errors.question.answers[index].ar = "Is required";
        }
      });
    }
  }

  return errors;
};

const UpdateCreateModal: FC<Props> = ({ item, onClose, onCreate }) => {
  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      id: item.id,
      cover: item.cover || null,
      title_en: item.title_en || "",
      title_ar: item.title_ar || "",
      questionType:
        !item.question || item.question?.length === 0
          ? "None"
          : item.question[0].type,
      question: {
        id: item.question && item.question[0] ? item.question[0].id : undefined,
        title_en:
          item.question && item.question?.length > 0
            ? item.question[0].title_en
            : "",
        title_ar:
          item.question && item.question?.length > 0
            ? item.question[0].title_ar
            : "",

        answers: parseAnswers(item.question),
      },
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate,
    onSubmit: handleCreateUpdate,
  });
  const inputFileRef = useRef(null);

  function handleCreateUpdate() {
    onCreate({
      ...values,
      title_en: values.title_en.trimEnd(),
      title_ar: values.title_ar.trimEnd(),
      question: {
        ...values.question,
        title_en: values.question.title_en.trimEnd(),
        title_ar: values.question.title_ar.trimEnd(),
        answers: values.question.answers.map((question: IQuestion) => ({
          ...question,
          title_en: question.title_en.trimEnd(),
          title_ar: question.title_ar.trimEnd(),
        })),
      },
    });
  }

  const handleChangeAvatar = (e: any) => {
    setFieldValue("cover", e.target.files[0]);
  };

  const handleDeleteAnswer = (index: number) => {
    const newAnswers = [...values.question.answers];
    newAnswers.splice(index, 1);

    setFieldValue("question.answers", newAnswers);
  };

  const onChangeTitle = (key: string, value: string) => {
    const hasConsecutiveSpaces = / {2,}/.test(value);

    if (hasConsecutiveSpaces) return;

    setFieldValue(key, value.trimStart());
  };

  return (
    <Modal
      isOpen={!!item}
      //@ts-ignore
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>
            {values.id ? "Edit Category" : "New Category"}
          </h4>
          <div className={styles.wrapper_header_icon} onClick={onClose}>
            <ICONS.Cross />
          </div>
        </header>

        <div className={styles.wrapper_avatar}>
          <div className={styles.wrapper_avatar_wrapper}>
            <Avatar
              img={values.cover}
              dimensions={{ width: 100, height: 100 }}
            />
          </div>
          <p
            className={styles.wrapper_avatar_title}
            //@ts-ignore
            onClick={() => inputFileRef?.current?.click()}
          >
            Change Avatar
          </p>
        </div>

        <div className={styles.wrapper_input}>
          <Input
            label="Category name (english)"
            value={values.title_en}
            error={errors.title_en}
            onChange={(val) => onChangeTitle("title_en", val)}
          />
        </div>

        <div className={styles.wrapper_input}>
          <Input
            label="Category name (arabic)"
            value={values.title_ar}
            error={errors.title_ar}
            onChange={(val) => onChangeTitle("title_ar", val)}
          />
        </div>

        <div className={styles.wrapper_question}>
          <h4 className={styles.wrapper_question_title}>Set question</h4>

          <div className={styles.wrapper_question_type}>
            <div
              className={cls(styles.wrapper_question_type_item, {
                [styles.wrapper_question_type_item_active]:
                  values.questionType === "None",
              })}
              onClick={() => setFieldValue("questionType", "None")}
            >
              <p>None</p>
            </div>
            <div
              className={cls(styles.wrapper_question_type_item, {
                [styles.wrapper_question_type_item_active]:
                  values.questionType === QuestionType.Checkbox,
              })}
              onClick={() =>
                setFieldValue("questionType", QuestionType.Checkbox)
              }
            >
              <p>Checkbox</p>
            </div>
            <div
              className={cls(styles.wrapper_question_type_item, {
                [styles.wrapper_question_type_item_active]:
                  values.questionType === QuestionType.Counter,
              })}
              onClick={() =>
                setFieldValue("questionType", QuestionType.Counter)
              }
            >
              <p>Counter</p>
            </div>
          </div>
        </div>

        {values.questionType !== "None" && (
          <div>
            <div className={styles.wrapper_input}>
              <Input
                label="Question name (english)"
                value={values.question.title_en}
                error={errors.question?.title_en}
                onChange={(val) => onChangeTitle("question.title_en", val)}
              />
            </div>

            <div className={styles.wrapper_input}>
              <Input
                label="Question name (arabic)"
                value={values.question.title_ar}
                error={errors.question?.title_ar}
                onChange={(val) => onChangeTitle("question.title_ar", val)}
              />
            </div>

            {values.questionType === QuestionType.Checkbox && (
              <div className={styles.wrapper_question_answers}>
                <h4 className={styles.wrapper_question_answers_titile}>
                  Answers
                </h4>

                {values.question.answers.map((a, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.wrapper_question_answers_item}
                    >
                      <header
                        className={styles.wrapper_question_answers_item_header}
                      >
                        <h5
                          className={
                            styles.wrapper_question_answers_item_header_title
                          }
                        >
                          #{index + 1}
                        </h5>

                        {values.question.answers.length > 2 && (
                          <div
                            className={
                              styles.wrapper_question_answers_item_header_delete
                            }
                            onClick={() => handleDeleteAnswer(index)}
                          >
                            <ICONS.Delete />
                          </div>
                        )}
                      </header>

                      <div className={styles.wrapper_input}>
                        <Input
                          label="Answer (english)"
                          value={a.en}
                          error={
                            errors?.question?.answers &&
                            errors?.question?.answers[index] &&
                            //@ts-ignore
                            errors?.question?.answers[index].en
                          }
                          onChange={(val) => {
                            onChangeTitle(`question.answers[${index}].en`, val);
                          }}
                        />
                      </div>

                      <div className={styles.wrapper_input}>
                        <Input
                          label="Answer (arabic)"
                          value={a.ar}
                          error={
                            errors?.question?.answers &&
                            errors?.question?.answers[index] &&
                            //@ts-ignore
                            errors?.question?.answers[index].ar
                          }
                          onChange={(val) =>
                            onChangeTitle(`question.answers[${index}].ar`, val)
                          }
                        />
                      </div>
                    </div>
                  );
                })}

                {values.question.answers.length < 4 && (
                  <Button
                    title="Add Anwser"
                    onClick={() => {
                      setFieldValue("question.answers", [
                        ...values.question.answers,
                        { en: "", ar: "" },
                      ]);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}

        <div className={styles.wrapper_button}>
          {values.id && (
            <div className={styles.wrapper_button_item_delete}>
              <Button
                title="Delete"
                variant={VariantButton.Red}
                onClick={handleSubmit}
              />
            </div>
          )}
          <div className={styles.wrapper_button_item}>
            <Button title={values.id ? "Edit" : "New"} onClick={handleSubmit} />
          </div>
        </div>
      </div>

      <input
        type="file"
        ref={inputFileRef}
        accept="image/jpeg, image/png"
        style={{ display: "none" }}
        onChange={handleChangeAvatar}
      />
    </Modal>
  );
};

export default UpdateCreateModal;
