import { gql } from "@apollo/client";

export const DELETE_EQUIPMENT = gql`
  mutation deleteEquipment($input: DeleteEquipmentInput!) {
    deleteEquipment(input: $input) {
      success
    }
  }
`;

export const CREATE_EQUIPMENT = gql`
  mutation createEquipment($input: CreateEquipmentInput!) {
    createEquipment(input: $input) {
      id
    }
  }
`;

export const UPDATE_EQUIPMENT = gql`
  mutation updateEquipment($input: UpdateEquipmentInput!) {
    updateEquipment(input: $input) {
      id
    }
  }
`;

export const DEACTIVATE_EQUIPMENT = gql`
  mutation deactivateEquipment($input: DeactivateEquipmentInput!) {
    deactivateEquipment(input: $input) {
      success
    }
  }
`;

export const ACTIVATE_EQUIPMENT = gql`
  mutation activateEquipment($input: ActivateEquipmentInput!) {
    activateEquipment(input: $input) {
      success
    }
  }
`;

export const CREATE_EQUIPMENT_RENTAL = gql`
  mutation createEquipmentRental($input: CreateEquipmentRentalInput!) {
    createEquipmentRental(input: $input) {
      id
    }
  }
`;
