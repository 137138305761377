import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      title
      title_en
      title_ar
      cover
      question {
        id
        title
        title_ar
        title_en
        answers
        max
        min
        step
        type
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      title
      title_en
      title_ar
      cover
      question {
        id
        title
        title_ar
        title_en
        answers
        max
        min
        step
        type
      }
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation createQuestion($input: CreateQuestionInput!) {
    createQuestion(input: $input) {
      id
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation updateQuestion($input: UpdateQuestionInput!) {
    updateQuestion(input: $input) {
      id
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation removeQuestion($id: Int!) {
    removeQuestion(id: $id) {
      id
    }
  }
`;
