import { FC, useState } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

type Props = {
  value: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  isSucure?: boolean;
  placeholder?: string;
  maxLength?: number;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  onChange: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
};

const Textarea: FC<Props> = ({
  value,
  placeholder,
  label,
  maxLength,
  isSucure = false,
  disabled = false,
  error,
  containerStyle,
  style,
  onChange,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(!isSucure);

  const handleChange = (value: string) => {
    const isHaveTwoEmptyLines = /\n\s*\n\s*\n/.test(value);

    const hasConsecutiveSpaces = / {2,}/.test(value);

    const isHaveStartSpaceOnSomeLine = /^ /m.test(value);

    if (
      isHaveTwoEmptyLines ||
      isHaveStartSpaceOnSomeLine ||
      hasConsecutiveSpaces
    )
      return;

    onChange(value.trimStart());
  };

  return (
    <div className={styles.wrapper} style={containerStyle}>
      {label ? <p className={styles.wrapper_label}>{label}</p> : null}

      <div className={styles.wrapper_input_wrapper}>
        <textarea
          value={value}
          placeholder={placeholder}
          className={cls(styles.wrapper_input, {
            [styles.wrapper_input_focused]: isFocused,
          })}
          style={style}
          disabled={disabled}
          autoComplete="new-password"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          maxLength={maxLength}
          onChange={(e) => handleChange(e.target.value)}
          {...rest}
        />

        {isSucure && (
          <div
            className={styles.wrapper_input_secure}
            onClick={() => setShowPassword(!showPassword)}
          >
            <p>{showPassword ? "Hide" : "Show"}</p>
          </div>
        )}
      </div>

      {error && <p className={styles.wrapper_error}>{error}</p>}
    </div>
  );
};

export default Textarea;
