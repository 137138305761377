import Sidebar from "components/Sidebar";
import {
  Login,
  Dashboard,
  ClientItem,
  DisputItem,
  JobPosting,
  ProfessionalItem,
  ProposalItem,
} from "pages";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const routesConfig = [
  {
    path: "statistics",
    element: <Dashboard.Statistics />,
  },
  {
    path: "clients",
    element: <Dashboard.Clients />,
  },
  {
    path: "professionals",
    element: <Dashboard.Professionals />,
  },
  {
    path: "professionals/:id",
    element: <ProfessionalItem />,
  },
  {
    path: "reports",
    element: <Dashboard.Reports />,
  },
  {
    path: "staff",
    element: <Dashboard.StaffManagment />,
  },
  {
    path: "categories",
    element: <Dashboard.Categories />,
  },
  {
    path: "equipments",
    element: <Dashboard.Equipments />,
  },
  {
    path: "studios",
    element: <Dashboard.Studios />,
  },
  {
    path: "studios/:id",
    element: <Dashboard.StudioDetail />,
  },
  {
    path: "equipments/:id",
    element: <Dashboard.EquipmentDetail />,
  },
  {
    path: "banners",
    element: <Dashboard.Banners />,
  },
  {
    path: "banners/:id",
    element: <Dashboard.BannerDetail />,
  },
  {
    path: "reports/:reportId",
    element: <DisputItem />,
  },
  {
    path: "clients/:clientId",
    element: <ClientItem />,
  },
  {
    path: "clients/:clientId/jobs/:jobId",
    element: <JobPosting />,
  },
  {
    path: "clients/:clientId/jobs/:jobId/proposal/:proposalId",
    element: <ProposalItem />,
  },
  {
    path: "courses",
    element: <Dashboard.Courses />,
  },
  {
    path: "courses/:id",
    element: <Dashboard.CoursesDetailPage />,
  },
  {
    path: "verification",
    element: <Dashboard.Verification />,
  },
];

const Navigation = () => {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: (
        <Sidebar>
          <Dashboard.Main />
        </Sidebar>
      ),
      children: routesConfig,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Navigation;
