import { FC, useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { BeatLoader } from "react-spinners";
import styles from "./styles.module.scss";

import { UserQueries } from "graphql/queries";

import { EmptyMessage, Tabs } from "components";
import List from "./List";
import InfoModal from "./InfoModal";

import { IJobCandidate, IPaginationMeta } from "types/interfaces";
import { JobCandidateStatus, JobCandidateType } from "types/enums";

type Props = {
  id: number;
};

const Jobs: FC<Props> = ({ id }) => {
  const [selectedCandidate, setSelectedCandidate] = useState<
    false | IJobCandidate
  >(false);
  const [currentTab, setCurrentTab] = useState<number>(0);

  const { data, loading, error } = useQuery<{
    proposals: {
      meta: IPaginationMeta;
    };
    invitations: {
      meta: IPaginationMeta;
    };
    interviews: {
      meta: IPaginationMeta;
    };
  }>(UserQueries.GET_PROFESSIONAL_JOB_COUNTER, {
    variables: { id },
  });

  const requestData = useMemo(() => {
    if (currentTab === 0)
      return {
        type: JobCandidateType.Proposal,
        status: JobCandidateStatus.Submitted,
      };

    if (currentTab === 1)
      return {
        type: JobCandidateType.Invitation,
        status: JobCandidateStatus.Submitted,
      };

    return {
      status: JobCandidateStatus.Active,
    };
  }, [currentTab]);

  const renderTab = () => {
    switch (currentTab) {
      case 0:
        return (
          <List
            id={id}
            emptyTitle="No proposals yet"
            onPress={(candidate) => setSelectedCandidate(candidate)}
            requestData={requestData}
          />
        );
      case 1:
        return (
          <List
            id={id}
            emptyTitle="No invitations yet"
            onPress={(candidate) => setSelectedCandidate(candidate)}
            requestData={requestData}
          />
        );
      default:
        return (
          <List
            id={id}
            emptyTitle="No interviews yet"
            onPress={(candidate) => setSelectedCandidate(candidate)}
            requestData={requestData}
          />
        );
    }
  };

  const tabs = useMemo(() => {
    if (!data) return [];

    return [
      { title: "Proposals", count: data.proposals.meta.itemCount },
      { title: "Invitations", count: data.invitations.meta.itemCount },
      { title: "Interviews", count: data.interviews.meta.itemCount },
    ];
  }, [data]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <BeatLoader color="black" />
      </div>
    );
  }

  if (error) {
    return <EmptyMessage title={error.message} />;
  }

  if (!data) return null;

  return (
    <div>
      <div className={styles.wrapper_tabs}>
        <Tabs tabs={tabs} currentIndex={currentTab} onChange={setCurrentTab} />
      </div>
      {renderTab()}

      <InfoModal
        candidate={selectedCandidate}
        onClose={() => setSelectedCandidate(false)}
      />
    </div>
  );
};

export default Jobs;
