import React, { FC, useEffect } from "react";
import { Outlet, NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from "./Main.module.scss";

import { ICONS } from "assets";

import ProfileBlock from "./ProfileBlock";

import SocketService from "utils/socketService";
import { useUser } from "hooks";
import { ProfileType, Roles } from "types";
import { useQuery } from "@apollo/client";
import { GET_UNVERIFIED_USERS } from "graphql/queries/user";

type RoleConfig = {
  [key in Roles]: { path: string; label: string; icon: React.ReactElement }[];
};

const roleConfig: RoleConfig = {
  [Roles.Admin]: [
    { path: "/clients", label: "Clients", icon: <ICONS.nav.Clients /> },
    {
      path: "/professionals",
      label: "Professionals",
      icon: <ICONS.nav.Professionals />,
    },
    {
      path: "/categories",
      label: "Categories",
      icon: <ICONS.nav.Categories />,
    },
    { path: "/studios", label: "Studios", icon: <ICONS.nav.Studios /> },
    { path: "/equipments", label: "Equipment", icon: <ICONS.nav.Equipments /> },
    { path: "/courses", label: "Courses", icon: <ICONS.nav.Courses /> },
    { path: "/banners", label: "Banners", icon: <ICONS.nav.Banners /> },
    {
      path: "/verification",
      label: "Verification",
      icon: <ICONS.nav.Verifications />,
    },
    { path: "/reports", label: "Reports", icon: <ICONS.nav.Reports /> },
    { path: "/staff", label: "Staff Management", icon: <ICONS.nav.Staff /> },
  ],
  [Roles.Moderator]: [
    { path: "/clients", label: "Clients", icon: <ICONS.nav.Clients /> },
    {
      path: "/professionals",
      label: "Professionals",
      icon: <ICONS.nav.Professionals />,
    },
    {
      path: "/categories",
      label: "Categories",
      icon: <ICONS.nav.Categories />,
    },
    { path: "/studios", label: "Studios", icon: <ICONS.nav.Studios /> },
    { path: "/equipments", label: "Equipment", icon: <ICONS.nav.Equipments /> },
    { path: "/courses", label: "Courses", icon: <ICONS.nav.Courses /> },
    { path: "/banners", label: "Banners", icon: <ICONS.nav.Banners /> },
    {
      path: "/verification",
      label: "Verification",
      icon: <ICONS.nav.Verifications />,
    },
    { path: "/reports", label: "Reports", icon: <ICONS.nav.Reports /> },
  ],
  [Roles.EquipmentManager]: [
    { path: "/equipments", label: "Equipment", icon: <ICONS.nav.Equipments /> },
  ],
  [Roles.StudioManager]: [
    { path: "/studios", label: "Studios", icon: <ICONS.nav.Studios /> },
  ],
};

const Main: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();

  const { data } = useQuery<{
    findUnverifiedUsers: {
      count: number;
    };
  }>(GET_UNVERIFIED_USERS, {
    variables: {
      input: {
        profileTypes: [ProfileType.Client, ProfileType.Photographer],
      },
    },
  });

  useEffect(() => {
    const isEquipmentManager = user?.role === Roles.EquipmentManager;
    const isStudioManager = user?.role === Roles.StudioManager;
    const isModerator = user?.role === Roles.Moderator;

    if (isEquipmentManager && location.pathname !== "/equipments")
      return navigate("/equipments");

    if (isStudioManager && location.pathname !== "/studios")
      return navigate("/studios");

    if (isModerator && location.pathname === "/staff")
      return navigate("/clients");

    if (location.pathname === "/") {
      if (isEquipmentManager) return navigate("/equipments");

      if (isStudioManager) return navigate("/studios");

      navigate("/clients");
    }
  }, [user]);

  useEffect(() => {
    SocketService.connect();
    SocketService.addEventListener("connected", () => {
      console.log("connected");
    });
  }, []);

  if (!user) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_menu}>
        <div className={styles.wrapper_menu_logo}>
          <ICONS.Logo />
        </div>
        <nav className={styles.wrapper_menu_nav}>
          {roleConfig[user.role].map((it) => (
            <NavLink
              key={it.path}
              to={it.path}
              className={({ isActive }) =>
                `${styles.wrapper_menu_nav_item} ${
                  isActive && styles.wrapper_menu_nav_item_active
                }`
              }
            >
              <div className={styles.wrapper_menu_nav_item_icon}>{it.icon}</div>
              <p className={styles.wrapper_menu_nav_item_text}>{it.label}</p>

              {it.path === "/verification" && (
                <div
                  className={styles.wrapper_menu_nav_item_verification}
                  style={{
                    backgroundColor:
                      location.pathname === "/verification"
                        ? "#141415"
                        : "#FF3C3C",
                  }}
                >
                  {data?.findUnverifiedUsers.count}
                </div>
              )}
            </NavLink>
          ))}
        </nav>

        <ProfileBlock />
      </div>

      <div className={styles.wrapper_content}>
        <Outlet />
      </div>
    </div>
  );
};

export default Main;
