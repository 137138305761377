import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { Avatar, RentedBlock, ScheduleCalendar } from "components";
import styles from "./styles.module.scss";
import { useLazyQuery } from "@apollo/client";
import { GET_RENTAL_EQUIPMENT_BY_DATES } from "graphql/queries/equipments";
import ScheduleEquipmentModal from "../ScheduleEquipmentModal";

interface IProps {
  equipmentId?: number;
}

interface IEquipmentRental {
  endsAt: string;
  equipmentId: number;
  externalRenter?: { fullName: string; id: number; phone: string };
  externalRenterId: number;
  id: number;
  renter?: any;
  renterId: number;
  startsAt: string;
}

const EquipmentCalendar: FC<IProps> = ({ equipmentId }) => {
  const [currentDate, setCurrentDate] = useState<string>(
    moment().format("DD-MM-YYYY")
  );
  const [rangeDate, setRangeDate] = useState<string[]>([]);
  const [selectedEquipmentsRental, setSelectedEquipmentsRental] =
    useState<IEquipmentRental>();
  const [isOpen, setOpen] = useState(false);

  const [fetchEquipmentByDate, { data, refetch }] = useLazyQuery<{
    findEquipmentRentalsByDates: IEquipmentRental[];
  }>(GET_RENTAL_EQUIPMENT_BY_DATES, {
    variables: {
      input: {
        equipmentId,
        from: moment(rangeDate[0]),
        to: moment(rangeDate[1]),
      },
    },
    onCompleted(data) {
      const foundRental = data.findEquipmentRentalsByDates.find(
        (rentalEquipment) => {
          return (
            moment(rentalEquipment.startsAt).format("DD-MM-YYYY") ===
              currentDate ||
            moment(rentalEquipment.endsAt).format("DD-MM-YYYY") === currentDate
          );
        }
      );

      setSelectedEquipmentsRental(foundRental);
    },
  });

  useEffect(() => {
    if (equipmentId) fetchEquipmentByDate();
  }, [rangeDate, currentDate, equipmentId]);

  const onOpenModal = () => {
    if (selectedEquipmentsRental) return;

    if (moment().isAfter(moment(currentDate, "DD-MM-YYYY"), "day")) return;

    setOpen(true);
  };

  return (
    <div className={styles.wrapper}>
      <ScheduleCalendar
        currentDate={currentDate}
        onSelectDay={(date: string) => setCurrentDate(date)}
        setRangeDate={setRangeDate}
        rentedDates={data?.findEquipmentRentalsByDates}
      />

      <RentedBlock
        selectedRental={selectedEquipmentsRental}
        onOpenModal={onOpenModal}
        content={"Full day"}
      />

      <ScheduleEquipmentModal
        selectedDay={moment(currentDate, "DD-MM-YYYY").format("DD MMMM, YYYY")}
        equipmentId={equipmentId}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        refetch={refetch}
      />
    </div>
  );
};

export default EquipmentCalendar;
