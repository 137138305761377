import { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
  title: string;
};

const EmptyMessage: FC<Props> = ({ title }) => {
  return <p className={styles.empty}>{title}</p>;
};

export default EmptyMessage;
