import React, { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Order } from "types/enums";

type Props = {
  items: Array<
    | {
        title: string;
        disableSort?: boolean;
        value?: string;
        style?: React.CSSProperties;
      }
    | false
  >;
  sorted: {
    sortedBy: string | null;
    type: Order | null;
  };
  flexes?: Array<number>;
  onChangeSort: (data: { sortedBy: string | null; type: Order | null }) => void;
};

const TableHead: FC<Props> = ({ items, flexes = [], sorted, onChangeSort }) => {
  const handleChangeSort = (index: number) => {
    const selectedItem = items[index];
    if (!selectedItem) return;

    if (selectedItem.value === sorted.sortedBy && sorted.type === Order.DESC) {
      onChangeSort({ sortedBy: null, type: null });
      return;
    }

    if (selectedItem.value === sorted.sortedBy) {
      onChangeSort({ ...sorted, type: Order.DESC });
      return;
    }

    onChangeSort({ sortedBy: selectedItem.value || null, type: Order.ASC });
  };

  const renderItems = () => {
    return items.map((item, index) => {
      const flex = flexes[index];
      // @ts-ignore
      const isSelected = sorted.sortedBy === item?.value;
      const style = item && item.style ? item.style : {};

      return (
        <div
          key={index}
          className={styles.wrapper_item}
          style={{
            flex: flex || undefined,
            width: item ? "auto" : 24,
            ...style,
            cursor: item && item.disableSort ? "default" : "pointer",
          }}
          onClick={() => {
            if (item && !item.disableSort) {
              handleChangeSort(index);
            }
          }}
        >
          {item && (
            <>
              <p
                className={cls(styles.wrapper_item_title, {
                  [styles.wrapper_item_title_active]: isSelected,
                })}
              >
                {item.title}
              </p>

              {!item.disableSort && (
                <div
                  className={styles.wrapper_item_arrow}
                  style={{
                    transform: `rotate(${
                      isSelected && sorted.type === Order.DESC ? "180deg" : 0
                    })`,
                  }}
                >
                  <ICONS.SmallArrow />
                </div>
              )}
            </>
          )}
        </div>
      );
    });
  };

  return <div className={styles.wrapper}>{renderItems()}</div>;
};

export default TableHead;
