import { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
  attachments: Array<string>;
};

const Attachments: FC<Props> = ({ attachments }) => {
  const downloadAttachment = (link: string) => {
    var a = document.createElement("a");
    a.href = link;
    a.target = "_blank";

    a.click();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_attachments}>
        {attachments.map((a, index) => (
          <div
            key={index}
            className={styles.wrapper_attachments_item}
            onClick={() => downloadAttachment(a)}
          >
            {a.split("/").at(-1)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Attachments;
