import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { AdminQueries } from "graphql/queries";

import { TokensService } from "utils";
import { IAdmin } from "types/interfaces";

const useUser = () => {
  const { data, loading, error, refetch } = useQuery<{ currentAdmin: IAdmin }>(
    AdminQueries.GET_CURRENT_ADMIN,
    {
      notifyOnNetworkStatusChange: true,
    }
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      TokensService.clearTokens();
      navigate("/login");
    }
  }, [error]);

  return { user: data?.currentAdmin, loading, error, refetch };
};

export default useUser;
