import { FC, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

import { UserQueries } from "graphql/queries";

import {
  ContractModal,
  CompletedContractItem,
  TableHead,
  Pagination,
} from "components";

import { IContract } from "types/interfaces";
import { Order } from "types/enums";

const LIMIT = 10;

const Completed: FC = () => {
  const { clientId } = useParams();
  const [modalPayload, setModalPayload] = useState<false | IContract>(false);
  const [count, setCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [fetchCompleted, { data, loading }] = useLazyQuery<{
    adminClientContracts: {
      data: Array<IContract>;
      meta: { itemCount: number };
    };
  }>(UserQueries.GET_CLIENT_ACTIVE_CONTRACTS, {
    onCompleted(data) {
      setCount(data.adminClientContracts.meta.itemCount);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    fetchCompleted({
      variables: {
        adminClientContractsId: Number(clientId),
        input: {
          skip: (currentPage - 1) * LIMIT,
          take: LIMIT,
          ended: true,
        },
      },
    });
  }, [currentPage]);

  const renderCompleted = () => {
    if (
      !data?.adminClientContracts.data ||
      data.adminClientContracts.data.length === 0
    )
      return <p className={styles.wrapper_empty}>No completed contracts yet</p>;

    return data.adminClientContracts.data.map((item) => {
      const feedback = item.feedbacks.find(
        (f) => f.subjectId === Number(clientId)
      );

      return (
        <div key={item.id} onClick={() => setModalPayload(item)}>
          <CompletedContractItem contract={item} feedback={feedback} />
        </div>
      );
    });
  };

  return (
    <div>
      <TableHead
        items={[
          { title: "Contract" },
          { title: "Professional" },
          { title: "Budget" },
          { title: "Rating" },
          { title: "Start date" },
          { title: "End date" },
          false,
        ]}
        flexes={[3, 2, 1, 1, 1, 1]}
        sorted={{ sortedBy: "", type: Order.ASC }}
        onChangeSort={() => {}}
      />
      {loading ? (
        <Skeleton
          height={50}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
          count={5}
        />
      ) : (
        renderCompleted()
      )}

      {count > 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            count={count}
            currentPage={currentPage}
            limit={LIMIT}
            onChange={setCurrentPage}
          />
        </div>
      )}

      <ContractModal
        payload={modalPayload}
        onClose={() => setModalPayload(false)}
      />
    </div>
  );
};

export default Completed;
