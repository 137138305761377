import { FC, useState, useMemo, useContext } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

import { UserQueries } from "graphql/queries";
import { UserMutations } from "graphql/mutations";

import {
  BackHeader,
  Button,
  Avatar,
  Tabs,
  ConfirmModal,
  BlockReason,
} from "components";
import { ActiveContracts, Postings, Completed, Transactions } from "./Tabs";

import { Loading } from "context";

import { useTab } from "hooks";

import { VariantButton } from "types/enums";
import { IUser } from "types/interfaces";

const ClientItem: FC = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useContext(Loading.Context)!;
  const { clientId } = useParams();

  const { currentTab, onChange: setCurrentTab } = useTab();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [blockModalOpen, setBlockModalOpen] = useState<boolean>(false);
  const [blockReason, setBlockReason] = useState<string | null>(null);

  const [unblockUser, { client }] = useMutation(UserMutations.UNBLOCK_USER);
  const [blockUser] = useMutation(UserMutations.BLOCK_USER);
  const [deleteUser] = useMutation(UserMutations.DELETE_USER);

  const { data, loading, error } = useQuery<{
    user: IUser;
    active: { meta: { itemCount: number } };
    ended: { meta: { itemCount: number } };
    adminClientJobs: { meta: { itemCount: number } };
  }>(UserQueries.CLIENT_PROFILE, {
    variables: { id: Number(clientId) },
  });

  const renderTab = () => {
    switch (currentTab) {
      case 0:
        return <ActiveContracts />;
      case 1:
        return <Postings />;
      case 2:
        return <Completed />;
      case 3:
        return <Transactions />;
      default:
        return (
          <p style={{ textAlign: "center" }}>Monthly Summary will be here</p>
        );
    }
  };

  const tabs = useMemo(() => {
    if (!data)
      return [
        { title: "Active Contracts" },
        { title: "Postings" },
        { title: "Completed" },
        { title: "Transactions" },
        { title: "Monthly Summary" },
      ];

    return [
      { title: "Active Contracts", count: data.active.meta.itemCount },
      { title: "Postings", count: data.adminClientJobs.meta.itemCount },
      { title: "Completed", count: data.ended.meta.itemCount },
      { title: "Transactions" },
      { title: "Monthly Summary" },
    ];
  }, [data]);

  const handleDeleteUser = async () => {
    try {
      setIsLoading(true);
      await deleteUser({ variables: { id: data?.user.id } });

      setDeleteModalOpen(false);
      navigate(-1);
      client.cache.evict({ id: `User:${data?.user.id}` });
    } catch (e) {
      console.log(e, "---e delete");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBlockUnblock = async () => {
    try {
      setIsLoading(true);
      if (data?.user.blocked) {
        await unblockUser({ variables: { id: data.user.id } });
        client.cache.writeFragment({
          id: `User:${data.user.id}`,
          fragment: gql`
            fragment MyTodo on User {
              blocked
            }
          `,
          data: {
            blocked: false,
          },
        });
      } else {
        await blockUser({
          variables: { id: data?.user.id, reason: blockReason?.trimEnd() },
        });

        client.cache.writeFragment({
          id: `User:${data?.user.id}`,
          fragment: gql`
            fragment MyTodo on User {
              blocked
            }
          `,
          data: {
            blocked: true,
          },
        });
      }

      setBlockModalOpen(false);
      setBlockReason(null);
      return;
    } catch (e) {
      console.log(e, "---e");
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <BeatLoader color="black" />
      </div>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <div>
      <BackHeader
        title="Client’s Profile"
        actions={[
          <Button
            title={data?.user.blocked ? "Unblock" : "Block"}
            variant={VariantButton.Gray}
            onClick={() => setBlockModalOpen(true)}
          />,
          <Button
            title="Delete"
            variant={VariantButton.Red}
            onClick={() => setDeleteModalOpen(true)}
          />,
        ]}
      />
      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_info}>
            <div className={styles.wrapper_header_info_avavatr}>
              <Avatar
                img={data?.user.avatar}
                dimensions={{ width: 100, height: 100 }}
              />
            </div>
            <div>
              <p className={styles.wrapper_header_info_name}>
                {data?.user.firstName} {data?.user.lastName}
              </p>
              <p className={styles.wrapper_header_info_location}>
                {data?.user.profile?.location?.short_title}
              </p>
            </div>
          </div>

          <div className={styles.wrapper_header_balance}>
            <p className={styles.wrapper_header_balance_count}>$400.00</p>
            <p className={styles.wrapper_header_balance_title}>
              Current Balance
            </p>
          </div>
        </header>

        <div className={styles.wrapper_tabs}>
          <Tabs
            tabs={tabs}
            currentIndex={currentTab}
            onChange={setCurrentTab}
          />
        </div>

        {/* <div className={styles.wrapper_search}>
          <SearchInput
            value={search}
            placeholder="Serach"
            onChange={setSearch}
          />
        </div> */}

        <div className={styles.wrapper_table}>
          {/* <TableHeader
            items={["Contract", "Professional", "Budget", "Start date", false]}
            flexes={[3, 2, 1, 1]}
            selectedIndex={sortedIndex}
            sortedType={sortedType}
            onChangeSorted={setSortedIndex}
            onChangeType={setSortedType}
          /> */}
          {renderTab()}
        </div>
      </div>

      <ConfirmModal
        isOpen={deleteModalOpen}
        title="Delete"
        description="Are you sure? All user's data will be lost"
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteUser}
      />

      <ConfirmModal
        isOpen={blockModalOpen}
        title={data?.user.blocked ? "Unblock" : "Block"}
        disableConfirmBtn={
          !data?.user.blocked && (!blockReason || blockReason.trim() === "")
        }
        onClose={() => setBlockModalOpen(false)}
        onConfirm={handleBlockUnblock}
      >
        {!data?.user.blocked && (
          <div style={{ marginBottom: "20px" }}>
            <BlockReason value={blockReason} onChange={setBlockReason} />
          </div>
        )}
      </ConfirmModal>
    </div>
  );
};

export default ClientItem;
