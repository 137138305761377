import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar } from "components";

import { IJobCandidate } from "types/interfaces";

import { UserService } from "utils";

type Props = {
  candidate: IJobCandidate;
};

const CandidateItem: FC<Props> = ({ candidate }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_info}>
        <div className={styles.wrapper_info_avatar}>
          <Avatar img={candidate.photographer.avatar} />
        </div>
        <p className={styles.wrapper_info_name}>
          {candidate.photographer.firstName} {candidate.photographer.lastName}
        </p>
      </div>

      <div className={styles.wrapper_location}>
        {candidate.photographer.profile?.location?.short_title}
      </div>

      <div className={styles.wrapper_rate}>
        {UserService.getCandidateRate(candidate) || "n/a"}
      </div>

      <div className={styles.wrapper_jobs}>
        {candidate.photographer.profile?.completed || 0}
      </div>

      <div className={styles.wrapper_rating}>
        <div className={styles.wrapper_rating_icon}>
          <ICONS.Star />
        </div>
        <p className={styles.wrapper_rating_text}>
          {
            //@ts-ignore
            UserService.addZeroToRate(candidate.photographer.profile?.rating) ||
              "n/a"
          }
        </p>
      </div>
      <div className={styles.wrapper_date}>
        {moment(candidate.createdAt).format("MMM DD, YYYY")}
      </div>

      <div className={styles.wrapper_btn}>
        <div className={styles.wrapper_btn_wrapper}>
          <ICONS.RightArrow />
        </div>
      </div>
    </div>
  );
};

export default CandidateItem;
