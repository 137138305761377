import { gql } from "@apollo/client";

export const DEACTIVATE_COURSE = gql`
  mutation DeactivateCourse($input: AdminDeactivateCourseInput!) {
    deactivateCourse(input: $input) {
      success
    }
  }
`;

export const ACTIVATE_COURSE = gql`
  mutation ActivateCourse($input: AdminActivateCourseInput!) {
    activateCourse(input: $input) {
      success
    }
  }
`;

export const UPDATE_COURSE = gql`
  mutation UpdateCourse($input: UpdateCourseInput!) {
    updateCourse(input: $input) {
      id
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation DeleteCourse($input: AdminDeleteCourseInput!) {
    deleteCourse(input: $input) {
      success
    }
  }
`;

export const CREATE_MOSAWER_COURSE = gql`
  mutation CreateMosawerCourse($input: CreateMosawerCourseInput!) {
    createMosawerCourse(input: $input) {
      id
      ownerId
      title
      description
      price
      currency
      status
    }
  }
`;

export const UPDATE_COURSE_LESSON = gql`
  mutation UpdateCourseLesson($input: UpdateCourseLessonInput!) {
    updateCourseLesson(input: $input) {
      success
    }
  }
`;
