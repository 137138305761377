import { FC, useCallback } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { useDropzone } from "react-dropzone";
import { ICONS } from "assets";
import styles from "./styles.module.scss";
import cls from "classnames";
import { chunk } from "lodash";

interface IProps {
  handleSave: (image: Blob[], amountImages: number) => void;
  images: string[];
  error?: string;
  onRemoveImage: (index: number) => void;
  handleDragEnd: (result: DropResult) => void;
}

const ImageMultipleDropZone: FC<IProps> = ({
  handleSave,
  images,
  error,
  onRemoveImage,
  handleDragEnd,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: Blob[]) => {
      if (!Boolean(acceptedFiles.length)) return;

      handleSave(acceptedFiles, images.length);
    },
    [images]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,

    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  return (
    <>
      <div className={styles.dropZone__label}>Photos</div>
      <div className={styles.dropZone__sublabel}>
        Rearrange photos by drag-n-drop them
      </div>

      <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          {chunk(images, 5).map((chunkImagesBy5, chunkIndex) => (
            <Droppable
              key={chunkImagesBy5[chunkIndex] + chunkIndex}
              droppableId={`chunkImages${chunkIndex}`}
              direction="horizontal"
            >
              {(provided) => (
                <div
                  style={{
                    display: "flex",
                    gap: 5,
                    flexWrap: "wrap",
                  }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {chunkImagesBy5.map((imgUrl, index) => {
                    return (
                      <Draggable
                        key={imgUrl + index}
                        draggableId={String(imgUrl)}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              key={imgUrl}
                              style={{ border: "none" }}
                              className={cls(
                                styles.dropZone__zone,
                                !!error && styles.error
                              )}
                            >
                              <img
                                src={imgUrl}
                                style={{ height: "100%", width: "100%" }}
                                alt="img"
                              />
                              {index === 0 && chunkIndex === 0 && (
                                <p className={styles.main_label}>Main</p>
                              )}

                              <button
                                className={styles.delete_icon}
                                onClick={() =>
                                  onRemoveImage(
                                    chunkIndex === 0 ? index : index + 5
                                  )
                                }
                              >
                                <ICONS.DeleteWhite color="white" />
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
          <div className={styles.dropZone__images}>
            <div
              className={cls(styles.dropZone__zone, !!error && styles.error)}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <>
                <ICONS.PlusDropImage />
                <p style={{ fontWeight: 700 }}>Add image</p>
              </>
            </div>
          </div>
        </DragDropContext>
      </div>

      {!!error && <p className={styles.dropZone__error}>{error}</p>}
    </>
  );
};

export default ImageMultipleDropZone;
