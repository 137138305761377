export const setTokens = async (accessToken: string) => {
  localStorage.setItem("@accessToken", accessToken);
};

export const getTokens = (): string | null => {
  const accessToken = localStorage.getItem("@accessToken");

  if (!accessToken) return null;

  return accessToken;
};

export const clearTokens = () => {
  localStorage.removeItem("@accessToken");
};
