import { gql } from "@apollo/client";

export const UPDATE_DISPUT = gql`
  mutation updateDispute($input: UpdateDisputeInput!) {
    updateDispute(input: $input) {
      id
      open
      status
      chat {
        id
      }
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation updateReport($input: UpdateReportInput!) {
    updateReport(input: $input) {
      id
      status
      action
      blockedReason
    }
  }
`;
