// styles
import { FC } from "react";
import styles from "./styles.module.scss";
import cls from "classnames";

interface IProps {
  handleClick: () => void;
  isActive?: boolean;
}

const Switcher: FC<IProps> = ({ isActive, handleClick }) => {
  return (
    <div
      onClick={handleClick}
      className={cls(styles.switcher, isActive && styles.active)}
    >
      <div
        className={cls(styles.switcher__circle, isActive && styles.active)}
      />
    </div>
  );
};

export default Switcher;
