import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

type Props = {
  items: Array<string | false>;
  selectedIndex: number | null;
  sortedType: "desc" | "asc" | null;
  flexes?: Array<number>;
  onChangeSorted: (index: number | null) => void;
  onChangeType: (type: "desc" | "asc" | null) => void;
};

const TableHeader: FC<Props> = ({
  items,
  selectedIndex,
  sortedType,
  flexes = [],
  onChangeSorted,
  onChangeType,
}) => {
  const handleChangeSort = (index: number) => {
    if (index === selectedIndex && sortedType === "desc") {
      onChangeSorted(null);
      onChangeType(null);
      return;
    }

    if (index === selectedIndex) {
      onChangeType("desc");
      return;
    }

    onChangeSorted(index);
    onChangeType("asc");
  };

  const renderItems = () => {
    return items.map((item, index) => {
      const flex = flexes[index];
      const isSelected = selectedIndex === index;

      return (
        <div
          key={index}
          className={styles.wrapper_item}
          style={{ flex: flex || undefined, width: item ? "auto" : 24 }}
          onClick={() => handleChangeSort(index)}
        >
          {item && (
            <>
              <p
                className={cls(styles.wrapper_item_title, {
                  [styles.wrapper_item_title_active]: isSelected,
                })}
              >
                {item}
              </p>
              <div
                className={styles.wrapper_item_arrow}
                style={{
                  transform: `rotate(${
                    isSelected && sortedType === "desc" ? "180deg" : 0
                  })`,
                }}
              >
                <ICONS.SmallArrow />
              </div>
            </>
          )}
        </div>
      );
    });
  };

  return <div className={styles.wrapper}>{renderItems()}</div>;
};

export default TableHeader;
