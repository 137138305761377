import { FC } from "react";
import styles from "./styles.module.scss";

import { Avatar, DotSelector } from "components";

import { ICONS } from "assets";
import moment from "moment";
import { CourseOwnership, ICourse } from "types";
import { useNavigate } from "react-router-dom";
import { formatSecondsToHoursMinutes } from "utils/userService";

interface IProps {
  course: ICourse;
  onDelete: (course: ICourse) => void;
}

const CourseCard: FC<IProps> = ({ course, onDelete }) => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.course}
      onClick={() =>
        navigate(`${course.id}`, { state: { ownership: course.ownership } })
      }
    >
      <div className={styles.course_info}>
        <p className={styles.course_info_title}>{course.title}</p>
        {course.ownership === CourseOwnership.Photographer ? (
          <div className={styles.course_row}>
            <div className={styles.course_info_wrap_img}>
              <Avatar
                img={course.owner.avatar}
                dimensions={{ width: 24, height: 24 }}
              />
            </div>
            <p className={styles.course_info_author}>
              {course.owner.firstName + " " + course.owner.lastName}
            </p>
          </div>
        ) : null}

        <p className={styles.course_info_id}>{`id${course?.id}`}</p>
      </div>

      <div className={styles.course_lessons}>
        <p className={styles.course_lessons_text}>{course.numberOfLessons}</p>
      </div>

      <div className={styles.course_duration}>
        <p className={styles.course_duration_text}>
          {formatSecondsToHoursMinutes(course.totalDurationInSeconds) || "0m"}
        </p>
      </div>

      <div className={styles.course_price}>
        <p className={styles.course_price_text}>
          {`${course.price} ${course.currency}`}
        </p>
      </div>

      <div className={styles.course_students}>
        <p className={styles.course_students_text}>
          {course.numberOfPurchasers}
        </p>
      </div>

      <div className={styles.course_rating}>
        <div className={styles.course_row} style={{ height: 20 }}>
          <div>
            <ICONS.Star />
          </div>
          <p className={styles.course_price_text}>{course.rating || "N/A"}</p>
          <p
            className={styles.course_rating_reviews}
          >{`(${course.numberOfReviews})`}</p>
        </div>
      </div>

      <div className={styles.course_date}>
        {moment(course.createdAt).format("MMM DD, YYYY")}

        <div>
          <DotSelector
            items={[
              {
                label: "Edit",
                icon: <ICONS.Edit />,
                onClick: () =>
                  navigate(`${course.id}`, {
                    state: { isEdit: true, ownership: course.ownership },
                  }),
              },
              {
                label: "Delete",
                icon: <ICONS.Delete />,
                onClick: () => onDelete(course),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
