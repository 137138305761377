import React, { FC } from "react";
import cls from "classnames";
import Avatar from "components/Avatar";
import styles from "./styles.module.scss";
import { IRental } from "types";

interface IProps {
  disabled?: boolean;
  selectedRental?: IRental;
  content: string;
  onOpenModal: () => void;
}

const RentedBlock: FC<IProps> = ({
  selectedRental,
  onOpenModal,
  content,
  disabled,
}) => {
  return (
    <div
      className={cls(styles.rented_block, {
        [styles.rented_block_rented]: selectedRental,
        [styles.rented_block_hovered]: !selectedRental && !disabled,
        [styles.rented_block_disabled]: disabled && !selectedRental,
      })}
      onClick={onOpenModal}
    >
      {selectedRental ? (
        <div className={styles.rented_block_info}>
          <Avatar
            img={selectedRental?.renter?.avatar}
            dimensions={{ width: 24, height: 24 }}
          />

          <p className={styles.rented_block_username}>
            {selectedRental?.renter
              ? `${selectedRental?.renter.firstName} ${selectedRental?.renter.lastName}`
              : selectedRental?.externalRenter?.fullName}
          </p>
        </div>
      ) : null}

      <p
        className={cls(styles.rented_block_text, {
          [styles.rented_block_text_disabled]: disabled && !selectedRental,
        })}
      >
        {content}
      </p>
      {disabled && !selectedRental ? null : (
        <div
          className={styles.rented_block_status}
          style={{
            background: selectedRental ? "#B2B3BD" : "#63CD5A",
          }}
        >
          {selectedRental ? "Rented" : "Free"}
        </div>
      )}
    </div>
  );
};

export default RentedBlock;
