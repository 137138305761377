import { FC, useState } from "react";
import {
  Bar,
  BarChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import styles from "./Top.module.scss";

import { ICONS } from "assets";

import { Tabs } from "components";

const chartData = [
  { x: moment("2022-01-01", "YYYY-MM-DD").toDate(), y: 100 },
  { x: moment("2022-02-01", "YYYY-MM-DD").toDate(), y: 200 },
  { x: moment("2022-03-01", "YYYY-MM-DD").toDate(), y: 150 },
  { x: moment("2022-04-01", "YYYY-MM-DD").toDate(), y: 600 },
  { x: moment("2022-05-01", "YYYY-MM-DD").toDate(), y: 400 },
  { x: moment("2022-06-01", "YYYY-MM-DD").toDate(), y: 900 },
  { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
  // { x: moment("2022-07-01", "YYYY-MM-DD").toDate(), y: 1100 },
];

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length > 0) {
    return <div className={styles.tooltip}>{payload[0].payload.y}</div>;
  }

  return null;
};

const Top: FC = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_left}>
        <header className={styles.wrapper_left_header}>
          <h4 className={styles.wrapper_left_header_title}>All Users</h4>
          <p className={styles.wrapper_left_header_value}>129,633</p>
        </header>
        <footer className={styles.wrapper_left_footer}>
          <h5 className={styles.wrapper_left_footer_title}>Today</h5>
          <div className={styles.wrapper_left_footer_wrapper}>
            <p className={styles.wrapper_left_footer_wrapper_value}>347</p>
            <div className={styles.wrapper_left_footer_wrapper_icon}>
              <ICONS.CircleUp />
            </div>
            <p className={styles.wrapper_left_footer_wrapper_helper}>6%</p>
          </div>
        </footer>
      </div>

      <div className={styles.wrapper_right}>
        <div className={styles.wrapper_right_tabs}>
          <Tabs
            // tabs={["Week", "Month", "Year", "All time"]}
            tabs={[
              { title: "Week" },
              { title: "Month" },
              { title: "Year" },
              { title: "All time" },
            ]}
            currentIndex={currentTabIndex}
            onChange={setCurrentTabIndex}
          />
        </div>

        <div style={{ height: 300 }}>
          <ResponsiveContainer>
            <BarChart data={chartData}>
              <XAxis
                dataKey="x"
                axisLine={false}
                tickLine={false}
                tickFormatter={(tickItem) => {
                  return moment(tickItem).format("MMM");
                }}
              />
              <YAxis dataKey="y" axisLine={false} tickLine={false} />
              {/* <Tooltip /> */}
              <Tooltip cursor={{ fill: "none" }} content={<CustomTooltip />} />
              <Bar dataKey="y" fill="#D4D5DB" radius={4} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Top;
