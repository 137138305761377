import React, { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { VariantButton } from "types/enums";

type Props = {
  title: string;
  disable?: boolean;
  variant?: VariantButton;
  icon?: React.ReactNode;
  onClick: () => void;
  styleBtn?: React.CSSProperties;
};

const Button: FC<Props> = ({
  title,
  disable = false,
  variant = VariantButton.General,
  icon,
  styleBtn,
  onClick,
}) => {
  return (
    <button
      style={styleBtn}
      className={cls(styles.button, {
        [styles.button_gray]: variant === VariantButton.Gray,
        [styles.button_red]: variant === VariantButton.Red,
        [styles.button_disable]: disable,
      })}
      disabled={disable}
      onClick={onClick}
      type="button"
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <p>{title}</p>
    </button>
  );
};

export default Button;
