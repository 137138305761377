import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import cls from "classnames";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { IEquipmentLocation } from "types";

interface IProps {
  value: string;
  onSelect: (location: IEquipmentLocation) => void;
  error?: string;
  containerStyle?: React.CSSProperties;
}

const SearchLocation: FC<IProps> = ({
  value,
  onSelect,
  error,
  containerStyle,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const handleSearchValue = (newValue: string) => {
    setSearchValue(newValue);
  };

  const onSelectLocation = async (selectedLocation: any) => {
    const results = await geocodeByAddress(selectedLocation);
    const { lat, lng } = await getLatLng(results[0]);

    setSearchValue(selectedLocation);

    onSelect({
      latitude: lat,
      longitude: lng,
      address: results[0].formatted_address,
    });
  };

  return (
    <div className={styles.wrapper} style={containerStyle}>
      <PlacesAutocomplete
        debounce={500}
        value={searchValue}
        onChange={handleSearchValue}
        onSelect={onSelectLocation}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <p className={styles.label}>Location</p>

            <input
              {...getInputProps({
                placeholder: "Enter address",
                onBlur: () => setIsFocused(false),
                onFocus: () => setIsFocused(true),
                className: cls(styles.locationInput, {
                  [styles.locationInput_focused]: isFocused,
                }),
                style: { fontWeight: searchValue ? 700 : 400 },
              })}
            />

            {error && <p className={styles.wrapper_error}>{error}</p>}

            {searchValue ? (
              <div className={styles.places}>
                <div>
                  {loading ? <div>...loading</div> : null}

                  {suggestions.map((suggestion) => {
                    return (
                      <div
                        className={styles.place}
                        {...getSuggestionItemProps(suggestion, {
                          key: suggestion,
                        })}
                      >
                        <p>{suggestion.description}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </>
        )}
      </PlacesAutocomplete>
    </div>
  );

};

export default SearchLocation;
