import { FC, useContext, useState } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import styles from "./styles.module.scss";

import {
  Button,
  Input,
  Select,
  SelectEquipments,
  SelectStudios,
  ImageDropZone,
  SelectPhotographers,
  SelectCourses,
} from "components";
import { ICONS } from "assets";

import { IBanner } from "types/interfaces";
import { v4 as uuid } from "uuid";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GoogleService } from "utils";
import { AdminQueries } from "graphql/queries";
import { CREATE_BANNER, UPDATE_BANNER } from "graphql/mutations";
import { Loading } from "context";
import toast from "react-hot-toast";
import { BannerAudience, BannerTargetRedirection } from "types";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
    zIndex: 2000,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 2000,
  },
};

const targetRedirectionOptions = [
  { label: "Equipment", value: BannerTargetRedirection.Equipment },
  { label: "Course", value: BannerTargetRedirection.Course },
  { label: "Profile", value: BannerTargetRedirection.Profile },
  { label: "Studio", value: BannerTargetRedirection.Studio },
];

const validate = (values: any) => {
  const errors: any = {};

  if (values.title.trim() === "") {
    errors.title = "Title is required";
  }

  if (!values.img) {
    errors.img = "Image of banner is required";
  }

  return errors;
};

interface IProps {
  banner: IBanner | null;
  bannerAudience?: BannerAudience;
  isOpen: boolean;
  onClose: () => void;
  refetch: any;
}

const CreateUpdateBannerModal: FC<IProps> = ({
  banner,
  onClose,
  refetch,
  isOpen,
  bannerAudience,
}) => {
  const { setIsLoading } = useContext(Loading.Context)!;
  const [savedImage, setSavedImage] = useState<File | null>(null);

  const [createBanner] = useMutation(CREATE_BANNER);
  const [updateBanner] = useMutation(UPDATE_BANNER);
  const [createUploadUrl] = useLazyQuery(AdminQueries.GET_UPLOAD_URLS);

  const { values, errors, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      title: banner?.title || "",
      targetId: banner?.redirection?.targetId || "",
      targetType: banner?.redirection?.target || "",
      img: banner?.image || "",
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate,
    onSubmit: banner ? handleUpdateBanner : handleCreateBanner,
  });

  async function handleCreateBanner() {
    try {
      if (!values.targetId) {
        return toast.error("Link is required");
      }

      setIsLoading(true);

      const fileUrl = await saveNewBannerImage();

      await createBanner({
        variables: {
          input: {
            redirection: {
              targetId: values.targetId,
              target: values.targetType,
            },
            title: values.title.trimEnd(),
            image: fileUrl,
            targetAudience: bannerAudience,
          },
        },
      });

      await refetch();

      onCloseModal();

      toast.success("Banner created successfully");
    } catch (error) {
      toast.error("Error created banner");
    } finally {
      setIsLoading(false);
    }
  }

  async function handleUpdateBanner() {
    try {
      if (errors.targetId) {
        return toast.error("Link is required");
      }

      setIsLoading(true);

      const fileUrl = await saveNewBannerImage();

      await updateBanner({
        variables: {
          input: {
            id: banner?.id,
            redirection: {
              targetId: values.targetId,
              target: values.targetType,
            },
            title: values.title.trimEnd(),
            image: savedImage ? fileUrl : banner?.image,
            targetAudience: banner?.targetAudience,
          },
        },
      });

      await refetch();

      onCloseModal();

      toast.success("Banner updated successfully");
    } catch (error) {
      toast.error("Error updated banner");
      console.error("Error updated banner", error);
    } finally {
      setIsLoading(false);
    }
  }

  const saveNewBannerImage = async () => {
    if (!savedImage) return;

    const uniqueId = uuid();

    const {
      data: { generateUploadUrls: urls },
    } = await createUploadUrl({
      variables: { filename: `${uniqueId}${savedImage?.name}` },
    });

    await GoogleService.uploadFileToStorage(savedImage as File, urls.uploadUrl);

    return urls.fileUrl;
  };

  const handleDropImage = (image: Blob) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setFieldValue("img", event?.target?.result);
    };
    reader.readAsDataURL(image);

    setSavedImage(image as File);

    return setFieldValue("img", image);
  };

  const onChangeBannerName = (value: string) => {
    const hasConsecutiveSpaces = / {2,}/.test(value);

    if (hasConsecutiveSpaces) return;

    setFieldValue("title", value.trimStart());
  };

  const onCloseModal = () => {
    onClose();
    resetForm();
    setSavedImage(null);
  };

  const onRemoveImage = () => {
    setFieldValue("img", "");
    setSavedImage(null);
  };

  const onClickTargetType = (val: number) => {
    setFieldValue("targetType", val);
    setFieldValue("targetId", "");
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles as any}
      ariaHideApp={false}
      onRequestClose={onCloseModal}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>
            {banner ? "Edit Banner" : "New Banner"}
          </h4>
          <div className={styles.wrapper_header_icon} onClick={onCloseModal}>
            <ICONS.Cross />
          </div>
        </header>

        <Input
          label="Title"
          placeholder="Enter title"
          value={values.title}
          error={errors.title}
          containerStyle={{ marginBottom: 22 }}
          onChange={onChangeBannerName}
        />

        <Select
          label="Type"
          options={targetRedirectionOptions}
          placeholder="Select type"
          optionsStyle={{ position: "relative" }}
          containerStyle={{ marginBottom: 22 }}
          value={values.targetType}
          onChange={(val) => onClickTargetType(val.value)}
        />

        {values.targetType === BannerTargetRedirection.Equipment && (
          <SelectEquipments
            onChange={(val) => setFieldValue("targetId", val)}
            containerStyle={{ marginBottom: 22 }}
          />
        )}

        {values.targetType === BannerTargetRedirection.Studio && (
          <SelectStudios
            onChange={(val) => setFieldValue("targetId", val)}
            containerStyle={{ marginBottom: 22 }}
          />
        )}

        {values.targetType === BannerTargetRedirection.Profile && (
          <SelectPhotographers
            onChange={(val) => setFieldValue("targetId", val)}
            containerStyle={{ marginBottom: 22 }}
          />
        )}

        {values.targetType === BannerTargetRedirection.Course && (
          <SelectCourses
            onChange={(val) => setFieldValue("targetId", val)}
            containerStyle={{ marginBottom: 22 }}
          />
        )}

        <ImageDropZone
          image={values.img}
          handleSave={handleDropImage}
          error={errors.img}
          onRemoveImage={onRemoveImage}
        />

        <div className={styles.wrapper_button}>
          <div className={styles.wrapper_button_item}>
            <Button
              title={banner ? "Edit" : "Create "}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateUpdateBannerModal;
