import { ReactComponent as Statistics } from "./statistics.svg";
import { ReactComponent as Clients } from "./clients.svg";
import { ReactComponent as Reports } from "./reports.svg";
import { ReactComponent as Professionals } from "./professionals.svg";
import { ReactComponent as Staff } from "./staff.svg";
import { ReactComponent as Categories } from "./categories.svg";
import { ReactComponent as Banners } from "./banners.svg";
import { ReactComponent as Equipments } from "./equipments.svg";
import { ReactComponent as Studios } from "./studios.svg";
import { ReactComponent as Courses } from "./courses.svg";
import { ReactComponent as Verifications } from "./verifications.svg";

const nav = {
  Statistics,
  Clients,
  Reports,
  Professionals,
  Staff,
  Categories,
  Banners,
  Equipments,
  Studios,
  Courses,
  Verifications,
};

export default nav;
