import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "@apollo/client";
import styles from "./styles.module.scss";

import { ReportQueries } from "graphql/queries";
import { ReportMutations } from "graphql/mutations";

import { Pagination, TableHead, SearchInput } from "components";
import Filter from "../Filter";
import ModalInfo from "./ModalInfo";
import Item from "./Item";

import { IDisput } from "types/interfaces";
import { DisputFilterStatus, DisputStatus, Order } from "types/enums";

const getStatus = (status: DisputFilterStatus | null) => {
  if (!status) return {};

  if (status === DisputFilterStatus.Resolved)
    return {
      status: DisputStatus.Resolved,
    };

  return {
    status: DisputStatus.Active,
    open: status === DisputFilterStatus.Active,
  };
};

const LIMIT = 10;

const Disputs: FC = () => {
  const navigate = useNavigate();
  const [updateDisput] = useMutation(ReportMutations.UPDATE_DISPUT);

  const [modalInfo, setModalInfo] = useState<false | IDisput>(false);
  const [count, setCount] = useState<number>(1);

  const [sort, setSort] = useState<{
    sortedBy: string | null;
    type: Order | null;
    currentPage: number;
    disputStatus: null | DisputFilterStatus;
    search: string;
    range: null | [Date | null, Date | null];
  }>({
    sortedBy: null,
    search: "",
    type: Order.DESC,
    currentPage: 1,
    range: null,
    disputStatus: null,
  });

  const [fetchDisputs, { data, loading }] = useLazyQuery<{
    adminDisputes: {
      data: Array<IDisput>;
      meta: { itemCount: number };
    };
  }>(ReportQueries.GET_DISPUTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted(data) {
      setCount(data.adminDisputes.meta.itemCount);
    },
  });

  useEffect(() => {
    fetchDisputs({
      variables: {
        input: {
          skip: (sort.currentPage - 1) * LIMIT,
          take: LIMIT,
          search: sort.search,
          order: sort.type || Order.DESC,
          createdAt: sort.range
            ? {
                from: sort.range[0],
                to: sort.range[1],
              }
            : null,
          ...getStatus(sort.disputStatus),
        },
      },
    });
  }, [sort]);

  const openDisput = async () => {
    try {
      if (!modalInfo) return;
      const resp = await updateDisput({
        variables: { input: { id: modalInfo.id, action: "Open" } },
      });

      navigate(`/reports/${modalInfo.id}`);
    } catch (e) {
      console.log(e);
    } finally {
      setModalInfo(false);
    }
  };

  const renderDisputs = () => {
    if (data?.adminDisputes?.data && data.adminDisputes.data.length > 0) {
      return data?.adminDisputes.data.map((disput) => (
        <Item
          key={disput.id}
          report={disput}
          onPress={() => {
            if (!disput.open && disput.status !== DisputStatus.Resolved) {
              setModalInfo(disput);
              return;
            }

            navigate(`/reports/${disput.id}`);
          }}
        />
      ));
    }

    return <p className={styles.wrapper_empty}>No reports yet</p>;
  };

  return (
    <div>
      <header className={styles.wrapper_header}>
        <div className={styles.wrapper_header_input}>
          <SearchInput
            placeholder="Search"
            value={sort.search}
            onChange={(search) => setSort({ ...sort, search, currentPage: 1 })}
          />
        </div>
        <div className={styles.wrapper_header_filter}>
          <Filter
            dateRange={sort.range}
            disputStatus={sort.disputStatus}
            onChange={(filter) =>
              setSort({
                ...sort,
                range: filter.range,
                //@ts-ignore
                disputStatus: filter.disputStatus,
                currentPage: 1,
              })
            }
          />
        </div>
      </header>
      <TableHead
        items={[
          { title: "Contract", disableSort: true },
          { title: "Reporter", disableSort: true },
          { title: "Reported on", disableSort: true },
          { title: "Status", disableSort: true },
          { title: "Report date", value: "CreatedAt" },
          false,
        ]}
        flexes={[1, 1, 1, 1, 1]}
        sorted={sort}
        onChangeSort={(val) => setSort({ ...sort, ...val })}
      />

      {loading ? (
        <Skeleton
          height={50}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
          count={5}
        />
      ) : (
        renderDisputs()
      )}

      {count !== 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            currentPage={sort.currentPage}
            count={count}
            limit={LIMIT}
            onChange={(page) => setSort({ ...sort, currentPage: page })}
          />
        </div>
      )}
      <ModalInfo
        payload={modalInfo}
        onClose={() => setModalInfo(false)}
        onOpenDisput={openDisput}
      />
    </div>
  );
};

export default Disputs;
