import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation singInAdmin($input: SignInAdminInput!) {
    singInAdmin(input: $input)
  }
`;

export const CREATE_ADMIN = gql`
  mutation createAdmin($input: CreateAdminInput!) {
    createAdmin(input: $input) {
      id
      email
      firstName
      lastName
      avatar
      role
    }
  }
`;

export const UPDATE_ADMIN = gql`
  mutation updateAdmin($input: UpdateAdminInput!) {
    updateAdmin(input: $input) {
      id
      email
      firstName
      lastName
      avatar
      role
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation removeAdmin($id: Int!) {
    removeAdmin(id: $id) {
      id
    }
  }
`;
