import { FC } from "react";
import styles from "./styles.module.scss";

import { Input } from "components";

type Props = {
  from: number | null;
  to: number | null;
  onChange: (from: number | null, to: number | null) => void;
};

const NumberRange: FC<Props> = ({ from, to, onChange }) => {
  const handleFromChange = (newValue: string) => {
    if (/^\d*$/.test(newValue)) {
      const newFromValue = newValue === "" ? null : Number(newValue);
      if (to === null || newFromValue === null || newFromValue <= to) {
        onChange(newFromValue, to);
      }
    }
  };

  const handleToChange = (newValue: string) => {
    if (/^\d*$/.test(newValue)) {
      const newToValue = newValue === "" ? null : Number(newValue);
      onChange(from, newToValue);
    }
  };

  const handleToBlur = () => {
    if (to === null || from === null) return;

    if (to < from) {
      onChange(from, null);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_item}>
        <Input
          label="From"
          value={from !== null ? String(from) : ""}
          onChange={handleFromChange}
        />
      </div>

      <div className={styles.wrapper_seperator} />

      <div className={styles.wrapper_item}>
        <Input
          label="To"
          value={to !== null ? String(to) : ""}
          onBlur={handleToBlur}
          onChange={handleToChange}
        />
      </div>
    </div>
  );
};

export default NumberRange;
