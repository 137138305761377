import { useMemo, useState, useRef, FC } from "react";
import styles from "./styles.module.scss";
import { ICONS } from "assets";
import { useOutsideClick } from "hooks";
import cls from "classnames";
import Input from "components/Input";
import Avatar from "components/Avatar";
import { useQuery } from "@apollo/client";
import { GET_RENTERS } from "graphql/queries/equipments";
import { IUser, UserAccount } from "types";
import Skeleton from "react-loading-skeleton";

interface IOption {
  value: number;
  label: {
    avatarUrl?: string | null;
    userName?: string;
  };
}

interface IProps {
  label?: string;
  placeholder?: string;
  onChange: (val: number) => void;
  containerStyle?: React.CSSProperties;
}

const SelectUsers: FC<IProps> = ({
  label,
  placeholder = "Choose renter’s name",
  containerStyle,
  onChange,
}) => {
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  const { data, loading, fetchMore } = useQuery<{
    findUserPreviews: { previews: IUser[] };
  }>(GET_RENTERS, {
    variables: {
      input: {
        search,
        take: 20,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const options = useMemo(() => {
    if (!data?.findUserPreviews) return [];

    return data.findUserPreviews.previews.map((user) => {
      const userName =
        user.profile?.account === UserAccount.Agency
          ? user.profile.agencyName
          : user.firstName + " " + user.lastName;

      return {
        value: user.id,
        label: {
          avatarUrl: user.avatar,
          userName,
        },
      };
    });
  }, [data]);

  useOutsideClick({
    ref: optionsContainerRef,
    onClickOutside: () => setIsOpen(false),
  });

  const handleSelectOption = (option: IOption) => {
    setSelectedUser(option);
    setIsOpen(false);
    onChange(option.value);
  };

  const foundUsers = useMemo(() => {
    if (!search.trim()) return options;

    if (search) {
      return options.filter((option) =>
        option.label.userName
          ?.toLocaleLowerCase()
          .includes(search.toLowerCase())
      );
    }
    return options;
  }, [search, options]);

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
      event.currentTarget.clientHeight;

    if (bottom) {
      fetchMore({
        variables: {
          input: {
            search,
            take: 20,
            skip: data?.findUserPreviews.previews.length,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;
          return {
            findUserPreviews: {
              previews: [
                ...previousResult.findUserPreviews.previews,
                ...fetchMoreResult.findUserPreviews.previews,
              ],
            },
          };
        },
      });
    }
  };

  return (
    <>
      {label ? <p className={styles.label}>{label}</p> : null}

      <div
        ref={optionsContainerRef}
        className={styles.wrapper}
        style={containerStyle}
      >
        <div
          className={cls(
            styles.wrapper_block,
            isOpen && styles.wrapper_block_active
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div style={{ display: "flex", alignItems: "center", columnGap: 7 }}>
            {!selectedUser ? null : (
              <Avatar
                img={selectedUser?.label.avatarUrl}
                dimensions={{ width: 24, height: 24 }}
              />
            )}

            <p
              className={`${styles.wrapper_block_title} ${
                !selectedUser ? styles.wrapper_block_placeholder : ""
              }`}
            >
              {selectedUser ? selectedUser.label.userName : placeholder}
            </p>
          </div>
          <div className={`${styles.wrapper_block_icon}`}>
            <ICONS.SelectArrow />
          </div>
        </div>

        {isOpen && (
          <div className={styles.wrapper_options} onScroll={handleScroll}>
            <div style={{ marginRight: 8, marginLeft: 8, marginBottom: 8 }}>
              <Input
                value={search}
                placeholder="Search"
                onChange={(val) => setSearch(val)}
                inputStyle={{
                  height: 44,
                  border: "none",
                  background: "#E4E4E440",
                  paddingLeft: 52,
                }}
                icon={<ICONS.Search />}
              />
            </div>
            {loading ? (
              <Skeleton
                height={42}
                style={{
                  marginBottom: 2,
                  marginTop: 2,
                  borderRadius: 10,
                  marginLeft: 10,
                  marginRight: 10,
                }}
                count={3}
              />
            ) : foundUsers && foundUsers.length ? (
              foundUsers?.map((option) => (
                <div
                  key={option.value}
                  className={`${styles.wrapper_options_item} ${
                    option.value === selectedUser?.value
                      ? styles.wrapper_options_item_selected
                      : ""
                  }`}
                  onClick={() => handleSelectOption(option)}
                >
                  <Avatar
                    img={option.label.avatarUrl}
                    dimensions={{ width: 24, height: 24 }}
                  />
                  <p className={styles.wrapper_block_title}>
                    {option.label.userName}
                  </p>
                </div>
              ))
            ) : (
              <div className={styles.no_found}>No photographers found</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SelectUsers;
