import { FC, useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./styles.module.scss";

import { ICONS, IMAGES } from "assets";

type Props = {
  img?: string | null;
  dimensions?: { width: number; height: number; borderRadius?: number };
};

const Avatar: FC<Props> = ({ img, dimensions = { width: 44, height: 44 } }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [localImage, setLocalImage] = useState<null | string>(null);

  useEffect(() => {
    if (typeof img !== "string" && img !== null && img !== undefined) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLocalImage(e.target?.result as string);
      };
      //@ts-ignore
      reader.readAsDataURL(img);
    } else {
      setLocalImage(null);
    }
  }, [img]);

  return (
    <div className={styles.wrapper} style={{ ...dimensions }}>
      {img ? (
        <img
          className={styles.wrapper_image}
          src={localImage || img}
          onLoad={() => setIsLoaded(true)}
          onError={() => setIsError(true)}
          alt="avatar"
        />
      ) : (
        <ICONS.NoAvatar />
      )}

      {!isLoaded && img && (
        <div className={styles.wrapper_skeletone}>
          <Skeleton
            containerClassName="flex-1"
            height={dimensions.height}
            circle
            style={{ zIndex: 0 }}
          />
        </div>
      )}
    </div>
  );
};

export default Avatar;
