import { gql } from "@apollo/client";

export const GET_JOB = gql`
  query adminJob($id: Int!) {
    adminJob(id: $id) {
      id
      status
      asap
      date
      hours
      length
      location {
        id
        short_title
      }
      description
      headline
      createdAt
      category {
        id
        title
      }
    }
  }
`;

export const GET_JOB_CANDIDATE = gql`
  query jobCandidate($id: Int!) {
    jobCandidate(id: $id) {
      id
      type
      status
      message
      attachments
      photographer {
        id
        firstName
        lastName
        avatar
        profile {
          id
          location {
            id
            short_title
          }
          completed
          rating
        }
      }
      createdAt
      payAmount
      payCurrency
      payType
    }
  }
`;

export const GET_JOB_CANDIDATES = gql`
  query adminJobCandidates($input: AdminFindJobCandidateInput!) {
    adminJobCandidates(input: $input) {
      data {
        id
        type
        message
        photographer {
          id
          firstName
          lastName
          avatar
          profile {
            id
            location {
              id
              short_title
            }
            completed
            rating
          }
        }
        createdAt
        payAmount
        payCurrency
        payType
      }
      meta {
        itemCount
      }
    }
  }
`;

export const GET_JOB_DETAIL = gql`
  query jobDetail($id: Int!) {
    adminJob(id: $id) {
      id
      status
      asap
      date
      hours
      length
      attachments
      location {
        id
        short_title
      }
      description
      headline
      createdAt
      category {
        id
        title
      }
    }
    proposal: adminJobCandidates(
      input: { skip: 0, take: 1, jobId: $id, type: Proposal, status: Submitted }
    ) {
      meta {
        itemCount
      }
    }
    invitation: adminJobCandidates(
      input: {
        skip: 0
        take: 1
        jobId: $id
        type: Invitation
        status: Submitted
      }
    ) {
      meta {
        itemCount
      }
    }
    interview: adminJobCandidates(
      input: { skip: 0, take: 1, jobId: $id, status: Active }
    ) {
      meta {
        itemCount
      }
    }
  }
`;

export const GET_PROFESSIONAL_JOBS = gql`
  query adminPhotographerJobs($input: AdminFindPhotographerJobsInput!) {
    adminPhotographerJobs(input: $input) {
      data {
        id
        type
        status
        message
        job {
          id
          asap
          headline
          location {
            id
            full_title
            short_title
            latitude
            longitude
          }
          category {
            id
            title
          }
          date
          hours
          length
          user {
            id
            firstName
            lastName
            avatar
            profile {
              id
              location {
                id
                short_title
              }
              rating
              completed
              jobs
            }
          }
        }
        attachments
        createdAt
        payAmount
        payCurrency
        payType
      }
      meta {
        itemCount
      }
    }
  }
`;
