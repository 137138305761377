import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

import { UserQueries } from "graphql/queries";

import { ICONS } from "assets";

import { JobTags, Categories, Pagination, TableHead } from "components";

import { IJob } from "types/interfaces";
import { Order } from "types/enums";

const LIMIT = 5;

const Postings: FC = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [count, setCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [fetchJobs, { data, loading }] = useLazyQuery<{
    adminClientJobs: {
      data: Array<IJob>;
      meta: { itemCount: number };
    };
  }>(UserQueries.GET_CLIENT_POSTINGS, {
    onCompleted(data) {
      setCount(data.adminClientJobs.meta.itemCount);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    fetchJobs({
      variables: {
        id: Number(clientId),
        input: {
          skip: (currentPage - 1) * LIMIT,
          take: LIMIT,
        },
      },
    });
  }, [currentPage]);

  const renderPostings = () => {
    if (!data?.adminClientJobs.data || data.adminClientJobs.data.length === 0)
      return <p className={styles.wrapper_empty}>No postings yet</p>;

    return data.adminClientJobs.data.map((item) => (
      <div
        key={item.id}
        className={styles.wrapper}
        onClick={() => navigate(`jobs/${item.id}`)}
      >
        <div className={styles.wrapper_info}>
          <JobTags job={item} />
          <h4 className={styles.wrapper_info_title}>{item.headline}</h4>
          <p className={styles.wrapper_info_id}>ID {item.id}</p>
        </div>

        <div className={styles.wrapper_type}>
          <Categories categoris={[item.category]} />
        </div>

        <div className={styles.wrapper_duration}>
          {item.length ? `${item.length} h` : "n/a"}
        </div>

        <div className={styles.wrapper_proposals}>
          {item._count?.JobCandidate || 0}
        </div>

        <div className={styles.wrapper_date}>
          {moment(item.createdAt).format("MMM DD, YYYY")}
        </div>

        <div className={styles.wrapper_btn}>
          <div className={styles.wrapper_btn_wrapper}>
            <ICONS.RightArrow />
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <TableHead
        items={[
          { title: "Posting", disableSort: true },
          { title: "Type", disableSort: true },
          {
            title: "Duration",
            disableSort: true,
            style: { justifyContent: "center" },
          },
          {
            title: "Proposals",
            disableSort: true,
            style: { justifyContent: "center" },
          },
          { title: "Posting date", disableSort: true },
          false,
        ]}
        flexes={[3, 1, 1, 1, 1]}
        sorted={{ sortedBy: "", type: Order.DESC }}
        onChangeSort={() => {}}
      />
      {loading ? (
        <Skeleton
          height={50}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
          count={5}
        />
      ) : (
        renderPostings()
      )}

      {count > 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            count={count}
            currentPage={currentPage}
            limit={LIMIT}
            onChange={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default Postings;
