import { FC, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { BeatLoader } from "react-spinners";
import styles from "./styles.module.scss";

import { JobQueries } from "graphql/queries";

import { ICONS } from "assets";

import { Attachments, Avatar, BackHeader, EmptyMessage } from "components";

import { UserService } from "utils";

import { IJobCandidate } from "types/interfaces";
import { JobCandidateStatus, JobCandidateType } from "types/enums";

const ProposalItem: FC = () => {
  const navigate = useNavigate();
  const { proposalId } = useParams();
  const { data, loading, error } = useQuery<{ jobCandidate: IJobCandidate }>(
    JobQueries.GET_JOB_CANDIDATE,
    {
      variables: { id: Number(proposalId) },
    }
  );

  const links = useMemo(() => {
    if (!data) return [];

    let links = ["Client’s Profile", "Job posting"];
    if (
      data.jobCandidate.type === JobCandidateType.Proposal &&
      data.jobCandidate.status === JobCandidateStatus.Submitted
    ) {
      links.push("Proposal");
    } else if (
      data.jobCandidate.type === JobCandidateType.Invitation &&
      data.jobCandidate.status === JobCandidateStatus.Submitted
    ) {
      links.push("Invitation");
    } else {
      links.push("Inteview");
    }

    return links;
  }, [data]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <BeatLoader color="black" />
      </div>
    );
  }

  if (error) {
    return <EmptyMessage title={error.message} />;
  }

  return (
    <div>
      <BackHeader links={links} title="Some" />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div
            className={styles.wrapper_header_info}
            onClick={() =>
              navigate(`/professionals/${data?.jobCandidate.photographer.id}`)
            }
          >
            <div className={styles.wrapper_header_info_avatar}>
              <Avatar
                img={data?.jobCandidate.photographer.avatar}
                dimensions={{ width: 100, height: 100 }}
              />
            </div>

            <div>
              <p className={styles.wrapper_header_info_name}>
                {data?.jobCandidate.photographer.firstName}{" "}
                {data?.jobCandidate.photographer.lastName}
              </p>
              <p className={styles.wrapper_header_info_location}>
                Los Angeles, CA
              </p>
            </div>
          </div>

          <div className={styles.wrapper_header_statistic}>
            <div className={styles.wrapper_header_statistic_item}>
              <div>
                <p className={styles.wrapper_header_statistic_item_value}>
                  {data?.jobCandidate
                    ? UserService.getCandidateRate(data?.jobCandidate)
                    : "-"}
                </p>
                <p className={styles.wrapper_header_statistic_item_label}>
                  Rate
                </p>
              </div>

              <div className={styles.wrapper_header_statistic_item_line} />
            </div>
            <div className={styles.wrapper_header_statistic_item}>
              <div>
                <p className={styles.wrapper_header_statistic_item_value}>
                  {data?.jobCandidate.photographer.profile?.completed}
                </p>
                <p className={styles.wrapper_header_statistic_item_label}>
                  Successful Jobs
                </p>
              </div>

              <div className={styles.wrapper_header_statistic_item_line} />
            </div>
            <div className={styles.wrapper_header_statistic_item}>
              <div>
                <div className={styles.wrapper_header_statistic_item_rating}>
                  <div
                    className={styles.wrapper_header_statistic_item_rating_icon}
                  >
                    <ICONS.Star />
                  </div>
                  <p className={styles.wrapper_header_statistic_item_value}>
                    {UserService.addZeroToRate(
                      //@ts-ignore
                      data.jobCandidate.photographer.profile?.rating
                    )}
                  </p>
                </div>
                <p className={styles.wrapper_header_statistic_item_label}>
                  Rating
                </p>
              </div>
            </div>
          </div>
        </header>

        <div className={styles.wrapper_section}>
          <p className={styles.wrapper_section_title}>
            {data?.jobCandidate.type === JobCandidateType.Invitation
              ? "Client"
              : "Photographer"}{" "}
            cover letter
          </p>
          <p className={styles.wrapper_cover}>{data?.jobCandidate.message}</p>
        </div>

        {data?.jobCandidate.attachments &&
          data?.jobCandidate.attachments.length > 0 && (
            <div className={styles.wrapper_section}>
              <p className={styles.wrapper_section_title}>Attachments</p>
              <Attachments attachments={data?.jobCandidate.attachments} />
            </div>
          )}
      </div>
    </div>
  );
};

export default ProposalItem;
