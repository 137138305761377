import { FC, useState } from "react";
import styles from "./styles.module.scss";

import {
  Filter,
  TransactionItem,
  TransactionModal,
  TableHead,
} from "components";

import { Order } from "types/enums";

const Transactions: FC = () => {
  const [range, setRange] = useState<null | [Date | null, Date | null]>(null);
  const [isOpenModal, setIsOpenModal] = useState<false | any>(false);

  const renderTransactions = () => {
    return Array.from(Array(10).keys()).map((_, index) => (
      <div key={index} onClick={() => setIsOpenModal(true)}>
        <TransactionItem />
      </div>
    ));
  };

  return (
    <div>
      <div className={styles.wrapper_range}>
        <div className={styles.wrapper_range_item}>
          <Filter.DateRange
            labelFrom={false}
            labelTo={false}
            value={range}
            onChange={setRange}
          />
        </div>
      </div>

      <TableHead
        items={[
          { title: "Transaction", disableSort: true },
          { title: "Type", disableSort: true },
          { title: "Amount", disableSort: true },
          { title: "Date", disableSort: true },
          false,
        ]}
        flexes={[2, 2, 1, 1]}
        sorted={{ sortedBy: "", type: Order.ASC }}
        onChangeSort={() => {}}
      />
      {renderTransactions()}

      <TransactionModal
        payload={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </div>
  );
};

export default Transactions;
