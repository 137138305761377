import { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
  message: any;
};

const File: FC<Props> = ({ message }) => {
  //@ts-ignore
  const props = JSON.parse(message.attachmentProps);

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = message.attachment;
    link.target = "_blank";

    link.click();
  };

  return (
    <div className={styles.wrapper} onClick={downloadFile}>
      <p className={styles.wrapper_name}>{props?.name}</p>
      <p className={styles.wrapper_size}>{props?.size}</p>
    </div>
  );
};

export default File;
