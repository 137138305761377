import { FC, useState, useEffect } from "react";
import Modal from "react-modal";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Select } from "components";

import { IPortfolio } from "types/interfaces";
import { BeatLoader } from "react-spinners";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "100%",
    height: "100%",
    bottom: "auto",
    backgroundColor: "transparent",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 1001,
  },
};

type Props = {
  initialImage: number | false;
  images: Array<IPortfolio>;
  categories: Array<{ label: string; value: any; count: number }>;
  selectedCategoryId: number | "all";
  isLoading?: boolean;
  onChangeCategory: (val: any) => void;
  onFetchMore: () => void;
  onClose: () => void;
};

const Gallery: FC<Props> = ({
  initialImage,
  images,
  categories,
  selectedCategoryId,
  isLoading = false,
  onChangeCategory,
  onFetchMore,
  onClose,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(
    initialImage === false ? 0 : initialImage
  );

  useEffect(() => {
    setCurrentImageIndex(initialImage === false ? 0 : initialImage);
  }, [initialImage]);

  useEffect(() => {
    if (initialImage !== false && images.length - currentImageIndex <= 5) {
      onFetchMore();
    }
  }, [currentImageIndex, initialImage]);

  const currentImage = images[currentImageIndex];

  return (
    <Modal
      isOpen={initialImage !== false}
      // @ts-ignore
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_select}>
            <Select
              options={categories}
              value={selectedCategoryId}
              onChange={(option) => {
                setCurrentImageIndex(0);
                onChangeCategory(option.value);
              }}
            />
          </div>

          <div className={styles.wrapper_header_cross} onClick={onClose}>
            <ICONS.GalleryCross />
          </div>
        </header>

        {isLoading ? (
          <div className={styles.loading}>
            <BeatLoader color="white" size={25} />
          </div>
        ) : (
          <div className={styles.wrapper_content}>
            <div
              className={cls(styles.wrapper_content_arrow, {
                [styles.wrapper_content_arrow_hidden]: currentImageIndex === 0,
              })}
              onClick={() => setCurrentImageIndex(currentImageIndex - 1)}
            >
              <ICONS.GalleryArrow />
            </div>
            <div className={styles.wrapper_content_img}>
              <div className={styles.wrapper_content_img_wrapper}>
                <img src={currentImage.original} />

                <div className={styles.wrapper_content_img_wrapper_likes}>
                  <div
                    className={styles.wrapper_content_img_wrapper_likes_icon}
                  >
                    <ICONS.Like />
                  </div>
                  <p className={styles.wrapper_content_img_wrapper_likes_text}>
                    {images[currentImageIndex].likes || 0}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={cls(
                styles.wrapper_content_arrow,
                styles.wrapper_content_arrow_rotate,
                {
                  [styles.wrapper_content_arrow_hidden]:
                    currentImageIndex === images.length - 1,
                }
              )}
              onClick={() => setCurrentImageIndex(currentImageIndex + 1)}
            >
              <ICONS.GalleryArrow />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Gallery;
