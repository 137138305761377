import { FC, useContext, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "@apollo/client";
import { v4 as uuid } from "uuid";
import styles from "./styles.module.scss";

import { CategoriesQueries, AdminQueries } from "graphql/queries";
import { CategoryMutations } from "graphql/mutations";

import { ICONS } from "assets";

import {
  Button,
  Header,
  SearchInput,
  TableHead,
  ConfirmModal,
} from "components";
import CategoryItem from "./CategoryItem";
import UpdateCreateModal from "./UpdateCreateModal";

import { Loading } from "context";
import { GoogleService } from "utils";

import { Order, QuestionType } from "types/enums";
import { ICategory } from "types/interfaces";

const Categories: FC = () => {
  const { setIsLoading } = useContext(Loading.Context)!;
  const [search, setSearch] = useState<string>("");
  const { data, loading, refetch } = useQuery<{ categories: Array<ICategory> }>(
    CategoriesQueries.GET_CATEGORIES,
    {
      fetchPolicy: "network-only",
    }
  );
  const [deleteModal, setDeleteModal] = useState<false | ICategory>(false);
  const [updateCreateModal, setUpdateCreateModal] = useState<false | any>(
    false
  );

  const [createCategory] = useMutation(CategoryMutations.CREATE_CATEGORY);
  const [updateCategory] = useMutation(CategoryMutations.UPDATE_CATEGORY);
  const [createQuestion] = useMutation(
    CategoryMutations.CREATE_QUESTION
  );
  const [removeQuestion] = useMutation(CategoryMutations.DELETE_QUESTION);
  const [updateQuestion] = useMutation(CategoryMutations.UPDATE_QUESTION);
  const [createUploadUrls] = useLazyQuery(AdminQueries.GET_UPLOAD_URLS);

  const onCreateCategory = async (newCategory: any) => {
    try {
      setIsLoading(true);

      const uniqueId = uuid();

      const {
        data: { generateUploadUrls: urls },
      } = await createUploadUrls({
        variables: { filename: `${uniqueId}${newCategory.cover.name}` },
      });

      await GoogleService.uploadFileToStorage(
        newCategory.cover,
        urls.uploadUrl
      );

      const {
        data: { createCategory: createdCategory },
      } = await createCategory({
        variables: {
          input: {
            cover: urls.fileUrl,
            title_en: newCategory.title_en,
            title_ar: newCategory.title_ar,
          },
        },
      });

      if (newCategory.questionType !== "None") {
        const questionInput: any = {
          categoryId: createdCategory.id,
          title_en: newCategory.question.title_en,
          title_ar: newCategory.question.title_ar,
          type: newCategory.questionType,
        };

        if (newCategory.questionType === QuestionType.Checkbox) {
          questionInput.answers = newCategory.question.answers;
        }

        if (newCategory.questionType === QuestionType.Counter) {
          questionInput.min = 1;
        }

        const {
          data: { createQuestion: createdQuestion },
        } = await createQuestion({
          variables: { input: questionInput },
        });
      }

      await refetch();
      setUpdateCreateModal(false);
      console.log(createdCategory, "----createdCategory");
    } catch (e) {
      debugger;

      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateCategory = async (newCategory: any) => {
    try {
      setIsLoading(true);

      const categoryData: any = {
        id: newCategory.id,
        title_en: newCategory.title_en,
        title_ar: newCategory.title_ar,
      };

      if (typeof newCategory.cover !== "string") {
        const uniqueId = uuid();

        const {
          data: { generateUploadUrls: urls },
        } = await createUploadUrls({
          variables: { filename: `${uniqueId}${newCategory.cover.name}` },
        });

        await GoogleService.uploadFileToStorage(
          newCategory.cover,
          urls.uploadUrl
        );

        categoryData.cover = urls.fileUrl;
      } else {
        categoryData.cover = newCategory.cover;
      }

      if (newCategory.questionType === "None" && newCategory.question.id) {
        await removeQuestion({ variables: { id: newCategory.question.id } });
      }

      if (newCategory.questionType !== "None") {
        if (newCategory.question.id) {
          const data: any = {
            id: newCategory.question.id,
            title_en: newCategory.question.title_en,
            title_ar: newCategory.question.title_ar,
          };

          if (newCategory.questionType === QuestionType.Counter) {
            data.min = 1;
            data.answers = [];
            data.type = QuestionType.Counter;
          }

          if (newCategory.questionType === QuestionType.Checkbox) {
            data.answers = newCategory.question.answers;
            data.type = QuestionType.Checkbox;
          }

          await updateQuestion({
            variables: {
              input: data,
            },
          });
        } else {
          const data: any = {
            categoryId: newCategory.id,
            title_en: newCategory.question.title_en,
            title_ar: newCategory.question.title_ar,
          };

          if (newCategory.questionType === QuestionType.Counter) {
            data.min = 1;
            data.answers = [];
            data.type = QuestionType.Counter;
          }

          if (newCategory.questionType === QuestionType.Checkbox) {
            data.answers = newCategory.question.answers;
            data.type = QuestionType.Checkbox;
          }

          await createQuestion({ variables: { input: data } });
        }
      }

      await updateCategory({ variables: { input: categoryData } });
      await refetch();
      setUpdateCreateModal(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCategory = (newCategory: any) => {
    try {
      if (!newCategory.id) {
        onCreateCategory(newCategory);
      } else {
        onUpdateCategory(newCategory);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderCategories = () => {
    if (!data) return null;

    const filteredCategories = data.categories.filter((c) => {
      return c.title_en
        .toLowerCase()
        .includes(search.trim().toLocaleLowerCase());
    });

    return filteredCategories.map((c, index) => {
      return (
        <div
          key={c.id}
          style={{
            position: "relative",
            zIndex: data.categories.length - index,
          }}
        >
          <CategoryItem
            key={c.id}
            category={c}
            onDelete={() => setDeleteModal(c)}
            onEdit={() => setUpdateCreateModal(c)}
          />
        </div>
      );
    });
  };

  return (
    <div>
      <Header title="Categories" />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_input}>
            <SearchInput
              value={search}
              placeholder="Search"
              onChange={setSearch}
            />
          </div>
          <div className={styles.wrapper_header_button}>
            <Button
              title="Add new"
              icon={<ICONS.Plus />}
              onClick={() => {
                console.log("Some");
                setUpdateCreateModal({
                  avatar: null,
                  title_en: "",
                  title_ar: "",
                });
                //     setUpdateOrCreateManager({
                //       avatar: null,
                //       firstName: "",
                //       lastName: "",
                //       email: "",
                //       role: "Moderator",
                //     });
              }}
            />
          </div>
        </header>

        <TableHead
          items={[
            {
              title: "Category",
              disableSort: true,
            },
            {
              title: "Date edited",
              disableSort: true,
            },
            false,
          ]}
          flexes={[5, 1]}
          sorted={{ sortedBy: "", type: Order.DESC }}
          onChangeSort={() => {}}
        />

        {loading ? (
          <Skeleton
            height={50}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={5}
          />
        ) : (
          renderCategories()
        )}
      </div>

      <ConfirmModal
        isOpen={!!deleteModal}
        title="Are you sure you want to delete the category?"
        onClose={() => setDeleteModal(false)}
        onConfirm={() => console.log("Delete modal")}
      />

      <UpdateCreateModal
        item={updateCreateModal}
        onClose={() => {
          setUpdateCreateModal(false);
        }}
        onCreate={handleCreateCategory}
      />
    </div>
  );
};

export default Categories;
