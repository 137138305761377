import { FC } from "react";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

const TransactionItem: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_info}>Transaction #356788</div>

      <div className={styles.wrapper_type}>Fixed-price payment</div>

      <div className={styles.wrapper_amount}>$600</div>

      <div className={styles.wrapper_date}>Nov 23, 2022</div>

      <div className={styles.wrapper_btn}>
        <div className={styles.wrapper_btn_wrapper}>
          <ICONS.RightArrow />
        </div>
      </div>
    </div>
  );
};

export default TransactionItem;
