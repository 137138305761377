import { FC, useContext, useState } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";
import toast from "react-hot-toast";

import {
  Avatar,
  Button,
  RadioButton,
  Textarea,
  UserRoleBlock,
} from "components";
import { ICONS } from "assets";

import { Loading } from "context";
import { IUnverifiedUser, IUser, ProfileType } from "types";
import _ from "lodash";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { UserMutations } from "graphql/mutations";
import { UserQueries } from "graphql/queries";
import { Loader } from "rsuite";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
    zIndex: 2000,
    padding: " 32px 32px 40px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 2000,
  },
};

enum ReasonsReject {
  MinorSafety = "MinorSafety",
  AdultNudityAndSexualActivities = "AdultNudityAndSexualActivities",
  BullingAndHarassment = "BullingAndHarassment",
  IllegalActivitiesAndRegulatedGoods = "IllegalActivitiesAndRegulatedGoods",
  Other = "Other",
}

const reasonsList = {
  [ReasonsReject.MinorSafety]: "Minor safety",
  [ReasonsReject.AdultNudityAndSexualActivities]:
    "Adult nudity and sexual activities",
  [ReasonsReject.BullingAndHarassment]: "Bulling and harassment",
  [ReasonsReject.IllegalActivitiesAndRegulatedGoods]:
    "Illegal activities and regulated goods",
  [ReasonsReject.Other]: "Other",
};

interface IProps {
  user: IUnverifiedUser | null;
  isOpen: boolean;
  onClose: () => void;
}

const VerificationUserModal: FC<IProps> = ({ user, onClose, isOpen }) => {
  const client = useApolloClient();
  const { isLoading, setIsLoading } = useContext(Loading.Context)!;
  const [otherReason, setOtherReason] = useState("");
  const [approveType, setApproveType] = useState<"Approve" | "Reject">(
    "Approve"
  );
  const [reasonReject, setReasonReject] = useState<ReasonsReject | null>(null);

  const [approveVerification] = useMutation(
    UserMutations.APPROVE_USER_VERIFICATION
  );
  const [rejectVerification] = useMutation(
    UserMutations.REJECT_USER_VERIFICATION
  );

  const { data, loading } = useQuery<{ user: IUser }>(UserQueries.GET_USER, {
    variables: { id: user?.id },
  });

  const onApproveUser = async () => {
    try {
      setIsLoading(true);

      await approveVerification({
        variables: {
          input: {
            id: user?.id,
          },
        },
        refetchQueries: [UserQueries.GET_UNVERIFIED_USERS],
      });

      refetchQueries();

      toast.success(
        `${data?.user.firstName} ${data?.user.lastName} verified successfully`
      );

      onClose();
    } catch (error) {
      toast.error("User approve verification error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRejectUser = async () => {
    try {
      setIsLoading(true);

      await rejectVerification({
        variables: {
          input: {
            id: user?.id,
            rejectedReason: reasonReject,
            ...(reasonReject === ReasonsReject.Other
              ? { rejectedOtherReason: otherReason.trimEnd() }
              : undefined),
          },
        },
        refetchQueries: [UserQueries.GET_UNVERIFIED_USERS],
      });

      refetchQueries();

      toast.success(
        `${data?.user.firstName} ${data?.user.lastName} rejected successfully`
      );

      onClose();
    } catch (error) {
      toast.error("User reject verification error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refetchQueries = () => {
    const unverifiedUsers = client.readQuery({
      query: UserQueries.GET_UNVERIFIED_USERS,
      variables: {
        input: {
          profileTypes: [ProfileType.Client, ProfileType.Photographer],
        },
      },
    });

    client.writeQuery({
      query: UserQueries.GET_UNVERIFIED_USERS,
      variables: {
        input: {
          profileTypes: [ProfileType.Client, ProfileType.Photographer],
        },
      },
      data: {
        findUnverifiedUsers: {
          users: unverifiedUsers.findUnverifiedUsers.users.filter(
            (it: any) => it.id !== user?.id
          ),
          count: unverifiedUsers.findUnverifiedUsers.count - 1,
        },
      },
    });
  };

  const disableButton = () => {
    if (isLoading) return true;

    if (reasonReject === ReasonsReject.Other && otherReason.length < 5)
      return true;

    return false;
  };

  const isPhotographer = data?.user.profile?.type === ProfileType.Photographer;

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles as any}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      {loading ? (
        <Loader
          style={{
            width: 900,
            height: 500,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : (
        <div style={{ overflow: "hidden" }} className={styles.wrapper}>
          <header className={styles.wrapper_header}>
            <h4 className={styles.wrapper_header_title}>Approve user</h4>
            <div className={styles.wrapper_header_icon} onClick={onClose}>
              <ICONS.Cross />
            </div>
          </header>
          <div className={styles.container}>
            <div>
              <UserRoleBlock userRole={data?.user.profile?.type} />
              <div className={styles.user_avatar}>
                <Avatar
                  dimensions={{ width: 44, height: 44 }}
                  img={data?.user.avatar}
                />
                <div>
                  <p
                    className={styles.user_name}
                  >{`${data?.user.firstName} ${data?.user.lastName}`}</p>
                  <p className={styles.user_location}>
                    {data?.user.profile?.location?.short_title}
                  </p>
                </div>
              </div>
              <div className={styles.user_info}>
                <div className={styles.user_info_column}>
                  {isPhotographer && (
                    <div>
                      <p className={styles.user_info_label}>Type</p>
                      <p className={styles.user_info_value}>
                        {data?.user.profile?.account}
                      </p>
                    </div>
                  )}

                  {data?.user.profile?.gender && (
                    <div>
                      <p className={styles.user_info_label}>Gender</p>
                      <p className={styles.user_info_value}>
                        {data?.user.profile?.gender}
                      </p>
                    </div>
                  )}

                  <div>
                    <p className={styles.user_info_label}>Email</p>
                    <p className={styles.user_info_value}>{data?.user.email}</p>
                  </div>
                  {isPhotographer && (
                    <div style={{ maxWidth: 200 }}>
                      <p className={styles.user_info_label}>Speciality</p>
                      <p className={styles.user_info_value}>
                        {data?.user.profile?.categories
                          ?.map((it) => it.title_en)
                          .join(", ")}
                      </p>
                    </div>
                  )}

                  {isPhotographer && (
                    <div>
                      <p className={styles.user_info_label}>Rate</p>
                      <p className={styles.user_info_value}>
                        {data?.user.profile?.rateAmount
                          ? `${data?.user.profile?.rateAmount} ${data?.user.profile?.rateCurrency}`
                          : "N/A"}
                      </p>
                    </div>
                  )}

                  {isPhotographer && (
                    <div>
                      <p className={styles.user_info_label}>Studio</p>
                      <p className={styles.user_info_value}>
                        {data?.user.profile?.studio ? "Yes" : "No"}
                      </p>
                    </div>
                  )}
                </div>
                <div style={{ width: 272, marginTop: "auto" }}>
                  <p className={styles.user_info_label}>About me</p>
                  <p className={styles.user_info_about}>
                    {data?.user.profile?.description || "No info about user "}
                  </p>

                  {data?.user.profile?.photos?.length ? (
                    <div className={styles.user_info_images}>
                      {data?.user.profile?.photos?.map((url, index, photos) => (
                        <img
                          alt="portfolio"
                          src={url}
                          className={styles.user_info_img}
                          style={{ width: `${100 / photos.length}%` }}
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div>
              <div className={styles.user_form}>
                <RadioButton
                  label="Approve User"
                  value={approveType === "Approve"}
                  containerStyle={{ marginBottom: 25 }}
                  onSelect={() => {
                    setApproveType("Approve");
                    setReasonReject(null);
                  }}
                />
                <RadioButton
                  label="Reject"
                  value={approveType === "Reject"}
                  containerStyle={{ marginBottom: 25 }}
                  onSelect={() => {
                    setApproveType("Reject");
                    setReasonReject(ReasonsReject.MinorSafety);
                  }}
                />
                <div style={{ marginLeft: 28 }}>
                  <p className={styles.user_form_label}>Reason of rejection:</p>
                  <div className={styles.user_form_reasons}>
                    {_.entries(reasonsList).map(([key, reason]) => (
                      <RadioButton
                        label={reason}
                        smallTitle
                        value={key === reasonReject}
                        onSelect={() => {
                          if (approveType === "Reject") {
                            setReasonReject(key as ReasonsReject);
                          }
                        }}
                      />
                    ))}
                  </div>

                  {reasonReject === ReasonsReject.Other && (
                    <Textarea
                      value={otherReason}
                      placeholder="Message"
                      onChange={(val) => setOtherReason(val)}
                      maxLength={250}
                      style={{
                        backgroundColor: "#E4E4E47F",
                        borderRadius: 12,
                        height: 153,
                      }}
                      containerStyle={{ marginTop: 12 }}
                    />
                  )}
                </div>
              </div>

              <Button
                title="Save"
                onClick={
                  approveType === "Approve" ? onApproveUser : onRejectUser
                }
                disable={disableButton()}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default VerificationUserModal;
