import { FC, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

import styles from "./styles.module.scss";
import { ICONS } from "assets";
import {
  Button,
  ConfirmModal,
  Header,
  Pagination,
  SearchInput,
  TableHead,
} from "components";
import { Order } from "types/enums";
import { EquipmentsQueries } from "graphql/queries";
import { IEquipment } from "types";
import { useDebounce } from "hooks";
import { DELETE_EQUIPMENT } from "graphql/mutations/equipments";
import CreateUpdateEquipmentModal from "./CreateUpdateEquipmentModal";
import EquipmentItem from "./EquipmentItem";

interface IEquipmentsReq {
  findEquipments: {
    equipments: IEquipment[];
    count: number;
  };
}

enum OrderBy {
  Title = "Title",
  RentPricePerDay = "RentPricePerDay",
  CreatedAt = "CreatedAt",
}

const EquipmentsPage: FC = () => {
  const [currentEquipment, setCurrentEquipment] = useState<any>(null);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [count, setCount] = useState(1);

  const [sort, setSort] = useState<any>({
    sortedBy: OrderBy.CreatedAt,
    type: Order.DESC,
    currentPage: 1,
    search: "",
  });

  const [deleteEquipmentReq] = useMutation(DELETE_EQUIPMENT);

  const [equipments, setEquipments] = useState<IEquipment[]>();
  const [isOpenCreateUpdateModal, setOpenCreateUpdateModal] = useState(false);

  const debounceSearchValue = useDebounce(sort.search, 500);

  const [getEquipments, { loading, refetch }] = useLazyQuery<IEquipmentsReq>(
    EquipmentsQueries.GET_EQUIPMENTS,
    {
      variables: {
        input: {
          title: debounceSearchValue,
          skip: (sort.currentPage - 1) * 10,
          order: sort.type,
          orderBy: sort.sortedBy,
        },
      },
      onCompleted(data) {
        refetch();
        setCount(data?.findEquipments.count);
        setEquipments(data?.findEquipments.equipments);
      },
    }
  );

  useEffect(() => {
    getEquipments();
  }, []);

  const onEditEquipment = (equipment: IEquipment) => {
    setCurrentEquipment(equipment);
    setOpenCreateUpdateModal(true);
  };

  const onDeleteEquipment = (equipment: IEquipment) => {
    setCurrentEquipment(equipment);
    setOpenDeleteModal(true);
  };

  const deleteEquipment = async (equipment: IEquipment | null) => {
    try {
      await deleteEquipmentReq({
        variables: {
          input: {
            id: equipment?.id,
          },
        },
      });

      await refetch();

      setOpenDeleteModal(false);

      toast.success("Equipment deleted successfully");
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const onClickAddNewEquipments = () => {
    setCurrentEquipment(null);
    setOpenCreateUpdateModal(true);
  };

  return (
    <div>
      <Header title="Equipments" />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_input}>
            <SearchInput
              value={sort.search}
              placeholder="Search"
              onChange={(val) =>
                setSort({ ...sort, search: val, currentPage: 1 })
              }
            />
          </div>
          <div className={styles.wrapper_header_button}>
            <Button
              title="Add new"
              icon={<ICONS.Plus />}
              onClick={onClickAddNewEquipments}
            />
          </div>
        </header>

        <TableHead
          items={[
            {
              title: "Equipment",
              value: OrderBy.Title,
              style: {
                width: "30%",
              },
            },
            {
              title: "Address",
              disableSort: true,
              style: {
                width: "23.5%",
              },
            },
            {
              title: "Price (day)",
              value: OrderBy.RentPricePerDay,
              style: {
                width: "10%",
              },
            },
            {
              title: "Status",
              disableSort: true,
              style: {
                width: "12%",
              },
            },
            {
              title: "Date added",
              value: OrderBy.CreatedAt,
              style: { width: "15%" },
            },
            false,
          ]}
          sorted={sort}
          onChangeSort={(val) =>
            setSort({ ...sort, ...val, currentPage: sort.currentPage })
          }
        />

        {loading ? (
          <Skeleton
            height={80}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={5}
          />
        ) : (
          <>
            {equipments?.map((equipment) => {
              return (
                <EquipmentItem
                  key={equipment.id}
                  equipment={equipment}
                  onEdit={onEditEquipment}
                  onDelete={onDeleteEquipment}
                />
              );
            })}
          </>
        )}
      </div>

      <ConfirmModal
        isOpen={isOpenDeleteModal}
        hideCrossIcon
        title={`Are you sure you want to delete the equipment?`}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={() => deleteEquipment(currentEquipment)}
      />

      <CreateUpdateEquipmentModal
        equipment={currentEquipment}
        isOpen={isOpenCreateUpdateModal}
        onClose={() => setOpenCreateUpdateModal(false)}
        refetch={refetch}
      />

      {count > 0 && (
        <div style={{ margin: "10px 0" }}>
          <Pagination
            limit={10}
            count={count}
            currentPage={sort.currentPage}
            onChange={(page) => setSort({ ...sort, currentPage: page })}
          />
        </div>
      )}
    </div>
  );
};

export default EquipmentsPage;
