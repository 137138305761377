import { gql } from "@apollo/client";

export const MOVE_BANNER = gql`
  mutation moveBanner($input: MoveBannerInput!) {
    moveBanner(input: $input) {
      id
    }
  }
`;

export const CREATE_BANNER = gql`
  mutation createBanner($input: CreateBannerInput!) {
    createBanner(input: $input) {
      id
    }
  }
`;

export const UPDATE_BANNER = gql`
  mutation updateBanner($input: UpdateBannerInput!) {
    updateBanner(input: $input) {
      id
    }
  }
`;

export const DELETE_BANNER = gql`
  mutation deleteBanner($input: DeleteBannerInput!) {
    deleteBanner(input: $input) {
      deleted
    }
  }
`;

export const DEACTIVATE_BANNER = gql`
  mutation deactivateBanner($input: DeactivateBannerInput!) {
    deactivateBanner(input: $input) {
      id
    }
  }
`;

export const ACTIVATE_BANNER = gql`
  mutation activateBanner($input: ActivateBannerInput!) {
    activateBanner(input: $input) {
      id
    }
  }
`;
