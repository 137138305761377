import { FC, useRef, useState } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";
import { ICONS } from "assets";
import { ILesson } from "types";
import { formatSecondsToHoursMinutes } from "utils/userService";

const customStyles = {
  content: {
    top: "8%",
    left: "27%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    border: "none",
    borderRadius: 24,
    zIndex: 2000,
    padding: 0,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 2000,
  },
};

interface IProps {
  lesson: ILesson | null;
  isOpen: boolean;
  onClose: () => void;
}

const LessonViewModal: FC<IProps> = ({ lesson, isOpen, onClose }) => {
  const [isPlaying, setPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  if (!lesson) return null;

  const handlePlay = () => {
    videoRef.current?.play();
    setPlaying(true);
  };

  const onDownloadVideo = () => {
    const anchor = document.createElement("a");
    anchor.href = lesson.videoUrl;
    anchor.download = lesson.videoFilename;
    anchor.click();
  };

  const formatSecondsToHMS = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (!hours)
      return `${minutes || "00"}:${
        remainingSeconds
          ? `${remainingSeconds}`.length > 1
            ? remainingSeconds
            : `0${remainingSeconds}`
          : "00"
      }`;

    return `${hours}:${
      minutes ? (`${minutes}`.length > 1 ? minutes : `0${minutes}`) : "00"
    }:${remainingSeconds || "00"}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles as any}
      ariaHideApp={false}
      onAfterClose={() => {
        onClose();
        setPlaying(false);
      }}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4
            className={styles.wrapper_header_title}
          >{`Lesson ${lesson.numbering}`}</h4>
          <div className={styles.wrapper_header_icon} onClick={onClose}>
            <ICONS.Cross />
          </div>
        </header>

        <p className={styles.lesson_name}>{lesson.title}</p>

        <p className={styles.lesson_text}>
          {formatSecondsToHoursMinutes(lesson.durationInSeconds)}
        </p>

        <div className={styles.lesson_video}>
          <video
            ref={videoRef}
            style={{ width: "100%", height: "100%" }}
            controls={isPlaying}
            src={lesson.videoUrl}
          />

          {!isPlaying && (
            <div className={styles.lesson_video_duration}>
              {formatSecondsToHMS(lesson.durationInSeconds)}
            </div>
          )}

          {!isPlaying && (
            <button className={styles.lesson_video_play} onClick={handlePlay}>
              <ICONS.Play style={{ marginLeft: 5 }} />
            </button>
          )}
        </div>

        <p className={styles.lesson_text}>{lesson.description}</p>

        <div className={styles.lesson_download} onClick={onDownloadVideo}>
          <div className={styles.lesson_download_icon}>
            <ICONS.Video />
          </div>
          <div style={{ flex: 1 }}>
            <p className={styles.lesson_download_video_name}>
              {lesson.videoFilename}
            </p>
            <p className={styles.lesson_download_video_size}>
              {lesson.videoSize}
            </p>
          </div>

          <ICONS.Download />
        </div>
      </div>
    </Modal>
  );
};

export default LessonViewModal;
