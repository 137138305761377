import { FC, useState, useRef } from "react";
import moment from "moment";
import cls from "classnames";
import styles from "./styles.module.scss";

import Calendar from "./Calendar";

import { useOutsideClick } from "hooks";

type Props = {
  value: Date | null;
  label?: string | false;
  onChange: (value: Date | null) => void;
};

const DatePicker: FC<Props> = ({ value, label, onChange }) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState<boolean>(false);
  const divRef = useRef<any>(null);
  useOutsideClick({
    ref: divRef,
    onClickOutside: () => setIsOpenCalendar(false),
  });

  return (
    <div ref={divRef} className={styles.container}>
      {label && <p className={styles.container_label}>{label}</p>}
      <div
        className={cls(styles.wrapper, {
          [styles.wrapper_active]: value || isOpenCalendar,
        })}
        onClick={() => setIsOpenCalendar(!isOpenCalendar)}
      >
        {!value ? "MM.DD.YYYY" : moment(value).format("MM.DD.YYYY")}
      </div>

      {isOpenCalendar && (
        <div className={styles.calendar}>
          <Calendar
            value={value}
            onChange={(date) => {
              onChange(date);
              setIsOpenCalendar(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
