import { FC, useRef } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar, Button, Input, Select } from "components";
import { Roles } from "types";

const RoleOptions = [
  { label: "Moderator", value: Roles.Moderator },
  { label: "Equipment Manager", value: Roles.EquipmentManager },
  { label: "Studio Manager", value: Roles.StudioManager },
  { label: "Admin", value: Roles.Admin },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "40%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
    zIndex: 2000,
    padding: "30px",
    maxHeight: "90vh",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 2000,
  },
};

interface IProps {
  item: any | false;
  onClose: () => void;
  onCreate: (newManager: any) => void;
}

function validate(formData: any) {
  const errors: any = {};

  if (
    !formData.firstName ||
    formData.firstName.length < 3 ||
    formData.firstName.length > 30
  ) {
    errors.firstName = "First name must be between 3 and 30 characters.";
  }

  if (
    !formData.lastName ||
    formData.lastName.length < 3 ||
    formData.lastName.length > 30
  ) {
    errors.lastName = "Last name must be between 3 and 30 characters.";
  }

  if (!formData.email || !isValidEmail(formData.email)) {
    errors.email = "Please provide a valid email address.";
  }

  if (
    !formData.id &&
    (!formData.password ||
      formData.password.length < 8 ||
      !containsLetter(formData.password))
  ) {
    errors.password =
      "Password must be at least 8 characters long and contain at least one letter.";
  }

  if (
    formData.id &&
    formData.password.length > 0 &&
    (!formData.password ||
      formData.password.length < 8 ||
      !containsLetter(formData.password))
  ) {
    errors.password =
      "Password must be at least 8 characters long and contain at least one letter.";
  }

  return errors;
}

function containsLetter(str: string) {
  const letterRegex = /[a-zA-Z]/;
  return letterRegex.test(str);
}

// Helper function to check valid email format
function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const CreateUpdateModal: FC<IProps> = ({ item, onClose, onCreate }) => {
  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      id: item.id,
      firstName: item.firstName || "",
      lastName: item.lastName || "",
      avatar: item.avatar || null,
      email: item.email || "",
      password: item.password || "",
      role: item.role || "",
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate,
    onSubmit: handleCreateUpdate,
  });
  const inputFileRef = useRef(null);

  const handleChangeAvatar = (e: any) => {
    setFieldValue("avatar", e.target.files[0]);
  };

  function handleCreateUpdate() {
    onCreate({
      ...values,
      firstName: values.firstName.trimEnd(),
      lastName: values.lastName.trimEnd(),
    });
  }

  const onChangeName = (key: string, value: string) => {
    const hasConsecutiveSpaces = / {2,}/.test(value);

    if (hasConsecutiveSpaces) return;

    setFieldValue(key, value.trimStart());
  };

  return (
    <Modal
      isOpen={!!item}
      //@ts-ignore
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>
            {values.id ? "Update" : "Create"} Manager
          </h4>
          <div className={styles.wrapper_header_icon} onClick={onClose}>
            <ICONS.Cross />
          </div>
        </header>

        <div className={styles.wrapper_avatar}>
          <div className={styles.wrapper_avatar_wrapper}>
            <Avatar
              img={values.avatar}
              dimensions={{ width: 100, height: 100 }}
            />
          </div>
          <p
            className={styles.wrapper_avatar_title}
            //@ts-ignore
            onClick={() => inputFileRef?.current?.click()}
          >
            Change Avatar
          </p>
        </div>

        <div className={styles.wrapper_name}>
          <div className={styles.wrapper_name_item}>
            <Input
              value={values.firstName}
              label="First name"
              placeholder="First name"
              //@ts-ignore
              error={errors.firstName}
              onChange={(val) => onChangeName("firstName", val)}
            />
          </div>
          <div className={styles.wrapper_name_item}>
            <Input
              value={values.lastName}
              label="Last name"
              placeholder="Last name"
              //@ts-ignore
              error={errors.lastName}
              onChange={(val) => onChangeName("lastName", val)}
            />
          </div>
        </div>

        {!values.id && (
          <div className={styles.wrapper_password}>
            <Input
              value={values.email}
              label="Email"
              placeholder="Email"
              //@ts-ignore
              error={errors.email}
              onChange={(val) => setFieldValue("email", val)}
            />
          </div>
        )}

        <div className={styles.wrapper_password}>
          <Input
            value={values.password}
            label="Password"
            placeholder="Password"
            isSucure
            //@ts-ignore
            error={errors.password}
            onChange={(val) => setFieldValue("password", val)}
          />
        </div>

        <div className={styles.wrapper_role}>
          <Select
            label="Role"
            options={RoleOptions}
            value={values.role}
            optionsStyle={{ position: "relative" }}
            onChange={(val) => setFieldValue("role", val.value)}
          />
        </div>

        <Button
          title={values.id ? "Update" : "Create"}
          onClick={handleSubmit}
        />
      </div>

      <input
        type="file"
        ref={inputFileRef}
        accept="image/jpeg, image/png"
        style={{ display: "none" }}
        onChange={handleChangeAvatar}
      />
    </Modal>
  );
};

export default CreateUpdateModal;
