import { FC } from "react";
import styles from "./styles.module.scss";

import { RadioButton } from "components";

type Props = {
  items: Array<{ label: string; value: any }>;
  value: string | null;
  title: string;
  onChange: (value: any | null) => void;
};

const List: FC<Props> = ({ items, value, title, onChange }) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{title}</p>
      {items.map((item, index) => (
        <div key={index} className={styles.wrapper_item}>
          <RadioButton
            value={item.value === value}
            label={item.label}
            onSelect={() => onChange(item.value)}
          />
        </div>
      ))}
    </div>
  );
};

export default List;
