import { FC, useMemo } from "react";
import Modal from "react-modal";
import moment from "moment";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar, JobTags, Attachments, Categories } from "components";

import { IJobCandidate } from "types/interfaces";
import { UserService } from "utils";
import { JobCandidateStatus, JobCandidateType } from "types/enums";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "50%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

type Props = {
  candidate: IJobCandidate | false;
  onClose: () => void;
};

const InfoModal: FC<Props> = ({ candidate, onClose }) => {
  const headerTitle = useMemo(() => {
    if (!candidate) return "";

    if (
      candidate.type === JobCandidateType.Invitation &&
      candidate.status === JobCandidateStatus.Submitted
    )
      return "Invitation";
    if (
      candidate.type === JobCandidateType.Proposal &&
      candidate.status === JobCandidateStatus.Submitted
    )
      return "Proposal";

    return "Interview";
  }, [candidate]);

  if (!candidate) return null;

  return (
    <Modal
      isOpen={!!candidate}
      //@ts-ignore
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h3 className={styles.wrapper_header_title}>{headerTitle}</h3>

          <div className={styles.wrapper_header_icon} onClick={onClose}>
            <ICONS.Cross />
          </div>
        </header>

        <div className={styles.wrapper_user}>
          <div className={styles.wrapper_user_avatar}>
            <Avatar img={candidate.job.user.avatar} />
          </div>
          <div className={styles.wrapper_user_info}>
            <p className={styles.wrapper_user_info_name}>
              {candidate.job.user.firstName} {candidate.job.user.lastName}
            </p>
            <p className={styles.wrapper_user_info_location}>
              {candidate.job.user.profile?.location?.short_title}
            </p>
          </div>
        </div>

        <h2 className={styles.wrapper_title}>{candidate.job.headline}</h2>

        <div className={styles.wrapper_tags}>
          <div>
            <JobTags job={candidate.job} />
          </div>

          <p className={styles.wrapper_tags_date}>
            {moment(candidate.createdAt).format("MMM DD, YYYY")}
          </p>
        </div>

        {candidate.message && (
          <div className={styles.wrapper_cover}>
            <h4 className={styles.wrapper_subtitle}>Cover Letter</h4>
            <p className={styles.wrapper_cover_text}>{candidate.message}</p>
          </div>
        )}
        {candidate.attachments.length > 0 && (
          <div className={styles.wrapper_attachments}>
            <h4 className={styles.wrapper_subtitle}>Attachments</h4>
            <Attachments attachments={candidate.attachments} />
          </div>
        )}

        <div className={styles.wrapper_rate}>
          <p className={styles.wrapper_rate_title}>Project Rate</p>
          <p className={styles.wrapper_rate_value}>
            {UserService.getCandidateRate(candidate)}
          </p>
        </div>

        <div className={styles.wrapper_categories}>
          <Categories categoris={[candidate.job.category]} />
        </div>

        <div className={styles.wrapper_link}>
          <Link
            to={`/clients/${candidate.job.user.id}/jobs/${candidate.job.id}`}
            className={styles.wrapper_link_text}
          >
            Show Job Posting
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
