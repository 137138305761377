import { FC, useState } from "react";
import styles from "./styles.module.scss";

import { RadioButton } from "components";

type Props = {
  value: string | null;
  onChange: (value: string) => void;
};

const Reasons = [
  "Minor safety",
  "Adult nudity and sexual activities",
  "Bulling and harassment",
  "Illegal activities and regulated goods",
];

const BlockReason: FC<Props> = ({ value, onChange }) => {
  const [isOther, setIsOther] = useState<boolean>(false);

  const handleChange = (value: string) => {
    const isHaveTwoEmptyLines = /\n\s*\n\s*\n/.test(value);

    const hasConsecutiveSpaces = / {2,}/.test(value);

    const isHaveStartSpaceOnSomeLine = /^ /m.test(value);

    if (
      isHaveTwoEmptyLines ||
      isHaveStartSpaceOnSomeLine ||
      hasConsecutiveSpaces
    )
      return;

    onChange(value.trimStart());
  };

  return (
    <div className={styles.wrapper_second}>
      <p className={styles.wrapper_second_title}>Choose a reason:</p>
      {Reasons.map((r, index) => (
        <div key={index} className={styles.wrapper_second_item}>
          <RadioButton
            value={value === r}
            label={r}
            smallTitle
            onSelect={() => {
              setIsOther(false);
              onChange(r);
            }}
          />
        </div>
      ))}

      <RadioButton
        value={isOther}
        label="Other"
        smallTitle
        onSelect={() => {
          onChange("");
          setIsOther(true);
        }}
      />

      {isOther && (
        <textarea
          className={styles.wrapper_second_input}
          value={value || ""}
          placeholder="Message"
          onChange={(e) => handleChange(e.target.value)}
        />
      )}
    </div>
  );
};

export default BlockReason;
