import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      title
      title_en
      title_ar
      cover
      question {
        id
        title
        title_ar
        title_en
        answers
        max
        min
        step
        type
      }
    }
  }
`;
