import { FC } from "react";
import cls from "classnames";
import styles from "./Tabs.module.scss";

type Props = {
  tabs: Array<{ title: string; count?: number }>;
  currentIndex: number;
  onChange: (newIndex: number) => void;
  containerStyle?: React.CSSProperties
};

const Tabs: FC<Props> = ({ tabs, currentIndex, onChange, containerStyle }) => {
  return (
    <div className={styles.wrapper} style={containerStyle}>
      {tabs.map((t, index) => {
        const isSelected = index === currentIndex;

        return (
          <div
            key={index}
            className={cls(styles.wrapper_item, {
              [styles.wrapper_item_active]: isSelected,
            })}
            onClick={() => onChange(index)}
          >
            <p
              className={cls(styles.wrapper_item_title, {
                [styles.wrapper_item_title_active]: isSelected,
              })}
            >
              {t.title}
            </p>

            {t.count !== undefined && (
              <div
                className={cls(styles.wrapper_item_count, {
                  [styles.wrapper_item_count_active]: isSelected,
                })}
              >
                {t.count}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
