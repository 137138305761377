import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import { Button, Avatar } from "components";
import Messages from "../Messages";

import { IDisput } from "types/interfaces";
import { ProfileType } from "types/enums";

type Props = {
  disput: IDisput;
};

const Wrapper: FC<Props> = ({ disput }) => {
  const reporter =
    disput.creatorId === disput.contract.client.id
      ? disput.contract.client
      : disput.contract.photographer;
  const reported =
    disput.subjectId === disput.contract.client.id
      ? disput.contract.client
      : disput.contract.photographer;

  return (
    <>
      <header className={styles.content_header}>
        <div className={styles.content_header_item}>
          <p className={styles.content_header_item_title}>Reporter</p>

          <div className={styles.content_header_item_info}>
            <div className={styles.content_header_item_info_avatar}>
              <Avatar img={reporter.avatar} />
            </div>
            <div>
              <p className={styles.content_header_item_info_name}>
                {reporter.firstName} {reporter.lastName}
              </p>
              <p className={styles.content_header_item_info_type}>
                {reporter.profile?.type === ProfileType.Client
                  ? "Client"
                  : "Professional"}
              </p>
            </div>
          </div>

          <div>
            <Button
              title="Close in favour of Client"
              onClick={() => console.log("Some txt")}
            />
          </div>
        </div>

        <div className={styles.content_header_item}>
          <p className={styles.content_header_item_title}>Reported On</p>

          <div className={styles.content_header_item_info}>
            <div className={styles.content_header_item_info_avatar}>
              <Avatar img={reported.avatar} />
            </div>
            <div>
              <p className={styles.content_header_item_info_name}>
                {reported.firstName} {reported.lastName}
              </p>
              <p className={styles.content_header_item_info_type}>
                {reported.profile?.type === ProfileType.Client
                  ? "Client"
                  : "Professional"}
              </p>
            </div>
          </div>

          <div>
            <Button
              title="Close in favour of Client"
              onClick={() => console.log("Some txt")}
            />
          </div>
        </div>

        <div className={styles.content_header_date}>
          {moment(disput.createdAt).format("MMM DD, YYYY")}
        </div>
      </header>

      <div className={styles.content_messages}>
        <Messages
          // @ts-ignore
          chatId={disput.chat.id}
          reporterId={reporter.id}
          reportedId={reported.id}
        />
      </div>
    </>
  );
};

export default Wrapper;
