import { FC, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

import { JobQueries } from "graphql/queries";

import {
  TableHead,
  Pagination,
  EmptyMessage,
  PhotographerJobCandidate,
  SearchInput,
} from "components";
import CandidateItem from "../CandidateItem";

import { IJobCandidate, IPaginationMeta } from "types/interfaces";
import { JobCandidateStatus, JobCandidateType, Order } from "types/enums";

type Props = {
  id: number;
  requestData: {
    status: JobCandidateStatus;
    type?: JobCandidateType;
  };
  emptyTitle?: string;
  onPress: (candidate: IJobCandidate) => void;
};

const LIMIT = 10;

const List: FC<Props> = ({
  id,
  emptyTitle = "No proposals yet",
  requestData,
  onPress,
}) => {
  const [count, setCount] = useState<number>(1);
  const [sort, setSort] = useState<{
    sortedBy: string | null;
    type: Order | null;
    search: string;
    currentPage: number;
  }>({
    sortedBy: null,
    type: null,
    search: "",
    currentPage: 1,
  });

  const [fetchProposals, { data, loading }] = useLazyQuery<{
    adminPhotographerJobs: {
      data: Array<IJobCandidate>;
      meta: IPaginationMeta;
    };
  }>(JobQueries.GET_PROFESSIONAL_JOBS, {
    onCompleted(data) {
      setCount(data.adminPhotographerJobs.meta.itemCount);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    fetchProposals({
      variables: {
        input: {
          skip: (sort.currentPage - 1) * LIMIT,
          take: LIMIT,
          userId: id,
          search: sort.search,
          orderBy: sort.sortedBy,
          order: sort.type,
          ...requestData,
        },
      },
    });
  }, [sort, requestData]);

  const renderPhotographers = () => {
    if (
      !data?.adminPhotographerJobs.data ||
      data.adminPhotographerJobs.data.length === 0
    )
      return <EmptyMessage title={emptyTitle} />;

    return data?.adminPhotographerJobs.data.map((item) => (
      <div key={item.id} onClick={() => onPress(item)}>
        <PhotographerJobCandidate candidate={item} />
      </div>
    ));
  };

  return (
    <div>
      <div className={styles.wrapper_input}>
        <SearchInput
          value={sort.search}
          placeholder="Search"
          onChange={(val) => setSort({ ...sort, currentPage: 1, search: val })}
        />
      </div>
      <TableHead
        items={[
          { title: "Contract", disableSort: true },
          { title: "Client", value: "Client" },
          { title: "Budget", value: "Budget" },
          { title: "Received", value: "Received" },
          false,
        ]}
        flexes={[2, 2, 1, 1]}
        sorted={sort}
        onChangeSort={(data) => setSort({ ...sort, ...data })}
      />
      {loading ? (
        <Skeleton
          height={50}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
          count={5}
        />
      ) : (
        renderPhotographers()
      )}

      {count > 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            count={count}
            currentPage={sort.currentPage}
            limit={LIMIT}
            onChange={(page) => setSort({ ...sort, currentPage: page })}
          />
        </div>
      )}
    </div>
  );
};

export default List;
