import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

type Props = {
  message: any;
};

const Video: FC<Props> = ({ message }) => {
  return <video className={styles.video} src={message.attachment} controls />;
};

export default Video;
