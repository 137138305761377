import { gql } from "@apollo/client";

export const DELETE_STUDIO = gql`
  mutation deleteStudio($input: DeleteStudioInput!) {
    deleteStudio(input: $input) {
      success
    }
  }
`;

export const CREATE_STUDIO = gql`
  mutation createStudio($input: CreateStudioInput!) {
    createStudio(input: $input) {
      id
    }
  }
`;

export const UPDATE_STUDIO = gql`
  mutation updateStudio($input: UpdateStudioInput!) {
    updateStudio(input: $input) {
      id
    }
  }
`;

export const DEACTIVATE_STUDIO = gql`
  mutation deactivateStudio($input: DeactivateStudioInput!) {
    deactivateStudio(input: $input) {
      success
    }
  }
`;

export const ACTIVATE_STUDIO = gql`
  mutation activateStudio($input: ActivateStudioInput!) {
    activateStudio(input: $input) {
      success
    }
  }
`;

export const CREATE_STUDIO_RENTAL = gql`
  mutation createStudioRental($input: CreateStudioRentalInput!) {
    createStudioRental(input: $input) {
      success
    }
  }
`;
