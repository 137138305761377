import { FC } from "react";
import { useQuery } from "@apollo/client";
import { BeatLoader } from "react-spinners";
import styles from "./styles.module.scss";

import { UserQueries } from "graphql/queries";

import { EmptyMessage, Categories } from "components";
import About from "./About";
import Portfolio from "./Portfolio";

import { IUser } from "types/interfaces";

type Props = {
  id: number;
};

const Profile: FC<Props> = ({ id }) => {
  const { data, loading, error } = useQuery<{ user: IUser }>(
    UserQueries.GET_USER,
    { variables: { id } }
  );

  if (loading) {
    return (
      <div className={styles.loading}>
        <BeatLoader color="black" />
      </div>
    );
  }

  if (error) {
    return <EmptyMessage title={error.message} />;
  }

  if (!data) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_categories}>
        <Categories categoris={data.user.profile?.categories || []} />
      </div>

      <div className={styles.wrapper_section}>
        <About user={data.user} />
      </div>
      <div className={styles.wrapper_section}>
        <Portfolio id={id} />
      </div>
    </div>
  );
};

export default Profile;
