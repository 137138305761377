import { FC } from "react";
import cls from "classnames";
import styles from "./Middle.module.scss";

import { ICONS } from "assets";

const data = [
  { title: "Professionals", percent: -16 },
  { title: "Clients", percent: 20 },
  { title: "Contracts", percent: 32 },
  { title: "Job Postings", percent: 71 },
  { title: "Commissions", percent: -32 },
];

const Middle: FC = () => {
  return (
    <div className={styles.wrapper}>
      {data.map((item, index) => (
        <div key={index} className={styles.wrapper_item}>
          <header className={styles.wrapper_item_header}>
            <h5 className={styles.wrapper_item_header_title}>{item.title}</h5>
            <p className={styles.wrapper_item_header_value}>67,747</p>
          </header>
          <footer className={styles.wrapper_item_footer}>
            <h5 className={styles.wrapper_item_footer_title}>Today</h5>
            <div className={styles.wrapper_item_footer_wrapper}>
              <p className={styles.wrapper_item_footer_wrapper_value}>347</p>
              <div className={styles.wrapper_item_footer_wrapper_icon}>
                {item.percent < 0 ? <ICONS.CircleDown /> : <ICONS.CircleUp />}
              </div>
              <p
                className={cls(styles.wrapper_item_footer_wrapper_helper, {
                  [styles.wrapper_item_footer_wrapper_helper_minus]:
                    item.percent < 0,
                })}
              >
                {Math.abs(item.percent)}%
              </p>
            </div>
          </footer>
        </div>
      ))}
    </div>
  );
};

export default Middle;
