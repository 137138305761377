import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar } from "components";

import { IReport } from "types/interfaces";

type Props = {
  report: IReport;
};

const Item: FC<Props> = ({ report: item }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_info}>
        <div className={styles.wrapper_info_avatar}>
          <Avatar img={item.reporter.avatar} />
        </div>
        <div>
          <p className={styles.wrapper_info_name}>
            {item.reporter.firstName} {item.reporter?.lastName}
          </p>
        </div>
      </div>

      <div className={styles.wrapper_category}>{item.subject}</div>

      <div className={styles.wrapper_date}>
        {moment(item.createdAt).format("MMM DD, YYYY")}
      </div>

      <div className={styles.wrapper_btn}>
        <div className={styles.wrapper_btn_wrapper}>
          <ICONS.RightArrow />
        </div>
      </div>
    </div>
  );
};

export default Item;
