import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import { DatePicker } from "components";

type Props = {
  value: null | [Date | null, Date | null];
  labelFrom?: string | false;
  labelTo?: string | false;
  onChange: (val: null | [Date | null, Date | null]) => void;
};

const DateRange: FC<Props> = ({
  value,
  labelFrom = "From",
  labelTo = "To",
  onChange,
}) => {
  const handleChangeFrom = (day: Date | null) => {
    if (!value || !value[1]) {
      onChange([day, value ? value[1] : null]);
      return;
    }

    if (moment(day).diff(moment(value[1]), "day") > 0) {
      onChange([day, null]);
      return;
    }

    onChange([day, value[1]]);
  };

  const handleChangeTo = (day: Date | null) => {
    if (!value || !value[0]) {
      onChange([value ? value[0] : null, day]);
      return;
    }

    if (moment(day).diff(moment(value[0]), "day") < 0) {
      onChange([null, day]);
      return;
    }

    onChange([value[0], day]);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_item}>
        <DatePicker
          value={value ? value[0] : null}
          label={labelFrom}
          onChange={handleChangeFrom}
        />
      </div>
      <p className={styles.wrapper_separator}>-</p>
      <div className={styles.wrapper_item}>
        <DatePicker
          value={value ? value[1] : null}
          label={labelTo}
          onChange={handleChangeTo}
        />
      </div>
    </div>
  );
};

export default DateRange;
