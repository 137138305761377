import React, { FC } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";

import { Button } from "components";

import { ICONS } from "assets";
import { VariantButton } from "types/enums";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "30%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
    padding: 0,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 1010,
  },
};

type Props = {
  isOpen: boolean;
  title: string;
  disableConfirmBtn?: boolean;
  children?: React.ReactNode;
  description?: string;
  onConfirm: () => void;
  onClose: () => void;
  hideCrossIcon?: boolean;
  confirmTextBtn?: string;
};

const ConfirmModal: FC<Props> = ({
  isOpen,
  title,
  description,
  children,
  hideCrossIcon,
  disableConfirmBtn = false,
  confirmTextBtn = "Confirm",
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      //@ts-ignore
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4
            className={styles.wrapper_header_title}
            style={{ textAlign: hideCrossIcon ? "center" : "left" }}
          >
            {title}
          </h4>
          {hideCrossIcon ? null : (
            <div className={styles.wrapper_header_icon} onClick={onClose}>
              <ICONS.Cross />
            </div>
          )}
        </header>

        {description && (
          <p className={styles.wrapper_description}>{description}</p>
        )}

        {children}

        <div className={styles.wrapper_buttons}>
          <div className={styles.wrapper_buttons_item}>
            <Button title="Cancel" onClick={onClose} />
          </div>
          <div className={styles.wrapper_buttons_item}>
            <Button
              title={confirmTextBtn}
              variant={VariantButton.Red}
              disable={disableConfirmBtn}
              onClick={onConfirm}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
