import { FC, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

import { UserQueries } from "graphql/queries";

import {
  ActiveContractItem,
  ContractModal,
  EmptyMessage,
  Pagination,
  TableHead,
  SearchInput,
} from "components";

import { Order } from "types/enums";
import { IContract } from "types/interfaces";

type Props = {
  id: number;
};

const LIMIT = 10;

const ActiveContracts: FC<Props> = ({ id }) => {
  const [modalPayload, setModalPayload] = useState<IContract | any>(false);
  const [count, setCount] = useState<number>(1);
  const [sort, setSort] = useState<{
    sortedBy: string | null;
    type: Order | null;
    search: string;
    currentPage: number;
  }>({
    sortedBy: null,
    type: null,
    search: "",
    currentPage: 1,
  });

  const [fetchContracts, { data, loading }] = useLazyQuery<{
    adminPhotographerContracts: {
      data: Array<IContract>;
      meta: { itemCount: number };
    };
  }>(UserQueries.GET_PROFESSIONAL_CONTRACTS, {
    onCompleted(data) {
      setCount(data.adminPhotographerContracts.meta.itemCount);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    fetchContracts({
      variables: {
        adminPhotographerContractsId: Number(id),
        input: {
          skip: (sort.currentPage - 1) * LIMIT,
          take: LIMIT,
          ended: false,
          orderBy: sort.sortedBy,
          order: sort.type,
          search: sort.search,
        },
      },
    });
  }, [sort]);

  const renderContracts = () => {
    if (
      !data?.adminPhotographerContracts.data ||
      data.adminPhotographerContracts.data.length === 0
    )
      return <EmptyMessage title="No active contracts yet" />;

    return data?.adminPhotographerContracts.data.map((item) => (
      <div key={item.id} onClick={() => setModalPayload(item)}>
        <ActiveContractItem contract={item} showPhotographer={false} />
      </div>
    ));
  };

  return (
    <div>
      <div className={styles.wrapper_input}>
        <SearchInput
          value={sort.search}
          placeholder="Search"
          onChange={(val) => setSort({ ...sort, currentPage: 1, search: val })}
        />
      </div>
      <TableHead
        items={[
          { title: "Contract", disableSort: true },
          { title: "Client", value: "User" },
          { title: "Budget", value: "Budget" },
          { title: "Start date", value: "Start" },
          false,
        ]}
        sorted={sort}
        onChangeSort={(data) => setSort({ ...sort, ...data })}
        flexes={[3, 2, 1, 1]}
      />
      {loading ? (
        <Skeleton
          height={50}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
          count={5}
        />
      ) : (
        renderContracts()
      )}

      {count > 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            count={count}
            currentPage={sort.currentPage}
            limit={LIMIT}
            onChange={(page) => setSort({ ...sort, currentPage: page })}
          />
        </div>
      )}

      <ContractModal
        payload={modalPayload}
        showPhotographer={false}
        onClose={() => setModalPayload(false)}
      />
    </div>
  );
};

export default ActiveContracts;
