import { FC, useContext } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import styles from "./styles.module.scss";
import toast from "react-hot-toast";

import { Button, Input, Textarea } from "components";
import { ICONS } from "assets";

import { ICourseDetail } from "types/interfaces";
import { useMutation } from "@apollo/client";
import { Loading } from "context";

import { CoursesMutations } from "graphql/mutations";
import { CoursesQueries } from "graphql/queries";

const customStyles = {
  content: {
    position: "relative",
    width: "660px",
    maxHeight: "90vh",
    padding: 30,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 2000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const validate = (values: any) => {
  const errors: any = {};

  if (values.title.length < 3) {
    errors.title = "Min length: 3";
  }

  if (values.title.trim() === "") {
    errors.title = "Title is required";
  }

  if (values.title.length > 300) {
    errors.title = "Max length: 300";
  }

  if (values.desc.length < 3) {
    errors.desc = "Min length: 3";
  }

  if (values.desc.length > 3000) {
    errors.desc = "Max length: 3000";
  }

  if (values.desc.trim() === "") {
    errors.desc = "Description is required";
  }

  if (values.price > 1000) {
    errors.price = "Max price: 100000";
  }

  return errors;
};

interface IProps {
  course?: ICourseDetail | null;
  isOpen: boolean;
  onClose: () => void;
}

const CreateUpdateCourseModal: FC<IProps> = ({ course, onClose, isOpen }) => {
  const { setIsLoading } = useContext(Loading.Context)!;

  const [createMosawerCourse] = useMutation(
    CoursesMutations.CREATE_MOSAWER_COURSE
  );

  const [updateCourse] = useMutation(CoursesMutations.UPDATE_COURSE);

  const { values, errors, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      title: course?.title || "",
      desc: course?.description || "",
      price: String(course?.price || ""),
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: (values) => validate(values),
    onSubmit: course ? handleUpdateCourse : handleCreateCourse,
  });

  async function handleCreateCourse() {
    try {
      setIsLoading(true);

      await createMosawerCourse({
        variables: {
          input: {
            title: values.title.trimEnd(),
            description: values.desc.trimEnd(),
            price: values.price ? Number(values.price) : 0,
          },
        },
        refetchQueries: [CoursesQueries.GET_COURSES],
      });

      onCloseModal();

      toast.success("Course created successfully");
    } catch (error) {
      console.log(error);

      toast.error("Error created course");
    } finally {
      setIsLoading(false);
    }
  }

  async function handleUpdateCourse() {
    try {
      setIsLoading(true);

      const lessons = course?.lessons.length
        ? {
            lessons: course?.lessons.map((it) => ({ id: it.id })),
          }
        : undefined;

      await updateCourse({
        variables: {
          input: {
            id: course?.id,
            title: values.title.trimEnd(),
            description: values.desc.trimEnd(),
            price: Number(values.price),
            ...lessons,
          },
        },
        refetchQueries: [CoursesQueries.GET_COURSE_DETAIL],
      });

      onCloseModal();

      toast.success("Course updated successfully");
    } catch (error) {
      toast.error("Error updated course");
      console.error("Error updated course", error);
    } finally {
      setIsLoading(false);
    }
  }

  const onChangePriceCourse = (price: string) => {
    const formattedPrice = price.replace(/[^0-9.]/g, "");

    if (formattedPrice.startsWith(".")) return;

    setFieldValue("price", formattedPrice);
  };

  const onChangeCourseName = (value: string) => {
    const hasConsecutiveSpaces = / {2,}/.test(value);

    if (hasConsecutiveSpaces) return;

    setFieldValue("title", value.trimStart());
  };

  const onCloseModal = () => {
    onClose();
    resetForm();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles as any}
      ariaHideApp={false}
      onRequestClose={onCloseModal}
    >
      <div style={{ overflow: "hidden" }} className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>
            {course ? "Edit Course" : "New Course"}
          </h4>
          <div className={styles.wrapper_header_icon} onClick={onCloseModal}>
            <ICONS.Cross />
          </div>
        </header>

        <div className={styles.wrapper_input}>
          <Input
            label="Title"
            placeholder="Enter title"
            value={values.title}
            error={errors.title}
            maxLength={300}
            onChange={onChangeCourseName}
          />
        </div>

        <div className={styles.wrapper_input}>
          <Textarea
            label="Description"
            maxLength={5000}
            value={values.desc}
            error={errors.desc}
            onChange={(val) => setFieldValue("desc", val.trimStart())}
          />
        </div>

        <div className={styles.wrapper_input}>
          <Input
            label="Price (KWD)"
            placeholder="Enter price"
            value={values.price}
            error={errors.price}
            onChange={(val) => onChangePriceCourse(val.trimStart())}
          />
        </div>

        <div className={styles.wrapper_button}>
          <div className={styles.wrapper_button_item}>
            <Button
              title={course ? "Edit" : "Create"}
              onClick={handleSubmit}
              disable={!values.title || !values.desc}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateUpdateCourseModal;
