import { FC, useContext, useState, useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import styles from "./styles.module.scss";

import { UserQueries } from "graphql/queries";
import { UserMutations } from "graphql/mutations";

import { ICONS } from "assets";

import {
  Avatar,
  BackHeader,
  BlockReason,
  Button,
  ConfirmModal,
  EmptyMessage,
  Tabs,
  Select,
  Switcher,
} from "components";
import {
  Profile,
  ActiveContracts,
  CompletedContracts,
  Jobs,
  Transactions,
  MonthlySummary,
} from "./Tabs";

import { UserService } from "utils";
import { Loading } from "context";
import { useTab } from "hooks";

import { IPaginationMeta, IUser } from "types/interfaces";
import { VariantButton } from "types/enums";
import toast from "react-hot-toast";

const FeeOptions = [
  { label: "5%", value: 5 },
  { label: "10%", value: 10 },
  { label: "15%", value: 15 },
  { label: "20%", value: 20 },
  { label: "25%", value: 25 },
  { label: "30%", value: 30 },
];

const ProfessionalItem: FC = () => {
  const navigate = useNavigate();
  const { currentTab, onChange } = useTab();
  const { id } = useParams();
  const { setIsLoading } = useContext(Loading.Context)!;
  const { data, loading, error } = useQuery<{
    user: IUser;
    active: { meta: IPaginationMeta };
    completed: { meta: IPaginationMeta };
  }>(UserQueries.PROFESSIONAL_PROFILE, {
    variables: { id: Number(id) },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "cache-first",
  });

  const { data: isAllow, refetch } = useQuery<{
    findUserPrivileges: {
      isExemptFromRequiredCourseCreationRules: boolean;
    };
  }>(UserQueries.GET_ALLOW_CREATED_COURSE, {
    variables: {
      input: {
        userId: Number(id),
      },
    },
  });

  const isAllowCreatedCourse =
    isAllow?.findUserPrivileges.isExemptFromRequiredCourseCreationRules;

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [blockModalOpen, setBlockModalOpen] = useState<boolean>(false);
  const [blockReason, setBlockReason] = useState<string | null>(null);

  const [unblockUser, { client }] = useMutation(UserMutations.UNBLOCK_USER);
  const [blockUser] = useMutation(UserMutations.BLOCK_USER);
  const [updatePossiblyCreateCourse] = useMutation(
    UserMutations.UPDATE_POSSIBLY_CREATE_COURSE
  );
  const [deleteUser] = useMutation(UserMutations.DELETE_USER);
  const [changeFee] = useMutation(UserMutations.USER_CHANGE_FEE);

  const handleDeleteUser = async () => {
    try {
      setIsLoading(true);
      await deleteUser({ variables: { id: data?.user.id } });

      setDeleteModalOpen(false);
      navigate(-1);
      client.cache.evict({ id: `User:${data?.user.id}` });
    } catch (e) {
      console.log(e, "---e delete");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeFee = async (option: any) => {
    try {
      client.cache.writeFragment({
        id: `Profile:${data?.user?.profile?.id}`,
        fragment: gql`
          fragment MyTodo on Profile {
            fee
          }
        `,
        data: {
          fee: option.value,
        },
      });

      await changeFee({
        variables: { input: { userId: data?.user.id, fee: option.value } },
      });
    } catch (e) {
      console.log(e, "----change fee error");
    }
  };

  const handleBlockUnblock = async () => {
    try {
      setIsLoading(true);
      if (data?.user.blocked) {
        await unblockUser({ variables: { id: data.user.id } });
        client.cache.writeFragment({
          id: `User:${data.user.id}`,
          fragment: gql`
            fragment MyTodo on User {
              blocked
            }
          `,
          data: {
            blocked: false,
          },
        });
      } else {
        await blockUser({
          variables: { id: data?.user.id, reason: blockReason?.trimEnd() },
        });

        client.cache.writeFragment({
          id: `User:${data?.user.id}`,
          fragment: gql`
            fragment MyTodo on User {
              blocked
            }
          `,
          data: {
            blocked: true,
          },
        });
      }

      setBlockModalOpen(false);
      setBlockReason(null);
      return;
    } catch (e) {
      console.log(e, "---e");
    } finally {
      setIsLoading(false);
    }
  };

  const renderTab = () => {
    switch (currentTab) {
      case 0:
        return <Profile id={Number(id)} />;
      case 1:
        return <ActiveContracts id={Number(id)} />;
      case 2:
        return <CompletedContracts id={Number(id)} />;
      case 3:
        return <Jobs id={Number(id)} />;
      case 4:
        return <Transactions />;
      case 5:
        return <MonthlySummary />;
      default:
        return <p>Coming soon</p>;
    }
  };

  const tabs = useMemo(() => {
    if (!data) return [];

    return [
      { title: "Profile" },
      { title: "Active Contracts", count: data.active.meta.itemCount },
      { title: "Completed", count: data.completed.meta.itemCount },
      { title: "Jobs" },
      { title: "Transactions" },
      { title: "Monthly Summary" },
    ];
  }, [data]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <BeatLoader color="black" />
      </div>
    );
  }

  if (error) {
    return <EmptyMessage title={error.message} />;
  }

  const handleChangeStatus = async () => {
    try {
      await updatePossiblyCreateCourse({
        variables: {
          input: {
            userId: Number(id),
            isExemptFromRequiredCourseCreationRules: !isAllowCreatedCourse,
          },
        },
      });

      await refetch();

      toast.success(
        `${isAllowCreatedCourse ? "Forbidden" : "Allowed"} to create courses`
      );
    } catch (error) {}
  };

  return (
    <div>
      <BackHeader
        title="Photographer’s Profile"
        actions={[
          <Button
            title={data?.user.blocked ? "Unblock" : "Block"}
            variant={VariantButton.Gray}
            onClick={() => setBlockModalOpen(true)}
          />,
          <Button
            title="Delete"
            variant={VariantButton.Red}
            onClick={() => setDeleteModalOpen(true)}
          />,
          <div className={styles.wrapper_header_switcher}>
            <div>
              <p className={styles.wrapper_header_title}>
                {isAllowCreatedCourse ? "Allow" : "Forbid"} to create course
              </p>
              <p className={styles.wrapper_header_subtitle}>
                Switch to {isAllowCreatedCourse ? "forbid" : "allow"}
              </p>
            </div>
            <div style={{ marginLeft: 15 }}>
              <Switcher
                isActive={isAllowCreatedCourse}
                handleClick={handleChangeStatus}
              />
            </div>
          </div>,
        ]}
      />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_info}>
            <div className={styles.wrapper_header_info_avavatr}>
              <Avatar
                img={data?.user.avatar}
                dimensions={{ width: 100, height: 100 }}
              />
            </div>
            <div>
              <p className={styles.wrapper_header_info_name}>
                {data?.user.firstName} {data?.user.lastName}
              </p>
              <p className={styles.wrapper_header_info_location}>
                {data?.user.profile?.location?.short_title}
              </p>

              <span
                className={styles.wrapper_header_info_id}
                onClick={() => {
                  navigator.clipboard.writeText(String(data?.user.id));
                  toast.success(`Copied id${data?.user.id}`);
                }}
              >
                {`id${data?.user.id}`}
              </span>
            </div>
          </div>

          <div className={styles.wrapper_header_statistic}>
            <div className={styles.wrapper_header_statistic_item}>
              <div>
                <p className={styles.wrapper_header_statistic_item_value}>
                  {data?.user && UserService.getUserRate(data?.user)}
                </p>
                <p className={styles.wrapper_header_statistic_item_label}>
                  Rate
                </p>
              </div>

              <div className={styles.wrapper_header_statistic_item_line} />
            </div>
            <div className={styles.wrapper_header_statistic_item}>
              <div>
                <p className={styles.wrapper_header_statistic_item_value}>
                  {data?.user.profile?.completed}
                </p>
                <p className={styles.wrapper_header_statistic_item_label}>
                  Successful Jobs
                </p>
              </div>

              <div className={styles.wrapper_header_statistic_item_line} />
            </div>
            <div className={styles.wrapper_header_statistic_item}>
              <div>
                <div className={styles.wrapper_header_statistic_item_rating}>
                  <div
                    className={styles.wrapper_header_statistic_item_rating_icon}
                  >
                    <ICONS.Star />
                  </div>
                  <p className={styles.wrapper_header_statistic_item_value}>
                    {data?.user?.profile?.rating &&
                      UserService.addZeroToRate(data?.user?.profile?.rating)}
                  </p>
                </div>
                <p className={styles.wrapper_header_statistic_item_label}>
                  Rating
                </p>
              </div>
            </div>
          </div>

          {data?.user?.profile?.fee && (
            <div className={styles.wrapper_header_fee}>
              <Select
                label="Fee"
                options={FeeOptions}
                value={data.user.profile.fee}
                onChange={handleChangeFee}
              />
            </div>
          )}
        </header>

        <div className={styles.wrapper_tabs}>
          <Tabs tabs={tabs} currentIndex={currentTab} onChange={onChange} />
        </div>

        {renderTab()}
      </div>

      <ConfirmModal
        isOpen={deleteModalOpen}
        title="Delete"
        description="Are you sure? All user's data will be lost"
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteUser}
      />

      <ConfirmModal
        isOpen={blockModalOpen}
        title={data?.user.blocked ? "Unblock" : "Block"}
        disableConfirmBtn={
          !data?.user.blocked && (!blockReason || blockReason.trim() === "")
        }
        onClose={() => setBlockModalOpen(false)}
        onConfirm={handleBlockUnblock}
      >
        {!data?.user.blocked && (
          <div style={{ marginBottom: "20px" }}>
            <BlockReason value={blockReason} onChange={setBlockReason} />
          </div>
        )}
      </ConfirmModal>
    </div>
  );
};

export default ProfessionalItem;
