import { IContract, IJobCandidate, IUser } from "types/interfaces";
import { PayType, RateCurrency, RatePeriod, Roles } from "types/enums";
import { number } from "yup";

export const getBudgetAmount = (contract: IContract) => {
  const amount = `${getRateSymbolText(contract.payCurrency)}${
    contract.payAmount
  }/${contract.payType === PayType.Fixed ? "Fix" : "Hr"}`;

  return amount;
};

export const getRateSymbolText = (currency: RateCurrency) => {
  switch (currency) {
    case RateCurrency.KWD:
      return "KWD";
    case RateCurrency.USD:
      return "$";
    default:
      return "SAR";
  }
};

export const addZeroToRate = (rate: number) => {
  if (rate % 1 === 0) return `${rate}.0`;
  return rate.toFixed(1);
};

export const getRatePeriodText = (period: RatePeriod) => {
  switch (period) {
    case RatePeriod.Day:
      return "day";
    case RatePeriod.Hourly:
      return "hr";
    default:
      return "sess";
  }
};

export const getUserRate = (user: IUser) => {
  if (
    !user.profile?.rateAmount ||
    !user.profile?.rateCurrency ||
    !user.profile?.ratePeriod
  )
    return "n/a";

  return `${getRateSymbolText(user.profile.rateCurrency)}${
    user.profile.rateAmount
  }/${getRatePeriodText(user.profile.ratePeriod)}`;
};

export const getCandidateRate = (candidate: IJobCandidate) => {
  if (!candidate.payAmount || !candidate.payCurrency || !candidate.payType)
    return "n/a";

  return `${getRateSymbolText(candidate.payCurrency)}${candidate.payAmount}/${
    candidate.payType === PayType.Hourly ? "hr" : "fixed"
  }`;
};

export const formatSecondsToHoursMinutes = (seconds?: number) => {
  if (!seconds) return;

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (!hours) return `${minutes}m`;

  if (minutes) return `${hours}h ${minutes}m`;

  return `${hours}h`;
};

export const formatFileSize = (bytes: number, decimals: number = 1) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TiB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export function truncateString(str: string, maxLength: number) {
  if (str.length < maxLength) return str;

  return `${str.slice(0, maxLength)}...`;
}
