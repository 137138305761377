import { FC, useState } from "react";
import styles from "./styles.module.scss";

import { IUser } from "types/interfaces";

type Props = {
  user: IUser;
};

const LIMIT_LENGTH = 300;

const About: FC<Props> = ({ user }) => {
  const [showFull, setShowFull] = useState<boolean>(false);

  const getDescription = () => {
    if (!user?.profile?.description) return "";
    if (user.profile.description.length < LIMIT_LENGTH || showFull)
      return user.profile.description;

    return user.profile.description.substring(0, LIMIT_LENGTH);
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.wrapper_title}>About</h2>
      <p className={styles.wrapper_about}>{getDescription()}</p>

      {user?.profile?.description &&
        user?.profile?.description.length > LIMIT_LENGTH && (
          <div className={styles.wrapper_more_wrapper}>
            <p
              className={styles.wrapper_more}
              onClick={() => setShowFull(!showFull)}
            >
              {showFull ? "Show less" : "Show more"}
            </p>
          </div>
        )}
    </div>
  );
};

export default About;
