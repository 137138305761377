import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import { ICONS } from "assets";
import { Avatar, UserRoleBlock } from "components";
import { IUnverifiedUser } from "types/interfaces";
import { truncateString } from "utils/userService";

interface IProps {
  user: IUnverifiedUser;
  onClickCard: (user: IUnverifiedUser) => void;
}

const UserCard: FC<IProps> = ({ user, onClickCard }) => {
  return (
    <div className={styles.wrapper} onClick={() => onClickCard(user)}>
      <div className={styles.wrapper_info}>
        <div className={styles.wrapper_info_avatar}>
          <Avatar img={user.avatar} />
        </div>
        <p className={styles.wrapper_info_name}>{user.name}</p>
      </div>
      <div className={styles.wrapper_role}>
        <UserRoleBlock userRole={user.profileType} />
      </div>

      <div className={styles.wrapper_location}>{user.location}</div>

      <div className={styles.wrapper_email}>
        {truncateString(user.email, 28)}
      </div>

      <div className={styles.wrapper_btn}>
        {moment(user.createdAt).format("MMM DD, YYYY")}

        <div className={styles.wrapper_btn_row}>
          <div
            className={styles.wrapper_btn_wrapper}
            style={{ backgroundColor: "#63CD5A" }}
          >
            <ICONS.ApproveCheck className={styles.check_icon} />
          </div>
          <div className={styles.wrapper_btn_wrapper}>
            <ICONS.Cross className={styles.cross_icon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
