import { FC } from "react";
import styles from "./styles.module.scss";

import { UserComplaint, ProblemReport, Disputes } from "./Tabs";

import { Header, Tabs } from "components";

import { useTab } from "hooks";

const Reports: FC = () => {
  const { currentTab: currentTabIndex, onChange: setCurrentTabIndex } =
    useTab();

  const renderTab = () => {
    switch (currentTabIndex) {
      case 0:
        return <UserComplaint />;
      case 1:
        return <ProblemReport />;
      case 2:
        return <Disputes />;
    }
  };

  return (
    <div>
      <Header title="Reports" />

      <div className={styles.wrapper}>
        <div className={styles.wrapper_tabs}>
          <Tabs
            tabs={[
              { title: "User complaint" },
              { title: "Problem report" },
              { title: "Disputes" },
            ]}
            currentIndex={currentTabIndex}
            onChange={setCurrentTabIndex}
          />
        </div>

        {/* <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_input}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={setSearch}
            />
          </div>
          <div className={styles.wrapper_header_filter}>
            <ICONS.Filter />
          </div>
        </header> */}

        {renderTab()}
      </div>
    </div>
  );
};

export default Reports;
