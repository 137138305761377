import { useParams, useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";
import styles from "./styles.module.scss";
import { FC, useEffect, useState } from "react";
import { Status, BannerTargetRedirection, IBanner, VariantButton } from "types";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  DEACTIVATE_BANNER,
  ACTIVATE_BANNER,
  DELETE_BANNER,
} from "graphql/mutations";
import { BackHeader, Button, ConfirmModal, Switcher } from "components";
import CreateUpdateBannerModal from "../UpdateCreateBanner";
import { GET_BANNER_DETAIL } from "graphql/queries/banners";

const BannerDetail: FC = () => {
  const navigate = useNavigate();
  const { id: bannerId } = useParams();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenEditModal, setOpenEditModal] = useState(false);
  const [banner, setBanner] = useState<IBanner | null>(null);

  const [deactivateBanner] = useMutation(DEACTIVATE_BANNER);
  const [activateBanner] = useMutation(ACTIVATE_BANNER);
  const [deleteBannerReq] = useMutation(DELETE_BANNER);

  const [getBannerDetail, { loading, refetch }] = useLazyQuery<{
    findBannerById: IBanner;
  }>(GET_BANNER_DETAIL, {
    variables: {
      input: {
        id: Number(bannerId),
      },
    },
    onCompleted(data) {
      setBanner(data.findBannerById);
    },
  });

  const isActive = banner?.status === Status.Active;

  const handleActivateBanner = async () => {
    if (!banner) return;

    try {
      await activateBanner({
        variables: {
          input: {
            id: banner?.id,
          },
        },
      });

      await refetch();

      toast.success("Banner was activated");
    } catch (error) {
      toast.error(
        "The maximum number of banners is activated (5). Deactivate one of them or delete it to activate new ones."
      );

      console.log("e", error);
    }
  };

  useEffect(() => {
    getBannerDetail();
  }, []);

  const handleDeactivateBanner = async () => {
    try {
      if (!banner) return;

      await deactivateBanner({
        variables: {
          input: {
            id: banner?.id,
          },
        },
      });

      await refetch();

      toast.success("Banner was deactivated");
    } catch (error) {}
  };

  const handleChangeStatus = () => {
    if (!banner) return;

    banner?.status === Status.Active
      ? handleDeactivateBanner()
      : handleActivateBanner();
  };

  const handleDeleteBanner = async () => {
    if (!banner) return;

    try {
      await deleteBannerReq({
        variables: {
          input: {
            id: banner?.id,
          },
        },
      });

      toast.success("Banner successfully deleted");
      navigate("/banners");
    } catch (error: any) {
      toast.success(error?.message);
    }
  };

  const navigateToTarget = () => {
    switch (banner?.redirection.target) {
      case BannerTargetRedirection.Profile:
        navigate(`/profiles/${banner?.redirection.targetId}`);
        break;
      case BannerTargetRedirection.Course:
        navigate(`/courses/${banner?.redirection.targetId}`);
        break;
      case BannerTargetRedirection.Studio:
        navigate(`/studios/${banner?.redirection.targetId}`);
        break;
      case BannerTargetRedirection.Equipment:
        navigate(`/equipments/${banner?.redirection.targetId}`);
        break;
    }
  };

  return (
    <div>
      {loading ? (
        <PulseLoader
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 300,
          }}
        />
      ) : (
        <>
          <BackHeader
            title="Banner"
            actions={[
              <Button
                onClick={() => setOpenEditModal(true)}
                title="Edit"
                variant={VariantButton.Gray}
              />,
              <Button
                onClick={() => setOpenDeleteModal(true)}
                title="Delete"
                variant={VariantButton.Red}
              />,
              <div className={styles.banner__header_actions}>
                <div>
                  <p className={styles["banner__header-title"]}>
                    Banner is {isActive ? "Active" : "Deactivated"}
                  </p>
                  <p className={styles["banner__header-subtitle"]}>
                    Switch to {isActive ? "disable" : "enable"}
                  </p>
                </div>
                <div className={styles["banner__header-switcher"]}>
                  <Switcher
                    isActive={isActive}
                    handleClick={handleChangeStatus}
                  />
                </div>
              </div>,
            ]}
          />

          <div className="shadow_box">
            <p className={styles.banner__title}>{banner?.title} </p>

            <p className={styles.banner__subtitle} onClick={navigateToTarget}>
              {`${banner?.redirection?.target} - ${banner?.redirection?.targetTitle}`}
            </p>

            <p
              className={styles.banner__id}
            >{`id${banner?.redirection?.targetId}`}</p>

            <img
              src={banner?.image}
              className={styles.banner__image}
              alt="banner"
            />
          </div>
        </>
      )}

      <CreateUpdateBannerModal
        isOpen={isOpenEditModal}
        onClose={() => setOpenEditModal(false)}
        banner={banner}
        refetch={refetch}
      />

      <ConfirmModal
        isOpen={isOpenDeleteModal}
        hideCrossIcon
        title={`Are you sure you want to delete this banner?`}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={() => handleDeleteBanner()}
      />
    </div>
  );
};

export default BannerDetail;
