import { FC, useContext, useState } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import styles from "./styles.module.scss";

import { Button, Input, Textarea, VideoDropZone } from "components";
import { ICONS } from "assets";

import { useLazyQuery, useMutation } from "@apollo/client";
import { GoogleService } from "utils";
import { AdminQueries, CoursesQueries } from "graphql/queries";
import { Loading } from "context";
import toast from "react-hot-toast";
import { ICourseDetail, ILesson } from "types";
import { CoursesMutations } from "graphql/mutations";
import { formatFileSize } from "utils/userService";

const MAX_VIDEO_SIZE = 500 * 1048576; // 500MB in bytes

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
    zIndex: 2000,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 2000,
  },
};

const validate = (values: any) => {
  const errors: any = {};

  if (values.title.trim() === "") {
    errors.title = "Title is required";
  }

  if (!values.hours && !values.minutes) {
    errors.hours = "Duration must be more than 0 minutes";
  }

  if (!values.desc) {
    errors.desc = "Description is required";
  }

  if (!values.videoName) {
    errors.videoName = "Video is required";
  }

  if (values.videoSize > MAX_VIDEO_SIZE) {
    errors.videoName = "Video is required";
  }

  return errors;
};

interface IProps {
  courseDetail?: ICourseDetail;
  lesson: ILesson | null;
  isOpen: boolean;
  onClose: () => void;
}

const CreateEditLessonModal: FC<IProps> = ({
  lesson,
  onClose,
  isOpen,
  courseDetail,
}) => {
  const [video, setVideo] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);

  const { setIsLoading } = useContext(Loading.Context)!;

  const [createLesson] = useMutation(CoursesMutations.UPDATE_COURSE);
  const [updateLesson] = useMutation(CoursesMutations.UPDATE_COURSE_LESSON);

  const [createUploadUrl] = useLazyQuery(AdminQueries.GET_UPLOAD_URLS);

  const { values, errors, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      title: lesson?.title || "",
      desc: lesson?.description || "",
      hours: convertSeconds().hours,
      minutes: convertSeconds().minutes,
      videoName: lesson?.videoFilename || "",
      videoSize: lesson?.videoSize || "",
      videUrl: lesson?.videoUrl || "",
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate,
    onSubmit: lesson ? handleUpdateLesson : handleCreateLesson,
  });

  function convertSeconds() {
    if (!lesson)
      return {
        hours: 0,
        minutes: 0,
      };

    const hours = Math.floor(lesson.durationInSeconds / 3600);
    const minutes = Math.floor((lesson.durationInSeconds % 3600) / 60);

    return { hours, minutes };
  }

  async function handleUpdateLesson() {
    try {
      const videoUrl = await saveNewLessonVideo();

      setIsLoading(true);

      await updateLesson({
        variables: {
          input: {
            id: lesson?.id,
            title: values.title.trimEnd(),
            description: values.desc.trimEnd(),
            durationInSeconds: values.hours * 3600 + values.minutes * 60,
            videoUrl: videoUrl || lesson?.videoUrl,
            videoFilename: values.videoName,
            videoSize: values.videoSize,
          },
        },
        refetchQueries: [CoursesQueries.GET_COURSE_DETAIL],
      });

      onCloseModal();

      toast.success(`Lesson update successfully`);
    } catch (error) {
      toast.error(`Error update lesson`);
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  }

  async function handleCreateLesson() {
    try {
      const videoUrl = await saveNewLessonVideo();

      const data = dataForSaveLessons(videoUrl);

      await createLesson({
        variables: {
          input: {
            id: courseDetail?.id,
            lessons: data,
          },
        },
        refetchQueries: [CoursesQueries.GET_COURSE_DETAIL],
      });

      onCloseModal();

      toast.success(`Lesson ${lesson ? "edited" : "created"} successfully`);
    } catch (error) {
      toast.error(`Error ${lesson ? "edited" : "created"} lesson`);
    } finally {
      setProgress(0);
    }
  }

  const saveNewLessonVideo = async () => {
    if (!values.videoName || values.videUrl) return null;

    const {
      data: { generateUploadUrls: urls },
    } = await createUploadUrl({
      variables: { filename: `${values.videoName}` },
    });

    await GoogleService.uploadFileToStorage(
      video as File,
      urls.uploadUrl,
      (progress) => setProgress(progress)
    );

    return urls.fileUrl;
  };

  const dataForSaveLessons = (videoUrl: string) => {
    const lessonsExistIds = courseDetail?.lessons.length
      ? courseDetail?.lessons.map((it) => ({ id: it.id }))
      : [];

    const dataForNewLesson = [
      ...lessonsExistIds,
      {
        new: {
          title: values.title.trimEnd(),
          description: values.desc.trimEnd(),
          durationInSeconds: values.hours * 3600 + values.minutes * 60,
          videoUrl,
          videoFilename: values.videoName,
          videoSize: values.videoSize,
        },
      },
    ];

    return dataForNewLesson;
  };

  const onChangeDuration = (val: string, type: "hours" | "minutes") => {
    const cleanedVal = val.replace(/\D/g, "");

    if (cleanedVal === "0") return;

    if (cleanedVal.length === 1) {
      return setFieldValue(type, 0);
    }

    if (!val.includes(type[0])) {
      return setFieldValue(type, val.slice(0, val.length - 2));
    }

    if (type === "hours" && +cleanedVal <= 23) {
      setFieldValue(type, cleanedVal[0] === "0" ? +cleanedVal[1] : +cleanedVal);
    }

    if (type === "minutes" && +cleanedVal <= 59) {
      setFieldValue(type, cleanedVal[0] === "0" ? +cleanedVal[1] : +cleanedVal);
    }
  };

  const onChangeLessonName = (value: string) => {
    const hasConsecutiveSpaces = / {2,}/.test(value);

    if (hasConsecutiveSpaces) return;

    setFieldValue("title", value.trimStart());
  };

  const handleDropVideo = (video: File) => {
    setVideo(video);
    setFieldValue("videoName", video.name);
    setFieldValue("videoSize", formatFileSize(video.size));
  };

  const onCloseModal = () => {
    onClose();
    resetForm();
    setVideo(null);
  };

  const onRemoveVideo = () => {
    setFieldValue("videoName", "");
    setFieldValue("videoSize", "");
    setFieldValue("videoUrl", "");

    setVideo(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles as any}
      ariaHideApp={false}
      onRequestClose={onCloseModal}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>
            {lesson ? "Edit lesson" : "New lesson"}
          </h4>
          <div className={styles.wrapper_header_icon} onClick={onCloseModal}>
            <ICONS.Cross />
          </div>
        </header>

        <Input
          label="Title"
          placeholder="Enter title"
          value={values.title}
          error={errors.title}
          maxLength={300}
          containerStyle={{ marginBottom: 22 }}
          onChange={onChangeLessonName}
        />

        <Textarea
          label="Lesson Description"
          value={values.desc}
          error={errors.desc}
          maxLength={5000}
          onChange={(val) => setFieldValue("desc", val)}
          containerStyle={{ marginBottom: 22 }}
        />

        <div className={styles.wrapper_duration}>
          <Input
            label="Lesson Duration"
            value={`${values.hours} h`}
            error={errors.hours}
            onChange={(val) => onChangeDuration(val, "hours")}
            style={{ width: "100%" }}
            containerStyle={{ width: "100%" }}
          />
          <Input
            label=""
            value={`${values.minutes} m`}
            onChange={(val) => onChangeDuration(val, "minutes")}
            error={errors.hours}
            containerStyle={{ marginTop: 25, width: "100%" }}
          />
        </div>

        <VideoDropZone
          videoName={values.videoName}
          videoSize={values.videoSize}
          handleDropVideo={handleDropVideo}
          error={errors.videoName}
          onRemoveVideo={onRemoveVideo}
          progressUpload={progress}
        />

        <div className={styles.wrapper_button}>
          <div className={styles.wrapper_button_item}>
            <Button
              disable={!!progress}
              title={lesson ? "Edit lesson" : "Create lesson"}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateEditLessonModal;
