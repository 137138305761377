import { gql } from "@apollo/client";

export const GET_COUNT_PORTFOLIO = gql`
  query countPortfolio($userId: Int!) {
    countPortfolio(userId: $userId) {
      all
      grouped {
        categoryId
        categoryTitle
        _count {
          _all
        }
      }
    }
  }
`;

export const GET_PORTFOLIO = gql`
  query adminPortfolios($input: FindPortfolioInput!) {
    adminPortfolios(input: $input) {
      id
      original
      width
      height
      likes
    }
  }
`;
