import { useParams, useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";
import styles from "./styles.module.scss";
import { FC, useEffect, useState } from "react";
import { IStudio, Status, VariantButton } from "types";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { BackHeader, Button, ConfirmModal, Switcher, Tabs } from "components";

import { useTab } from "hooks";
import CreateUpdateStudioModal from "../CreateUpdateStudioModal";
import { StudiosMutations } from "graphql/mutations";
import { StudiosQueries } from "graphql/queries";
import StudioPhotos from "./StudioPhotos";
import StudioCalendar from "./StudioCalendar";

const StudioDetail: FC = () => {
  const navigate = useNavigate();
  const { id: studioId } = useParams();

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenEditModal, setOpenEditModal] = useState(false);
  const [studio, setStudio] = useState<IStudio | null>(null);
  const { currentTab: tabIndex, onChange: setTabIndex } = useTab();

  const [deactivateStudio] = useMutation(StudiosMutations.DEACTIVATE_STUDIO);
  const [activateStudio] = useMutation(StudiosMutations.ACTIVATE_STUDIO);
  const [deleteStudioReq] = useMutation(StudiosMutations.DELETE_STUDIO);

  const { data } = useQuery<{ countStudioRentals: number }>(
    StudiosQueries.GET_COUNT_RENTAL_STUDIO,
    {
      variables: {
        input: {
          studioId: Number(studioId),
        },
      },
    }
  );

  const [getStudioDetail, { loading, refetch }] = useLazyQuery<{
    findStudioById: IStudio;
  }>(StudiosQueries.GET_STUDIO_DETAIL, {
    variables: {
      input: {
        id: Number(studioId),
      },
    },
    onCompleted(data) {
      setStudio(data.findStudioById);
    },
  });

  const isActive = studio?.status === Status.Active;

  const handleActivateStudio = async () => {
    if (!studio) return;

    try {
      await activateStudio({
        variables: {
          input: {
            id: studio?.id,
          },
        },
      });

      await refetch();

      toast.success("Studio was activated");
    } catch (error) {
      toast.error(
        "The maximum number of studio is activated (5). Deactivate one of them or delete it to activate new ones."
      );

      console.log("e", error);
    }
  };

  useEffect(() => {
    getStudioDetail();
  }, []);

  const handleDeactivateStudio = async () => {
    try {
      if (!studio) return;

      await deactivateStudio({
        variables: {
          input: {
            id: studio?.id,
          },
        },
      });

      await refetch();

      toast.success("Studio was deactivated");
    } catch (error) {}
  };

  const handleChangeStatus = () => {
    if (!studio) return;

    studio.status === Status.Active
      ? handleDeactivateStudio()
      : handleActivateStudio();
  };

  const handleDeleteStudio = async () => {
    if (!studio) return;

    try {
      await deleteStudioReq({
        variables: {
          input: {
            id: studio?.id,
          },
        },
      });

      toast.success("Studio successfully deleted");

      setOpenDeleteModal(false);

      navigate("/studios");
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <div>
      {loading ? (
        <PulseLoader
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 300,
          }}
        />
      ) : (
        <>
          <BackHeader
            title="Studio"
            actions={[
              <Button
                onClick={() => setOpenEditModal(true)}
                title="Edit"
                variant={VariantButton.Gray}
              />,
              <Button
                onClick={() => setOpenDeleteModal(true)}
                title="Delete"
                variant={VariantButton.Red}
              />,
              <div className={styles.studio_header_actions}>
                <div>
                  <p className={styles["studio_header_title"]}>
                    Studio is {isActive ? "Active" : "Deactivated"}
                  </p>
                  <p className={styles["studio_header_subtitle"]}>
                    Switch to {isActive ? "disable" : "enable"}
                  </p>
                </div>
                <div className={styles["studio_header_switcher"]}>
                  <Switcher
                    isActive={isActive}
                    handleClick={handleChangeStatus}
                  />
                </div>
              </div>,
            ]}
          />

          <div className="shadow_box">
            <div className={styles.studio_row}>
              <img
                src={studio?.images[0].url}
                className={styles.studio_image}
                alt="studio"
              />

              <div style={{ marginRight: "auto", maxWidth: 597 }}>
                <p className={styles.studio_title}>{studio?.title} </p>
                <p className={styles.studio_subtitle}>
                  {studio?.location.address}
                </p>
                <span
                  className={styles.studio_id}
                  onClick={() => {
                    navigator.clipboard.writeText(String(studioId));
                    toast.success(`Copied id${studioId}`);
                  }}
                >{`id${studioId}`}</span>
              </div>

              <div>
                <span className={styles.studio_price}>
                  {`${studio?.rentPricePerHour} ${studio?.rentCurrency}`}
                </span>
                <span className={styles.studio_day}>/hr</span>

                <p className={styles.studio_price_label}>Price</p>
              </div>

              <div className={styles.studio_line} />

              <div>
                <p className={styles.studio_price}>
                  {data?.countStudioRentals}
                </p>
                <p className={styles.studio_price_label}>Rents</p>
              </div>
            </div>

            <p style={{ marginBottom: 25 }}>{studio?.description}</p>

            <Tabs
              tabs={[{ title: "Schedule" }, { title: "Photos" }]}
              currentIndex={tabIndex}
              onChange={setTabIndex}
              containerStyle={{ marginBottom: 25 }}
            />

            {tabIndex === 0 ? (
              <StudioCalendar studioId={Number(studioId)} />
            ) : (
              <StudioPhotos images={studio?.images} />
            )}
          </div>
        </>
      )}

      <CreateUpdateStudioModal
        isOpen={isOpenEditModal}
        onClose={() => setOpenEditModal(false)}
        studio={studio}
        refetch={refetch}
      />

      <ConfirmModal
        isOpen={isOpenDeleteModal}
        hideCrossIcon
        title={`Are you sure you want to delete this studio?`}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={handleDeleteStudio}
      />
    </div>
  );
};

export default StudioDetail;
