import React, { FC, MouseEvent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { IEquipmentImage } from "types";
import { ICONS } from "assets";

interface IProps {
  images?: IEquipmentImage[];
}

const EquipmentPhotos: FC<IProps> = ({ images }) => {
  const [imagesList, setImagesList] = useState(images);
  const [isOpenOverlay, setOpenOverlay] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    setImagesList(images);
  }, [images]);

  const onClickImage = (index: number) => {
    setOpenOverlay(true);
    setImageIndex(index);
  };

  const onNextImage = (e: MouseEvent) => {
    e.stopPropagation();

    if (imagesList && imageIndex === imagesList.length - 1) return;

    setImageIndex(imageIndex + 1);
  };

  const onPrevImage = (e: MouseEvent) => {
    e.stopPropagation();

    if (imagesList && imageIndex === 0) return;

    setImageIndex(imageIndex - 1);
  };

  return (
    <>
      <div className={styles.wrapper}>
        {imagesList?.map((imgUrl, index) => (
          <div className={styles.image} onClick={() => onClickImage(index)}>
            <img
              src={imgUrl.url}
              style={{ height: "100%", width: "100%" }}
              alt="img"
            />

            {index === 0 && <p className={styles.main_label}>Main</p>}
          </div>
        ))}
      </div>

      {isOpenOverlay ? (
        <div className={styles.overlay} onClick={() => setOpenOverlay(false)}>
          <div className={styles.overlay_cross}>
            <ICONS.GalleryCross />
          </div>

          {imageIndex !== 0 && (
            <div
              style={{ padding: 50, cursor: "pointer" }}
              onClick={(e) => onPrevImage(e)}
            >
              <ICONS.GalleryArrow />
            </div>
          )}

          <div className={styles.overlay_view}>
            <img
              src={imagesList?.[imageIndex].url}
              alt="img"
              className={styles.overlay_img}
            />
          </div>

          {imagesList && imageIndex !== imagesList?.length - 1 && (
            <div
              style={{
                transform: "rotate(180deg)",
                padding: 50,
                marginBottom: 10,
                cursor: "pointer",
              }}
              onClick={(e) => onNextImage(e)}
            >
              <ICONS.GalleryArrow />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default EquipmentPhotos;
