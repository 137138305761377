import { FC, useRef, useState, useEffect } from "react";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Filter as FilterComponents } from "components";

import { useOutsideClick } from "hooks";

import { IClientFilter } from "types/interfaces";
import { ClientFilterCompleted } from "types/enums";

type Props = {
  filter: IClientFilter;
  onChange: (value: IClientFilter) => void;
};

const Filter: FC<Props> = ({ filter: initialFilter, onChange }) => {
  const [filter, setFilter] = useState<IClientFilter>(initialFilter);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpenFilter) {
      setFilter(initialFilter);
    }
  }, [isOpenFilter, initialFilter]);

  const divRef = useRef(null);
  useOutsideClick({
    ref: divRef,
    onClickOutside: () => setIsOpenFilter(false),
  });

  const handleApplyFilter = () => {
    onChange(filter);
    setIsOpenFilter(false);
  };

  const handleClearFilter = () => {
    onChange({
      rating: null,
      completed: null,
      hasActiveContracts: null,
      blocked: null,
    });
    setIsOpenFilter(false);
  };

  return (
    <div ref={divRef} className={styles.wrapper}>
      <div
        className={styles.wrapper_icon}
        onClick={() => setIsOpenFilter(!isOpenFilter)}
      >
        <ICONS.Filter />
      </div>

      {isOpenFilter && (
        <div className={styles.wrapper_filter}>
          <FilterComponents.Wrapper
            onApply={handleApplyFilter}
            onClear={handleClearFilter}
          >
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.Rating
                value={filter.rating}
                onChange={(val) => setFilter({ ...filter, rating: val })}
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.List
                items={[
                  { label: "50+", value: ClientFilterCompleted.More50 },
                  { label: "30-50", value: ClientFilterCompleted.From30to50 },
                  { label: "1-10", value: ClientFilterCompleted.From1to10 },
                  { label: "None", value: ClientFilterCompleted.None },
                ]}
                title="Contract history"
                value={filter.completed}
                //@ts-ignore
                onChange={(val) => setFilter({ ...filter, completed: val })}
              />
            </div>
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.Checkbox
                value={!!filter.hasActiveContracts}
                label="Has active contracts"
                onChange={(val) =>
                  setFilter({ ...filter, hasActiveContracts: val })
                }
              />
            </div>
            <FilterComponents.Checkbox
              value={!!filter.blocked}
              label="Blocked"
              onChange={(val) => setFilter({ ...filter, blocked: val })}
            />
          </FilterComponents.Wrapper>
        </div>
      )}
    </div>
  );
};

export default Filter;
