import { FC, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styles from "./Clients.module.scss";

import { UserQueries } from "graphql/queries";

import { Header, Pagination, SearchInput, TableHead } from "components";
import ClientItem from "./ClientItem";
import Filter from "./Filter";

import { IClientFilter, IUser } from "types/interfaces";
import { ClientFilterCompleted, Order } from "types/enums";

const FromTo = {
  [ClientFilterCompleted.More50]: { from: 50, to: 1000000 },
  [ClientFilterCompleted.From30to50]: { from: 30, to: 50 },
  [ClientFilterCompleted.From1to10]: { from: 1, to: 10 },
  [ClientFilterCompleted.None]: { from: 0, to: 0 },
};

const convertFilter = (filter: IClientFilter) => {
  const completed = filter.completed
    ? {
        from: FromTo[filter.completed].from,
        to: FromTo[filter.completed].to,
      }
    : null;

  return {
    completed,
    rating: filter.rating,
    hasActiveContracts: filter.hasActiveContracts,
    blocked: filter.blocked,
  };
};

const LIMIT = 10;

const Clients: FC = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [count, setCount] = useState<number>(1);

  const [sort, setSort] = useState<
    {
      sortedBy: string | null;
      type: Order | null;
      currentPage: number;
    } & IClientFilter
  >({
    sortedBy: null,
    type: Order.DESC,
    currentPage: 1,
    rating: null,
    completed: null,
    hasActiveContracts: null,
    blocked: null,
  });

  const [fetchClients, { data, loading }] = useLazyQuery<{
    adminClients: {
      data: Array<IUser>;
      meta: { itemCount: number };
    };
  }>(UserQueries.GET_CLIENTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted(data) {
      setCount(data.adminClients.meta.itemCount);
    },
  });

  useEffect(() => {
    const sortBy =
      sort.sortedBy !== null
        ? {
            //@ts-ignore
            orderBy: sort.sortedBy,
            order: sort.type,
          }
        : {};

    fetchClients({
      variables: {
        input: {
          skip: (sort.currentPage - 1) * LIMIT,
          take: LIMIT,
          search,
          ...sortBy,
          ...convertFilter(sort),
        },
      },
    });
  }, [sort, search]);

  const renderClient = () => {
    if (data?.adminClients?.data && data.adminClients.data.length > 0) {
      return data?.adminClients.data.map((user) => (
        <div key={user.id} onClick={() => navigate(`${user.id}`)}>
          <ClientItem user={user} />
        </div>
      ));
    }
  };

  return (
    <div>
      <Header title="Clients" />

      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_input}>
            <SearchInput
              placeholder="Search"
              value={search}
              onChange={setSearch}
            />
          </div>
          <Filter
            filter={sort}
            onChange={(filter) =>
              setSort({ ...sort, ...filter, currentPage: 1 })
            }
          />
        </header>

        <div className={styles.table}>
          <TableHead
            items={[
              { title: "Client name", value: "Name" },
              { title: "Location", disableSort: true },
              { title: "Rating", value: "Rating" },
              { title: "Contracts", value: "Contracts" },
              { title: "Postings", value: "Postings" },
              { title: "Completed", value: "Completed" },
              { title: "Registration date", value: "CreatedAt" },
              false,
            ]}
            flexes={[2, 2, 1, 1, 1, 1, 1]}
            sorted={sort}
            onChangeSort={(val) => setSort({ ...sort, ...val, currentPage: 1 })}
          />
        </div>
        {loading ? (
          <Skeleton
            height={50}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={5}
          />
        ) : (
          renderClient()
        )}

        {count > 0 && (
          <div style={{ margin: "10px 0" }}>
            <Pagination
              limit={LIMIT}
              count={count}
              currentPage={sort.currentPage}
              onChange={(page) => setSort({ ...sort, currentPage: page })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Clients;
