import { gql } from "@apollo/client";

export const GET_EQUIPMENTS = gql`
  query findEquipments($input: FindEquipmentsInput!) {
    findEquipments(input: $input) {
      count
      equipments {
        id
        title
        description
        rentPricePerDay
        rentCurrency
        status
        createdAt
        location {
          address
          latitude
          longitude
        }
        images {
          id
          equipmentId
          url
          order
          createdAt
        }
      }
    }
  }
`;

export const GET_EQUIPMENT_DETAIL = gql`
  query findEquipmentById($input: FindEquipmentByIdInput!) {
    findEquipmentById(input: $input) {
      id
      title
      description
      rentPricePerDay
      rentCurrency
      status
      createdAt
      location {
        id
        equipmentId
        address
        latitude
        longitude
      }
      images {
        id
        equipmentId
        url
        order
        createdAt
      }
    }
  }
`;

export const GET_COUNT_RENTAL_EQUIPMENT = gql`
  query query($input: CountEquipmentRentalsInput!) {
    countEquipmentRentals(input: $input)
  }
`;

export const GET_RENTAL_EQUIPMENT_BY_DATES = gql`
  query findEquipmentRentalsByDates($input: FindEquipmentRentalsByDatesInput!) {
    findEquipmentRentalsByDates(input: $input) {
      id
      equipmentId
      startsAt
      endsAt
      renterId
      externalRenterId
      renter {
        id
        email
        phone
        firstName
        lastName
        avatar
        profile {
          id
          userId
          type
          account
          agencyName
        }
      }
      externalRenter {
        id
        fullName
        phone
      }
      equipment {
        id
      }
    }
  }
`;

export const GET_RENTERS = gql`
  query findUserPreviews($input: FindUserPreviewsInput!) {
    findUserPreviews(input: $input) {
      previews {
        avatar
        firstName
        id
        lastName
        profile {
          account
          agencyName
        }
      }
    }
  }
`;
