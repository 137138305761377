import { FC } from "react";
import styles from "./styles.module.scss";

import { LevelPhotographAccount, UserAccount } from "types";
import { ICONS } from "assets";

const colorConfig = {
  [LevelPhotographAccount.Top]: "#7BC631",
  [LevelPhotographAccount.Master]: "#9933FF",
  [LevelPhotographAccount.Pro]: "#FDBD39",
};

const levelPhotographAccount = {
  [LevelPhotographAccount.Top]: {
    label: LevelPhotographAccount.Top,
    icon: <ICONS.TopLevelAccount />,
  },
  [LevelPhotographAccount.Pro]: {
    label: LevelPhotographAccount.Pro,
    icon: <ICONS.ProLevelAccount />,
  },
  [LevelPhotographAccount.Master]: {
    label: LevelPhotographAccount.Master,
    icon: <ICONS.MasterLevelAccount />,
  },
};

export const getLevelPhotographAccount = (completedJobs?: number) => {
  if (!completedJobs)
    return {
      label: null,
      icon: null,
    };

  if (completedJobs >= 50 && completedJobs < 150) {
    return levelPhotographAccount[LevelPhotographAccount.Top];
  }

  if (completedJobs >= 150 && completedJobs < 300) {
    return levelPhotographAccount[LevelPhotographAccount.Pro];
  }
  if (completedJobs >= 300) {
    return levelPhotographAccount[LevelPhotographAccount.Master];
  }

  return {
    label: null,
    icon: null,
  };
};

interface IProps {
  successfulJobs?: number;
  accountType?: UserAccount;
}

const LevelAccountPhotographerBlock: FC<IProps> = ({
  successfulJobs,
  accountType,
}) => {
  const { label, icon } = getLevelPhotographAccount(successfulJobs);

  return (
    <div className={styles.container}>
      {label ? (
        <div
          className={styles.level}
          style={{ borderColor: colorConfig[label] }}
        >
          {icon}
          <p
            className={styles.level_text}
            style={{ color: colorConfig[label] }}
          >
            {label.toUpperCase()}
          </p>
        </div>
      ) : null}

      <div
        className={styles.account}
        style={{
          backgroundColor:
            accountType === UserAccount.Agency ? "#141415" : "white",
          color: accountType === UserAccount.Agency ? "white" : "#141415",
        }}
      >
        {accountType?.toUpperCase()}
      </div>
    </div>
  );
};

export default LevelAccountPhotographerBlock;
