import { useParams, useNavigate, useLocation } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";
import styles from "./styles.module.scss";
import { FC, useState } from "react";
import {
  CourseOwnership,
  ICourseDetail,
  IUser,
  Status,
  VariantButton,
} from "types";
import { useMutation, useQuery } from "@apollo/client";
import {
  Avatar,
  BackHeader,
  Button,
  LevelAccountPhotographer,
  Switcher,
  Tabs,
} from "components";
import { useTab } from "hooks";
import { CoursesQueries, UserQueries } from "graphql/queries";
import { formatSecondsToHoursMinutes } from "utils/userService";
import LessonsListTab from "./LessonsListTab";
import BoughtThisCourseTab from "./BoughtThisCourse";
import { CoursesMutations } from "graphql/mutations";
import DeleteCourseModal from "../DeleteCourseModal";
import CreateUpdateCourseModal from "../CreateUpdateCourseModal";

const CourseDetailPage: FC = () => {
  const { id }: any = useParams();
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const [isOpenCourseModal, setCourseModal] = useState(Boolean(state?.isEdit));
  const { currentTab: tabIndex, onChange: setTabIndex } = useTab();

  const [deactivateCourse] = useMutation(CoursesMutations.DEACTIVATE_COURSE);
  const [activateCourse] = useMutation(CoursesMutations.ACTIVATE_COURSE);

  const { data, loading, refetch } = useQuery<{
    findCourseForAdmin: ICourseDetail;
  }>(CoursesQueries.GET_COURSE_DETAIL, {
    variables: {
      input: {
        id: +id,
      },
    },
  });

  const courseDetail = data?.findCourseForAdmin;

  const { data: author } = useQuery<{ user: IUser }>(UserQueries.GET_USER, {
    variables: { id: courseDetail?.ownerId },
  });

  const isActive = courseDetail?.status === Status.Active;

  const handleActivateCourse = async () => {
    if (!courseDetail) return;

    try {
      await activateCourse({
        variables: {
          input: {
            id: courseDetail?.id,
          },
        },
      });
      await refetch();
      toast.success("Course was activated");
    } catch (error: any) {
      toast.error(error.message);
      console.log("e", error);
    }
  };

  const handleDeactivateCourse = async () => {
    try {
      if (!courseDetail) return;

      await deactivateCourse({
        variables: {
          input: {
            id: courseDetail?.id,
          },
        },
      });
      await refetch();
      toast.success("Course was deactivated");
    } catch (error: any) {
      toast.error(error?.message);
      console.log("e", error);
    }
  };

  const handleChangeStatus = () => {
    if (!courseDetail) return;

    courseDetail.status === Status.Active
      ? handleDeactivateCourse()
      : handleActivateCourse();
  };

  return (
    <div>
      {loading ? (
        <PulseLoader
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 300,
          }}
        />
      ) : (
        <>
          <BackHeader
            title="Course"
            actions={[
              <Button
                onClick={() => setCourseModal(true)}
                title="Edit"
                variant={VariantButton.Gray}
              />,
              <Button
                onClick={() => setOpenDeleteModal(true)}
                title="Delete"
                variant={VariantButton.Red}
              />,
              <div className={styles.course_header_actions}>
                <div>
                  <p className={styles["course_header_title"]}>
                    Course is {isActive ? "Active" : "Deactivated"}
                  </p>
                  <p className={styles["course_header_subtitle"]}>
                    Switch to {isActive ? "disable" : "enable"}
                  </p>
                </div>
                <div className={styles["course_header_switcher"]}>
                  <Switcher
                    isActive={isActive}
                    handleClick={handleChangeStatus}
                  />
                </div>
              </div>,
            ]}
          />

          <div className="shadow_box">
            <div className={styles.course_row}>
              <div style={{ marginRight: "auto", maxWidth: 597 }}>
                <p className={styles.course_title}>{courseDetail?.title} </p>
                <span
                  className={styles.course_id}
                  onClick={() => {
                    navigator.clipboard.writeText(String(id));
                    toast.success(`Copied id${id}`);
                  }}
                >{`id${id}`}</span>

                {courseDetail?.ownership ===
                CourseOwnership.MosawerExclusive ? null : (
                  <div className={styles.course_author}>
                    <Avatar
                      img={author?.user.avatar}
                      dimensions={{ width: 32, height: 32 }}
                    />
                    <div>
                      <p className={styles.course_author_label}>Author:</p>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <p className={styles.course_author_name}>
                          {author?.user.firstName}
                        </p>
                        <LevelAccountPhotographer
                          successfulJobs={author?.user.completedContracts}
                          accountType={author?.user.profile?.account}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <p className={styles.course_price}>
                  {`${data?.findCourseForAdmin.price} ${data?.findCourseForAdmin.currency}`}
                </p>
                <p className={styles.course_price_label}>Price</p>
              </div>

              <div className={styles.course_line} />

              <div>
                <p className={styles.course_price}>
                  {data?.findCourseForAdmin.numberOfLessons}
                </p>
                <p className={styles.course_price_label}>Lessons</p>
              </div>

              <div className={styles.course_line} />

              <div>
                <p className={styles.course_price}>
                  {formatSecondsToHoursMinutes(
                    courseDetail?.totalLessonsDurationInSeconds
                  ) || "0m"}
                </p>
                <p className={styles.course_price_label}>Duration</p>
              </div>

              <div className={styles.course_line} />

              <div>
                <p className={styles.course_price}>
                  {data?.findCourseForAdmin.sales}
                </p>
                <p className={styles.course_price_label}>Sales</p>
              </div>
            </div>

            <p style={{ marginBottom: 25 }}>{courseDetail?.description}</p>

            <p className={styles.course_label}>Lessons</p>

            <Tabs
              tabs={[
                { title: "Lessons", count: courseDetail?.numberOfLessons },
                { title: "Bought this course", count: courseDetail?.sales },
              ]}
              currentIndex={tabIndex}
              onChange={setTabIndex}
              containerStyle={{ marginBottom: 10 }}
            />

            {tabIndex === 0 ? (
              <LessonsListTab courseDetail={courseDetail} />
            ) : (
              <BoughtThisCourseTab courseId={courseDetail?.id} />
            )}
          </div>
        </>
      )}

      <CreateUpdateCourseModal
        isOpen={isOpenCourseModal}
        course={courseDetail}
        onClose={() => setCourseModal(false)}
      />

      <DeleteCourseModal
        courseId={courseDetail?.id}
        isOpen={isOpenDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
          navigate(-1);
        }}
      />
    </div>
  );
};

export default CourseDetailPage;
