import { FC, useState } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

interface IProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  value: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  isSucure?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  icon?: React.ReactNode;
}

const Input: FC<IProps> = ({
  value,
  icon,
  containerStyle,
  inputStyle,
  placeholder,
  label,
  isSucure = false,
  disabled = false,
  error,
  onChange,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(!isSucure);

  return (
    <div className={styles.wrapper} style={containerStyle}>
      {label ? <p className={styles.wrapper_label}>{label}</p> : null}

      <div className={styles.wrapper_input_wrapper}>
        <input
          value={value}
          placeholder={placeholder}
          className={cls(styles.wrapper_input, {
            [styles.wrapper_input_focused]: isFocused,
          })}
          type={showPassword ? "text" : "password"}
          disabled={disabled}
          autoComplete="new-password"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => onChange(e.target.value)}
          style={{ fontWeight: value ? 700 : 400, ...inputStyle }}
          {...rest}
        />
        <div
          style={{
            position: "absolute",
            top: 11,
            left: 20,
            height: 20,
            width: 20,
          }}
        >
          {icon}
        </div>

        {isSucure && (
          <div
            className={styles.wrapper_input_secure}
            onClick={() => setShowPassword(!showPassword)}
          >
            <p>{showPassword ? "Hide" : "Show"}</p>
          </div>
        )}
      </div>

      {error && <p className={styles.wrapper_error}>{error}</p>}
    </div>
  );
};

export default Input;
