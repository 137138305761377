import { FC } from "react";
import styles from "./styles.module.scss";

import { DotSelector } from "components";

import { ICONS } from "assets";
import moment from "moment";
import { IStudio, Status } from "types";
import { useNavigate } from "react-router-dom";

interface IProps {
  studio: IStudio;
  onDelete: (studio: IStudio) => void;
  onEdit: (studio: IStudio) => void;
}

const StudioItem: FC<IProps> = ({ studio, onEdit, onDelete }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.studio} onClick={() => navigate(`${studio.id}`)}>
      <div className={styles.studio_info}>
        <div className={styles.studio_info_wrap_img}>
          <img
            src={studio.images?.[0]?.url}
            className={styles.studio_info_img}
            alt="img"
          />
        </div>
        <p className={styles.studio_info_title}>{studio.title}</p>
      </div>

      <div className={styles.studio_address}>
        <p className={styles.studio_address_text}>{studio.location.address}</p>
      </div>

      <div className={styles.studio_price}>
        <p className={styles.studio_price_text}>
          {`${studio.rentPricePerHour} ${studio.rentCurrency}`}
        </p>
      </div>

      <div className={styles.studio_status}>
        <button
          className={styles.studio_status_btn}
          style={{
            backgroundColor:
              studio.status === Status.Active ? "#63CD5A" : "#B2B3BD",
          }}
        >
          <p className={styles.studio_status_text}>{studio.status}</p>
        </button>
      </div>

      <div className={styles.studio_date}>
        {moment(studio.createdAt).format("MMM DD, YYYY")}

        <div>
          <DotSelector
            items={[
              {
                label: "Edit",
                icon: <ICONS.Edit />,
                onClick: () => onEdit(studio),
              },
              {
                label: "Delete",
                icon: <ICONS.Delete />,
                onClick: () => onDelete(studio),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default StudioItem;
