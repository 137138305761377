import { useMemo, useState, useRef, FC } from "react";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { useOutsideClick } from "hooks";
import cls from "classnames";

interface Option {
  value: any;
  label: string;
}

interface SelectProps {
  value: any;
  label?: string;
  options: Option[];
  placeholder?: string;
  onChange: (option: Option) => void;
  containerStyle?: React.CSSProperties;
  optionsStyle?: React.CSSProperties;
}

const Select: FC<SelectProps> = ({
  label,
  value,
  options,
  placeholder = "Search",
  onChange,
  containerStyle,
  optionsStyle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: optionsContainerRef,
    onClickOutside: () => setIsOpen(false),
  });

  const handleSelectOption = (option: Option) => {
    setIsOpen(false);
    onChange(option);
  };

  const findValue = useMemo(() => {
    if (value) return options.find((o) => o.value === value);
    return null;
  }, [value, options]);

  return (
    <>
      {label ? <p className={styles.label}>{label}</p> : null}
      <div
        ref={optionsContainerRef}
        className={styles.wrapper}
        style={containerStyle}
      >
        <div
          className={cls(
            styles.wrapper_block,
            isOpen && styles.wrapper_block_active
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <p
            className={`${styles.wrapper_block_title} ${
              !value ? styles.wrapper_block_placeholder : ""
            }`}
          >
            {findValue ? findValue.label : placeholder}
          </p>
          <div className={`${styles.wrapper_block_icon}`}>
            <ICONS.SelectArrow />
          </div>
        </div>

        {isOpen && (
          <div className={styles.wrapper_options} style={optionsStyle}>
            {options.map((option) => (
              <div
                key={option.value}
                className={`${styles.wrapper_options_item} ${
                  option.value === value
                    ? styles.wrapper_options_item_selected
                    : ""
                }`}
                onClick={() => handleSelectOption(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Select;
