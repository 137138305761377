import { gql } from "@apollo/client";

export const GET_CURRENT_ADMIN = gql`
  query currentAdmin {
    currentAdmin {
      id
      firstName
      lastName
      avatar
      role
    }
  }
`;

export const GET_UPLOAD_URLS = gql`
  query generateUploadUrls($filename: String!) {
    generateUploadUrls(filename: $filename) {
      uploadUrl
      fileUrl
    }
  }
`;

export const GET_ADMINS = gql`
  query admins($input: FindAdminsInput!) {
    admins(input: $input) {
      data {
        id
        email
        firstName
        lastName
        avatar
        role
      }
      meta {
        itemCount
      }
    }
  }
`;
