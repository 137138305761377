import { FC, useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "@apollo/client";
import styles from "./styles.module.scss";
import { ICONS } from "assets";
import {
  Button,
  Header,
  SearchInput,
  TableHead,
  ConfirmModal,
  Tabs,
} from "components";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

import { BannerAudience, Order } from "types/enums";
import { BannersQueries } from "graphql/queries";
import { IBanner } from "types";
import BannerItem from "./BannerItem";
import { DELETE_BANNER, MOVE_BANNER } from "graphql/mutations";
import { useDebounce, useTab } from "hooks";
import CreateUpdateBannerModal from "./UpdateCreateBanner";
import toast from "react-hot-toast";

interface IBannersReq {
  findBanners: {
    banners: IBanner[];
  };
}

const Banners: FC = () => {
  const [search, setSearch] = useState("");
  const { currentTab: currentTabIndex, onChange: setCurrentTabIndex } =
    useTab();
  const [currentBanner, setCurrentBanner] = useState<IBanner | null>(null);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const [endMoveBanner] = useMutation(MOVE_BANNER);
  const [deleteBannerReq] = useMutation(DELETE_BANNER);

  const [banners, setBanners] = useState<IBanner[]>();
  const [isOpenCreateUpdateModal, setOpenCreateUpdateModal] = useState(false);

  const debounceSearchValue = useDebounce(search, 500);

  const [getBanners, { loading, refetch }] = useLazyQuery<IBannersReq>(
    BannersQueries.GET_BANNERS,
    {
      variables: {
        input: {
          title: debounceSearchValue,
          audience:
            currentTabIndex === 0
              ? BannerAudience.Photographers
              : BannerAudience.Clients,
        },
      },
      fetchPolicy: "cache-first",
      onCompleted(data) {
        refetch();
        setBanners(data?.findBanners.banners);
      },
    }
  );

  useEffect(() => {
    getBanners();
  }, []);

  const handleDragEnd = async (result: DropResult) => {
    try {
      if (!banners || !result.destination) return;

      const copyBanners = banners.slice();

      if (!result.destination) return;

      const [reorderedItem] = copyBanners.splice(result.source.index, 1);
      copyBanners.splice(result.destination.index, 0, reorderedItem);

      const newOrderMovedBanner = banners?.[result.destination.index].order;

      setBanners(copyBanners);

      await endMoveBanner({
        variables: {
          input: {
            id: +result.draggableId,
            order: newOrderMovedBanner,
          },
        },
      });

      await refetch();
    } catch (error: any) {
      toast.error(error?.message);
      setBanners(banners);
    }
  };

  const onEditBanner = (banner: IBanner) => {
    setCurrentBanner(banner);
    setOpenCreateUpdateModal(true);
  };

  const onClickDeleteBanner = (banner: IBanner) => {
    setCurrentBanner(banner);
    setOpenDeleteModal(true);
  };

  const deleteBanner = async (banner: IBanner | null) => {
    try {
      await deleteBannerReq({
        variables: {
          input: {
            id: banner?.id,
          },
        },
      });

      await refetch();

      setOpenDeleteModal(false);

      toast.success("Banner deleted successfully");
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const onClickAddNewBanner = () => {
    setCurrentBanner(null);
    setOpenCreateUpdateModal(true);
  };

  return (
    <div>
      <Header title="Banners" />

      <div className="shadow_box">
        <div className={styles.wrapper_tabs}>
          <Tabs
            tabs={[
              { title: BannerAudience.Photographers },
              { title: BannerAudience.Clients },
            ]}
            currentIndex={currentTabIndex}
            onChange={setCurrentTabIndex}
          />
        </div>
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_input}>
            <SearchInput
              value={search}
              placeholder="Search"
              onChange={setSearch}
            />
          </div>
          <div className={styles.wrapper_header_button}>
            <Button
              title="Add new"
              icon={<ICONS.Plus />}
              onClick={onClickAddNewBanner}
            />
          </div>
        </header>

        <TableHead
          items={[
            {
              title: "#",
              disableSort: true,
              style: {
                width: "5.7%",
                paddingRight: 10,
                justifyContent: "flex-end",
              },
            },
            {
              title: "Banner",
              disableSort: true,
              style: {
                width: "20%",
              },
            },
            {
              title: "Type",
              disableSort: true,
              style: {
                width: "8.8%",
              },
            },
            {
              title: "Link",
              disableSort: true,
              style: {
                width: "13.5%",
              },
            },
            {
              title: "ID",
              disableSort: true,
              style: {
                width: "9.7%",
              },
            },
            {
              title: "Status",
              disableSort: true,
              style: {
                width: "9.7%",
              },
            },
            {
              title: "Date added",
              disableSort: true,
              style: { width: "15.6%" },
            },
            false,
          ]}
          sorted={{ sortedBy: "", type: Order.DESC }}
          onChangeSort={() => {}}
        />

        {loading ? (
          <Skeleton
            height={87}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={5}
          />
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="banners">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {banners?.map((banner, index) => {
                    return (
                      <Draggable
                        key={banner.id}
                        draggableId={String(banner.id)}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <BannerItem
                              banner={banner}
                              onEdit={onEditBanner}
                              onDelete={onClickDeleteBanner}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>

      <ConfirmModal
        isOpen={isOpenDeleteModal}
        hideCrossIcon
        title={`Are you sure you want to delete the banner?`}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={() => deleteBanner(currentBanner)}
      />

      <CreateUpdateBannerModal
        banner={currentBanner}
        isOpen={isOpenCreateUpdateModal}
        onClose={() => {
          setOpenCreateUpdateModal(false);
        }}
        refetch={refetch}
        bannerAudience={
          currentTabIndex === 0
            ? BannerAudience.Photographers
            : BannerAudience.Clients
        }
      />
    </div>
  );
};

export default Banners;
