import { FC } from "react";
import styles from "./styles.module.scss";

import { DotSelector } from "components";

import { ICONS } from "assets";
import moment from "moment";
import { Status, IBanner } from "types";
import { useNavigate } from "react-router-dom";

interface IProps {
  banner: IBanner;
  onDelete: (banner: IBanner) => void;
  onEdit: (banner: IBanner) => void;
}

const BannerItem: FC<IProps> = ({ banner, onEdit, onDelete }) => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.banner}
      onClick={() => {
        navigate(`${banner.id}`);
      }}
    >
      <div className={styles.banner_first_colum}>
        <ICONS.Burger />
        <span style={{ fontWeight: 700 }}>{banner.order}</span>
      </div>

      <div className={styles.banner_desc}>
        <div className={styles.banner_desc_wrap_img}>
          <img
            src={banner.image}
            className={styles.banner_desc_img}
            alt="img"
          />
        </div>
        <div style={{ maxWidth: "70%" }}>
          <p className={styles.banner_desc_title}>{banner.title}</p>
        </div>
      </div>

      <div className={styles.banner_type}>
        <p className={styles.banner_link_text}>{banner.redirection?.target}</p>
      </div>

      <div className={styles.banner_link}>
        <p className={styles.banner_link_text}>
          {banner.redirection?.targetTitle}
        </p>
      </div>

      <div className={styles.banner_id}>
        <p className={styles.banner_link_text}>
          {`id${banner.redirection?.targetId}`}
        </p>
      </div>

      <div className={styles.banner_status}>
        <button
          className={styles.banner_status_btn}
          style={{
            backgroundColor:
              banner.status === Status.Active ? "#63CD5A" : "#B2B3BD",
          }}
        >
          <p className={styles.banner_status_text}>{banner.status}</p>
        </button>
      </div>

      <div className={styles.banner_date}>
        {moment(banner.createdAt).format("MMM DD, YYYY")}

        <div>
          <DotSelector
            items={[
              {
                label: "Edit",
                icon: <ICONS.Edit />,
                onClick: () => onEdit(banner),
              },
              {
                label: "Delete",
                icon: <ICONS.Delete />,
                onClick: () => onDelete(banner),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerItem;
