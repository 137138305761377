import { FC } from "react";
import styles from "./styles.module.scss";
import { DotSelector } from "components";
import { ICONS } from "assets";
import { ILesson } from "types";
import { formatSecondsToHoursMinutes } from "utils/userService";

interface IProps {
  lesson: ILesson;
  onDelete: (lesson: ILesson) => void;
  onEdit: (lesson: ILesson) => void;
  onClickLesson: (lesson: ILesson) => void;
}

const LessonCard: FC<IProps> = ({
  lesson,
  onEdit,
  onDelete,
  onClickLesson,
}) => {
  return (
    <div className={styles.lesson} onClick={() => onClickLesson(lesson)}>
      <div className={styles.lesson_numbering}>{lesson.numbering} </div>

      <div className={styles.lesson_info}>
        <p className={styles.lesson_info_title}>{lesson.title}</p>
        <p className={styles.lesson_info_subtitle}>{lesson.description}</p>
      </div>

      <div className={styles.lesson_duration}>
        <p className={styles.lesson_info_text}>
          {formatSecondsToHoursMinutes(lesson.durationInSeconds)}
        </p>

        <div>
          <DotSelector
            items={[
              {
                label: "Edit",
                icon: <ICONS.Edit />,
                onClick: () => onEdit(lesson),
              },
              {
                label: "Delete",
                icon: <ICONS.Delete />,
                onClick: () => onDelete(lesson),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default LessonCard;
