import { FC, useState } from "react";
import styles from "./styles.module.scss";

import { Button, RadioButton } from "components";

import { IReport } from "types/interfaces";

type Props = {
  report: IReport;
  onDelete: () => void;
  onSkip: () => void;
  onBlock: (reason: string) => void;
};

const SecondReason = [
  "Minor safety",
  "Adult nudity and sexual activities",
  "Bulling and harassment",
  "Illegal activities and regulated goods",
];

const SelectReport: FC<Props> = ({ report, onDelete, onSkip, onBlock }) => {
  const [mainReason, setMainReason] = useState<number | null>(null);
  const [secondReasonReason, setSecondReasonReason] = useState<number | null>(
    null
  );
  const [other, setOther] = useState("");

  const handleReport = () => {
    if (mainReason === 0) {
      onSkip();
      return;
    }

    if (mainReason === 1) {
      const reason =
        //@ts-ignore
        secondReasonReason === 4 ? other : SecondReason[secondReasonReason];

      onBlock(reason);
      return;
    }

    onDelete();
  };

  const handleChange = (value: string) => {
    const isHaveTwoEmptyLines = /\n\s*\n\s*\n/.test(value);

    const hasConsecutiveSpaces = / {2,}/.test(value);

    const isHaveStartSpaceOnSomeLine = /^ /m.test(value);

    if (
      isHaveTwoEmptyLines ||
      isHaveStartSpaceOnSomeLine ||
      hasConsecutiveSpaces
    )
      return;

    setOther(value.trimStart());
  };

  const getIsDisableButton = () => {
    if (mainReason === null) return true;

    if (mainReason === 1) {
      if (secondReasonReason === null) return true;
      if (secondReasonReason === 4 && other.trim() === "") return true;
    }

    return false;
  };

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.wrapper_radio}>
          <RadioButton
            value={mainReason === 0}
            label="Skip this report"
            onSelect={() => setMainReason(0)}
          />
        </div>
        <div className={styles.wrapper_radio}>
          <RadioButton
            value={mainReason === 1}
            label={`Block ${report.reported?.firstName} ${report.reported?.lastName}`}
            onSelect={() => setMainReason(1)}
          />
        </div>
        {mainReason === 1 && (
          <div className={styles.wrapper_second}>
            <p className={styles.wrapper_second_title}>Choose a reason:</p>
            <div className={styles.wrapper_second_item}>
              <RadioButton
                value={secondReasonReason === 0}
                label="Minor safety"
                smallTitle
                onSelect={() => setSecondReasonReason(0)}
              />
            </div>
            <div className={styles.wrapper_second_item}>
              <RadioButton
                value={secondReasonReason === 1}
                label="Adult nudity and sexual activities"
                smallTitle
                onSelect={() => setSecondReasonReason(1)}
              />
            </div>
            <div className={styles.wrapper_second_item}>
              <RadioButton
                value={secondReasonReason === 2}
                label="Bulling and harassment"
                smallTitle
                onSelect={() => setSecondReasonReason(2)}
              />
            </div>
            <div className={styles.wrapper_second_item}>
              <RadioButton
                value={secondReasonReason === 3}
                label="Illegal activities and regulated goods"
                smallTitle
                onSelect={() => setSecondReasonReason(3)}
              />
            </div>
            <RadioButton
              value={secondReasonReason === 4}
              label="Other"
              smallTitle
              onSelect={() => setSecondReasonReason(4)}
            />

            {secondReasonReason === 4 && (
              <textarea
                className={styles.wrapper_second_input}
                value={other}
                placeholder="Message"
                onChange={(e) => handleChange(e.target.value)}
              />
            )}
          </div>
        )}
        <div>
          <RadioButton
            value={mainReason === 2}
            label={`Delete ${report.reported?.firstName} ${report.reported?.lastName} account`}
            onSelect={() => setMainReason(2)}
          />
        </div>
      </div>

      <div className={styles.wrapper_button}>
        <div className={styles.wrapper_button_item}>
          <Button
            title="Send"
            disable={getIsDisableButton()}
            onClick={handleReport}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectReport;
