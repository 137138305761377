import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

type Props = {
  message: any;
};

const Image: FC<Props> = ({ message }) => {
  return <img className={styles.image} src={message.attachment} />;
};

export default Image;
