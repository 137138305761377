import { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
  title: string;
  amount: number;
  children: React.ReactNode;
  width?: number; // in percent
};

const Section: FC<Props> = ({ children, title, amount, width = 10 }) => {
  const formatNumberToCurrency = (number: number): string => {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
    return formattedNumber;
  };

  return (
    <div>
      <h4 className={styles.wrapper_title}>{title}</h4>
      {children}

      <footer className={styles.wrapper_footer}>
        <p className={styles.wrapper_footer_label}>Total</p>
        <p
          className={styles.wrapper_footer_value}
          style={{ flexBasis: `${width}%` }}
        >
          {formatNumberToCurrency(amount)}
        </p>
      </footer>
    </div>
  );
};

export default Section;
