import Wrapper from "./Wrapper";
import Rating from "./Rating";
import List from "./List";
import Checkbox from "./Checkbox";
import DateRange from "./DateRange";
import CheckboxList from "./CheckboxList";
import NumberRange from "./NumberRange";

const Components = {
  Wrapper,
  Rating,
  List,
  Checkbox,
  DateRange,
  CheckboxList,
  NumberRange,
};

export default Components;
