import { gql } from "@apollo/client";

export const GET_STUDIOS = gql`
  query findStudios($input: FindStudiosInput) {
    findStudios(input: $input) {
      studios {
        id
        title
        description
        rentPricePerHour
        rentCurrency
        status
        createdAt
        location {
          id
          studioId
          address
          latitude
          longitude
        }
        images {
          id
          studioId
          url
          order
          createdAt
        }
      }
      count
    }
  }
`;

export const GET_STUDIO_DETAIL = gql`
  query findStudioById($input: FindStudioByIdInput!) {
    findStudioById(input: $input) {
      id
      title
      description
      rentPricePerHour
      rentCurrency
      status
      createdAt
      location {
        id
        studioId
        address
        latitude
        longitude
      }
      images {
        id
        studioId
        url
        order
        createdAt
      }
    }
  }
`;

export const GET_COUNT_RENTAL_STUDIO = gql`
  query query($input: CountStudioRentalsInput) {
    countStudioRentals(input: $input)
  }
`;

export const GET_RENTAL_STUDIO_BY_DATES = gql`
  query findStudioRentalsByDates(
    $input: FindStudioRentalDatetimesByDatesInput!
  ) {
    findStudioRentalsByDates(input: $input) {
      id
      studioRentalId
      startsAt
      endsAt
      rental {
        id
        studioId
        renterId
        externalRenterId
        createdAt
        renter {
          id
          email
          phone
          firstName
          lastName
          avatar
          profile {
            id
            userId
            type
            account
            agencyName
          }
        }
        externalRenter {
          id
          fullName
          phone
        }
        studio {
          id
          title
          description
          rentPricePerHour
          rentCurrency
          status
          createdAt
          location {
            id
            studioId
            address
            latitude
            longitude
          }
          images {
            id
            studioId
            url
            order
            createdAt
          }
        }
        datetimes {
          id
          studioRentalId
          startsAt
          endsAt
        }
      }
    }
  }
`;
