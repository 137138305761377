import { FC, useState } from "react";
import { useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";

import styles from "./styles.module.scss";
import { ICONS } from "assets";
import {
  Button,
  Header,
  Pagination,
  SearchInput,
  TableHead,
  Tabs,
} from "components";
import { CourseOwnership, Order } from "types/enums";
import { useDebounce, useTab } from "hooks";
import CourseCard from "./CourseCard";
import { CoursesQueries } from "graphql/queries";
import { ICourse } from "types";
import DeleteCourseModal from "./DeleteCourseModal";
import CreateUpdateCourseModal from "./CreateUpdateCourseModal";

interface ICoursesReq {
  findCourseCardsForAdmin: {
    courses: ICourse[];
    count: number;
  };
}

enum OrderBy {
  Title = "Title",
  Price = "Price",
  Rating = "Rating",
  CreationDate = "CreationDate",
  TotalDuration = "TotalDuration",
  NumberOfLessons = "NumberOfLessons",
  NumberOfPurchasers = "NumberOfPurchasers",
}

const CoursesPage: FC = () => {
  const { currentTab, onChange: setCurrentTabIndex } = useTab();
  const [currentCourse, setCurrentCourse] = useState<ICourse | null>(null);
  const [count, setCount] = useState(1);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const [sort, setSort] = useState<any>({
    sortedBy: OrderBy.CreationDate,
    type: Order.DESC,
    currentPage: 1,
    search: "",
  });

  const [courses, setCourses] = useState<ICourse[]>();
  const [isOpenCreateUpdateModal, setOpenCreateUpdateModal] = useState(false);

  const debounceSearchValue = useDebounce(sort.search, 500);

  const { loading, refetch } = useQuery<ICoursesReq>(
    CoursesQueries.GET_COURSES,
    {
      variables: {
        input: {
          ownership:
            currentTab === 0
              ? CourseOwnership.Photographer
              : CourseOwnership.MosawerExclusive,
          search: debounceSearchValue,
          skip: (sort.currentPage - 1) * 10,
          order: sort.type,
          orderBy: sort.sortedBy,
        },
      },
      onCompleted(data) {
        refetch();
        setCount(data?.findCourseCardsForAdmin.count);
        setCourses(data?.findCourseCardsForAdmin.courses);
      },
    }
  );

  const onDeleteCourse = (course: ICourse) => {
    setCurrentCourse(course);
    setOpenDeleteModal(true);
  };

  const onClickAddNewCourse = () => {
    setCurrentCourse(null);
    setOpenCreateUpdateModal(true);
  };

  return (
    <div>
      <Header title="Courses" />

      <div className="shadow_box">
        <div className={styles.wrapper_tabs}>
          <Tabs
            tabs={[{ title: "Photographers" }, { title: "Mosawer Exclusives" }]}
            currentIndex={currentTab}
            onChange={(newIndex) => {
              setCurrentTabIndex(newIndex);
              setSort((prev: any) => ({ ...prev, currentPage: 1 }));
            }}
          />
        </div>
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_input}>
            <SearchInput
              value={sort.search}
              placeholder="Search"
              onChange={(val) =>
                setSort({ ...sort, search: val, currentPage: 1 })
              }
            />
          </div>

          {currentTab === 0 ? null : (
            <Button
              title="Add new"
              icon={<ICONS.Plus />}
              onClick={onClickAddNewCourse}
              styleBtn={{ width: 160, marginLeft: "auto" }}
            />
          )}
          <div className={styles.wrapper_header_button}></div>
        </header>

        <TableHead
          items={[
            {
              title: "Course",
              value: OrderBy.Title,
              style: { width: "30%" },
            },
            {
              title: "Lessons",
              value: OrderBy.NumberOfLessons,
              style: { width: "10%" },
            },
            {
              title: "Duration",
              value: OrderBy.TotalDuration,
              style: { width: "10%" },
            },
            {
              title: "Price",
              value: OrderBy.Price,
              style: { width: "10%" },
            },
            {
              title: "Students",
              value: OrderBy.NumberOfPurchasers,
              style: { width: "10%" },
            },
            {
              title: "Rating",
              value: OrderBy.Rating,
              style: { width: "10%" },
            },
            {
              title: "Date added",
              value: OrderBy.CreationDate,
              style: { width: "10%" },
            },
            false,
          ]}
          sorted={sort}
          onChangeSort={(val) =>
            setSort({ ...sort, ...val, currentPage: sort.currentPage })
          }
        />

        {loading ? (
          <Skeleton
            height={80}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={5}
          />
        ) : (
          <>
            {courses?.map((course) => {
              return (
                <CourseCard
                  key={course.id}
                  course={course}
                  onDelete={onDeleteCourse}
                />
              );
            })}
          </>
        )}

        {count > 0 && (
          <div style={{ marginTop: "25px" }}>
            <Pagination
              limit={10}
              count={count}
              currentPage={sort.currentPage}
              onChange={(page) => setSort({ ...sort, currentPage: page })}
            />
          </div>
        )}
      </div>

      <DeleteCourseModal
        courseId={currentCourse?.id}
        isOpen={isOpenDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
      />

      <CreateUpdateCourseModal
        course={null}
        isOpen={isOpenCreateUpdateModal}
        onClose={() => setOpenCreateUpdateModal(false)}
      />
    </div>
  );
};

export default CoursesPage;
