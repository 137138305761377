import { FC } from "react";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar, JobTags } from "components";
import { IContract } from "types/interfaces";
import { UserService } from "utils";
import moment from "moment";

type Props = {
  showPhotographer?: boolean;
  contract?: IContract;
  showArrow?: boolean;
};

const ActiveContractItem: FC<Props> = ({
  contract,
  showArrow = true,
  showPhotographer = true,
}) => {
  if (!contract)
    return (
      <div className={styles.wrapper}>
        <div className={styles.wrapper_info}>
          <JobTags job={"Some"} />
          <h4 className={styles.wrapper_info_title}>
            Wedding photo shoot on the beach with our family
          </h4>
          <p className={styles.wrapper_info_id}>ID 456789090</p>
        </div>

        <div className={styles.wrapper_user}>
          <div className={styles.wrapper_user_avatar}>
            <Avatar />
          </div>
          <p className={styles.wrapper_user_name}>James Peterson</p>
        </div>

        <div className={styles.wrapper_budget}>$600</div>

        <div className={styles.wrapper_date}>Nov 23, 2022</div>

        {showArrow && (
          <div className={styles.wrapper_btn}>
            <div className={styles.wrapper_btn_wrapper}>
              <ICONS.RightArrow />
            </div>
          </div>
        )}
      </div>
    );

  const contact = showPhotographer ? contract.photographer : contract.client;

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_info}>
        <JobTags job={contract.job} />
        <h4 className={styles.wrapper_info_title}>{contract.job.headline}</h4>
        <p className={styles.wrapper_info_id}>ID {contract.id}</p>
      </div>

      <div className={styles.wrapper_user}>
        <div className={styles.wrapper_user_avatar}>
          <Avatar img={contact.avatar} />
        </div>
        <p className={styles.wrapper_user_name}>
          {contact.firstName} {contact.lastName}
        </p>
      </div>

      <div className={styles.wrapper_budget}>
        {UserService.getBudgetAmount(contract)}
      </div>

      <div className={styles.wrapper_date}>
        {moment(contract.createdAt).format("MMM DD, YYYY")}
      </div>

      {showArrow && (
        <div className={styles.wrapper_btn}>
          <div className={styles.wrapper_btn_wrapper}>
            <ICONS.RightArrow />
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveContractItem;
