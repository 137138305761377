import { gql } from "@apollo/client";

export const BLOCK_USER = gql`
  mutation blockUser($id: Int!, $reason: String!) {
    blockUser(id: $id, reason: $reason)
  }
`;

export const UNBLOCK_USER = gql`
  mutation unblockUser($id: Int!) {
    unblockUser(id: $id)
  }
`;

export const DELETE_USER = gql`
  mutation removeUser($id: Int!) {
    removeUser(id: $id) {
      id
    }
  }
`;

export const USER_CHANGE_FEE = gql`
  mutation changeUserFee($input: ChangeFeeInput!) {
    changeUserFee(input: $input) {
      id
    }
  }
`;

export const APPROVE_USER_VERIFICATION = gql`
  mutation ApproveUserVerification($input: ApproveUserVerificationInput!) {
    approveUserVerification(input: $input) {
      success
    }
  }
`;

export const REJECT_USER_VERIFICATION = gql`
  mutation RejectUserVerification($input: RejectUserVerificationInput!) {
    rejectUserVerification(input: $input) {
      success
    }
  }
`;

export const UPDATE_POSSIBLY_CREATE_COURSE = gql`
  mutation UpdateUserPrivileges($input: UpdateUserPrivilegesInput!) {
    updateUserPrivileges(input: $input) {
      id
    }
  }
`;
