import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { IMAGES } from "assets";

import { Avatar } from "components";
import Image from "./Image";
import Video from "./Video";
import File from "./File";

import { IMessage } from "types/interfaces";
import { MessageType } from "types/enums";

type Props = {
  message: IMessage;
  reportedId: number;
  reporterId: number;
};

const MessageItem: FC<Props> = ({ message, reportedId, reporterId }) => {
  const renderMessage = () => {
    if (message.type === MessageType.Attachment) {
      // @ts-ignore
      const props = JSON.parse(message.attachmentProps);
      if (props.type === "image") {
        return <Image message={message} />;
      } else if (props.type === "video") {
        return (
          <Video
            message={message}
            // currentPlayVideo={currentPlayVideoId}
            // onPlayVideo={onPlayVideo}
          />
        );
      } else {
        return <File message={message} />;
      }
    }

    return (
      <>
        <p className={styles.msg_name}>
          {message?.user
            ? `${message.user.firstName} ${message.user.lastName}`
            : `${message.admin.firstName} ${message.admin.lastName}`}
        </p>
        <p className={styles.msg}>{message.body}</p>
      </>
    );
  };

  return (
    <div
      className={cls(styles.wrapper, { [styles.wrapper_own]: message.admin })}
    >
      <div className={styles.message_wrapper}>
        {!message.admin && (
          <Avatar
            // @ts-ignore
            img={message?.user?.avatar}
            dimensions={{ width: 24, height: 24 }}
          />
        )}

        <div
          className={cls(styles.message, {
            [styles.message_reporterOn]: message.userId === reporterId,
            [styles.message_admin]: message.admin,
            [styles.message_attachment]:
              message.type === MessageType.Attachment,
          })}
        >
          {renderMessage()}
        </div>

        {message.admin && (
          <Avatar
            // @ts-ignore
            img={IMAGES.chatAvatar}
            dimensions={{ width: 24, height: 24 }}
          />
        )}
      </div>
    </div>
  );
};

export default MessageItem;
