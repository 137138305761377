import { FC } from "react";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar } from "components";
import { IContract, IFeedback } from "types/interfaces";
import { UserService } from "utils";
import moment from "moment";

type Props = {
  contract?: IContract;
  feedback?: IFeedback;
  showArrow?: boolean;
  showPhotographer?: boolean;
};

const CompletedContractItem: FC<Props> = ({
  showArrow = true,
  showPhotographer = true,
  contract,
  feedback,
}) => {
  if (!contract) {
    <div className={styles.wrapper}>
      <div className={styles.wrapper_info}>
        <h4 className={styles.wrapper_info_title}>
          Wedding photo shoot on the beach with our family
        </h4>
        <p className={styles.wrapper_info_id}>ID 456789090</p>

        <div className={styles.wrapper_info_feedback}>
          <div className={styles.wrapper_info_feedback_symbol}>
            <ICONS.Quote />
          </div>
          <p className={styles.wrapper_info_feedback_text}>
            Working with Michael and Amelia was a pleasure! They were easygoing
            and open to trying out different poses and locations.
          </p>
        </div>
      </div>

      <div className={styles.wrapper_user}>
        <div className={styles.wrapper_user_avatar}>
          <Avatar />
        </div>
        <p className={styles.wrapper_user_name}>James Peterson</p>
      </div>

      <div className={styles.wrapper_budget}>$600</div>

      <div className={styles.wrapper_rating}>
        <div className={styles.wrapper_rating_icon}>
          <ICONS.Star />
        </div>
        <p className={styles.wrapper_rating_text}>5.00</p>
      </div>

      <div className={styles.wrapper_date}>Nov 23, 2022</div>

      <div className={styles.wrapper_date}>Mar 23, 2023</div>

      {showArrow && (
        <div className={styles.wrapper_btn}>
          <div className={styles.wrapper_btn_wrapper}>
            <ICONS.RightArrow />
          </div>
        </div>
      )}
    </div>;
  }

  const contact = showPhotographer ? contract?.photographer : contract?.client;

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_info}>
        <h4 className={styles.wrapper_info_title}>{contract?.job.headline}</h4>
        <p className={styles.wrapper_info_id}>ID {contract?.id}</p>

        {feedback && feedback.text && (
          <div className={styles.wrapper_info_feedback}>
            <div className={styles.wrapper_info_feedback_symbol}>
              <ICONS.Quote />
            </div>
            <p className={styles.wrapper_info_feedback_text}>{feedback.text}</p>
          </div>
        )}
      </div>

      <div className={styles.wrapper_user}>
        <div className={styles.wrapper_user_avatar}>
          <Avatar img={contact?.avatar} />
        </div>
        <p className={styles.wrapper_user_name}>
          {contact?.firstName} {contact?.lastName}
        </p>
      </div>

      <div className={styles.wrapper_budget}>
        {contract ? UserService.getBudgetAmount(contract) : "N/A"}
      </div>

      <div className={styles.wrapper_rating}>
        <div className={styles.wrapper_rating_icon}>
          <ICONS.Star />
        </div>
        <p className={styles.wrapper_rating_text}>
          {feedback ? UserService.addZeroToRate(feedback.avgRating) : "N/A"}
        </p>
      </div>

      <div className={styles.wrapper_date}>
        {moment(contract?.startAt).format("MMM DD, YYYY")}
      </div>

      <div className={styles.wrapper_date}>
        {moment(contract?.endAt).format("MMM DD, YYYY")}
      </div>

      {showArrow && (
        <div className={styles.wrapper_btn}>
          <div className={styles.wrapper_btn_wrapper}>
            <ICONS.RightArrow />
          </div>
        </div>
      )}
    </div>
  );
};

export default CompletedContractItem;
