import { FC, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

import styles from "./styles.module.scss";
import { ICONS } from "assets";
import {
  Button,
  ConfirmModal,
  Header,
  Pagination,
  SearchInput,
  TableHead,
} from "components";
import { Order } from "types/enums";
import { StudiosQueries } from "graphql/queries";
import { IStudio } from "types";
import { useDebounce } from "hooks";
import StudioItem from "./StudioItem";
import { StudiosMutations } from "graphql/mutations";
import CreateUpdateStudioModal from "./CreateUpdateStudioModal";

interface IStudiosReq {
  findStudios: {
    studios: IStudio[];
    count: number;
  };
}

enum OrderBy {
  Title = "Title",
  RentPricePerHour = "RentPricePerHour",
  CreatedAt = "CreatedAt",
}

const StudiosPage: FC = () => {
  const [currentStudio, setCurrentStudio] = useState<IStudio | null>(null);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [count, setCount] = useState(1);

  const [sort, setSort] = useState<any>({
    sortedBy: OrderBy.CreatedAt,
    type: Order.DESC,
    currentPage: 1,
    search: "",
  });

  const [deleteStudioReq] = useMutation(StudiosMutations.DELETE_STUDIO);

  const [studios, setStudios] = useState<IStudio[]>();
  const [isOpenCreateUpdateModal, setOpenCreateUpdateModal] = useState(false);

  const debounceSearchValue = useDebounce(sort.search, 500);

  const [getStudios, { loading, refetch }] = useLazyQuery<IStudiosReq>(
    StudiosQueries.GET_STUDIOS,
    {
      variables: {
        input: {
          title: debounceSearchValue,
          skip: (sort.currentPage - 1) * 10,
          order: sort.type,
          orderBy: sort.sortedBy,
        },
      },
      onCompleted(data) {
        refetch();
        setCount(data?.findStudios.count);
        setStudios(data?.findStudios.studios);
      },
    }
  );

  useEffect(() => {
    getStudios();
  }, []);

  const onEditStudio = (studio: IStudio) => {
    setCurrentStudio(studio);
    setOpenCreateUpdateModal(true);
  };

  const onDeleteStudio = (studio: IStudio) => {
    setCurrentStudio(studio);
    setOpenDeleteModal(true);
  };

  const deleteStudio = async (studio: IStudio | null) => {
    try {
      await deleteStudioReq({
        variables: {
          input: {
            id: studio?.id,
          },
        },
      });

      await refetch();

      setOpenDeleteModal(false);

      toast.success("Studio deleted successfully");
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const onClickAddNewStudios = () => {
    setCurrentStudio(null);
    setOpenCreateUpdateModal(true);
  };

  return (
    <div>
      <Header title="Studios" />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_input}>
            <SearchInput
              value={sort.search}
              placeholder="Search"
              onChange={(val) =>
                setSort({ ...sort, search: val, currentPage: 1 })
              }
            />
          </div>
          <div className={styles.wrapper_header_button}>
            <Button
              title="Add new"
              icon={<ICONS.Plus />}
              onClick={onClickAddNewStudios}
            />
          </div>
        </header>

        <TableHead
          items={[
            {
              title: "Studio",
              value: OrderBy.Title,
              style: {
                width: "30%",
              },
            },
            {
              title: "Address",
              disableSort: true,
              style: {
                width: "23.5%",
              },
            },
            {
              title: "Price (hour)",
              value: OrderBy.RentPricePerHour,
              style: {
                width: "10%",
              },
            },
            {
              title: "Status",
              disableSort: true,
              style: {
                width: "12%",
              },
            },
            {
              title: "Date added",
              value: OrderBy.CreatedAt,
              style: { width: "15%" },
            },
            false,
          ]}
          sorted={sort}
          onChangeSort={(val) =>
            setSort({ ...sort, ...val, currentPage: sort.currentPage })
          }
        />

        {loading ? (
          <Skeleton
            height={80}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={5}
          />
        ) : (
          <>
            {studios?.map((studio) => {
              return (
                <StudioItem
                  key={studio.id}
                  studio={studio}
                  onEdit={onEditStudio}
                  onDelete={onDeleteStudio}
                />
              );
            })}
          </>
        )}
      </div>

      <ConfirmModal
        isOpen={isOpenDeleteModal}
        hideCrossIcon
        title={`Are you sure you want to delete the studio?`}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={() => deleteStudio(currentStudio)}
      />

      <CreateUpdateStudioModal
        studio={currentStudio}
        isOpen={isOpenCreateUpdateModal}
        onClose={() => setOpenCreateUpdateModal(false)}
        refetch={refetch}
      />

      {count > 0 && (
        <div style={{ margin: "10px 0" }}>
          <Pagination
            limit={10}
            count={count}
            currentPage={sort.currentPage}
            onChange={(page) => setSort({ ...sort, currentPage: page })}
          />
        </div>
      )}
    </div>
  );
};

export default StudiosPage;
