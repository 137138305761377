import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as Arrows } from "./arrows.svg";
import { ReactComponent as CircleUp } from "./circleUp.svg";
import { ReactComponent as CircleDown } from "./circleDown.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as SmallArrow } from "./smallArrow.svg";
import { ReactComponent as RightArrow } from "./rightArrow.svg";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as VerticalLogo } from "./verticalLogo.svg";
import { ReactComponent as BackArrow } from "./backArrow.svg";
import { ReactComponent as AttachFile } from "./attachFile.svg";
import { ReactComponent as AttachImage } from "./attachImage.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as LocationPoint } from "./locationPoint.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Quote } from "./quote.svg";
import { ReactComponent as Star } from "./star.svg";
import { ReactComponent as SendArrow } from "./sendArrow.svg";
import { ReactComponent as SmallLeftArrow } from "./smallLeftArrow.svg";
import { ReactComponent as SelectArrow } from "./selectArrow.svg";
import { ReactComponent as Like } from "./like.svg";
import { ReactComponent as GalleryCross } from "./galleryCross.svg";
import { ReactComponent as GalleryArrow } from "./galleryArrow.svg";
import { ReactComponent as CalendarArrow } from "./calendarArrow.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as Dots } from "./dots.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as Burger } from "./burger.svg";
import { ReactComponent as PlusDropImage } from "./plusDropImage.svg";
import { ReactComponent as DeleteWhite } from "./deleteWhite.svg";
import { ReactComponent as CalendarArrowLeft } from "./calendarArrowLeft.svg";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as NoAvatar } from "./noAvatar.svg";
import { ReactComponent as ProLevelAccount } from "./proLevelAccount.svg";
import { ReactComponent as MasterLevelAccount } from "./masterLevelAccount.svg";
import { ReactComponent as TopLevelAccount } from "./topLevelAccount.svg";
import { ReactComponent as Video } from "./video.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as Play } from "./play.svg";
import { ReactComponent as ApproveCheck } from "./approveCheck.svg";

import nav from "./nav";

const ICONS = {
  Logo,
  Arrows,
  CircleUp,
  CircleDown,
  Search,
  Filter,
  SmallArrow,
  RightArrow,
  Cross,
  VerticalLogo,
  BackArrow,
  AttachFile,
  AttachImage,
  Logout,
  LocationPoint,
  Calendar,
  Quote,
  Burger,
  Star,
  SendArrow,
  SmallLeftArrow,
  SelectArrow,
  Like,
  GalleryCross,
  GalleryArrow,
  CalendarArrow,
  Plus,
  Dots,
  Edit,
  Delete,
  PlusDropImage,
  nav,
  DeleteWhite,
  CalendarArrowLeft,
  Check,
  NoAvatar,
  ProLevelAccount,
  MasterLevelAccount,
  TopLevelAccount,
  Video,
  Download,
  Play,
  ApproveCheck,
};

export default ICONS;
