import { FC, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

import { JobQueries } from "graphql/queries";

import { TableHead, Pagination, EmptyMessage } from "components";
import CandidateItem from "../../CandidateItem";

import { IJobCandidate, IPaginationMeta } from "types/interfaces";
import { JobCandidateStatus, JobCandidateType, Order } from "types/enums";

type Props = {
  id: number;
  requestData: {
    status: JobCandidateStatus;
    type?: JobCandidateType;
  };
  emptyTitle?: string;
};

const LIMIT = 10;

const ListCandidates: FC<Props> = ({
  id,
  emptyTitle = "No proposals yet",
  requestData,
}) => {
  const navigate = useNavigate();
  const [count, setCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [fetchProposals, { data, loading }] = useLazyQuery<{
    adminJobCandidates: {
      data: Array<IJobCandidate>;
      meta: IPaginationMeta;
    };
  }>(JobQueries.GET_JOB_CANDIDATES, {
    onCompleted(data) {
      setCount(data.adminJobCandidates.meta.itemCount);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    fetchProposals({
      variables: {
        input: {
          skip: (currentPage - 1) * LIMIT,
          take: LIMIT,
          jobId: id,
          ...requestData,
        },
      },
    });
  }, [currentPage, requestData]);

  const renderPhotographers = () => {
    if (
      !data?.adminJobCandidates.data ||
      data.adminJobCandidates.data.length === 0
    )
      return <EmptyMessage title={emptyTitle} />;

    return data?.adminJobCandidates.data.map((item) => (
      <div key={item.id} onClick={() => navigate(`proposal/${item.id}`)}>
        <CandidateItem candidate={item} />
      </div>
    ));
  };

  return (
    <div>
      <TableHead
        items={[
          { title: "Professional", disableSort: true },
          { title: "Location", disableSort: true },
          { title: "Rate", disableSort: true },
          { title: "Jobs", disableSort: true },
          { title: "Rating", disableSort: true },
          { title: "Time", disableSort: true },
          false,
        ]}
        flexes={[2, 2, 1, 1, 1, 1]}
        sorted={{ sortedBy: "", type: Order.DESC }}
        onChangeSort={() => {}}
      />
      {loading ? (
        <Skeleton
          height={50}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
          count={5}
        />
      ) : (
        renderPhotographers()
      )}

      {count > 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            count={count}
            currentPage={currentPage}
            limit={LIMIT}
            onChange={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default ListCandidates;
