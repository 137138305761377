import { FC, useMemo, useState } from "react";
import moment from "moment";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

interface IProps {
  onSelectDay: (date: string) => void;
  currentDate: string;
  setRangeDate: (datesFromTo: string[]) => void;
  rentedDates?: any[];
}

const DaysOfWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

const ScheduleCalendar: FC<IProps> = ({
  rentedDates,
  currentDate,
  onSelectDay,
  setRangeDate,
}) => {
  const [currentMonth, setCurrentMonth] = useState(
    moment().format("MMMM YYYY")
  );

  const daysOfMonth = useMemo(() => {
    const daysArray = [];

    const firstDayOfMonth = moment(currentMonth, "MMMM YYYY").startOf("month");
    const firstDayOfWeek = firstDayOfMonth.startOf("week");

    for (let i = 0; i < 35; i++) {
      const currentDay = firstDayOfWeek.clone().add(i, "days");
      daysArray.push(currentDay);
    }

    setRangeDate([
      daysArray[0].clone().add(1, "day").toISOString(),
      daysArray[34].clone().add(1, "day").toISOString(),
    ]);

    return daysArray;
  }, [currentMonth]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.wrapper_header}>
        <div
          className={styles.wrapper_header_arrow}
          onClick={() =>
            setCurrentMonth(
              moment(currentMonth, "MMMM YYYY")
                .subtract(1, "month")
                .format("MMMM YYYY")
            )
          }
        >
          <ICONS.CalendarArrowLeft />
        </div>
        <p className={styles.wrapper_header_title}>{currentMonth}</p>
        <div
          className={cls(
            styles.wrapper_header_arrow,
            styles.wrapper_header_arrow_rotate
          )}
          onClick={() =>
            setCurrentMonth(
              moment(currentMonth, "MMMM YYYY")
                .add(1, "month")
                .format("MMMM YYYY")
            )
          }
        >
          <ICONS.CalendarArrowLeft />
        </div>
      </header>

      <div className={styles.wrapper_weeks}>
        {DaysOfWeek.map((week, index) => (
          <p key={index} className={styles.wrapper_week}>
            {week}
          </p>
        ))}
      </div>

      <div className={styles.wrapper_month}>
        {daysOfMonth.map((day, index) => {
          const isRentedDay = rentedDates?.some(
            (date) =>
              moment(date.startsAt).isSame(day, "day") ||
              moment(date.endsAt).isSame(day, "day")
          );

          const isSelectedDay =
            moment(currentDate, "DD-MM-YYYY").format("DD-MM-YYYY") ===
            day.format("DD-MM-YYYY");

          const isPastMonth = day.isBefore(
            moment(currentMonth, "MMMM YYYY"),
            "month"
          );
          const isNextMonth = day.isAfter(
            moment(currentMonth, "MMMM YYYY"),
            "month"
          );
          const isDisableDay =
            moment().subtract(1, "day").isAfter(day) ||
            isPastMonth ||
            isNextMonth;

          return (
            <div
              key={index}
              className={cls(styles.wrapper_month_day, {
                [styles.wrapper_month_day_before]:
                  !isSelectedDay && isDisableDay,
                [styles.wrapper_month_day_rented]:
                  !isSelectedDay && isRentedDay,
                [styles.wrapper_month_day_active]: isSelectedDay,
              })}
              onClick={() => onSelectDay(day.format("DD-MM-YYYY"))}
            >
              {day.format("D")}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ScheduleCalendar;
