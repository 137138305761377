import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";
import { ICONS } from "assets";

type Props = {
  title?: string;
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  containerStyle?: React.CSSProperties;
};

const Checkbox: FC<Props> = ({ title, label, value,containerStyle, onChange }) => {
  return (
    <div style={containerStyle}>
      {title && <p className={styles.title}>{title}</p>}

      <div className={styles.wrapper} onClick={() => onChange(!value)}>
        <div
          className={cls(styles.wrapper_box, {
            [styles.wrapper_box_active]: value,
          })}
        >
          <ICONS.Check />
        </div>
        <p className={styles.wrapper_label}>{label}</p>
      </div>
    </div>
  );
};

export default Checkbox;
