import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

import { ICONS } from "assets";
import avatar from "../avatar.png";

import { useOutsideClick, useUser } from "hooks";
import { TokensService } from "utils";
import { Avatar } from "components";

const ProfileBlock: FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const divRef = useRef(null);
  useOutsideClick({ ref: divRef, onClickOutside: () => setIsOpenModal(false) });

  const logout = () => {
    TokensService.clearTokens();
    navigate("/login");
  };

  return (
    <div
      ref={divRef}
      className={styles.profile}
      onClick={() => setIsOpenModal(!isOpenModal)}
    >
      <div className={styles.profile_avatar}>
        <Avatar img={user?.avatar} />
      </div>
      <div className={styles.profile_info}>
        <p className={styles.profile_info_name}>
          {user?.firstName} {user?.lastName}
        </p>
      </div>
      <div className={styles.profile_arrows}>
        <ICONS.Arrows />
      </div>

      {isOpenModal && (
        <div className={styles.modal}>
          <div className={styles.modal_wrapper}>
            <div className={styles.modal_wrapper_item} onClick={logout}>
              <div className={styles.modal_wrapper_item_icon}>
                <ICONS.Logout />
              </div>
              <p>Logout</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileBlock;
