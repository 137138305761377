import { FC, useState } from "react";
import cls from "classnames";
import BeatLoader from "react-spinners/BeatLoader";
import styles from "./styles.module.scss";

type Props = {
  title: string;
  items: Array<{ label: string; value: any }>;
  value: Array<any>;
  loading?: boolean;
  onChange: (value: Array<any>) => void;
};

const CheckboxList: FC<Props> = ({
  title,
  items,
  value,
  loading = false,
  onChange,
}) => {
  const [isHide, setIsHide] = useState<boolean>(true);

  const toggleValue = (newValue: any) => {
    const findIndex = value.findIndex((v) => v === newValue);

    if (findIndex === -1) {
      onChange([...value, newValue]);
      return;
    }

    const updatedValue = [...value];
    updatedValue.splice(findIndex, 1);
    onChange(updatedValue);
  };

  const renderItems = () => {
    const filteredItems =
      items.length <= 6 || !isHide ? items : [...items].splice(0, 6);

    return filteredItems.map((item, index) => {
      const isSelected = value.some((v) => v === item.value);

      return (
        <div
          key={index}
          className={styles.wrapper_item}
          onClick={() => toggleValue(item.value)}
        >
          <div
            className={cls(styles.wrapper_item_box, {
              [styles.wrapper_item_box_active]: isSelected,
            })}
          />
          <p className={styles.wrapper_item_label}>{item.label}</p>
        </div>
      );
    });
  };

  return (
    <div>
      <p className={styles.wrapper_title}>{title}</p>
      {loading ? (
        <BeatLoader color="black" />
      ) : (
        <div>
          {renderItems()}
          {items.length > 6 && (
            <p
              className={styles.wrapper_more}
              onClick={() => setIsHide(!isHide)}
            >
              {isHide ? "Show more" : "Show less"}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckboxList;
