import React, { FC, useState } from "react";
import styles from "./styles.module.scss";
import Modal from "react-modal";
import { ICONS } from "assets";
import { Button, Filter, Input, SelectUsers } from "components";
import toast from "react-hot-toast";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import { CREATE_EQUIPMENT_RENTAL } from "graphql/mutations/equipments";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: 526,
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
    padding: 32,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedDay: string;
  equipmentId?: number;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

const ScheduleEquipmentModal: FC<IProps> = ({
  isOpen,
  selectedDay,
  onClose,
  equipmentId,
  refetch,
}) => {
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [isUserFromApp, setUserFromApp] = useState(true);
  const [newRentalName, setNewRentalName] = useState("");
  const [error, setError] = useState("");

  const [createRental] = useMutation(CREATE_EQUIPMENT_RENTAL);

  const createEquipmentRental = async () => {
    if (!isUserFromApp && !newRentalName) {
      return setError("Rental name is required");
    }

    if (isUserFromApp && !selectedUserId) {
      return toast.error("Rental is required");
    }

    const externalRenter = {
      newExternalRenter: {
        fullName: newRentalName.trimEnd(),
      },
    };

    const rentalFromApp = {
      renterId: selectedUserId,
    };

    await createRental({
      variables: {
        input: {
          endsAt: selectedDay,
          startsAt: selectedDay,
          equipmentId,
          renter: isUserFromApp ? rentalFromApp : externalRenter,
        },
      },
    });

    await refetch();
    toast.success("Schedule a Equipment created successfully");

    onSuccess();

    try {
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const onChangeName = (name: string) => {
    if (error) setError("");

    const hasConsecutiveSpaces = / {2,}/.test(name);

    if (hasConsecutiveSpaces) return;

    setNewRentalName(name.trimStart());
  };

  const onSuccess = () => {
    onClose();
    setError("");
    setSelectedUserId(null);
    setNewRentalName("");
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles as any}>
      <div className={styles.wrapper}>
        <div className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>Schedule a Equipment</h4>
          <div className={styles.wrapper_header_icon} onClick={onClose}>
            <ICONS.Cross />
          </div>
        </div>
        <div className={styles.wrapper_data}>
          <p className={styles.wrapper_data_data}>{selectedDay}</p>
          <p className={styles.wrapper_data_range}>Full Day</p>
        </div>

        <Filter.Checkbox
          label="Renter is not from the app"
          value={!isUserFromApp}
          onChange={() => setUserFromApp(!isUserFromApp)}
          containerStyle={{ marginBottom: 22, marginTop: 22 }}
        />

        {isUserFromApp ? (
          <SelectUsers
            label="Renter’s name"
            onChange={(val) => setSelectedUserId(val)}
            containerStyle={{ marginBottom: 22 }}
          />
        ) : (
          <Input
            label="Renter’s name"
            placeholder="Enter a name"
            value={newRentalName}
            onChange={onChangeName}
            containerStyle={{ marginBottom: 22 }}
            error={error}
          />
        )}

        <Button title="Schedule a Equipment" onClick={createEquipmentRental} />
      </div>
    </Modal>
  );
};

export default ScheduleEquipmentModal;
