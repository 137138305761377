import { gql } from "@apollo/client";

export const GET_COURSES = gql`
  query findCourseCardsForAdmin($input: FindCourseCardsForAdminInput!) {
    findCourseCardsForAdmin(input: $input) {
      courses {
        id
        ownerId
        owner {
          id
          firstName
          lastName
          avatar
          profile {
            id
            userId
            type
            account
            agencyName
          }
        }
        ownership
        title
        price
        currency
        createdAt
        numberOfLessons
        rating
        numberOfReviews
        numberOfPurchasers
        totalDurationInSeconds
      }
      count
    }
  }
`;

export const GET_COURSE_DETAIL = gql`
  query findCourseForAdmin($input: FindCourseForAdminInput!) {
    findCourseForAdmin(input: $input) {
      id
      ownerId
      title
      description
      price
      currency
      status
      numberOfLessons
      ownership
      totalLessonsDurationInSeconds
      sales
      lessons {
        id
        courseId
        title
        description
        durationInSeconds
        videoUrl
        videoFilename
        videoSize
        numbering
      }
    }
  }
`;

export const GET_PURCHASERS_COURSE = gql`
  query findCoursePurchasersForAdmin(
    $input: FindCoursePurchasersForAdminInput!
  ) {
    findCoursePurchasersForAdmin(input: $input) {
      count
      purchasers {
        id
        firstName
        lastName
        avatar
        locationShortTitle
        averageRating
        purchaseDate
        completionDate
        profile {
          type
          account
          agencyName
        }
      }
    }
  }
`;
