import { FC, useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import styles from "./styles.module.scss";
import toast from "react-hot-toast";

import { Button, Input, SearchLocation, Textarea } from "components";
import { ICONS } from "assets";

import { IEquipment, IEquipmentLocation } from "types/interfaces";
import { v4 as uuid } from "uuid";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GoogleService } from "utils";
import { AdminQueries } from "graphql/queries";
import { Loading } from "context";
import {
  CREATE_EQUIPMENT,
  UPDATE_EQUIPMENT,
} from "graphql/mutations/equipments";
import ImageMultipleDropZone from "components/ImageMultipleDrop";
import { DropResult } from "react-beautiful-dnd";

const customStyles = {
  content: {
    position: "relative",
    width: "660px",
    maxHeight: "90vh",
    padding: 30,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 2000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const validate = (values: any) => {
  const errors: any = {};

  if (values.title.length < 3) {
    errors.title = "Min length: 3";
  }

  if (values.title.trim() === "") {
    errors.title = "Title is required";
  }

  if (values.title.length > 300) {
    errors.title = "Max length: 300";
  }

  if (values.desc.length < 3) {
    errors.desc = "Min length: 3";
  }

  if (values.desc.length > 3000) {
    errors.desc = "Max length: 3000";
  }

  if (values.desc.trim() === "") {
    errors.desc = "Description is required";
  }

  if (!values?.location?.address) {
    errors.location = "Location is required";
  }

  if (!values?.images.length) {
    errors.images = "Images of equipment is required";
  }

  if (!values.priceByDay) {
    errors.priceByDay = "Price by day is required";
  }

  if (values.priceByDay > 100000) {
    errors.priceByDay = "Max price: 100000";
  }

  if (values.priceByDay && Number(values.priceByDay) === 0) {
    errors.priceByDay = "Price by day must be more than 0";
  }

  return errors;
};

const initialImages = {
  imagesUrls: [],
  fileImages: [],
};

interface IProps {
  equipment: IEquipment | null;
  isOpen: boolean;
  onClose: () => void;
  refetch: any;
}

const CreateUpdateEquipmentModal: FC<IProps> = ({
  equipment,
  onClose,
  refetch,
  isOpen,
}) => {
  const { setIsLoading } = useContext(Loading.Context)!;

  const [createEquipment] = useMutation(CREATE_EQUIPMENT);
  const [updateEquipment] = useMutation(UPDATE_EQUIPMENT);

  const [createUploadUrl] = useLazyQuery(AdminQueries.GET_UPLOAD_URLS);
  const [images, setImages] = useState<{
    imagesUrls: string[];
    fileImages: File[] | null[];
  }>(initialImages);

  const {
    values,
    errors,
    setFieldValue,
    setFieldError,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      title: equipment?.title || "",
      desc: equipment?.description || "",
      priceByDay: String(equipment?.rentPricePerDay || ""),
      location: equipment?.location,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: (values) => validate({ ...values, images: images.imagesUrls }),
    onSubmit: equipment ? handleUpdateEquipment : handleCreateEquipment,
  });

  useEffect(() => {
    if (equipment) {
      const existImageUrls = equipment.images.map((it) => it.url);

      const existImageFileAsPlug = equipment.images.map((it) => null);

      return setImages({
        imagesUrls: existImageUrls,
        fileImages: existImageFileAsPlug,
      });
    }

    setImages(initialImages);
  }, [isOpen, equipment]);

  async function handleCreateEquipment() {
    try {
      setIsLoading(true);

      const imageUrls = await saveEquipmentImages();

      console.log({ description: values.desc.trimEnd() });

      await createEquipment({
        variables: {
          input: {
            address: values.location?.address,
            description: values.desc.trimEnd(),
            imageUrls,
            latitude: values.location?.latitude,
            longitude: values.location?.longitude,
            rentPricePerDay: Number(values.priceByDay),
            title: values.title.trimEnd(),
          },
        },
      });

      await refetch();

      onCloseModal();

      toast.success("Equipment created successfully");
    } catch (error) {
      toast.error("Error created equipment");
    } finally {
      setIsLoading(false);
    }
  }

  async function handleUpdateEquipment() {
    try {
      setIsLoading(true);

      const newImageUrls = await saveEquipmentImages();

      const updateDataImages = getFormattedImagesData(newImageUrls);

      console.log({ description: values.desc.trimEnd() });

      await updateEquipment({
        variables: {
          input: {
            id: equipment?.id,
            images: updateDataImages,
            location: {
              address: values.location?.address,
              latitude: values.location?.latitude,
              longitude: values.location?.longitude,
            },
            rentPricePerDay: Number(values.priceByDay),
            title: values.title.trimEnd(),
            description: values.desc.trimEnd(),
          },
        },
      });

      await refetch();

      onCloseModal();

      toast.success("Equipment updated successfully");
    } catch (error) {
      toast.error("Error updated equipment");
      console.error("Error updated equipment", error);
    } finally {
      setIsLoading(false);
    }
  }

  const getFormattedImagesData = (newUrls?: string[]) => {
    if (!equipment) return;

    const foundExistImageIds = images.imagesUrls
      .map((imageUrl) => {
        const matchedImage = equipment.images.find(
          (image) => image.url === imageUrl
        );
        return matchedImage ? { id: matchedImage.id } : null;
      })
      .filter((imageId) => imageId !== null);

    if (!newUrls) return foundExistImageIds;

    const newImageUrlsData = newUrls.map((url) => {
      return {
        new: {
          url,
        },
      };
    });

    return [...foundExistImageIds, ...newImageUrlsData];
  };

  const saveEquipmentImages = async () => {
    try {
      if (!images.fileImages || !images.fileImages.length) return;

      //@ts-ignore
      const newImagesWithoutPlugs = images.fileImages.filter(
        (it: any) => it !== null
      );

      const urls: string[] = [];

      for (const image of newImagesWithoutPlugs as File[]) {
        const uniqueId = uuid();

        const {
          data: { generateUploadUrls },
        } = await createUploadUrl({
          variables: { filename: `${uniqueId}${image.name}` },
        });

        await GoogleService.uploadFileToStorage(
          image,
          generateUploadUrls.uploadUrl
        );

        urls.push(generateUploadUrls.fileUrl);
      }

      return urls;
    } catch (error) {
      toast.error("Error save images");
      console.error("Error save images");
    }
  };

  const onSelectLocation = (location: IEquipmentLocation) => {
    setFieldValue("location", location);
  };

  const onChangePriceByDay = (price: string) => {
    const formattedPrice = price.replace(/[^0-9.]/g, "");

    if (formattedPrice[0] === "0" && formattedPrice[1] === "0") {
      return setFieldValue("priceByDay", formattedPrice.slice(1) + ".");
    }

    if (formattedPrice.startsWith(".")) return;

    setFieldValue("priceByDay", formattedPrice);
  };

  const onChangeLessonName = (value: string) => {
    const hasConsecutiveSpaces = / {2,}/.test(value);

    if (hasConsecutiveSpaces) return;

    setFieldValue("title", value.trimStart());
  };

  const handleDropImages = (imagesDrop: Blob[], amountImages: number) => {
    const maxSize = 15000 * 1024;

    let totalImages = amountImages + imagesDrop.length;

    if (totalImages >= 11) return toast.error("Maximum 10 images");

    imagesDrop.forEach((image) => {
      if (image.size > maxSize) {
        return toast.error(
          "The maximum allowed weight of the equipment image is 15MB"
        );
      }

      const reader = new FileReader();

      reader.readAsDataURL(image);

      reader.onload = () => {
        const dataURL = reader.result as string;

        setImages((prev) => ({
          ...prev,
          imagesUrls: [...prev.imagesUrls, dataURL],
        }));
      };
    });

    setImages((prev) => ({
      ...prev,
      fileImages: [...prev.fileImages, ...imagesDrop] as File[],
    }));

    setFieldError("images", "");
  };

  const onCloseModal = () => {
    onClose();
    resetForm();
    setImages(initialImages);
  };

  const onRemoveImage = (removeIndex: number) => {
    setImages((prev) => ({
      imagesUrls: prev.imagesUrls.filter(
        (_: any, index: number) => index !== removeIndex
      ),
      //@ts-ignore
      fileImages: prev.fileImages.filter(
        (_: any, index: number) => index !== removeIndex
      ) as File[],
    }));
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const { imagesUrls, fileImages } = images;

    const copyImageUrls = [...imagesUrls];
    const copyImageFiles = [...fileImages];

    const sourceIndex =
      result.source.droppableId === "chunkImages1"
        ? result.source.index + 5
        : result.source.index;

    const destinationIndex =
      result.destination.droppableId === "chunkImages1"
        ? result.destination.index + 5
        : result.destination.index;

    const [reorderedUrl] = copyImageUrls.splice(sourceIndex, 1);
    const [reorderedImage] = copyImageFiles.splice(sourceIndex, 1);

    copyImageUrls.splice(destinationIndex, 0, reorderedUrl);
    copyImageFiles.splice(destinationIndex, 0, reorderedImage);

    setImages({
      imagesUrls: copyImageUrls,
      fileImages: copyImageFiles as File[],
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles as any}
      ariaHideApp={false}
      onRequestClose={onCloseModal}
    >
      <div style={{ overflow: "hidden" }} className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>
            {equipment ? "Edit Equipment" : "New Equipment"}
          </h4>
          <div className={styles.wrapper_header_icon} onClick={onCloseModal}>
            <ICONS.Cross />
          </div>
        </header>

        <div className={styles.wrapper_input}>
          <Input
            label="Title"
            placeholder="Enter title"
            value={values.title}
            error={errors.title}
            maxLength={300}
            onChange={onChangeLessonName}
          />
        </div>

        <div className={styles.wrapper_input}>
          <Textarea
            label="Description"
            maxLength={3000}
            value={values.desc}
            error={errors.desc}
            onChange={(val) => setFieldValue("desc", val)}
          />
        </div>

        <SearchLocation
          value={values.location?.address || ""}
          onSelect={onSelectLocation}
          error={errors.location}
        />

        <div className={styles.wrapper_input}>
          <Input
            label="Price by Day (KWD)"
            placeholder="Enter price"
            value={values.priceByDay}
            error={errors.priceByDay}
            onChange={onChangePriceByDay}
          />
        </div>

        <ImageMultipleDropZone
          images={images.imagesUrls}
          handleSave={handleDropImages}
          handleDragEnd={handleDragEnd}
          onRemoveImage={onRemoveImage}
          //@ts-ignore
          error={errors.images}
        />

        <div className={styles.wrapper_button}>
          <div className={styles.wrapper_button_item}>
            <Button
              title={equipment ? "Edit" : "Create"}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateUpdateEquipmentModal;
