import { FC } from "react";
import moment from "moment";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar } from "components";

import { IReport } from "types/interfaces";
import { ProfileType, ReportStatus } from "types/enums";

type Props = {
  report: IReport;
};

const Item: FC<Props> = ({ report: item }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_info}>
        <div className={styles.wrapper_info_avatar}>
          <Avatar img={item.reporter.avatar} />
        </div>
        <div>
          <p className={styles.wrapper_info_name}>
            {item.reporter.firstName} {item.reporter.lastName}
          </p>
          <p className={styles.wrapper_info_type}>
            {item.reporter.profile?.type === ProfileType.Client
              ? "Client"
              : "Professional"}
          </p>
        </div>
      </div>

      <div className={styles.wrapper_category}>{item.subject}</div>

      <div className={styles.wrapper_status}>
        <div
          className={cls(styles.wrapper_status_wrapper, {
            [styles.wrapper_status_wrapper_active]:
              item.status === ReportStatus.Active,
            [styles.wrapper_status_wrapper_resolved]:
              item.status === ReportStatus.Resolved,
          })}
        >
          {item.status}
        </div>
      </div>

      <div className={styles.wrapper_info}>
        <div className={styles.wrapper_info_avatar}>
          <Avatar img={item?.reported?.avatar} />
        </div>
        <div>
          <p className={styles.wrapper_info_name}>
            {item.reported?.firstName} {item.reported?.lastName}
          </p>
          <p className={styles.wrapper_info_type}>
            {item.reported?.profile?.type === ProfileType.Client
              ? "Client"
              : "Professional"}
          </p>
        </div>
      </div>

      <div className={styles.wrapper_category}>
        {moment(item.createdAt).format("MMM DD, YYYY")}
      </div>

      <div className={styles.wrapper_btn}>
        <div className={styles.wrapper_btn_wrapper}>
          <ICONS.RightArrow />
        </div>
      </div>
    </div>
  );
};

export default Item;
