import React, { FC, useRef, useState } from "react";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { useOutsideClick } from "hooks";

type Props = {
  items: Array<{
    label: string;
    icon: React.ReactNode;
    onClick: () => void;
  }>;
};

const DotSelector: FC<Props> = ({ items }) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const divRef = useRef(null);
  useOutsideClick({ ref: divRef, onClickOutside: () => setIsOpenModal(false) });

  return (
    <div
      ref={divRef}
      className={styles.wrapper}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpenModal(!isOpenModal);
      }}
    >
      <div className={styles.wrapper_arrows}>
        <ICONS.Dots />
      </div>

      {isOpenModal && (
        <div className={styles.modal}>
          <div className={styles.modal_wrapper}>
            {items.map((item, index) => (
              <div
                key={index}
                className={styles.modal_wrapper_item}
                onClick={item.onClick}
              >
                <div className={styles.modal_wrapper_item_icon}>
                  {item.icon}
                </div>
                <p>{item.label}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DotSelector;
