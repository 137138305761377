import Statistics from "./Statistics";
import Clients from "./Clients";
import Main from "./Main";
import Reports from "./Reports";
import Professionals from "./Professionals";
import StaffManagment from "./StaffManagment";
import Categories from "./Categories";
import Banners from "./Banners";
import Equipments from "./Equipments";
import EquipmentDetail from "./Equipments/EquipmentDetail";
import BannerDetail from "./Banners/BannerDetail";
import Studios from "./Studios";
import StudioDetail from "./Studios/StudioDetail";
import Courses from "./Courses";
import CoursesDetailPage from "./Courses/CourseDetail";
import Verification from "./Verification";

export default {
  Statistics,
  Clients,
  Main,
  Reports,
  Professionals,
  StaffManagment,
  Categories,
  Banners,
  BannerDetail,
  Equipments,
  EquipmentDetail,
  Studios,
  StudioDetail,
  Courses,
  CoursesDetailPage,
  Verification,
};
