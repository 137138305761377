import { FC, useState } from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

import { DotSelector } from "components";

import { ICategory } from "types/interfaces";
import { ICONS, IMAGES } from "assets";

type Props = {
  category: ICategory;
  onDelete: () => void;
  onEdit: () => void;
};

const CategoryItem: FC<Props> = ({ category, onDelete, onEdit }) => {
  const [isLoadedImage, setIsLoadedImage] = useState<boolean>(false);
  const [isErrorImage, setIsErrorImage] = useState<boolean>(false);

  return (
    <div className={styles.item}>
      <div className={styles.item_info}>
        <div className={styles.item_info_image}>
          <img
            src={
              !category.cover || isErrorImage ? IMAGES.avatar : category.cover
            }
            onLoad={() => setIsLoadedImage(true)}
            onError={() => setIsErrorImage(true)}
          />
          {!isLoadedImage && (
            <div className={styles.item_skeletone}>
              <Skeleton
                containerClassName="flex-1"
                height="100%"
                style={{ zIndex: 0 }}
              />
            </div>
          )}
        </div>

        <p className={styles.item_info_title}>{category.title_en}</p>
      </div>

      <div className={styles.item_date}>Nov 23, 2022</div>

      <div>
        <div>
          <DotSelector
            items={[
              {
                label: "Edit",
                icon: <ICONS.Edit />,
                onClick: () => onEdit(),
              },
              {
                label: "Delete",
                icon: <ICONS.Delete />,
                onClick: () => onDelete(),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryItem;
