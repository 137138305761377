import React, { FC, useState } from "react";
import { Pagination, TableHead } from "components";
import { useQuery } from "@apollo/client";
import { CoursesQueries } from "graphql/queries";
import { IPurchaser, Order } from "types";
import Skeleton from "react-loading-skeleton";
import PurchaserCard from "../PurchaserCard";

enum OrderBy {
  PurchaserFullName = "PurchaserFullName",
  CompletionDate = "CompletionDate",
  PurchaseDate = "PurchaseDate",
  Rating = "Rating",
}

interface IProps {
  courseId?: number;
}

const BoughtThisCourseTab: FC<IProps> = ({ courseId }) => {
  const [purchasers, setPurchasers] = useState<IPurchaser[]>([]);
  const [count, setCount] = useState(0);

  const [sort, setSort] = useState<any>({
    sortedBy: OrderBy.CompletionDate,
    type: Order.DESC,
    currentPage: 1,
  });

  const { loading } = useQuery<{
    findCoursePurchasersForAdmin: {
      count: number;
      purchasers: IPurchaser[];
    };
  }>(CoursesQueries.GET_PURCHASERS_COURSE, {
    variables: {
      input: {
        courseId,
        skip: (sort.currentPage - 1) * 10,
        order: sort.type,
        orderBy: sort.sortedBy,
      },
    },
    onCompleted(data) {
      setPurchasers(data?.findCoursePurchasersForAdmin.purchasers);
      setCount(data.findCoursePurchasersForAdmin.count);
    },
  });

  return (
    <>
      <TableHead
        items={[
          {
            title: "Professional name",
            value: OrderBy.PurchaserFullName,
          },
          {
            title: "Location",
            disableSort: true,
          },
          {
            title: "Course Rating",
            value: OrderBy.Rating,
          },
          {
            title: "Completion date",
            value: OrderBy.CompletionDate,
          },
        ]}
        flexes={[1, 0.7, 0.35, 0.5]}
        sorted={sort}
        onChangeSort={(val) =>
          setSort({ ...sort, ...val, currentPage: sort.currentPage })
        }
      />
      {loading ? (
        <Skeleton
          height={64}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
          count={6}
        />
      ) : (
        <>
          {purchasers.map((it) => (
            <PurchaserCard purchaser={it} />
          ))}
        </>
      )}

      {count > 0 && (
        <div style={{ margin: "10px 0" }}>
          <Pagination
            limit={10}
            count={count}
            currentPage={sort.currentPage}
            onChange={(page) => setSort({ ...sort, currentPage: page })}
          />
        </div>
      )}
    </>
  );
};

export default BoughtThisCourseTab;
