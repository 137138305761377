import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar } from "components";

import { UserService } from "utils";

import { IUser } from "types/interfaces";

type Props = {
  user: IUser;
};

const ProfessionalItem: FC<Props> = ({ user }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_info}>
        <div className={styles.wrapper_info_avatar}>
          <Avatar img={user.avatar} />
        </div>
        <p className={styles.wrapper_info_name}>
          {user.firstName} {user.lastName}
        </p>
      </div>

      <div className={styles.wrapper_location}>
        {user.profile?.location?.short_title}
      </div>
      <div className={styles.wrapper_rate}>{UserService.getUserRate(user)}</div>
      <div className={styles.wrapper_jobs}>{user.profile?.completed}</div>
      <div className={styles.wrapper_rating}>
        <div className={styles.wrapper_rating_icon}>
          <ICONS.Star />
        </div>
        <p className={styles.wrapper_rating_text}>
          {
            // @ts-ignore
            UserService.addZeroToRate(user.profile?.rating) || "n/a"
          }
        </p>
      </div>
      <div className={styles.wrapper_date}>
        {moment(user.createdAt).format("MMM DD, YYYY")}
      </div>

      <div className={styles.wrapper_btn}>
        <div className={styles.wrapper_btn_wrapper}>
          <ICONS.RightArrow />
        </div>
      </div>
    </div>
  );
};

export default ProfessionalItem;
