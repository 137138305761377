import { useMemo, useState, useRef, FC } from "react";
import styles from "./styles.module.scss";
import { ICONS } from "assets";
import { useOutsideClick } from "hooks";
import cls from "classnames";
import Input from "components/Input";
import Avatar from "components/Avatar";
import { useQuery } from "@apollo/client";
import { IUser, UserAccount } from "types";
import Skeleton from "react-loading-skeleton";
import { GET_PROFESSIONALS } from "graphql/queries/user";

interface IOption {
  value: number;
  label: {
    avatarUrl?: string | null;
    userName?: string;
    id: number;
  };
}

interface IProps {
  label?: string;
  placeholder?: string;
  onChange: (val: number) => void;
  containerStyle?: React.CSSProperties;
}

const SelectPhotographers: FC<IProps> = ({
  label = "Link",
  placeholder = "Select photographer",
  containerStyle,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const [selectedPhotographer, setSelectedPhotographer] =
    useState<IOption | null>(null);

  const { data, loading, fetchMore } = useQuery<{
    adminProfessionals: { data: IUser[] };
  }>(GET_PROFESSIONALS, {
    variables: {
      input: {
        search,
        skip: 0,
        take: 20,
        orderBy: "CreatedAt",
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const options = useMemo(() => {
    if (!data?.adminProfessionals) return [];

    return data.adminProfessionals.data.map((user) => {
      const userName =
        user.profile?.account === UserAccount.Agency
          ? user.profile.agencyName
          : user.firstName + " " + user.lastName;

      return {
        value: user.id,
        label: {
          avatarUrl: user.avatar,
          userName,
          id: user.id,
        },
      };
    });
  }, [data]);

  useOutsideClick({
    ref: optionsContainerRef,
    onClickOutside: () => setIsOpen(false),
  });

  const handleSelectOption = (option: IOption) => {
    setIsOpen(false);
    setSelectedPhotographer(option);
    onChange(option.value);
  };

  const foundEquipments = useMemo(() => {
    if (!search.trim()) return options;

    if (search) {
      return options.filter(
        (option) =>
          option.label.userName
            ?.toLocaleLowerCase()
            .includes(search.toLowerCase()) ||
          String(option.label.id).includes(search)
      );
    }
    return options;
  }, [search, options]);

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
      event.currentTarget.clientHeight;

    if (bottom) {
      fetchMore({
        variables: {
          input: {
            search,
            take: 20,
            skip: data?.adminProfessionals.data.length,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;
          return {
            adminProfessionals: {
              data: [
                ...previousResult.adminProfessionals.data,
                ...fetchMoreResult.adminProfessionals.data,
              ],
            },
          };
        },
      });
    }
  };

  return (
    <>
      {label ? <p className={styles.label}>{label}</p> : null}

      <div
        ref={optionsContainerRef}
        className={styles.wrapper}
        style={containerStyle}
      >
        <div
          className={cls(
            styles.wrapper_block,
            isOpen && styles.wrapper_block_active
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div style={{ display: "flex", alignItems: "center", columnGap: 7 }}>
            {!selectedPhotographer ? null : (
              <Avatar
                img={selectedPhotographer?.label.avatarUrl}
                dimensions={{ width: 24, height: 24 }}
              />
            )}

            {selectedPhotographer ? (
              <div>
                <p className={styles.wrapper_block_title}>
                  {selectedPhotographer.label.userName}
                </p>
                <span className={styles.wrapper_block_id}>
                  {`id${selectedPhotographer.label.id}`}
                </span>
              </div>
            ) : (
              <p
                className={`${styles.wrapper_block_title} ${styles.wrapper_block_placeholder}`}
              >
                {placeholder}
              </p>
            )}
          </div>
          <div className={`${styles.wrapper_block_icon}`}>
            <ICONS.SelectArrow />
          </div>
        </div>

        {isOpen && (
          <div className={styles.wrapper_options} onScroll={handleScroll}>
            <div style={{ marginBottom: 8 }}>
              <Input
                value={search}
                placeholder="Search"
                onChange={(val) => setSearch(val)}
                inputStyle={{
                  height: 44,
                  border: "none",
                  background: "#E4E4E440",
                  paddingLeft: 52,
                }}
                icon={<ICONS.Search />}
              />
            </div>
            {loading ? (
              <Skeleton
                height={42}
                style={{
                  marginBottom: 2,
                  marginTop: 2,
                  borderRadius: 10,
                  marginLeft: 10,
                  marginRight: 10,
                }}
                count={3}
              />
            ) : foundEquipments && foundEquipments.length ? (
              foundEquipments.map((option) => (
                <div
                  key={option.value}
                  className={`${styles.wrapper_options_item} ${
                    option.value === selectedPhotographer?.value
                      ? styles.wrapper_options_item_selected
                      : ""
                  }`}
                  onClick={() => handleSelectOption(option)}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 10,
                    }}
                  >
                    <Avatar
                      img={option.label.avatarUrl}
                      dimensions={{ width: 24, height: 24 }}
                    />
                    <p className={styles.wrapper_block_title}>
                      {option.label.userName}
                    </p>
                  </div>
                  <div className={styles.wrapper_block_id}>
                    {option.label.id}
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.no_found}>No photographers found</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SelectPhotographers;
