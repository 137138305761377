import { FC } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar } from "components";

import { IDisput } from "types/interfaces";
import { ProfileType } from "types/enums";
import moment from "moment";

type Props = {
  payload: false | IDisput;
  onClose: () => void;
  onOpenDisput: () => void;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "50%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const ModalInfo: FC<Props> = ({ payload, onClose, onOpenDisput }) => {
  if (!payload) return null;

  const reporter =
    payload.creatorId === payload.contract.client.id
      ? payload.contract.client
      : payload.contract.photographer;
  const reported =
    payload.subjectId === payload.contract.client.id
      ? payload.contract.client
      : payload.contract.photographer;

  return (
    <Modal
      isOpen={!!payload}
      //@ts-ignore
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>Report</h4>
          <div className={styles.wrapper_header_icon} onClick={onClose}>
            <ICONS.Cross />
          </div>
        </header>

        <section className={styles.wrapper_info}>
          <h3 className={styles.wrapper_info_title}>Dispute</h3>

          <div className={styles.wrapper_info_wrapper}>
            <div className={styles.wrapper_info_wrapper_block}>
              <p className={styles.wrapper_info_wrapper_block_title}>
                Reporter
              </p>
              <div className={styles.wrapper_info_wrapper_block_wrapper}>
                <div
                  className={styles.wrapper_info_wrapper_block_wrapper_avatar}
                >
                  <Avatar img={reporter.avatar} />
                </div>
                <div>
                  <p className={styles.wrapper_info_wrapper_block_wrapper_name}>
                    {reporter.firstName} {reporter.lastName}
                  </p>
                  <p className={styles.wrapper_info_wrapper_block_wrapper_type}>
                    {reporter.profile?.type === ProfileType.Photographer
                      ? "Professional"
                      : "Client"}
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.wrapper_info_wrapper_block}>
              <p className={styles.wrapper_info_wrapper_block_title}>
                Reporter On
              </p>
              <div className={styles.wrapper_info_wrapper_block_wrapper}>
                <div
                  className={styles.wrapper_info_wrapper_block_wrapper_avatar}
                >
                  <Avatar img={reported.avatar} />
                </div>
                <div>
                  <p className={styles.wrapper_info_wrapper_block_wrapper_name}>
                    {reported.firstName} {reported.lastName}
                  </p>
                  <p className={styles.wrapper_info_wrapper_block_wrapper_type}>
                    {reported.profile?.type === ProfileType.Photographer
                      ? "Professional"
                      : "Client"}
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.wrapper_info_date}>
              {moment(payload.createdAt).format("MMM DD, YYYY")}
            </div>
          </div>
        </section>

        <p className={styles.wrapper_description_title}>Description</p>
        <p className={styles.wrapper_description_desc}>{payload.problem}</p>

        {/* <p className={styles.wrapper_description_title}>Attchments</p>

        <img
          src="https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg"
          className={styles.wrapper_attachment}
        /> */}

        <div className={styles.wrapper_button} onClick={onOpenDisput}>
          Open Dispute
        </div>
      </div>
    </Modal>
  );
};

export default ModalInfo;
