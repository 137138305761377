import { FC, useRef, useState, useEffect } from "react";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Filter as FilterComponents } from "components";

import { useOutsideClick } from "hooks";
import { DisputFilterStatus, ReportStatus } from "types/enums";

type Props = {
  dateRange: null | [Date | null, Date | null];
  disputStatus?: DisputFilterStatus | null;
  reportStatus?: ReportStatus | null;
  onChange: (filter: {
    range: null | [Date | null, Date | null];
    disputStatus?: DisputFilterStatus | null;
    reportStatus?: ReportStatus | null;
  }) => void;
};

const Filter: FC<Props> = ({
  dateRange,
  disputStatus: initialDisputStatus,
  reportStatus: initialReportStatus,
  onChange,
}) => {
  const [range, setRange] = useState<null | [Date | null, Date | null]>(
    dateRange
  );
  const [disputStatus, setDisputStatus] = useState<
    undefined | null | DisputFilterStatus
  >(initialDisputStatus);
  const [reportStatus, setReportStatus] = useState<
    undefined | null | ReportStatus
  >(initialReportStatus);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const divRef = useRef(null);

  useEffect(() => {
    if (!isOpenFilter) {
      setRange(dateRange);
      setDisputStatus(initialDisputStatus);
      setReportStatus(initialReportStatus);
    }
  }, [isOpenFilter, dateRange, initialDisputStatus, initialReportStatus]);

  useOutsideClick({
    ref: divRef,
    onClickOutside: () => setIsOpenFilter(false),
  });

  const handleApplyFilter = () => {
    onChange({ range, disputStatus, reportStatus });
    setIsOpenFilter(false);
  };

  const handleClearFilter = () => {
    const filter: any = { range: null };

    if (disputStatus !== undefined) {
      filter.disputStatus = null;
    }

    if (reportStatus !== undefined) {
      filter.reportStatus = null;
    }

    onChange(filter);
    setIsOpenFilter(false);
  };

  return (
    <div ref={divRef} className={styles.wrapper}>
      <div
        className={styles.wrapper_icon}
        onClick={() => setIsOpenFilter(!isOpenFilter)}
      >
        <ICONS.Filter />
      </div>

      {isOpenFilter && (
        <div className={styles.wrapper_filter}>
          <FilterComponents.Wrapper
            title="Filter by Date"
            contentClass={styles.content}
            onApply={handleApplyFilter}
            onClear={handleClearFilter}
          >
            <div className={styles.wrapper_filter_item}>
              <FilterComponents.DateRange value={range} onChange={setRange} />
            </div>
            {disputStatus !== undefined && (
              <div className={styles.wrapper_filter_item}>
                <FilterComponents.List
                  value={disputStatus}
                  items={[
                    { label: "Active", value: DisputFilterStatus.Active },
                    { label: "Waiting", value: DisputFilterStatus.Waiting },
                    { label: "Resolved", value: DisputFilterStatus.Resolved },
                  ]}
                  title="Status"
                  // @ts-ignore
                  onChange={setDisputStatus}
                />
              </div>
            )}
            {reportStatus !== undefined && (
              <div className={styles.wrapper_filter_item}>
                <FilterComponents.List
                  value={reportStatus}
                  items={[
                    // @ts-ignore
                    { label: "Any", value: null },
                    { label: "Active", value: ReportStatus.Active },
                    { label: "Resolved", value: ReportStatus.Resolved },
                  ]}
                  title="Status"
                  // @ts-ignore
                  onChange={setReportStatus}
                />
              </div>
            )}
          </FilterComponents.Wrapper>
        </div>
      )}
    </div>
  );
};

export default Filter;
