import { useParams, useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import toast from "react-hot-toast";
import styles from "./styles.module.scss";
import { FC, useEffect, useState } from "react";
import { Status, IEquipment, VariantButton } from "types";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { BackHeader, Button, ConfirmModal, Switcher, Tabs } from "components";
import CreateUpdateEquipmentModal from "../CreateUpdateEquipmentModal";
import {
  ACTIVATE_EQUIPMENT,
  DEACTIVATE_EQUIPMENT,
  DELETE_EQUIPMENT,
} from "graphql/mutations/equipments";
import {
  GET_COUNT_RENTAL_EQUIPMENT,
  GET_EQUIPMENT_DETAIL,
} from "graphql/queries/equipments";
import { useTab } from "hooks";
import EquipmentCalendar from "./EquipmentCalendar";
import EquipmentPhotos from "./EquipmentPhotos";

const EquipmentDetail: FC = () => {
  const navigate = useNavigate();
  const { id: equipmentId } = useParams();

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenEditModal, setOpenEditModal] = useState(false);
  const [equipment, setEquipment] = useState<IEquipment | null>(null);
  const { currentTab: tabIndex, onChange: setTabIndex } = useTab();

  const [deactivateEquipment] = useMutation(DEACTIVATE_EQUIPMENT);
  const [activateEquipment] = useMutation(ACTIVATE_EQUIPMENT);
  const [deleteEquipmentReq] = useMutation(DELETE_EQUIPMENT);

  const { data } = useQuery<{ countEquipmentRentals: number }>(
    GET_COUNT_RENTAL_EQUIPMENT,
    {
      variables: {
        input: {
          equipmentId: Number(equipmentId),
        },
      },
    }
  );

  const [getEquipmentDetail, { loading, refetch }] = useLazyQuery<{
    findEquipmentById: IEquipment;
  }>(GET_EQUIPMENT_DETAIL, {
    variables: {
      input: {
        id: Number(equipmentId),
      },
    },
    onCompleted(data) {
      setEquipment(data.findEquipmentById);
    },
  });

  const isActive = equipment?.status === Status.Active;

  const handleActivateEquipment = async () => {
    if (!equipment) return;

    try {
      await activateEquipment({
        variables: {
          input: {
            id: equipment?.id,
          },
        },
      });

      await refetch();

      toast.success("Equipment was activated");
    } catch (error) {
      toast.error(
        "The maximum number of equipments is activated (5). Deactivate one of them or delete it to activate new ones."
      );

      console.log("e", error);
    }
  };

  useEffect(() => {
    getEquipmentDetail();
  }, []);

  const handleDeactivateEquipment = async () => {
    try {
      if (!equipment) return;

      await deactivateEquipment({
        variables: {
          input: {
            id: equipment?.id,
          },
        },
      });

      await refetch();

      toast.success("Equipment was deactivated");
    } catch (error) {}
  };

  const handleChangeStatus = () => {
    if (!equipment) return;

    equipment?.status === Status.Active
      ? handleDeactivateEquipment()
      : handleActivateEquipment();
  };

  const handleDeleteEquipment = async () => {
    if (!equipment) return;

    try {
      await deleteEquipmentReq({
        variables: {
          input: {
            id: equipment?.id,
          },
        },
      });

      toast.success("Equipment successfully deleted");

      setOpenDeleteModal(false);

      navigate("/equipments");
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <div>
      {loading ? (
        <PulseLoader
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 300,
          }}
        />
      ) : (
        <>
          <BackHeader
            title="Equipment"
            actions={[
              <Button
                onClick={() => setOpenEditModal(true)}
                title="Edit"
                variant={VariantButton.Gray}
              />,
              <Button
                onClick={() => setOpenDeleteModal(true)}
                title="Delete"
                variant={VariantButton.Red}
              />,
              <div className={styles.equipment_header_actions}>
                <div>
                  <p className={styles["equipment_header_title"]}>
                    Equipment is {isActive ? "Active" : "Deactivated"}
                  </p>
                  <p className={styles["equipment_header_subtitle"]}>
                    Switch to {isActive ? "disable" : "enable"}
                  </p>
                </div>
                <div className={styles["equipment_header_switcher"]}>
                  <Switcher
                    isActive={isActive}
                    handleClick={handleChangeStatus}
                  />
                </div>
              </div>,
            ]}
          />

          <div className="shadow_box">
            <div className={styles.equipment_row}>
              <img
                src={equipment?.images[0].url}
                className={styles.equipment_image}
                alt="equipment"
              />

              <div style={{ marginRight: "auto", maxWidth: 597 }}>
                <p className={styles.equipment_title}>{equipment?.title} </p>
                <p className={styles.equipment_subtitle}>
                  {equipment?.location.address}
                </p>
                <span
                  className={styles.equipment_id}
                  onClick={() => {
                    navigator.clipboard.writeText(String(equipmentId));
                    toast.success(`Copied id${equipmentId}`);
                  }}
                >
                  {`id${equipmentId}`}
                </span>
              </div>

              <div>
                <span className={styles.equipment_price}>
                  {`${equipment?.rentPricePerDay} ${equipment?.rentCurrency}`}
                </span>
                <span className={styles.equipment_day}>/day</span>

                <p className={styles.equipment_price_label}>Price</p>
              </div>

              <div className={styles.equipment_line} />

              <div>
                <p className={styles.equipment_price}>
                  {data?.countEquipmentRentals}
                </p>
                <p className={styles.equipment_price_label}>Rents</p>
              </div>
            </div>

            <p style={{ marginBottom: 25 }}>{equipment?.description}</p>

            <Tabs
              tabs={[{ title: "Schedule" }, { title: "Photos" }]}
              currentIndex={tabIndex}
              onChange={setTabIndex}
              containerStyle={{ marginBottom: 25 }}
            />

            {tabIndex === 0 ? (
              <EquipmentCalendar equipmentId={Number(equipmentId)} />
            ) : (
              <EquipmentPhotos images={equipment?.images} />
            )}
          </div>
        </>
      )}

      <CreateUpdateEquipmentModal
        isOpen={isOpenEditModal}
        onClose={() => setOpenEditModal(false)}
        equipment={equipment}
        refetch={refetch}
      />

      <ConfirmModal
        isOpen={isOpenDeleteModal}
        hideCrossIcon
        title={`Are you sure you want to delete this equipment?`}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={handleDeleteEquipment}
      />
    </div>
  );
};

export default EquipmentDetail;
