import { FC, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

import { PortfolioQueries } from "graphql/queries";

import { ICONS } from "assets";

import { Button, Gallery, Select } from "components";

import { IPortfolio } from "types/interfaces";
import { VariantButton } from "types/enums";

type Props = {
  userId: number;
  categories: Array<{ label: string; value: any; count: number }>;
};

const LIMIT = 10;

const Wrapper: FC<Props> = ({ userId, categories }) => {
  const [isOpenGallery, setIsOpenGallery] = useState<number | false>(false);
  const [categoryId, setCategoryId] = useState<number | "all">("all");

  const { data, networkStatus, refetch, fetchMore } = useQuery<{
    adminPortfolios: Array<IPortfolio>;
  }>(PortfolioQueries.GET_PORTFOLIO, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch({
      input: {
        userId,
        categoryId: categoryId === "all" ? null : categoryId,
        take: LIMIT,
        skip: 0,
      },
    });
  }, [categoryId]);

  const handleFetchMore = () => {
    if (networkStatus !== 7 || !canShowMore) return;

    fetchMore({
      variables: {
        input: {
          take: LIMIT,
          skip: data?.adminPortfolios.length,
          userId,
          categoryId: categoryId === "all" ? null : categoryId,
        },
      },
    });
  };

  const canShowMore = useMemo(() => {
    const findCategory = categories.find((c) => c.value === categoryId);
    if (!findCategory || !data?.adminPortfolios) return false;

    return findCategory.count > data.adminPortfolios.length;
  }, [categories, categoryId, data]);

  return (
    <div>
      <div className={styles.select}>
        <Select
          options={categories}
          value={categoryId}
          placeholder="Category"
          onChange={(option) => setCategoryId(option.value)}
        />
      </div>

      {isOpenGallery === false &&
      (networkStatus === 1 || networkStatus === 4 || networkStatus === 2) ? (
        <Skeleton
          count={10}
          style={{
            flexBasis: "calc(20% - 10px)",
            height: "280px",
            marginRight: "10px",
            marginBottom: "10px",
          }}
          containerClassName={styles.skeleton_wrapper}
        />
      ) : (
        <>
          <div className={styles.wrapper}>
            {data?.adminPortfolios.map((portfolio, index) => (
              <div
                key={portfolio.id}
                className={styles.wrapper_item}
                onClick={() => setIsOpenGallery(index)}
              >
                <img src={portfolio.original} />
                <div className={styles.wrapper_item_likes}>
                  <div className={styles.wrapper_item_likes_icon}>
                    <ICONS.Like />
                  </div>
                  <p className={styles.wrapper_item_likes_text}>
                    {portfolio.likes || 0}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {canShowMore && (
            <div className={styles.wrapper_button}>
              <div className={styles.wrapper_button_item}>
                <Button
                  title="Show more"
                  variant={VariantButton.Gray}
                  onClick={handleFetchMore}
                />
              </div>
            </div>
          )}
        </>
      )}

      <Gallery
        initialImage={isOpenGallery}
        images={data?.adminPortfolios || []}
        categories={categories}
        selectedCategoryId={categoryId}
        isLoading={
          networkStatus === 1 || networkStatus === 4 || networkStatus === 2
        }
        onChangeCategory={(val) => setCategoryId(val)}
        onFetchMore={handleFetchMore}
        onClose={() => setIsOpenGallery(false)}
      />
    </div>
  );
};

export default Wrapper;
