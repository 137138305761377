import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar, JobTags } from "components";

import { IJobCandidate } from "types/interfaces";

import { UserService } from "utils";

type Props = {
  candidate: IJobCandidate;
};

const PhotographerJobCandidate: FC<Props> = ({ candidate }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_info}>
        <div className={styles.wrapper_info_tags}>
          <JobTags job={candidate.job} />
        </div>
        <p className={styles.wrapper_info_title}>{candidate.job.headline}</p>
        <p className={styles.wrapper_info_id}># {candidate.job.id}</p>
      </div>

      <div className={styles.wrapper_client}>
        <div className={styles.wrapper_client_avatar}>
          <Avatar img={candidate.job.user.avatar} />
        </div>
        <p className={styles.wrapper_client_name}>
          {candidate.job.user.firstName} {candidate.job.user.lastName}
        </p>
      </div>

      <div className={styles.wrapper_budget}>
        {UserService.getCandidateRate(candidate) || "n/a"}
      </div>

      <div className={styles.wrapper_date}>
        {moment(candidate.createdAt).format("MMM DD, YYYY")}
      </div>

      <div className={styles.wrapper_btn}>
        <div className={styles.wrapper_btn_wrapper}>
          <ICONS.RightArrow />
        </div>
      </div>
    </div>
  );
};

export default PhotographerJobCandidate;
