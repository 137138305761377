import { FC } from "react";
import styles from "./styles.module.scss";

import { ICategory } from "types/interfaces";

type Props = {
  categoris: Array<ICategory>;
};

const Categories: FC<Props> = ({ categoris }) => {
  return (
    <div className={styles.wrapper}>
      {categoris.map((category) => (
        <div key={category.id} className={styles.wrapper_item}>
          {category.title}
        </div>
      ))}
    </div>
  );
};

export default Categories;
