import { FC } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "30%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

type Props = {
  payload: any;
  onClose: () => void;
};

const TransactionModal: FC<Props> = ({ payload, onClose }) => {
  if (!payload) return null;

  return (
    <Modal
      isOpen={!!payload}
      //@ts-ignore
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>Transaction Details</h4>
          <div className={styles.wrapper_header_icon} onClick={onClose}>
            <ICONS.Cross />
          </div>
        </header>

        <div className={styles.wrapper_item}>
          <p className={styles.wrapper_item_label}>Reference ID</p>
          <p className={styles.wrapper_item_value}>3456789</p>
        </div>
        <div className={styles.wrapper_item}>
          <p className={styles.wrapper_item_label}>Date</p>
          <p className={styles.wrapper_item_value}>Mar 17, 2023</p>
        </div>
        <div className={styles.wrapper_item}>
          <p className={styles.wrapper_item_label}>Type</p>
          <p className={styles.wrapper_item_value}>Fixed-Price</p>
        </div>
        <div className={styles.wrapper_item}>
          <p className={styles.wrapper_item_label}>Description</p>
          <p className={styles.wrapper_item_value}>Invoice for</p>
        </div>
        <div className={styles.wrapper_item}>
          <p className={styles.wrapper_item_label}>Professional</p>
          <p className={styles.wrapper_item_value}>James Peterson</p>
        </div>
        <div className={styles.wrapper_item}>
          <p className={styles.wrapper_item_label}>Client</p>
          <p className={styles.wrapper_item_value}>Michael Brown</p>
        </div>
        <div className={styles.wrapper_item}>
          <p className={styles.wrapper_item_label}>Amount</p>
          <p className={styles.wrapper_item_value}>$350.50</p>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionModal;
