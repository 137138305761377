import { FC } from "react";
import styles from "./Header.module.scss";

type Props = {
  title: string;
};

const Header: FC<Props> = ({ title }) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.wrapper_title}>{title}</h2>
    </div>
  );
};

export default Header;
