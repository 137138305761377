import { gql } from "@apollo/client";

export const GET_REPORTS = gql`
  query reports($input: FindReportInput!) {
    reports(input: $input) {
      data {
        id
        status
        action
        blockedReason
        reporter {
          id
          firstName
          lastName
          avatar
          profile {
            id
            type
          }
        }
        reported {
          id
          firstName
          lastName
          avatar
          profile {
            id
            type
          }
        }
        subject
        body
        attachments
        createdAt
      }
      meta {
        itemCount
      }
    }
  }
`;

export const GET_DISPUTS = gql`
  query adminDisputes($input: AdminFindDisputeInput!) {
    adminDisputes(input: $input) {
      data {
        id
        creatorId
        subjectId
        problem
        contract {
          job {
            headline
          }
          client {
            id
            firstName
            lastName
            avatar
            profile {
              id
              type
            }
          }
          photographer {
            id
            firstName
            lastName
            avatar
            profile {
              id
              type
            }
          }
        }
        status
        open
        createdAt
      }
      meta {
        itemCount
      }
    }
  }
`;

export const GET_DISPUT = gql`
  query dispute($id: Int!) {
    dispute(id: $id) {
      id
      creatorId
      subjectId
      problem
      contract {
        job {
          headline
        }
        client {
          id
          firstName
          lastName
          avatar
          profile {
            id
            type
          }
        }
        photographer {
          id
          firstName
          lastName
          avatar
          profile {
            id
            type
          }
        }
      }
      status
      open
      chat {
        id
      }
      createdAt
    }
  }
`;
