import { FC } from "react";
import styles from "./styles.module.scss";
import { Avatar } from "components";
import { ICONS } from "assets";
import { IPurchaser } from "types";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface IProps {
  purchaser: IPurchaser;
}

const PurchaserCard: FC<IProps> = ({ purchaser }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.purchaser}
      onClick={() => navigate(`/professionals/${purchaser.id}`)}
    >
      <div className={styles.purchaser_info}>
        <div>
          <Avatar
            img={purchaser.avatar}
            dimensions={{ width: 44, height: 44 }}
          />
        </div>
        <p className={styles.purchaser_info_name}>
          {`${purchaser.firstName} ${purchaser.lastName}`}
        </p>
      </div>

      <div className={styles.purchaser_location}>
        {purchaser.locationShortTitle}
      </div>

      <div className={styles.purchaser_rating}>
        {purchaser.averageRating ? (
          <>
            <ICONS.Star style={{ marginBottom: 2 }} />
            <p className={styles.purchaser_rating_text}>
              {purchaser.averageRating}
            </p>
          </>
        ) : null}
      </div>

      <div className={styles.purchaser_date}>
        <span>
          {purchaser.completionDate
            ? moment(purchaser.completionDate).format("MMM DD, YYYY")
            : null}
        </span>

        <div className={styles.purchaser_date_icon}>
          <ICONS.RightArrow />
        </div>
      </div>
    </div>
  );
};

export default PurchaserCard;
