import { FC, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { BeatLoader } from "react-spinners";
import styles from "./styles.module.scss";

import { EmptyMessage, Select } from "components";
import Wrapper from "./Wrapper";

import { PortfolioQueries } from "graphql/queries";

type Props = {
  id: number;
};

const Portfolio: FC<Props> = ({ id }) => {
  const [selectedCategory, setSelectedCategory] = useState<number | "all">(
    "all"
  );

  const { data, loading, error } = useQuery<{
    countPortfolio: {
      all: number;
      grouped: Array<{
        categoryId: number;
        categoryTitle: string;
        _count: { _all: number };
      }>;
    };
  }>(PortfolioQueries.GET_COUNT_PORTFOLIO, {
    variables: { userId: id },
  });

  const options = useMemo(() => {
    if (!data) return [];

    return [
      {
        label: `All (${data.countPortfolio.all})`,
        value: "all",
        count: data.countPortfolio.all,
      },
      ...data.countPortfolio.grouped.map((g) => ({
        label: `${g.categoryTitle} (${g._count._all})`,
        value: g.categoryId,
        count: g._count._all,
      })),
    ];
  }, [data]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <BeatLoader color="black" />
      </div>
    );
  }

  if (error) {
    return <EmptyMessage title={error.message} />;
  }

  if (!data) return null;

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.wrapper_title}>Portfolio</h2>
      {data.countPortfolio.all === 0 ? (
        <EmptyMessage title="No portfolio yet" />
      ) : (
        <div className={styles.wrapper_portfolio}>
          <Wrapper userId={id} categories={options} />
        </div>
      )}
    </div>
  );
};

export default Portfolio;
