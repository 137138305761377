import { FC } from "react";
import styles from "./styles.module.scss";

import { RadioButton } from "components";

type Props = {
  value: number | null;
  onChange: (value: number | null) => void;
};

const Rating: FC<Props> = ({ value, onChange }) => {
  return (
    <div>
      <p className={styles.title}>Rating</p>

      <div className={styles.wrapper}>
        <div className={styles.wrapper_item}>
          <RadioButton
            value={value === null}
            label={`Any`}
            onSelect={() => onChange(null)}
          />
        </div>
        {Array.from(Array(5).keys()).map((_, index) => (
          <div key={index} className={styles.wrapper_item}>
            <RadioButton
              value={value === 5 - index}
              label={`${5 - index}`}
              onSelect={() => onChange(5 - index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Rating;
