import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { BeatLoader } from "react-spinners";
import moment from "moment";
import styles from "./styles.module.scss";

import { JobQueries } from "graphql/queries";

import { BackHeader, JobTags, Tabs, Attachments } from "components";
import { Description, ListCandidates } from "./Tabs";

import { IJob, IPaginationMeta } from "types/interfaces";
import { JobCandidateStatus, JobCandidateType, JobStatus } from "types/enums";

import { useTab } from "hooks";

const JobPosting: FC = () => {
  const { jobId } = useParams();

  const { currentTab, onChange: handleChangeTab } = useTab();
  const { data, loading, error } = useQuery<{
    adminJob: IJob;
    proposal: { meta: IPaginationMeta };
    invitation: { meta: IPaginationMeta };
    interview: { meta: IPaginationMeta };
  }>(JobQueries.GET_JOB_DETAIL, {
    variables: { id: Number(jobId) },
  });

  const tabs = useMemo(() => {
    if (!data) return [];

    return [
      { title: "Description" },
      { title: "Proposals", count: data.proposal.meta.itemCount },
      { title: "Invitations", count: data.invitation.meta.itemCount },
      { title: "Interviews", count: data.interview.meta.itemCount },
    ];
  }, [data]);

  const renderTab = () => {
    switch (currentTab) {
      case 0:
        return <Description id={Number(jobId)} />;
      case 1:
        return (
          <ListCandidates
            id={Number(jobId)}
            emptyTitle="No proposals yet"
            requestData={{
              type: JobCandidateType.Proposal,
              status: JobCandidateStatus.Submitted,
            }}
          />
        );
      case 2:
        return (
          <ListCandidates
            id={Number(jobId)}
            emptyTitle="No invitations yet"
            requestData={{
              type: JobCandidateType.Invitation,
              status: JobCandidateStatus.Submitted,
            }}
          />
        );
      default:
        return (
          <ListCandidates
            id={Number(jobId)}
            emptyTitle="No interviews yet"
            requestData={{
              status: JobCandidateStatus.Active,
            }}
          />
        );
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <BeatLoader color="black" />
      </div>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <div>
      <BackHeader
        title="Client’s Profile"
        links={["Client’s Profile", "Job posting"]}
      />

      <div className="shadow_box">
        <div className={styles.wrapper_tags}>
          <JobTags job={data?.adminJob} />
        </div>
        <h2 className={styles.wrapper_title}>{data?.adminJob.headline}</h2>
        <p className={styles.wrapper_date}>
          {moment(data?.adminJob.createdAt).fromNow()}
        </p>

        {data?.adminJob.attachments &&
          data?.adminJob.attachments.length > 0 && (
            <div className={styles.wrapper_attachments}>
              <p className={styles.wrapper_attachments_title}>Attachments</p>
              <Attachments attachments={data?.adminJob.attachments} />
            </div>
          )}

        <div className={styles.wrapper_tabs}>
          <Tabs
            tabs={tabs}
            currentIndex={currentTab}
            onChange={handleChangeTab}
          />
        </div>

        {renderTab()}
      </div>
    </div>
  );
};

export default JobPosting;
