import { FC } from "react";
import styles from "./styles.module.scss";

import { ProfileType } from "types";

interface IProps {
  userRole?: ProfileType;
}

const UserRoleBlock: FC<IProps> = ({ userRole }) => {
  return (
    <div
      className={styles.role}
      style={{
        backgroundColor:
          userRole === ProfileType.Photographer ? "#141415" : "white",
        color: userRole === ProfileType.Photographer ? "white" : "#141415",
      }}
    >
      {userRole}
    </div>
  );
};

export default UserRoleBlock;
