import { FC, useRef, useContext, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { v4 as uuid } from "uuid";

import styles from "./styles.module.scss";

import { AdminQueries } from "graphql/queries";

import { ICONS } from "assets";

import { GoogleService } from "utils";
import { Loading } from "context";

type Props = {
  value: string;
  onSend: () => void;
  onChange: (value: string) => void;
  onSaveFile: (value: any) => void;
};

const Input: FC<Props> = ({ value, onChange, onSend, onSaveFile }) => {
  const { setIsLoading } = useContext(Loading.Context)!;
  const imageRef = useRef<any>(null);
  const fileRef = useRef<any>(null);
  const textareaRef = useRef<any>(null);
  const [generateUploadUrls] = useLazyQuery(AdminQueries.GET_UPLOAD_URLS);

  useEffect(() => {
    handleTextareaInput();
  }, [value]);

  const handleSendMessage = (e: any) => {
    if (e.code === "Enter") {
      e.preventDefault();

      if (value.trim()) {
        onSend();
      }
    }
  };

  const formatBytes = (bytes: number, decimals: number = 1) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TiB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const handleOnChangeImage = async (e: any) => {
    const uniqueId = uuid();
    const file: File = e.target.files[0];

    if (file.type.includes("image")) {
      setIsLoading(true);
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = async () => {
        try {
          const {
            data: { generateUploadUrls: urls },
          } = await generateUploadUrls({
            variables: { filename: `${uniqueId}-${file.name}` },
          });

          await GoogleService.uploadFileToStorage(file, urls.uploadUrl);

          const attach = {
            type: "Attachment",
            attachmentProps: JSON.stringify({
              width: img.width,
              height: img.height,
              type: "image",
            }),
            attachment: urls.fileUrl,
          };

          onSaveFile(attach);
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      };
    }

    if (file.type.includes("video")) {
      setIsLoading(true);

      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      video.onloadedmetadata = async () => {
        if (video.duration > 30) {
          alert("Video size is too much(max 30 seconds)");
          return;
        }

        try {
          const {
            data: { generateUploadUrls: urls },
          } = await generateUploadUrls({
            variables: { filename: `${uniqueId}-${file.name}` },
          });

          await GoogleService.uploadFileToStorage(file, urls.uploadUrl);

          const attach = {
            type: "Attachment",
            attachmentProps: JSON.stringify({
              type: "video",
            }),
            attachment: urls.fileUrl,
          };

          onSaveFile(attach);
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      };
    }
  };

  const handleOnChangeFile = async (e: any) => {
    try {
      setIsLoading(true);

      const uniqueId = uuid();
      const file: File = e.target.files[0];

      const {
        data: { generateUploadUrls: urls },
      } = await generateUploadUrls({
        variables: { filename: `${uniqueId}-${file.name}` },
      });

      await GoogleService.uploadFileToStorage(file, urls.uploadUrl);

      const attach = {
        type: "Attachment",
        attachmentProps: JSON.stringify({
          type: "file",
          name: file.name,
          size: file.size ? formatBytes(file.size, 2) : null,
        }),
        attachment: urls.fileUrl,
      };

      onSaveFile(attach);
      fileRef.current.reset();
    } catch (e) {
      console.log(e, "---");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTextareaInput = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight - 36}px`;
  };

  return (
    <div className={styles.wrapper}>
      <textarea
        ref={textareaRef}
        value={value}
        placeholder="Write a message"
        className={styles.wrapper_input}
        onKeyDown={handleSendMessage}
        onChange={(e) => onChange(e.target.value)}
      />

      {value.trim().length > 0 ? (
        <div className={styles.wrapper_icon} onClick={onSend}>
          <ICONS.SendArrow />
        </div>
      ) : (
        <>
          <div
            className={styles.wrapper_icon}
            onClick={() => fileRef?.current.click()}
          >
            <ICONS.AttachFile />
          </div>
          <div
            className={styles.wrapper_icon}
            onClick={() => imageRef?.current.click()}
          >
            <ICONS.AttachImage />
          </div>
        </>
      )}

      <input
        ref={imageRef}
        accept="image/png, image/jpeg, video/mp4, video/x-m4v, video/*"
        type="file"
        style={{ display: "none" }}
        onChange={handleOnChangeImage}
      />
      <input
        ref={fileRef}
        accept=".pdf,video/*,image/*,.docx,.zip"
        type="file"
        style={{ display: "none" }}
        onChange={handleOnChangeFile}
      />
    </div>
  );
};

export default Input;
