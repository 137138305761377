import { FC } from "react";
import styles from "./styles.module.scss";

import { DotSelector } from "components";

import { ICONS } from "assets";
import moment from "moment";
import { Status, IEquipment } from "types";
import { useNavigate } from "react-router-dom";

interface IProps {
  equipment: IEquipment;
  onDelete: (equipment: IEquipment) => void;
  onEdit: (equipment: IEquipment) => void;
}

const EquipmentItem: FC<IProps> = ({ equipment, onEdit, onDelete }) => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.equipment}
      onClick={() => navigate(`${equipment.id}`)}
    >
      <div className={styles.equipment_info}>
        <div className={styles.equipment_info_wrap_img}>
          <img
            src={equipment.images?.[0]?.url}
            className={styles.equipment_info_img}
            alt="img"
          />
        </div>
        <p className={styles.equipment_info_title}>{equipment.title}</p>
      </div>

      <div className={styles.equipment_address}>
        <p className={styles.equipment_address_text}>
          {equipment.location.address}
        </p>
      </div>

      <div className={styles.equipment_price}>
        <p className={styles.equipment_price_text}>
          {`${equipment.rentPricePerDay} ${equipment.rentCurrency}`}
        </p>
      </div>

      <div className={styles.equipment_status}>
        <button
          className={styles.equipment_status_btn}
          style={{
            backgroundColor:
              equipment.status === Status.Active ? "#63CD5A" : "#B2B3BD",
          }}
        >
          <p className={styles.equipment_status_text}>{equipment.status}</p>
        </button>
      </div>

      <div className={styles.equipment_date}>
        {moment(equipment.createdAt).format("MMM DD, YYYY")}

        <div>
          <DotSelector
            items={[
              {
                label: "Edit",
                icon: <ICONS.Edit />,
                onClick: () => onEdit(equipment),
              },
              {
                label: "Delete",
                icon: <ICONS.Delete />,
                onClick: () => onDelete(equipment),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default EquipmentItem;
