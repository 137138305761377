import { FC } from "react";
import cls from "classnames";
import moment from "moment";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { Avatar } from "components";

import { IDisput } from "types/interfaces";
import { DisputStatus, ProfileType } from "types/enums";

type Props = {
  report: IDisput;
  onPress: () => void;
};

const Item: FC<Props> = ({ report: item, onPress }) => {
  const reporter =
    item.creatorId === item.contract.client.id
      ? item.contract.client
      : item.contract.photographer;
  const reported =
    item.subjectId === item.contract.client.id
      ? item.contract.client
      : item.contract.photographer;

  const getStatusText = () => {
    if (item.status === DisputStatus.Resolved) return "Resolved";

    if (item.status === DisputStatus.Active && item.open) return "Active";

    return "Waiting for admin";
  };

  return (
    <div className={styles.wrapper} onClick={onPress}>
      <div className={styles.wrapper_contract}>
        <p className={styles.wrapper_contract_id}># {item.id}</p>
        <p className={styles.wrapper_contract_title}>{item.problem}</p>
      </div>

      <div className={styles.wrapper_info}>
        <div className={styles.wrapper_info_avatar}>
          <Avatar img={reporter.avatar} />
        </div>
        <div>
          <p className={styles.wrapper_info_name}>
            {reporter.firstName} {reporter.lastName}
          </p>
          <p className={styles.wrapper_info_type}>
            {reporter.profile?.type === ProfileType.Client
              ? "Client"
              : "Professional"}
          </p>
        </div>
      </div>

      <div className={styles.wrapper_info}>
        <div className={styles.wrapper_info_avatar}>
          <Avatar img={reported.avatar} />
        </div>
        <div>
          <p className={styles.wrapper_info_name}>
            {reported.firstName} {reported.lastName}
          </p>
          <p className={styles.wrapper_info_type}>
            {reported.profile?.type === ProfileType.Client
              ? "Client"
              : "Professional"}
          </p>
        </div>
      </div>

      <div className={styles.wrapper_status}>
        <div
          className={cls(styles.wrapper_status_wrapper, {
            [styles.wrapper_status_wrapper_success]:
              item.status === DisputStatus.Active && item.open,
            [styles.wrapper_status_wrapper_waiting]:
              item.status === DisputStatus.Active && !item.open,
            [styles.wrapper_status_wrapper_resolved]:
              item.status === DisputStatus.Resolved,
          })}
        >
          {getStatusText()}
        </div>
      </div>

      <div className={styles.wrapper_date}>
        {moment(item.createdAt).format("MMM DD, YYYY")}
      </div>

      <div className={styles.wrapper_btn}>
        <div className={styles.wrapper_btn_wrapper}>
          <ICONS.RightArrow />
        </div>
      </div>
    </div>
  );
};

export default Item;
