import React, { FC, useState } from "react";

import { ConfirmModal, Select, Textarea } from "components";
import { useMutation } from "@apollo/client";
import { CoursesMutations } from "graphql/mutations";
import toast from "react-hot-toast";
import { CoursesQueries } from "graphql/queries";

enum DeleteReasons {
  A = "NoLongerRelevantOrOutdated",
  B = "DuplicationOfContentWithOtherCourses",
  C = "QualityConcernsOrPoorUserFeedback",
  D = "LegalOrCopyrightIssuesWithContent",
  E = "CreatorOrInstructorNoLongerAvailable",
}

const deleteReasonsList = [
  {
    value: DeleteReasons.A,
    label: "Course no longer relevant or outdated.",
  },
  {
    value: DeleteReasons.B,
    label: "Duplication of content with other courses.",
  },
  {
    value: DeleteReasons.C,
    label: "Quality concerns or poor user feedback.",
  },
  {
    value: DeleteReasons.D,
    label: "Legal or copyright issues with course content.",
  },
  {
    value: DeleteReasons.E,
    label: "Course creator or instructor no longer available.",
  },
];

interface IProps {
  courseId?: number;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteCourseModal: FC<IProps> = ({ courseId, isOpen, onClose }) => {
  const [reason, setReason] = useState<DeleteReasons | null>(null);
  const [desc, setDesc] = useState("");

  const [deleteCourse, { loading }] = useMutation(
    CoursesMutations.DELETE_COURSE
  );

  const onDeleteCourse = async () => {
    try {
      if (!courseId) return;

      await deleteCourse({
        variables: {
          input: {
            id: courseId,
            deletionReason: reason,
            deletionReasonDescription: desc.trimEnd(),
          },
        },
        refetchQueries: [CoursesQueries.GET_COURSES],
      });

      onClose();

      setReason(null);
      setDesc("");

      toast.success("Course deleted successfully");
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const disableConfirmBtn = () => {
    if (!reason) return true;

    if (desc.length < 3) return true;

    return false;
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      title={"Delete Course"}
      description="Why do you want to delete this course?"
      onClose={onClose}
      disableConfirmBtn={loading || disableConfirmBtn()}
      onConfirm={onDeleteCourse}
      confirmTextBtn="Delete Course"
    >
      <Select
        label="Reason"
        placeholder="Choose a reason"
        value={reason}
        onChange={(reason) => setReason(reason.value)}
        options={deleteReasonsList}
        containerStyle={{ marginBottom: 22 }}
        optionsStyle={{ zIndex: 1 }}
      />

      <Textarea
        label="Short Description"
        value={desc}
        maxLength={300}
        onChange={(val) => setDesc(val)}
        containerStyle={{ marginBottom: 22 }}
      />
    </ConfirmModal>
  );
};

export default DeleteCourseModal;
