import { FC, useState, useEffect, useRef, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import styles from "./styles.module.scss";

import { ChatQueries } from "graphql/queries";

import Input from "./Input";
import MessageItem from "./MessageItem";

import { IMessage } from "types/interfaces";
import { MessageType } from "types/enums";

import SocketService from "utils/socketService";
import { useStateRef } from "hooks";

const LIMIT = 10;

type Props = {
  chatId: number;
  reportedId: number;
  reporterId: number;
};

const Messages: FC<Props> = ({ chatId, reporterId, reportedId }) => {
  const [getMessages, { networkStatus }] = useLazyQuery<{
    chatMessages: Array<IMessage>;
  }>(ChatQueries.GET_CHAT_MESSAGE, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  const [messages, setMessages, messagesRef] = useStateRef([]);
  const [isFetchedAll, setIsFetchedAll] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const scrollRef = useRef<HTMLDivElement>(null);
  const isLoadingRef = useRef<boolean>(false);

  useEffect(() => {
    const isConnected = SocketService.isSocketConnected();
    SocketService.addEventListener("connected", subscribeToNewMessage);

    fetchMessages().then(() => {
      if (isConnected) {
        subscribeToNewMessage();
      }
    });

    return () => {
      SocketService.removeEventListener("connected", subscribeToNewMessage);
    };
  }, []);

  useEffect(() => {
    SocketService.addEventListener("connected", connectToSocket);

    if (SocketService.isSocketConnected()) {
      connectToSocket();
    }

    return () => {
      SocketService.emitEvent("leaveRoom", { chatId });
      SocketService.removeEventListener("connected", connectToSocket);
    };
  }, []);

  const connectToSocket = () => {
    SocketService.emitEvent("joinRoom", { chatId });
    // SocketService.addEventListener('join', handleJoin, true);
    // SocketService.addEventListener('typing', handleTyping, true);
  };

  const fetchMessages = async () => {
    try {
      if (isFetchedAll || isLoadingRef.current) return;
      isLoadingRef.current = true;

      const {
        // @ts-ignore
        data: { chatMessages },
      } = await getMessages({
        variables: {
          input: { take: LIMIT, skip: messagesRef.current.length, chatId },
        },
      });

      if (chatMessages.length < LIMIT) {
        setIsFetchedAll(true);
      }

      // @ts-ignore
      setMessages((prev) => [...chatMessages.reverse(), ...prev]);
    } catch (e) {
      console.log(e, "----eee");
    } finally {
      isLoadingRef.current = false;
    }
  };

  const subscribeToNewMessage = () => {
    SocketService.addEventListener("newMessage", handleAddNewMessage, true);
  };

  const handleAddNewMessage = (newMsg: IMessage) => {
    if (newMsg.chatId !== chatId) return;

    // @ts-ignore
    setMessages((prev) => [...prev, newMsg]);
  };

  const handleSendMessage = () => {
    SocketService.emitEvent("sendMessage", {
      type: MessageType.Text,
      body: message,
      chatId,
    });

    setMessage("");
  };

  const handleSendAttachment = (attachment: any) => {
    SocketService.emitEvent("sendMessage", { ...attachment, chatId });
  };

  const lastMessageId: number | null = useMemo(() => {
    if (messages.length === 0) return null;

    return messages.at(-1).id;
  }, [messages.length]);

  useEffect(() => {
    if (scrollRef.current) {
      if (
        scrollRef.current?.scrollHeight - scrollRef.current.scrollTop < 500 ||
        messages.length <= 10
      ) {
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      }
    }
  }, [lastMessageId, messages.length]);

  useEffect(() => {
    const scrollHandler = throttle(handleScroll, 100);

    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", scrollHandler);

      return () => {
        scrollRef.current?.removeEventListener("scroll", scrollHandler);
      };
    }
  }, []);

  const throttle = (fn: any, delay: number) => {
    let time = Date.now();
    return () => {
      if (time + delay - Date.now() <= 0) {
        fn();
        time = Date.now();
      }
    };
  };

  const handleScroll = (e: any) => {
    if (!scrollRef.current || isLoadingRef.current) return;

    if (scrollRef.current?.scrollTop < 1000) {
      fetchMessages();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div ref={scrollRef} className={styles.wrapper_messages}>
        <div className={styles.wrapper_messages_wrapper}>
          {messages.map((item: IMessage) => (
            <MessageItem
              key={item.id}
              message={item}
              reportedId={reportedId}
              reporterId={reporterId}
            />
          ))}
        </div>
      </div>

      <div className={styles.wrapper_input}>
        <Input
          value={message}
          onSend={handleSendMessage}
          onSaveFile={handleSendAttachment}
          onChange={setMessage}
        />
      </div>
    </div>
  );
};

export default Messages;
