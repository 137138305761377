import { FC } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import styles from "./styles.module.scss";

import { ReportQueries } from "graphql/queries";

import { BackHeader, Button } from "components";
import Wrapper from "./Wrapper";
import Messages from "./Messages";

import { IDisput } from "types/interfaces";

// @ts-ignore
const DisputItem: FC = () => {
  const { reportId } = useParams();
  const { data, loading } = useQuery<{ dispute: IDisput }>(
    ReportQueries.GET_DISPUT,
    {
      variables: { id: Number(reportId) },
    }
  );

  const renderContent = () => {
    if (!data?.dispute) return null;

    return <Wrapper disput={data.dispute} />;
  };

  return (
    <div className={styles.wrapper}>
      <BackHeader title="Dispute" />

      <div className={styles.content}>
        {loading ? (
          <div className={styles.loading}>
            <BeatLoader color="black" />
          </div>
        ) : (
          renderContent()
        )}
      </div>
    </div>
  );
};

export default DisputItem;
