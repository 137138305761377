import { FC } from "react";
import moment from "moment";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

import { IJob } from "types/interfaces";

type Props = {
  job: IJob | any;
};

const JobTags: FC<Props> = ({ job }) => {
  return (
    <div className={styles.topTagsWrapper}>
      <div className={styles.topTagsItem}>
        <div className={styles.topTagsItemIcon}>
          <ICONS.LocationPoint />
        </div>
        <p className={styles.topTagsItemText}>
          {job.location?.short_title}, {job.location?.short_title}
          {/* Los Angeles, CA */}
        </p>
      </div>

      <div className={styles.topTagsItem}>
        <div className={styles.topTagsItemIcon}>
          <ICONS.Calendar />
        </div>
        <p className={styles.topTagsItemText}>
          {job.asap ? "ASAP" : moment(job.date).format("MMMM DD, YYYY")}
          {/* September 23, 2023 */}
        </p>
      </div>
    </div>
  );
};

export default JobTags;
