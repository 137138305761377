import React, { FC, useContext, useEffect, useState } from "react";
import { Button, ConfirmModal, TableHead } from "components";
import LessonCard from "../LessonCard";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import LessonViewModal from "../LessonViewModal";
import { CourseOwnership, ICourseDetail, ILesson } from "types";
import CreateEditLessonModal from "../CreateEditLesson";
import { ICONS } from "assets";
import { useMutation } from "@apollo/client";
import { CoursesMutations } from "graphql/mutations";
import { CoursesQueries } from "graphql/queries";
import toast from "react-hot-toast";
import { Loading } from "context";

interface IProps {
  courseDetail?: ICourseDetail;
}

const LessonsListTab: FC<IProps> = ({ courseDetail }) => {
  const { setIsLoading } = useContext(Loading.Context)!;
  const [lessonsList, setLessonsList] = useState(courseDetail?.lessons);
  const [currentLesson, setCurrentLesson] = useState<ILesson | null>(null);
  const [isOpenLessonModal, setOpenLessonModal] = useState(false);
  const [isOpenDeleteLesson, setOpenDeleteLesson] = useState(false);

  const [updateLesson] = useMutation(CoursesMutations.UPDATE_COURSE);

  useEffect(() => {
    setLessonsList(courseDetail?.lessons);
  }, [courseDetail]);

  const [isShowLesson, setShowLesson] = useState(false);

  const handleDragEnd = (result: DropResult) => {
    if (!lessonsList || !result.destination) return;

    const copyLessons = lessonsList.slice();

    if (!result.destination) return;

    const [reorderedItem] = copyLessons.splice(result.source.index, 1);

    copyLessons.splice(result.destination.index, 0, reorderedItem);

    setLessonsList(copyLessons);

    onUpdatePlaceOfLesson(copyLessons);
  };

  const onUpdatePlaceOfLesson = async (newLessons: ILesson[]) => {
    try {
      await updateLesson({
        variables: {
          input: {
            id: courseDetail?.id,
            lessons: newLessons.map((it) => ({ id: it.id })),
          },
        },
        refetchQueries: [CoursesQueries.GET_COURSE_DETAIL],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickLesson = (lesson: ILesson) => {
    setCurrentLesson(lesson);
    setShowLesson(true);
  };

  const onDeleteLesson = async () => {
    try {
      setIsLoading(true);

      const newLessonsList = courseDetail?.lessons.filter(
        (it) => it.id !== currentLesson?.id
      );

      await updateLesson({
        variables: {
          input: {
            id: courseDetail?.id,
            lessons: newLessonsList?.map((it) => ({ id: it.id })),
          },
        },
        refetchQueries: [CoursesQueries.GET_COURSE_DETAIL],
      });

      toast.success(`Lesson deleted successfully`);
    } catch (error) {
      toast.error("You cannot delete this lesson as it is the only one.");
    } finally {
      setIsLoading(false);
      setOpenDeleteLesson(false);
    }
  };

  return (
    <>
      {lessonsList?.length ? (
        <TableHead
          items={[
            {
              title: "#",
              disableSort: true,
            },
            {
              title: "Lesson",
              disableSort: true,
            },
            {
              title: "Duration",
              disableSort: true,
            },
          ]}
          flexes={[0.4, 10, 1.5]}
          sorted={{ sortedBy: null, type: null }}
          onChangeSort={() => null}
        />
      ) : null}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable
          isDropDisabled={
            courseDetail?.ownership !== CourseOwnership.MosawerExclusive
          }
          droppableId="banners"
        >
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {lessonsList?.map((lesson, index) => {
                return (
                  <Draggable
                    isDragDisabled={
                      courseDetail?.ownership !==
                      CourseOwnership.MosawerExclusive
                    }
                    key={lesson.id}
                    draggableId={String(lesson.id)}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <LessonCard
                          lesson={lesson}
                          onDelete={(lesson) => {
                            setOpenDeleteLesson(true);
                            setCurrentLesson(lesson);
                          }}
                          onEdit={() => {
                            setCurrentLesson(lesson);
                            setOpenLessonModal(true);
                          }}
                          onClickLesson={onClickLesson}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button
        title="Add a lesson"
        onClick={() => setOpenLessonModal(true)}
        icon={<ICONS.Plus />}
        styleBtn={{
          marginTop: 25,
          width: 168,
        }}
      />

      <LessonViewModal
        lesson={currentLesson}
        isOpen={isShowLesson}
        onClose={() => {
          setShowLesson(false);
          setCurrentLesson(null);
        }}
      />

      <CreateEditLessonModal
        isOpen={isOpenLessonModal}
        onClose={() => {
          setOpenLessonModal(false);
          setCurrentLesson(null);
        }}
        lesson={currentLesson}
        courseDetail={courseDetail}
      />

      <ConfirmModal
        isOpen={isOpenDeleteLesson}
        hideCrossIcon
        title={`Are you sure you want to delete Lesson ${currentLesson?.numbering}?`}
        onClose={() => setOpenDeleteLesson(false)}
        onConfirm={onDeleteLesson}
      />
    </>
  );
};

export default LessonsListTab;
