import React, { FC, useState } from "react";
import styles from "./styles.module.scss";
import Modal from "react-modal";
import { ICONS } from "assets";
import { Button, Filter, Input, SelectUsers } from "components";
import toast from "react-hot-toast";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import { CREATE_STUDIO_RENTAL } from "graphql/mutations/studios";
import moment from "moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: 526,
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
    padding: 32,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedHours: string[];
  studioId?: number;
  refetch: () => Promise<ApolloQueryResult<any>>;
}

const ScheduleStudioModal: FC<IProps> = ({
  isOpen,
  selectedHours,
  onClose,
  studioId,
  refetch,
}) => {
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [isUserFromApp, setUserFromApp] = useState(true);
  const [newRentalName, setNewRentalName] = useState("");
  const [error, setError] = useState("");

  const [createRental] = useMutation(CREATE_STUDIO_RENTAL);

  const createStudioRental = async () => {
    if (!isUserFromApp && !newRentalName) {
      return setError("Rental name is required");
    }

    if (isUserFromApp && !selectedUserId) {
      return toast.error("Rental is required");
    }

    const externalRenter = {
      newExternalRenter: {
        fullName: newRentalName.trimEnd(),
      },
    };

    const rentalFromApp = {
      renterId: selectedUserId,
    };

    await createRental({
      variables: {
        input: {
          datetimes: [
            {
              endsAt: selectedHours[1],
              startsAt: selectedHours[0],
            },
          ],
          studioId,
          renter: isUserFromApp ? rentalFromApp : externalRenter,
        },
      },
    });

    await refetch();

    toast.success("Schedule a Studio created successfully");

    onSuccess();

    try {
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const onChangeName = (name: string) => {
    if (error) setError("");

    const hasConsecutiveSpaces = / {2,}/.test(name);

    if (hasConsecutiveSpaces) return;

    setNewRentalName(name.trimStart());
  };

  const onSuccess = () => {
    onClose();
    setError("");
    setSelectedUserId(null);
    setNewRentalName("");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      //@ts-ignore
      style={customStyles}
    >
      <div className={styles.wrapper}>
        <div className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>Schedule a Studio</h4>
          <div className={styles.wrapper_header_icon} onClick={onClose}>
            <ICONS.Cross />
          </div>
        </div>
        <div className={styles.wrapper_data}>
          <p className={styles.wrapper_data_data}>
            {moment(selectedHours[0]).format("DD MMMM, YYYY")}
          </p>
          <p className={styles.wrapper_data_range}>{`${moment(
            selectedHours[0]
          ).format("HH:mm")} - ${moment(selectedHours[1]).format("HH:mm")}`}</p>
        </div>

        <Filter.Checkbox
          label="Renter is not from the app"
          value={!isUserFromApp}
          onChange={() => setUserFromApp(!isUserFromApp)}
        />

        {isUserFromApp ? (
          <>
            <p className={styles.select_label}>Renter’s name</p>

            <SelectUsers
              onChange={(val) => setSelectedUserId(val)}
              containerStyle={{ marginBottom: 22 }}
            />
          </>
        ) : (
          <>
            <p className={styles.select_label}>Renter’s name</p>

            <Input
              placeholder="Enter a name"
              value={newRentalName}
              onChange={onChangeName}
              containerStyle={{ marginBottom: 22 }}
              error={error}
            />
          </>
        )}

        <Button title="Schedule a Studio" onClick={createStudioRental} />
      </div>
    </Modal>
  );
};

export default ScheduleStudioModal;
