import { FC } from "react";
import { useNavigate } from "react-router-dom";
import cls from "classnames";
import styles from "./styles.module.scss";

import { ICONS } from "assets";

type Props = {
  title: string;
  links?: Array<string>;
  actions?: Array<React.ReactElement>;
};

const BackHeader: FC<Props> = ({ title, links, actions }) => {
  const navigate = useNavigate();

  const handleBackClick = (index: number) => {
    if (!links || index === links.length - 1) return;

    navigate(index - (links.length - 1));
  };

  return (
    <header className={styles.header}>
      <div className={styles.header_arrow} onClick={() => navigate(-1)}>
        <ICONS.BackArrow />
      </div>

      {links && links.length > 0 ? (
        <div className={styles.header_links}>
          {links.map((link, index) => {
            const isLast = index === links.length - 1;

            return (
              <div
                key={index}
                className={cls(styles.header_links_item, {
                  [styles.header_links_item_active]: isLast,
                })}
                onClick={() => handleBackClick(index)}
              >
                {link}
              </div>
            );
          })}
        </div>
      ) : (
        <p className={styles.header_title}>{title}</p>
      )}

      {actions && (
        <div className={styles.header_actions}>
          {actions.map((action, index) => (
            <div key={index} className={styles.header_actions_item}>
              {action}
            </div>
          ))}
        </div>
      )}
    </header>
  );
};

export default BackHeader;
