import { FC } from "react";
import styles from "./Statistics.module.scss";

import { Header } from "components";
import Top from "./Top";
import Middle from "./Middle";
import Bottom from "./Bottom";

const Statistics: FC = () => {
  return (
    <div>
      <Header title="Statistics" />

      <div className={styles.wrapper}>
        <Top />
        <Middle />
        <Bottom />
      </div>
    </div>
  );
};

export default Statistics;
