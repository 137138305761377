import React, { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

import { Button } from "components";

type Props = {
  children: React.ReactNode;
  title?: string;
  contentClass?: string;
  onClear: () => void;
  onApply: () => void;
};

const Wrapper: FC<Props> = ({
  children,
  title = "Filter by",
  contentClass,
  onClear,
  onApply,
}) => {
  return (
    <div className={styles.wrapper}>
      <header className={styles.wrapper_header}>
        <p className={styles.wrapper_header_title}>Filter by</p>
        <p className={styles.wrapper_header_clear} onClick={onClear}>
          Clear all filters
        </p>
      </header>

      <div className={cls(styles.wrapper_content, contentClass)}>
        {children}
      </div>

      <div className={styles.wrapper_button}>
        <Button title="Apply" onClick={onApply} />
      </div>
    </div>
  );
};

export default Wrapper;
