import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import {
  getMainDefinition,
  offsetLimitPagination,
} from "@apollo/client/utilities";
import unionBy from "lodash/unionBy";

import { TokensService } from "utils";

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    debugger;
    if (graphQLErrors) {
      console.log("graphQLErrors", graphQLErrors);

      //   for (let err of graphQLErrors) {
      //     switch (err.extensions.code) {
      //       // Apollo Server sets code to UNAUTHENTICATED
      //       // when an AuthenticationError is thrown in a resolver
      //       case "UNAUTHENTICATED":
      //         // Modify the operation context with a new token
      //         const oldHeaders = operation.getContext().headers;
      //         operation.setContext({
      //           headers: {
      //             ...oldHeaders,
      //             authorization: getNewToken(),
      //           },
      //         });
      //         // Retry the request, returning the new observable
      //         return forward(operation);
      //     }
      //   }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const authLink = setContext(async (operation, { headers }) => {
  const token = TokensService.getTokens();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  /**
   * TODO: must be taken away to the .env file.
   */
  uri: "https://mosawer.com/graphql",
});

const link = from([errorLink, authLink, httpLink]);

// const splitLink = split(({ query }) => {
//   const definition = getMainDefinition(query);
//   return (
//     definition.kind === "OperationDefinition" &&
//     definition.operation === "subscription"
//   );
// }, link);

const client = new ApolloClient({
  link: link,
  connectToDevTools: true,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          adminPortfolios: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          },
          findUserPreviews: {
            keyArgs: false,
            merge(existing: any, incoming: any) {
              return {
                ...incoming,
                data: [...(existing?.data ?? []), ...(incoming.data ?? [])],
              };
            },
          },
        },
      },
    },
  }),
});

export default client;
