import { FC } from "react";
import styles from "./Item.module.scss";

type Props = {
  item: {
    title: string;
    percent: number;
  };
};

const Item: FC<Props> = ({ item }) => {
  return (
    <div className={styles.wrapper}>
      <header className={styles.wrapper_header}>
        <p className={styles.wrapper_header_title}>{item.title}</p>
        <p className={styles.wrapper_header_percent}>{item.percent}%</p>
      </header>

      <div className={styles.wrapper_progress}>
        <div
          className={styles.wrapper_progress_wrapper}
          style={{ width: `${item.percent}%` }}
        />
      </div>
    </div>
  );
};

export default Item;
