import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import styles from "./styles.module.scss";

import { AdminMutations } from "graphql/mutations";

import { ICONS } from "assets";

import { Input, Button } from "components";

import { TokensService } from "utils";
import { useUser } from "hooks";

const LoginSchema = yup.object({
  email: yup
    .string()
    .max(80, "Max size 80")
    .matches(/([a-z0-9._]{3,80})@([a-z]{3,80})\.[a-z]{2,3}/, `incorrect email`),
  password: yup.string().required(`Required`).min(8, `Min symbols 8`),
});

const Login: FC = () => {
  const navigate = useNavigate();
  const { loading: userLoading, refetch } = useUser();
  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    validateOnChange: false,
    onSubmit: login,
  });
  const [loginMutation, { error, loading }] = useMutation(AdminMutations.LOGIN);

  async function login() {
    try {
      const { email, password } = values;
      const {
        data: { singInAdmin },
      } = await loginMutation({
        variables: { input: { email, password } },
      });
      TokensService.setTokens(singInAdmin);
      await refetch();
      navigate("/");
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (TokensService.getTokens()) {
      navigate("/");
    }
  }, []);

  const isDisableBtn = !values.email.trim() || !values.password.trim();

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_logo}>
        <ICONS.VerticalLogo />
      </div>

      <div className={styles.wrapper_box}>
        <div className={styles.wrapper_box_input}>
          <Input
            value={values.email}
            label="Email"
            error={errors.email}
            onChange={(val) => setFieldValue("email", val)}
          />
        </div>
        <div className={styles.wrapper_box_input}>
          <Input
            value={values.password}
            label="Password"
            isSucure
            error={errors.password}
            onChange={(val) => setFieldValue("password", val)}
          />
        </div>

        {error && <p className={styles.wrapper_error}>{error.message}</p>}

        <Button
          title="Login"
          disable={isDisableBtn || loading || userLoading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default Login;
