import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.min.css";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { Loading } from "context";
import Navigation from "components/Navigation";

function App() {
  return (
    <Loading.Provider>
      <Navigation />
      <Toaster toastOptions={options} />
    </Loading.Provider>
  );
}

const options = {
  success: {
    iconTheme: {
      primary: "white",
      secondary: "#63CD5A",
    },
    style: {
      padding: "12px 22px",
      background: "rgba(213, 244, 211, 0.8)",
      backdropFilter: "blur(7.5px)",
      borderRadius: "10px",
      width: "100%",
      justifyContent: "flex-start",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
  error: {
    iconTheme: {
      primary: "white",
      secondary: "#FF3C3C",
    },
    style: {
      padding: "12px 22px",
      background: " rgba(254, 206, 206, 0.8)",
      backdropFilter: "blur(7.5px)",
      borderRadius: "10px",
      fontWeight: 500,
      width: "100%",
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
};

export default App;
