import { FC } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";

type Props = {
  value: boolean;
  label: string;
  smallTitle?: boolean;
  containerStyle?: React.CSSProperties;
  onSelect: () => void;
};

const RadioButton: FC<Props> = ({
  value,
  label,
  smallTitle = false,
  containerStyle,
  onSelect,
}) => {
  return (
    <div className={styles.wrapper} style={containerStyle} onClick={onSelect}>
      <div
        className={cls(styles.wrapper_radio, {
          [styles.wrapper_radio_selected]: value,
        })}
      >
        {value && <div className={styles.wrapper_radio_circle} />}
      </div>

      <p
        className={cls(styles.wrapper_title, {
          [styles.wrapper_title_small]: smallTitle,
        })}
      >
        {label}
      </p>
    </div>
  );
};

export default RadioButton;
