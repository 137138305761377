import { FC, useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { useFormik } from "formik";
import styles from "./styles.module.scss";
import toast from "react-hot-toast";

import { Button, Input, SearchLocation, Textarea } from "components";
import { ICONS } from "assets";

import { IStudioLocation, IStudio } from "types/interfaces";
import { v4 as uuid } from "uuid";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GoogleService } from "utils";
import { AdminQueries } from "graphql/queries";
import { Loading } from "context";

import ImageMultipleDropZone from "components/ImageMultipleDrop";
import { DropResult } from "react-beautiful-dnd";
import { StudiosMutations } from "graphql/mutations";

const isMac = window.navigator.platform.toUpperCase().indexOf("MAC") >= 0;

const customStyles = {
  content: {
    position: "relative",
    width: "660px",
    maxHeight: "90vh",
    padding: 30,
    paddingRight: isMac ? 30 : 15,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 2000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const validate = (values: any) => {
  const errors: any = {};

  if (values.title.length < 3) {
    errors.title = "Min length: 3";
  }

  if (values.title.trim() === "") {
    errors.title = "Title is required";
  }

  if (values.title.length > 300) {
    errors.title = "Max length: 300";
  }

  if (values.desc.length < 3) {
    errors.desc = "Min length: 3";
  }

  if (values.desc.length > 3000) {
    errors.desc = "Max length: 3000";
  }

  if (values.desc.trim() === "") {
    errors.desc = "Description is required";
  }

  if (!values?.location?.address) {
    errors.location = "Location is required";
  }

  if (!values?.images.length) {
    errors.images = "Images of studio is required";
  }

  if (values?.images.length > 10) {
    errors.images = "Max 10 images";
  }

  if (!values.priceByHour) {
    errors.priceByHour = "Price by day is required";
  }

  if (values.priceByHour > 100000) {
    errors.priceByHour = "Max price: 100000";
  }

  if (values.priceByHour && Number(values.priceByHour) === 0) {
    errors.priceByHour = "Price by day must be more than 0";
  }

  return errors;
};

const initialImages = {
  imagesUrls: [],
  fileImages: [],
};

interface IProps {
  studio: IStudio | null;
  isOpen: boolean;
  onClose: () => void;
  refetch: any;
}

const CreateUpdateStudioModal: FC<IProps> = ({
  studio,
  onClose,
  refetch,
  isOpen,
}) => {
  const { setIsLoading } = useContext(Loading.Context)!;

  const [createStudio] = useMutation(StudiosMutations.CREATE_STUDIO);
  const [updateStudio] = useMutation(StudiosMutations.UPDATE_STUDIO);

  const [createUploadUrl] = useLazyQuery(AdminQueries.GET_UPLOAD_URLS);

  const [images, setImages] = useState<{
    imagesUrls: string[];
    fileImages: File[] | null[];
  }>(initialImages);

  const {
    values,
    errors,
    setFieldValue,
    setFieldError,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      title: studio?.title || "",
      desc: studio?.description || "",
      priceByHour: String(studio?.rentPricePerHour || ""),
      location: studio?.location,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validate: (values) => validate({ ...values, images: images.imagesUrls }),
    onSubmit: studio ? handleUpdateStudio : handleCreateStudio,
  });

  useEffect(() => {
    if (studio) {
      const existImageUrls = studio.images.map((it) => it.url);

      const existImageFileAsPlug = studio.images.map((it) => null);

      return setImages({
        imagesUrls: existImageUrls,
        fileImages: existImageFileAsPlug,
      });
    }

    setImages(initialImages);
  }, [isOpen, studio]);

  async function handleCreateStudio() {
    try {
      setIsLoading(true);

      const imageUrls = await saveStudioImages();

      console.log({
        address: values.location?.address,
        description: values.desc,
        imageUrls,
        latitude: values.location?.latitude,
        longitude: values.location?.longitude,
        rentPricePerHour: Number(values.priceByHour),
        title: values.title,
      });

      await createStudio({
        variables: {
          input: {
            address: values.location?.address,
            description: values.desc.trimEnd(),
            imageUrls,
            latitude: values.location?.latitude,
            longitude: values.location?.longitude,
            rentPricePerHour: Number(values.priceByHour),
            title: values.title.trimEnd(),
          },
        },
      });

      await refetch();

      onCloseModal();

      toast.success("Studio created successfully");
    } catch (error) {
      toast.error("Error created studio");
    } finally {
      setIsLoading(false);
    }
  }

  async function handleUpdateStudio() {
    try {
      setIsLoading(true);

      const newImageUrls = await saveStudioImages();

      const updateDataImages = getFormattedImagesData(newImageUrls);

      await updateStudio({
        variables: {
          input: {
            id: studio?.id,
            images: updateDataImages,
            location: {
              address: values.location?.address,
              latitude: values.location?.latitude,
              longitude: values.location?.longitude,
            },
            rentPricePerHour: Number(values.priceByHour),
            title: values.title.trimEnd(),
            description: values.desc.trimEnd(),
          },
        },
      });

      await refetch();

      onCloseModal();

      toast.success("Studio updated successfully");
    } catch (error) {
      toast.error("Error updated studio");
      console.error("Error updated studio", error);
    } finally {
      setIsLoading(false);
    }
  }

  const getFormattedImagesData = (newUrls?: string[]) => {
    if (!studio) return;

    const foundExistImageIds = images.imagesUrls
      .map((imageUrl) => {
        const matchedImage = studio.images.find(
          (image) => image.url === imageUrl
        );
        return matchedImage ? { id: matchedImage.id } : null;
      })
      .filter((imageId) => imageId !== null);

    if (!newUrls) return foundExistImageIds;

    const newImageUrlsData = newUrls.map((url) => {
      return {
        new: {
          url,
        },
      };
    });

    return [...foundExistImageIds, ...newImageUrlsData];
  };

  const saveStudioImages = async () => {
    try {
      if (!images.fileImages || !images.fileImages.length) return;

      //@ts-ignore
      const newImagesWithoutPlugs = images.fileImages.filter(
        (it: any) => it !== null
      );

      const urls: string[] = [];

      for (const image of newImagesWithoutPlugs as File[]) {
        const uniqueId = uuid();

        const {
          data: { generateUploadUrls },
        } = await createUploadUrl({
          variables: { filename: `${uniqueId}${image.name}` },
        });

        await GoogleService.uploadFileToStorage(
          image,
          generateUploadUrls.uploadUrl
        );

        urls.push(generateUploadUrls.fileUrl);
      }

      return urls;
    } catch (error) {
      toast.error("Error save images");
      console.error("Error save images");
    }
  };

  const onSelectLocation = (location: IStudioLocation) => {
    setFieldValue("location", location);
  };

  const onChangePriceByHour = (price: string) => {
    const formattedPrice = price.replace(/[^0-9.]/g, "");

    if (formattedPrice[0] === "0" && formattedPrice[1] === "0") {
      return setFieldValue("priceByHour", formattedPrice.slice(1) + ".");
    }

    if (formattedPrice.startsWith(".")) return;

    setFieldValue("priceByHour", formattedPrice);
  };

  const handleDropImages = (imagesDrop: Blob[], amountImages: number) => {
    const maxSize = 15000 * 1024;

    let totalImages = amountImages + imagesDrop.length;

    if (totalImages >= 11) return toast.error("Maximum 10 images");

    imagesDrop.forEach((image) => {
      if (image.size > maxSize) {
        return toast.error(
          "The maximum allowed weight of the studio image is 15MB"
        );
      }

      const reader = new FileReader();

      reader.readAsDataURL(image);

      reader.onload = () => {
        const dataURL = reader.result as string;

        setImages((prev) => ({
          ...prev,
          imagesUrls: [...prev.imagesUrls, dataURL],
        }));
      };
    });

    setImages((prev) => ({
      ...prev,
      fileImages: [...prev.fileImages, ...imagesDrop] as File[],
    }));

    setFieldError("images", "");
  };

  const onCloseModal = () => {
    onClose();
    resetForm();
    setImages(initialImages);
  };

  const onRemoveImage = (removeIndex: number) => {
    setImages((prev) => ({
      imagesUrls: prev.imagesUrls.filter(
        (_: any, index: number) => index !== removeIndex
      ),
      //@ts-ignore
      fileImages: prev.fileImages.filter(
        (_: any, index: number) => index !== removeIndex
      ) as File[],
    }));
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const { imagesUrls, fileImages } = images;

    const copyImageUrls = [...imagesUrls];
    const copyImageFiles = [...fileImages];

    const sourceIndex =
      result.source.droppableId === "chunkImages1"
        ? result.source.index + 5
        : result.source.index;

    const destinationIndex =
      result.destination.droppableId === "chunkImages1"
        ? result.destination.index + 5
        : result.destination.index;

    const [reorderedUrl] = copyImageUrls.splice(sourceIndex, 1);
    const [reorderedImage] = copyImageFiles.splice(sourceIndex, 1);

    copyImageUrls.splice(destinationIndex, 0, reorderedUrl);
    copyImageFiles.splice(destinationIndex, 0, reorderedImage);

    setImages({
      imagesUrls: copyImageUrls,
      fileImages: copyImageFiles as File[],
    });
  };

  const onChangeStudioName = (value: string) => {
    const hasConsecutiveSpaces = / {2,}/.test(value);

    if (hasConsecutiveSpaces) return;

    setFieldValue("title", value.trimStart());
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles as any}
      ariaHideApp={false}
      onRequestClose={onCloseModal}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>
            {studio ? "Edit Studio" : "New Studio"}
          </h4>
          <div className={styles.wrapper_header_icon} onClick={onCloseModal}>
            <ICONS.Cross />
          </div>
        </header>

        <div className={styles.wrapper_input}>
          <Input
            label="Title"
            placeholder="Enter title"
            value={values.title}
            error={errors.title}
            maxLength={300}
            onChange={onChangeStudioName}
          />
        </div>

        <div className={styles.wrapper_input}>
          <Textarea
            label="Description"
            value={values.desc}
            error={errors.desc}
            maxLength={3000}
            onChange={(val) => setFieldValue("desc", val.trimStart())}
          />
        </div>

        <SearchLocation
          value={values.location?.address || ""}
          onSelect={onSelectLocation}
          error={errors.location}
        />

        <div className={styles.wrapper_input}>
          <Input
            label="Price by Hour (KWD)"
            placeholder="Enter price"
            value={values.priceByHour}
            error={errors.priceByHour}
            onChange={onChangePriceByHour}
          />
        </div>

        <ImageMultipleDropZone
          images={images.imagesUrls}
          handleSave={handleDropImages}
          handleDragEnd={handleDragEnd}
          onRemoveImage={onRemoveImage}
          //@ts-ignore
          error={errors.images}
        />

        <div className={styles.wrapper_button}>
          <div className={styles.wrapper_button_item}>
            <Button title={studio ? "Edit" : "Create"} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateUpdateStudioModal;
