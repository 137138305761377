import { FC } from "react";
import styles from "./Bottom.module.scss";

import Item from "./Item";

const data = [
  { title: "Food & Beverage", percent: 30 },
  { title: "Concerts", percent: 10 },
  { title: "Model", percent: 5 },
  { title: "Pets", percent: 3 },
  { title: "Food & Beverage", percent: 30 },
  { title: "Concerts", percent: 10 },
  { title: "Model", percent: 5 },
  { title: "Pets", percent: 3 },
  { title: "Food & Beverage", percent: 30 },
  { title: "Concerts", percent: 10 },
  { title: "Model", percent: 5 },
  { title: "Pets", percent: 3 },
  { title: "Food & Beverage", percent: 30 },
  { title: "Concerts", percent: 10 },
  { title: "Model", percent: 5 },
  { title: "Pets", percent: 3 },
];

const Bottom: FC = () => {
  return (
    <div className={styles.wrapper}>
      {data.map((i, index) => (
        <div key={index} className={styles.wrapper_item}>
          <Item item={i} />
        </div>
      ))}
    </div>
  );
};

export default Bottom;
