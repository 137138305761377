import { FC, useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { v4 as uuid } from "uuid";
import styles from "./styles.module.scss";

import { AdminQueries } from "graphql/queries";
import { AdminMutations } from "graphql/mutations";

import { ICONS } from "assets";

import {
  Header,
  TableHead,
  SearchInput,
  Button,
  DotSelector,
  Avatar,
  ConfirmModal,
  Pagination,
  Select,
} from "components";
import CreateUpdateModal from "./CreateUpdateModal";

import { Order, Roles } from "types/enums";
import { IAdmin } from "types/interfaces";
import Skeleton from "react-loading-skeleton";
import { Loading } from "context";
import { GoogleService } from "utils";
import { useUser } from "hooks";
import toast from "react-hot-toast";

const LIMIT = 10;

const RoleOptions = [
  { label: "Moderator", value: Roles.Moderator },
  { label: "Equipment Manager", value: Roles.EquipmentManager },
  { label: "Studio Manager", value: Roles.StudioManager },
  { label: "Admin", value: Roles.Admin },
];

const StaffManagment: FC = () => {
  const { setIsLoading } = useContext(Loading.Context)!;

  const [updateOrCreateManager, setUpdateOrCreateManager] = useState<
    any | false
  >(false);

  const [deleteModal, setDeleteModal] = useState<false | any>(false);

  const [sort, setSort] = useState<{
    sortedBy: string | null;
    type: Order | null;
    search: string;
    currentPage: number;
  }>({
    sortedBy: null,
    type: Order.DESC,
    currentPage: 1,
    search: "",
  });
  const [count, setCount] = useState(1);

  const [fetchAdmins, { data, loading, refetch }] = useLazyQuery<{
    admins: {
      data: IAdmin[];
      meta: { itemCount: number };
    };
  }>(AdminQueries.GET_ADMINS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted(data) {
      setCount(data.admins.meta.itemCount);
    },
  });
  const [createUploadUrls] = useLazyQuery(AdminQueries.GET_UPLOAD_URLS);
  const [createAdmin] = useMutation(AdminMutations.CREATE_ADMIN);
  const [updateAdmin] = useMutation(AdminMutations.UPDATE_ADMIN);
  const [deleteAdmin] = useMutation(AdminMutations.DELETE_ADMIN);

  useEffect(() => {
    fetchAdmins({
      variables: {
        input: {
          skip: (sort.currentPage - 1) * LIMIT,
          take: LIMIT,
          search: sort.search,
          order: sort.type || Order.DESC,
          orderBy: sort.sortedBy || "Id",
        },
      },
    });
  }, [sort]);

  const handleCreateAdmin = async (newAdmin: any) => {
    try {
      setIsLoading(true);

      if (!newAdmin.id) {
        const adminData: any = {
          firstName: newAdmin.firstName,
          lastName: newAdmin.lastName,
          password: newAdmin.password,
          email: newAdmin.email,
          role: newAdmin.role,
        };

        if (newAdmin.avatar) {
          const uniqueId = uuid();

          const {
            data: { generateUploadUrls: urls },
          } = await createUploadUrls({
            variables: { filename: `${uniqueId}${newAdmin.avatar}` },
          });
          await GoogleService.uploadFileToStorage(
            newAdmin.avatar,
            urls.uploadUrl
          );

          adminData.avatar = urls.fileUrl;
        }

        await createAdmin({ variables: { input: adminData } });

        toast.success("Admin created successfully");
      } else {
        handleUpdateAdmin(newAdmin);
      }

      await refetch();
      setUpdateOrCreateManager(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateAdmin = async (newAdmin: any, newRole?: Roles) => {
    const adminData: any = {
      id: newAdmin.id,
      firstName: newAdmin.firstName,
      lastName: newAdmin.lastName,
      role: newRole || newAdmin.role,
    };

    if (newAdmin.password.length > 0) {
      adminData.password = newAdmin.password;
    }

    if (newAdmin.avatar?.name) {
      const uniqueId = uuid();
      const {
        data: { generateUploadUrls: urls },
      } = await createUploadUrls({
        variables: { filename: `${uniqueId}${newAdmin.avatar}` },
      });
      await GoogleService.uploadFileToStorage(newAdmin.avatar, urls.uploadUrl);

      adminData.avatar = urls.fileUrl;
    }

    await updateAdmin({ variables: { input: adminData } });

    await refetch();

    toast.success("Admin updated successfully");
  };

  const handleUpdateRoleAdmin = async (adminId: number, newRole?: Roles) => {
    try {
      const adminData = {
        id: adminId,
        role: newRole,
      };

      await updateAdmin({ variables: { input: adminData } });

      await refetch();

      toast.success("Role updated successfully");
    } catch (error) {
      toast.error("Error update role");
    }
  };

  const handleDeleteAdmin = async () => {
    try {
      setIsLoading(true);

      await deleteAdmin({ variables: { id: deleteModal.id } });
      await refetch();
      setDeleteModal(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const renderUsers = () => {
    if (!data) return null;

    return data.admins.data.map((staff, index) => {
      const dotsMenu = [
        {
          label: "Edit",
          icon: <ICONS.Edit />,
          onClick: () => setUpdateOrCreateManager(staff),
        },
      ];

      if (staff.role !== Roles.Admin) {
        dotsMenu.push({
          label: "Delete",
          icon: <ICONS.Delete />,
          onClick: () => setDeleteModal(staff),
        });
      }

      return (
        <div
          key={index}
          className={styles.wrapper_user}
          style={{ zIndex: data.admins.data.length - index }}
        >
          <div className={styles.wrapper_user_info}>
            <div className={styles.wrapper_user_info_avatar}>
              <Avatar img={staff.avatar} />
            </div>
            <p className={styles.wrapper_user_info_name}>
              {staff.firstName} {staff.lastName}
            </p>
          </div>

          <div className={styles.wrapper_user_email}>{staff.email}</div>

          <Select
            options={RoleOptions}
            value={staff.role}
            containerStyle={{ width: 235, marginRight: "11%" }}
            onChange={(val) => handleUpdateRoleAdmin(staff.id, val.value)}
          />

          <div>
            <DotSelector items={dotsMenu} />
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <Header title="Staff Management" />

      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_input}>
            <SearchInput
              value={sort.search}
              placeholder="Search"
              onChange={(val) =>
                setSort({ ...sort, search: val, currentPage: 1 })
              }
            />
          </div>
          <div className={styles.wrapper_header_button}>
            <Button
              title="Add new"
              icon={<ICONS.Plus />}
              onClick={() => {
                setUpdateOrCreateManager({
                  avatar: undefined,
                  firstName: "",
                  lastName: "",
                  email: "",
                  role: "Moderator",
                });
              }}
            />
          </div>
        </header>

        <TableHead
          items={[
            {
              title: "User",
              value: "FirstName",
            },
            {
              title: "Email",
              value: "Email",
            },
            {
              title: "Role",
              value: "Role",
            },
            false,
          ]}
          flexes={[1, 1, 1]}
          sorted={sort}
          onChangeSort={(val) => setSort({ ...sort, ...val, currentPage: 1 })}
        />

        {loading ? (
          <Skeleton
            height={50}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={5}
          />
        ) : (
          renderUsers()
        )}

        {count > 0 && (
          <div style={{ margin: "10px 0" }}>
            <Pagination
              limit={LIMIT}
              count={count}
              currentPage={sort.currentPage}
              onChange={(page) => setSort({ ...sort, currentPage: page })}
            />
          </div>
        )}
      </div>

      <CreateUpdateModal
        item={updateOrCreateManager}
        onClose={() => setUpdateOrCreateManager(false)}
        onCreate={handleCreateAdmin}
      />

      <ConfirmModal
        isOpen={!!deleteModal}
        onClose={() => setDeleteModal(false)}
        onConfirm={handleDeleteAdmin}
        title="Are you sure"
      />
    </div>
  );
};

export default StaffManagment;
