import { FC, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";

import { UserQueries } from "graphql/queries";

import {
  ContractModal,
  ActiveContractItem,
  Pagination,
  TableHead,
} from "components";

import { Order } from "types/enums";
import { IContract } from "types/interfaces";

const LIMIT = 10;

const ActiveContracts: FC = () => {
  const { clientId } = useParams();
  const [modalPayload, setModalPayload] = useState<IContract | any>(false);
  const [count, setCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [fetchContracts, { data, loading }] = useLazyQuery<{
    adminClientContracts: {
      data: Array<IContract>;
      meta: { itemCount: number };
    };
  }>(UserQueries.GET_CLIENT_ACTIVE_CONTRACTS, {
    onCompleted(data) {
      setCount(data.adminClientContracts.meta.itemCount);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    fetchContracts({
      variables: {
        adminClientContractsId: Number(clientId),
        input: {
          skip: (currentPage - 1) * LIMIT,
          take: LIMIT,
          ended: false,
        },
      },
    });
  }, [currentPage]);

  const renderContracts = () => {
    if (
      !data?.adminClientContracts.data ||
      data.adminClientContracts.data.length === 0
    )
      return <p className={styles.wrapper_empty}>No active contracts yet</p>;

    return data?.adminClientContracts.data.map((item) => (
      <div key={item.id} onClick={() => setModalPayload(item)}>
        <ActiveContractItem contract={item} />
      </div>
    ));
  };

  return (
    <div>
      <TableHead
        items={[
          { title: "Contract", disableSort: true },
          { title: "Professional", disableSort: true },
          { title: "Budget", disableSort: true },
          { title: "Start date", disableSort: true },
          false,
        ]}
        sorted={{ sortedBy: "", type: Order.ASC }}
        onChangeSort={() => {}}
        flexes={[3, 2, 1, 1]}
      />
      {loading ? (
        <Skeleton
          height={50}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
          count={5}
        />
      ) : (
        renderContracts()
      )}

      {count > 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            count={count}
            currentPage={currentPage}
            limit={LIMIT}
            onChange={setCurrentPage}
          />
        </div>
      )}

      <ContractModal
        payload={modalPayload}
        onClose={() => setModalPayload(false)}
      />
    </div>
  );
};

export default ActiveContracts;
