import { FC, useState } from "react";
import { useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";

import styles from "./styles.module.scss";
import { Header, Pagination, SearchInput, TableHead, Tabs } from "components";
import { Order, ProfileType } from "types/enums";
import { useDebounce, useTab } from "hooks";
import UserCard from "./UserCard";
import VerificationUserModal from "./VerificationUserModal";
import { IUnverifiedUser } from "types";
import { GET_UNVERIFIED_USERS } from "graphql/queries/user";

interface IUnverifiedUsers {
  findUnverifiedUsers: {
    users: IUnverifiedUser[];
    count: number;
  };
}

enum OrderBy {
  Name = "Name",
  Email = "Email",
  CreatedAt = "CreatedAt",
}

const profileTypesConfig: Record<number, ProfileType[]> = {
  0: [ProfileType.Client, ProfileType.Photographer],
  1: [ProfileType.Client],
  2: [ProfileType.Photographer],
};

const VerificationPage: FC = () => {
  const { currentTab, onChange: setCurrentTabIndex } = useTab();
  const [currentUser, setCurrentUser] = useState<IUnverifiedUser | null>(null);
  const [count, setCount] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [users, setUsers] = useState<IUnverifiedUser[]>([]);

  const [sort, setSort] = useState<any>({
    sortedBy: OrderBy.CreatedAt,
    type: Order.DESC,
    currentPage: 1,
    search: "",
  });

  const debounceSearchValue = useDebounce(sort.search, 500);

  const { loading } = useQuery<IUnverifiedUsers>(GET_UNVERIFIED_USERS, {
    variables: {
      input: {
        search: debounceSearchValue,
        profileTypes: profileTypesConfig[currentTab],
        skip: (sort.currentPage - 1) * 10,
        order: sort.type,
        orderBy: sort.sortedBy,
      },
    },
    onCompleted(data) {
      setCount(data?.findUnverifiedUsers.count);
      setUsers(data?.findUnverifiedUsers.users);
    },
  });

  return (
    <div>
      <Header title="Verifications" />

      <div className="shadow_box">
        <div className={styles.wrapper_tabs}>
          <Tabs
            tabs={[
              { title: "All" },
              { title: "Clients" },
              { title: "Photographer" },
            ]}
            currentIndex={currentTab}
            onChange={(newIndex) => {
              setCurrentTabIndex(newIndex);
              setSort((prev: any) => ({ ...prev, currentPage: 1 }));
            }}
          />
        </div>
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_input}>
            <SearchInput
              value={sort.search}
              placeholder="Search"
              onChange={(val) =>
                setSort({ ...sort, search: val, currentPage: 1 })
              }
            />
          </div>

          <div className={styles.wrapper_header_button}></div>
        </header>

        <TableHead
          items={[
            {
              title: "Name",
              value: OrderBy.Name,
            },
            {
              title: "Role",
              disableSort: true,
            },
            {
              title: "Location",
              disableSort: true,
            },
            {
              title: "Email",
              value: OrderBy.Email,
            },
            {
              title: "Registration date",
              value: OrderBy.CreatedAt,
            },
            false,
          ]}
          flexes={[1, 0.5, 0.75, 0.85, 0.59]}
          sorted={sort}
          onChangeSort={(val) =>
            setSort({ ...sort, ...val, currentPage: sort.currentPage })
          }
        />

        {loading ? (
          <Skeleton
            height={80}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={5}
          />
        ) : (
          <>
            {users?.map((user) => {
              return (
                <UserCard
                  user={user}
                  onClickCard={(user) => {
                    setOpen(true);
                    setCurrentUser(user);
                  }}
                />
              );
            })}
          </>
        )}

        {count > 0 && (
          <div style={{ marginTop: "25px" }}>
            <Pagination
              limit={10}
              count={count}
              currentPage={sort.currentPage}
              onChange={(page) => setSort({ ...sort, currentPage: page })}
            />
          </div>
        )}

        <VerificationUserModal
          user={currentUser}
          onClose={() => setOpen(false)}
          isOpen={isOpen}
        />
      </div>
    </div>
  );
};

export default VerificationPage;
