import { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { ICONS } from "assets";
import styles from "./styles.module.scss";
import cls from "classnames";
import toast from "react-hot-toast";

interface IProps {
  handleSave: (image: Blob) => void;
  image?: string;
  error?: string;
  onRemoveImage: () => void;
}

const ImageDropZone: FC<IProps> = ({
  handleSave,
  image,
  error,
  onRemoveImage,
}) => {
  const maxSize = 15000 * 1024;

  const onDrop = useCallback((acceptedFiles: Blob[]) => {
    const file = acceptedFiles[0];

    if (!!file) {
      if (file.size > maxSize) {
        return toast.error("The maximum allowed weight of the banner is 15MB");
      }

      return handleSave(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,

    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const onClickRemoveButton = (e: any) => {
    e.stopPropagation();
    onRemoveImage();
  };

  return (
    <div className={styles.dropZone}>
      <div className={styles["dropZone__label-wrapper"]}>
        <div className={styles.dropZone__label}>Image</div>
      </div>

      <div
        className={cls(
          styles.dropZone__zone,
          !!error && styles.error,
          image && styles.focused
        )}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {!!image ? (
          <img
            src={image}
            alt="img"
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
          />
        ) : (
          <>
            <ICONS.PlusDropImage />
            <p style={{ fontWeight: 700 }}>Add image</p>
          </>
        )}
        {image ? (
          <button
            className={styles.delete_icon}
            onClick={(e) => onClickRemoveButton(e)}
          >
            <ICONS.DeleteWhite color="white" />
          </button>
        ) : null}
      </div>
      {!!error && <p className={styles.dropZone__error}>{error}</p>}
    </div>
  );
};

export default ImageDropZone;
