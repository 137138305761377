import { FC, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

import { ReportQueries } from "graphql/queries";

import {
  Pagination,
  ReportDescriptionModal,
  TableHead,
  SearchInput,
} from "components";
import Item from "./Item";
import Filter from "../Filter";

import { IReport } from "types/interfaces";
import { Order, ReportType } from "types/enums";

const LIMIT = 10;

const ProblemReport: FC = () => {
  const [count, setCount] = useState<number>(1);
  const [selectedReport, setSelectedReport] = useState<IReport | false>(false);

  const [sort, setSort] = useState<{
    sortedBy: string | null;
    type: Order | null;
    currentPage: number;
    search: string;
    range: null | [Date | null, Date | null];
  }>({
    sortedBy: null,
    search: "",
    type: Order.DESC,
    currentPage: 1,
    range: null,
  });

  const [fetchReports, { data, loading }] = useLazyQuery<{
    reports: {
      data: Array<IReport>;
      meta: { itemCount: number };
    };
  }>(ReportQueries.GET_REPORTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted(data) {
      setCount(data.reports.meta.itemCount);
    },
  });

  useEffect(() => {
    fetchReports({
      variables: {
        input: {
          skip: (sort.currentPage - 1) * LIMIT,
          take: LIMIT,
          type: ReportType.Problem,
          search: sort.search,
          order: sort.type || Order.DESC,
          createdAt: sort.range
            ? {
                from: sort.range[0],
                to: sort.range[1],
              }
            : null,
        },
      },
    });
  }, [sort]);

  const renderReports = () => {
    if (data?.reports?.data && data.reports.data.length > 0) {
      return data?.reports.data.map((report) => (
        <div key={report.id} onClick={() => setSelectedReport(report)}>
          <Item report={report} />
        </div>
      ));
    }

    return <p className={styles.wrapper_empty}>No reports yet</p>;
  };

  return (
    <div>
      <header className={styles.wrapper_header}>
        <div className={styles.wrapper_header_input}>
          <SearchInput
            placeholder="Search"
            value={sort.search}
            onChange={(search) => setSort({ ...sort, search, currentPage: 1 })}
          />
        </div>
        <div className={styles.wrapper_header_filter}>
          <Filter
            dateRange={sort.range}
            onChange={(filter) =>
              setSort({ ...sort, range: filter.range, currentPage: 1 })
            }
          />
        </div>
      </header>
      <TableHead
        items={[
          { title: "Reporter", disableSort: true },
          { title: "Category", disableSort: true },
          { title: "Report date", value: "CreatedAt" },
          false,
        ]}
        flexes={[1, 2, 1]}
        sorted={sort}
        onChangeSort={(val) => setSort({ ...sort, ...val })}
      />
      {loading ? (
        <Skeleton
          height={50}
          style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
          count={5}
        />
      ) : (
        renderReports()
      )}

      {count !== 0 && (
        <div className={styles.wrapper_pagination}>
          <Pagination
            currentPage={sort.currentPage}
            count={count}
            limit={LIMIT}
            onChange={(page) => setSort({ ...sort, currentPage: page })}
          />
        </div>
      )}

      <ReportDescriptionModal
        report={selectedReport}
        title="Problem in app"
        onClose={() => setSelectedReport(false)}
      />
    </div>
  );
};

export default ProblemReport;
