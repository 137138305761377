import { FC } from "react";
import { useQuery } from "@apollo/client";
import { BeatLoader } from "react-spinners";
import styles from "./styles.module.scss";

import { JobQueries } from "graphql/queries";

import { Categories } from "components";
import { IJob } from "types/interfaces";

type Props = {
  id: number;
};

const Description: FC<Props> = ({ id }) => {
  const { data, loading, error } = useQuery<{ adminJob: IJob }>(
    JobQueries.GET_JOB,
    {
      variables: { id },
    }
  );

  if (loading) {
    return (
      <div className={styles.loading}>
        <BeatLoader color="black" />
      </div>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <div className={styles.wrapper}>
      {data?.adminJob.length && (
        <div className={styles.wrapper_duration}>
          <p className={styles.wrapper_duration_value}>
            {data?.adminJob.length} h
          </p>
          <p className={styles.wrapper_duration_label}>Duration</p>
        </div>
      )}

      <p className={styles.wrapper_description}>{data?.adminJob.description}</p>

      {data?.adminJob.category && (
        <Categories categoris={[data?.adminJob.category]} />
      )}
    </div>
  );
};

export default Description;
