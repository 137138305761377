import { FC, useState } from "react";

import { TableHeader, TransactionItem, TransactionModal } from "components";

const Transactions: FC = () => {
  const [sortedIndex, setSortedIndex] = useState<null | number>(null);
  const [sortedType, setSortedType] = useState<"desc" | "asc" | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<false | any>(false);

  const renderTransactions = () => {
    return Array.from(Array(10).keys()).map((_, index) => (
      <div key={index} onClick={() => setIsOpenModal(true)}>
        <TransactionItem />
      </div>
    ));
  };

  return (
    <div>
      <TableHeader
        items={["Transaction", "Type", "Amount", "Date", false]}
        flexes={[2, 2, 1, 1]}
        selectedIndex={sortedIndex}
        sortedType={sortedType}
        onChangeSorted={setSortedIndex}
        onChangeType={setSortedType}
      />
      {renderTransactions()}

      <TransactionModal
        payload={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </div>
  );
};

export default Transactions;
