import { createContext, useState, FC } from "react";
import BeatLoader from "react-spinners/BeatLoader";

interface LoadingContextType {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Context = createContext<LoadingContextType | null>(null);

type Props = {
  children: React.ReactNode;
};

export const Provider: FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Context.Provider value={{ isLoading, setIsLoading }}>
      {children}
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 2100,
          }}
        >
          <BeatLoader color="white" />
        </div>
      )}
    </Context.Provider>
  );
};
