export enum UserAuthType {
  Email = "Email",
  Google = "Google",
  Apple = "Apple",
}

export enum Roles {
  Moderator = "Moderator",
  Admin = "Admin",
  StudioManager = "StudioManager",
  EquipmentManager = "EquipmentManager",
}

export enum UserAccount {
  Agency = "Agency",
  Freelancer = "Freelancer",
}

export enum UserGender {
  Male = "Male",
  Female = "Female",
}

export enum CourseOwnership {
  MosawerExclusive = "MosawerExclusive",
  Photographer = "Photographer",
}

export enum LevelPhotographAccount {
  Top = "Top",
  Master = "Master",
  Pro = "Pro",
}

export enum RateCurrency {
  KWD = "KWD",
  USD = "USD",
  SAR = "SAR",
}

export enum BannerAudience {
  Clients = "Clients",
  Photographers = "Photographers",
}

export enum BannerTargetRedirection {
  Profile = "Profile",
  Course = "Course",
  Studio = "Studio",
  Equipment = "Equipment",
}

export enum Status {
  Active = "Active",
  Deactivated = "Deactivated",
}

export enum BannerPublisher {
  Mosawer = "Mosawer",
  Photographer = "Photographer",
}

export enum QuestionType {
  Checkbox = "Checkbox",
  Counter = "Counter",
}

export enum ProfileType {
  Client = "Client",
  Photographer = "Photographer",
}

export enum RatePeriod {
  Hourly = "Hourly",
  Session = "Session",
  Day = "Day",
}

export enum ReportType {
  User = "User",
  Problem = "Problem",
  Dispute = "Dispute",
}

export enum DisputeStatus {
  Active = "Active",
  Resolved = "Resolved",
}

export enum ContractType {
  Offer = "Offer",
  Contract = "Contract",
}

export enum PayType {
  Fixed = "Fixed",
  Hourly = "Hourly",
}

export enum JobStatus {
  Active = "Active",
  Archive = "Archive",
}

export enum DisputStatus {
  Active = "Active",
  Resolved = "Resolved",
}

export enum VariantButton {
  General = "General",
  Gray = "Gray",
  Red = "Red",
}

export enum MessageType {
  Text = "Text",
  Attachment = "Attachment",
  Offer = "Offer",
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ClientFilterCompleted {
  More50 = "More50",
  From30to50 = "From30to50",
  From1to10 = "From1to10",
  None = "None",
}

export enum DisputFilterStatus {
  Active = "Active",
  Waiting = "Waiting",
  Resolved = "Resolved",
}

export enum ReportStatus {
  Active = "Active",
  Resolved = "Resolved",
}

export enum ReportAction {
  Skip = "Skip",
  Block = "Block",
  Delete = "Delete",
}

export enum JobCandidateStatus {
  Submitted = "Submitted",
  Active = "Active",
  Archived = "Archived",
}

export enum JobCandidateType {
  Invitation = "Invitation",
  Proposal = "Proposal",
}

export enum InvitationDeclineBy {
  Client = "Client",
  Photographer = "Photographer",
}
