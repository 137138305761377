import { FC, useContext } from "react";
import Modal from "react-modal";
import moment from "moment";
import { gql, useMutation } from "@apollo/client";
import styles from "./styles.module.scss";

import { ReportMutations, UserMutations } from "graphql/mutations";

import { ICONS } from "assets";

import { Avatar } from "components";
import SelectReport from "./SelectReport";

import { Loading } from "context";

import { IReport } from "types/interfaces";
import { ProfileType, ReportAction, ReportStatus } from "types/enums";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "50%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "24px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

type Props = {
  report: IReport | false;
  title?: string;
  onClose: () => void;
};

const ReportDescriptionModal: FC<Props> = ({
  report,
  title = `User’s behavior`,
  onClose,
}) => {
  const { setIsLoading } = useContext(Loading.Context)!;
  const [updateReport] = useMutation(ReportMutations.UPDATE_REPORT);
  const [blockUser] = useMutation(UserMutations.BLOCK_USER);
  const [deleteUser, { client }] = useMutation(UserMutations.DELETE_USER);

  if (!report) return null;

  const reporter = report.reporter;
  const reported = report.reported;

  const handleSkipReport = async () => {
    try {
      if (!report) return;
      setIsLoading(true);

      await updateReport({
        variables: {
          input: {
            id: report.id,
            status: ReportStatus.Resolved,
            action: ReportAction.Skip,
          },
        },
      });

      onClose();
    } catch (e) {
      console.log(e, "--update context");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBlockUser = async (reason: string) => {
    try {
      if (!report) return;
      setIsLoading(true);

      await updateReport({
        variables: {
          input: {
            id: report.id,
            status: ReportStatus.Resolved,
            action: ReportAction.Block,
            blockedReason: reason.trimEnd(),
          },
        },
      });

      await blockUser({
        variables: { id: reported?.id, reason },
      });

      client.cache.writeFragment({
        id: `User:${reported?.id}`,
        fragment: gql`
          fragment MyTodo on User {
            blocked
          }
        `,
        data: {
          blocked: true,
        },
      });

      onClose();
    } catch (e) {
      console.log(e, "--update context");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    try {
      if (!report) return;
      setIsLoading(true);

      await updateReport({
        variables: {
          input: {
            id: report.id,
            status: ReportStatus.Resolved,
            action: ReportAction.Delete,
          },
        },
      });

      await deleteUser({
        variables: { id: reported?.id },
      });
      client.cache.evict({ id: `User:${reported?.id}` });

      onClose();
    } catch (e) {
      console.log(e, "--update context");
    } finally {
      setIsLoading(false);
    }
  };

  const renderInfoAction = () => {
    return (
      <div>
        <h4 className={styles.wrapper_description_title}>Action</h4>
        <p className={styles.wrapper_description_text}>{report.action}</p>

        {report.blockedReason && (
          <>
            <h4 className={styles.wrapper_description_title}>Block reason</h4>
            <p className={styles.wrapper_description_text}>
              {report.blockedReason}
            </p>
          </>
        )}
      </div>
    );
  };

  return (
    <Modal
      isOpen={!!report}
      //@ts-ignore
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <header className={styles.wrapper_header}>
          <h4 className={styles.wrapper_header_title}>Report</h4>
          <div className={styles.wrapper_header_icon} onClick={onClose}>
            <ICONS.Cross />
          </div>
        </header>

        <section className={styles.wrapper_info}>
          <h3 className={styles.wrapper_info_title}>{title}</h3>

          <div className={styles.wrapper_info_wrapper}>
            <div className={styles.wrapper_info_wrapper_block}>
              <p className={styles.wrapper_info_wrapper_block_title}>
                Reporter
              </p>
              <div className={styles.wrapper_info_wrapper_block_wrapper}>
                <div
                  className={styles.wrapper_info_wrapper_block_wrapper_avatar}
                >
                  <Avatar img={reporter.avatar} />
                </div>
                <div>
                  <p className={styles.wrapper_info_wrapper_block_wrapper_name}>
                    {reporter.firstName} {reporter.lastName}
                  </p>
                  <p className={styles.wrapper_info_wrapper_block_wrapper_type}>
                    {reporter.profile?.type === ProfileType.Photographer
                      ? "Professional"
                      : "Client"}
                  </p>
                </div>
              </div>
            </div>

            {reported && (
              <div className={styles.wrapper_info_wrapper_block}>
                <p className={styles.wrapper_info_wrapper_block_title}>
                  Reporter On
                </p>
                <div className={styles.wrapper_info_wrapper_block_wrapper}>
                  <div
                    className={styles.wrapper_info_wrapper_block_wrapper_avatar}
                  >
                    <Avatar img={reported.avatar} />
                  </div>
                  <div>
                    <p
                      className={styles.wrapper_info_wrapper_block_wrapper_name}
                    >
                      {reported.firstName} {reported.lastName}
                    </p>
                    <p
                      className={styles.wrapper_info_wrapper_block_wrapper_type}
                    >
                      {reported.profile?.type === ProfileType.Photographer
                        ? "Professional"
                        : "Client"}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className={styles.wrapper_info_date}>
              {moment(report.createdAt).format("MMM DD, YYYY")}
            </div>
          </div>
        </section>

        <section className={styles.wrapper_description}>
          <h4 className={styles.wrapper_description_title}>Description</h4>
          <p className={styles.wrapper_description_text}>{report.body}</p>
        </section>

        {report.reported && report.status === ReportStatus.Active && (
          <SelectReport
            report={report}
            onSkip={handleSkipReport}
            onBlock={handleBlockUser}
            onDelete={handleDeleteUser}
          />
        )}

        {report.status === ReportStatus.Resolved && renderInfoAction()}
      </div>
    </Modal>
  );
};

export default ReportDescriptionModal;
