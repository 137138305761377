import { FC, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";

import { UserQueries } from "graphql/queries";

import {
  Header,
  SearchInput,
  TableHead,
  Pagination,
  ProfessionalItem,
} from "components";
import Filter from "./Filter";

import { Order } from "types/enums";
import { IUser, IProfessionalFilter } from "types/interfaces";

const LIMIT = 10;

const Professionals: FC = () => {
  const navigate = useNavigate();

  const [sort, setSort] = useState<
    {
      sortedBy: string | null;
      type: Order | null;
      search: string;
      currentPage: number;
    } & IProfessionalFilter
  >({
    sortedBy: null,
    type: Order.DESC,
    currentPage: 1,
    search: "",
    rating: null,
    rateAmount: null,
    category: [],
    account: null,
    ratePeriods: [],
    blocked: null,
  });
  const [count, setCount] = useState<number>(1);

  const [fetchProfessionals, { data, loading }] = useLazyQuery<{
    adminProfessionals: {
      data: Array<IUser>;
      meta: { itemCount: number };
    };
  }>(UserQueries.GET_PROFESSIONALS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted(data) {
      setCount(data.adminProfessionals.meta.itemCount);
    },
  });

  useEffect(() => {
    const sortBy =
      sort.sortedBy !== null
        ? {
            //@ts-ignore
            orderBy: sort.sortedBy,
            order: sort.type,
          }
        : {};

    fetchProfessionals({
      variables: {
        input: {
          skip: (sort.currentPage - 1) * LIMIT,
          take: LIMIT,
          search: sort.search,
          rating: sort.rating,
          account: sort.account,
          blocked: sort.blocked,
          category: sort.category.length === 0 ? null : sort.category,
          ratePeriods: sort.ratePeriods.length === 0 ? null : sort.ratePeriods,
          rateAmount: sort.rateAmount
            ? {
                from: Number(sort.rateAmount.from),
                to: Number(sort.rateAmount.to),
              }
            : null,
          ...sortBy,
        },
      },
    });
  }, [sort]);

  const renderProfessionals = () => {
    if (
      data?.adminProfessionals?.data &&
      data.adminProfessionals.data.length > 0
    ) {
      return data?.adminProfessionals.data.map((user) => (
        <div
          key={user.id}
          onClick={() => {
            navigate(`${user.id}`);
          }}
        >
          <ProfessionalItem user={user} />
        </div>
      ));
    }
  };

  return (
    <div>
      <Header title="Professionals" />
      <div className="shadow_box">
        <header className={styles.wrapper_header}>
          <div className={styles.wrapper_header_input}>
            <SearchInput
              placeholder="Search"
              value={sort.search}
              onChange={(val) =>
                setSort({ ...sort, search: val, currentPage: 1 })
              }
            />
          </div>
          <Filter
            filter={sort}
            onChange={(val) => setSort({ ...sort, ...val, currentPage: 1 })}
          />
        </header>

        <TableHead
          items={[
            { title: "Professional name", value: "Name" },
            { title: "Location", disableSort: true },
            { title: "Hourly rate", value: "Rate" },
            { title: "Jobs", value: "Completed" },
            { title: "Rating", value: "Rating" },
            { title: "Registration date", value: "CreatedAt" },
            false,
          ]}
          flexes={[2, 2, 1, 1, 1, 1]}
          sorted={sort}
          onChangeSort={(val) => setSort({ ...sort, ...val, currentPage: 1 })}
        />

        {loading ? (
          <Skeleton
            height={50}
            style={{ marginBottom: 2, marginTop: 2, borderRadius: 10 }}
            count={5}
          />
        ) : (
          renderProfessionals()
        )}

        {count > 0 && (
          <div style={{ margin: "10px 0" }}>
            <Pagination
              limit={LIMIT}
              count={count}
              currentPage={sort.currentPage}
              onChange={(page) => setSort({ ...sort, currentPage: page })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Professionals;
